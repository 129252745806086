import styled from '@emotion/styled';
import { User, UserComment } from '@gbhem/api';
import { useMemo } from 'react';
import { useModal } from 'react-modal-hook';
import { Column } from 'react-table';
import tw from 'twin.macro';

import { useAuthenticatedUser } from '../../providers';
import { useUserInfoStore } from '../../providers/UserProfileProvider/UserInfoProvider';
import { AdminCommentsModal, ColumnUnderline, dateTableFormat, Input, Table } from '..';
import { Button } from '../Button';

const HeaderWrapper = styled.div`
  ${tw`flex items-center self-start mb-4`}
  svg {
    ${tw`ml-auto w-5 h-5`}
  }
`;

interface AdminCommentsTableProps {
  user: User;
}

export function AdminCommentsTable({ user }: AdminCommentsTableProps) {
  const { removeComment } = useUserInfoStore();
  const currentUser = useAuthenticatedUser();

  const [showAddAdminComment, hideAddAdminComment] = useModal(
    () => <AdminCommentsModal name="Add Comments for User" onClose={hideAddAdminComment} />,
    []
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
            <Input type="checkbox" {...getToggleAllPageRowsSelectedProps()} />
          ),
          accessor: 'checkbox',
          id: 'checkbox',
          disableSortBy: true,
          Cell: ({ row }: any) => <Input type="checkbox" {...row.getToggleRowSelectedProps()} />
        },
        {
          Header: 'DATE',
          accessor: 'createdAt',
          id: 'createdAt',
          Cell: ({ value }: any) => (
            <ColumnUnderline>{String(dateTableFormat(value))}</ColumnUnderline>
          )
        },
        {
          Header: 'TYPE',
          accessor: 'type',
          id: 'type',
          disableSortBy: false,
          Cell: ({ value }: any) => String(value)
        },
        {
          Header: 'AUTHOR',
          accessor: 'admin',
          id: 'admin',
          disableSortBy: false,
          Cell: ({ row }: any) => {
            const value = row.original;
            return `${value.author?.firstName} ${value.author?.lastName}`;
          }
        },

        {
          Header: 'COMMENT',
          accessor: 'comment',
          id: 'comment',
          disableSortBy: true,
          Cell: ({ value }: any) => String(value)
        }
      ] as Column<UserComment>[],
    []
  );

  const onDelete = async (comments: UserComment[]) => {
    if (!user.comments) {
      return;
    }

    removeComment(user.id, comments, currentUser.id);
  };

  return (
    <>
      <HeaderWrapper>
        <div className="font-bold text-lg my-4">Comments</div>
        <Button className="ml-auto" onClick={showAddAdminComment}>
          + Add Comment
        </Button>
      </HeaderWrapper>
      <div className="-mx-8">
        <Table columns={columns} data={user.comments} onDelete={onDelete} />
      </div>
    </>
  );
}

export default AdminCommentsTable;
