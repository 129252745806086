"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class TrackService {
    static createTrackTemplate(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track/create-track-template',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updateTrackTemplate(trackTemplateId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/track/update-track-template/{trackTemplateId}',
            path: {
                'trackTemplateId': trackTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static createInstances(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track/instance',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updateInstance(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/track/instance',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static removeInstances(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/track/instance',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static removeAdvisorFromInstances(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/track/removeAdvisorFromInstances',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static assign(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track/assign',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static reassign(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/track/reassign',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static unassign(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/track/{id}/unassign',
            path: {
                'id': id,
            },
        });
    }
    static getTrackAdvisors(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track/{id}/advisors',
            path: {
                'id': id,
            },
        });
    }
    static getInstances(id, isQuickSearch, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track/{id}/{isQuickSearch}/instances',
            path: {
                'id': id,
                'isQuickSearch': isQuickSearch,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static getAssignedTracks() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track/assigned-tracks',
        });
    }
    static copyTrack(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track/copy-track/{trackTemplateId}/{conferenceId}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static isSystemTrackWithCopies(trackTemplateId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track/is-system-track-with-copies/{trackTemplateId}',
            path: {
                'trackTemplateId': trackTemplateId,
            },
        });
    }
    static getTrackInstance(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track/track-instance/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getTrackTemplate(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track/track-template/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getTrackAssignees(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track/track-assignees/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static verifyInstanceSteps(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track/verify-step-instances/{id}',
            path: {
                'id': id,
            },
        });
    }
    static deactivateTrackTemplate(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track/deactivate-track/{id}',
            path: {
                'id': id,
            },
        });
    }
    static deleteTrackTemplate(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track/delete-track/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getTrackTemplatesByConference(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track/track-templates/conference/{id}',
            path: {
                'id': id,
            },
        });
    }
    static createImportTrackTemplate(trackTemplateId, conferenceId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track/import-track-template/track-template/{trackTemplateId}/conference/{conferenceId}',
            path: {
                'trackTemplateId': trackTemplateId,
                'conferenceId': conferenceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static create(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static find(limit, offset, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track',
            query: {
                'limit': limit,
                'offset': offset,
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updateMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/track',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static removeMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/track',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static createMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track/bulk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static quickSearch(tableName, limit, offset, relations, isQuickSearch, orderBy, where) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track/quickSearch',
            query: {
                'tableName': tableName,
                'limit': limit,
                'offset': offset,
                'relations': relations,
                'isQuickSearch': isQuickSearch,
                'orderBy': orderBy,
                'where': where,
            },
        });
    }
    static count(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static query(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track/query',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static findOne(id, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/track/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static update(id, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/track/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static remove(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/track/{id}',
            path: {
                'id': id,
            },
        });
    }
    static search(requestBody, relations) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/track/search',
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
exports.TrackService = TrackService;
