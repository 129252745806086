import styled from '@emotion/styled';
import { File, FormInstance, FormService, FormTemplate, Layout as LayoutModel } from '@gbhem/api';
import { ComponentProps, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import tw from 'twin.macro';

import { Field as FieldModel } from '../../models';
import { Can } from '../../providers';
import { CellBody, Layout, OptionType } from '..';
import { Button } from '../Button';
import { Field } from '../Field';

type FormProperties = Omit<ComponentProps<'form'>, 'onSubmit'> & {
  form: FormTemplate;
  layout?: LayoutModel;
  fields?: FieldModel[];
  instance?: FormInstance;
  submitted?: boolean;
  onSubmit?: (form: {
    data: Record<string, any>;
    submitted: boolean;
    fieldFiles?: { field: FieldModel; file: File }[];
  }) => void;
  onUnsubmit?: (formInstance: FormInstance) => void;
};

const Description = styled.div`
  ${tw`text-xs`}
`;

const UnsubmitButton = styled(Button)`
  cursor: pointer;
  pointer-events: all;
`;

export const optionsResolver = (options?: OptionType<unknown>[] | string) => {
  if (!options) {
    return [];
  }

  if (typeof options === 'string') {
    return (_: string) => new Promise<OptionType<unknown>[]>((resolve) => resolve([]));
  }

  return options;
};

export function Form({
  form,
  layout = form.current.layout,
  fields: _fields = form.current.fields,
  instance,
  submitted,
  onSubmit,
  onUnsubmit,
  ...props
}: FormProperties) {
  const { id, description } = form;
  const {
    reset,
    handleSubmit,
    control,
    formState: { isValid, isDirty }
  } = useForm({ defaultValues: instance?.data, mode: 'all' });
  const fields = useMemo(
    () =>
      _fields.reduce<Record<string, FieldModel>>(
        (map, field) => (map[field.id] = field) && map,
        {}
      ),
    [_fields]
  );

  const FieldContainer = useCallback<CellBody>(
    ({ fieldId = '', ...cell }) => (
      <Controller
        name={fieldId}
        control={control}
        rules={fields[fieldId]?.properties}
        render={({ field }) => (
          <Field
            form={instance}
            key={fieldId}
            {...field}
            {...cell}
            {...fields[fieldId]}
            value={field.value || ''}
          />
        )}
      />
    ),
    [fields, control, instance]
  );

  return (
    <form
      {...props}
      id={id}
      onSubmit={handleSubmit((data) => onSubmit?.({ ...(instance || {}), data, submitted: true }))}
      className="space-y-2"
    >
      <Description>{description}</Description>
      {submitted && (
        <div className="text-neutral-300">
          Submitted{' '}
          {instance?.updatedAt &&
            new Date(instance?.updatedAt).toLocaleTimeString([], {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })}
        </div>
      )}
      <div>
        <Layout layout={layout} cell={FieldContainer} />
      </div>
      {!submitted ? (
        <div className="flex space-x-2 px-1">
          <Button type="submit" disabled={!isValid}>
            Submit
          </Button>
          <Button
            type="button"
            inverted
            disabled={!(isValid && isDirty)}
            onClick={handleSubmit((data) =>
              onSubmit?.({ ...(instance || {}), data, submitted: false })
            )}
          >
            Save
          </Button>
          <Button inverted type="reset" onClick={() => reset()}>
            Reset
          </Button>
        </div>
      ) : (
        <Can do="create" on="FormTemplate">
          <div>
            <UnsubmitButton
              onClick={async () => {
                if (onUnsubmit) {
                  const newInstance = await FormService.unsubmitForm(instance?.id || '');
                  onUnsubmit(newInstance);
                }
              }}
            >
              Unsubmit
            </UnsubmitButton>
          </div>
        </Can>
      )}
    </form>
  );
}

export default Form;
