import { SVGProps } from 'react';

export function Checkmark(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M46.524 8a3.382 3.382 0 00-2.32 1.013c-.14.14-2.134 2.07-4.746 4.556-2.613 2.486-5.99 5.695-9.366 8.902-3.374 3.206-6.747 6.41-9.365 8.901-1.392 1.326-1.753 1.667-2.594 2.468L5.604 23.02a3.387 3.387 0 10-4.43 5.125l14.85 12.825a3.373 3.373 0 004.598-.17 615.3 615.3 0 014.746-4.555c2.613-2.486 5.99-5.674 9.365-8.88 3.375-3.206 6.747-6.43 9.365-8.922 2.618-2.492 4.337-4.106 4.873-4.64a3.376 3.376 0 00-2.447-5.8V8z" />
    </svg>
  );
}

export default Checkmark;
