"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhaseService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class PhaseService {
    static createPhase(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/phase/create-phase',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updatePhase(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/phase/update-phase',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static deletePhase(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/phase/delete-phase/{id}',
            path: {
                'id': id,
            },
        });
    }
    static updatePhaseOrder(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/phase/update-phase-order',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static getPhase(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/phase/get-phase/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getPhases(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/phase/get-phases/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getPhasesByTrackTemplate(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/phase/get-phases/track-template/{id}',
            path: {
                'id': id,
            },
        });
    }
    static createImportPhase(phaseId, trackTemplateId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/phase/import-phase/phase/{phaseId}/track-template/{trackTemplateId}',
            path: {
                'phaseId': phaseId,
                'trackTemplateId': trackTemplateId,
            },
        });
    }
    static create(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/phase',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static find(limit, offset, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/phase',
            query: {
                'limit': limit,
                'offset': offset,
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updateMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/phase',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static removeMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/phase',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static createMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/phase/bulk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static quickSearch(tableName, limit, offset, relations, isQuickSearch, orderBy, where) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/phase/quickSearch',
            query: {
                'tableName': tableName,
                'limit': limit,
                'offset': offset,
                'relations': relations,
                'isQuickSearch': isQuickSearch,
                'orderBy': orderBy,
                'where': where,
            },
        });
    }
    static count(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/phase/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static query(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/phase/query',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static findOne(id, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/phase/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static update(id, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/phase/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static remove(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/phase/{id}',
            path: {
                'id': id,
            },
        });
    }
    static search(requestBody, relations) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/phase/search',
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
exports.PhaseService = PhaseService;
