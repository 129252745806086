import { Conference, District, Queryable, UserService } from '@gbhem/api';
import create from 'zustand';

import { orderBy, query } from '../utils/resource.helper';
import { immerMiddleware } from './utils';

type VisibleLocations = {
  conferences: Conference[];
  districts: District[];
};

export enum LocationStoreTypes {
  Conference = 'Conference',
  District = 'District'
}

interface LocationStore {
  conferences: Record<string, Conference>;
  districts: Record<string, District>;
  loading: boolean;
  system: Conference;
  load: (userId: string) => Promise<void>;
  get: (id: string) => Promise<Conference | District | undefined>;
  query: (
    condtions: Queryable,
    type: LocationStoreTypes,
    values?: Conference[] | District[]
  ) => any;
}

export const useLocationStore = create<LocationStore>(
  immerMiddleware((set, get) => ({
    conferences: {},
    districts: {},
    system: {
      id: null!,
      createdAt: '',
      updatedAt: '',
      name: 'System',
      districts: [],
      episcopalArea: null!,
      trackTemplates: [],
      active: true
    },
    loading: false,
    load: async (userId: string) => {
      set((state) => {
        state.loading = true;
      });
      const results: VisibleLocations = await UserService.getUserVisibleLocations(userId);

      set((state) => {
        state.conferences = results.conferences.reduce<Record<string, Conference>>(
          (conferences, conference) => {
            conferences[conference.id] = conference;

            return conferences;
          },
          {}
        );

        state.districts = results.districts.reduce<Record<string, District>>(
          (districts, district) => {
            districts[district.id] = district;

            return districts;
          },
          {}
        );
      });

      set((state) => {
        state.loading = false;
      });
    },
    get: async (id: string) => {
      const conference: Conference = get().conferences[id];
      if (conference) return conference;

      const district: District = get().districts[id];
      if (district) return district;

      return undefined;
    },
    query: (condtions: Queryable, type: LocationStoreTypes, values?: (Conference | District)[]) => {
      const data: (Conference | District)[] = values
        ? values
        : type === LocationStoreTypes.Conference
        ? Object.values(get().conferences)
        : Object.values(get().districts);

      let results: (Conference | District)[] = query<Conference | District>(data, condtions);

      if (!results) return [];

      if (condtions.orderBy) {
        results = orderBy<Conference | District>(results, 'name', condtions);
      }

      return results;
    }
  }))
);

export default useLocationStore;
