import styled from '@emotion/styled';
import { Cell, FormVersion } from '@gbhem/api';
import copy from 'fast-copy';
import equal from 'fast-deep-equal';
import {
  ComponentProps,
  DragEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import tw from 'twin.macro';

import { Field, FieldType } from '../../models';
import { DropArea, Layout } from '..';
import { FadeIn } from '../animations';
import { Widget } from '../Field';
import { FIELD_PREVIEW_ID, FieldEditor, getDependentFields } from '../FieldEditor';
import { FormPlaceholder } from '../icons';
import { Axis, intersects, LayoutEngine, Orientation } from '../Layout/model';
import { Widgets } from '.';
import { getWidget } from './model';

export function debounce(action: (...args: any[]) => void, duration: number) {
  let timer: NodeJS.Timeout;

  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => void action(...args), duration);
  };
}

const Container = styled.div`
  ${tw`relative h-full`}
`;

const Editor = styled.div`
  ${tw`absolute flex h-full w-full`}
`;

const ZoneContainer = styled.div`
  ${tw`w-full h-full overflow-y-auto bg-neutral-1000 py-8 px-16 mb-32`}
`;

const Zone = styled(DropArea)`
  ${tw`h-full`}
`;

interface PreviewPoint {
  nearestCell: string;
  axis?: Axis;
  orientation?: Orientation;
}

type FormEditorProperties = Omit<ComponentProps<'div'>, 'onChange' | 'placeholder' | 'ref'> & {
  form: Omit<FormVersion, 'id'>;
  onChange: (form: Omit<FormVersion, 'id' | 'fields'> & { fields: Record<string, Field> }) => void;
  placeholder?: ReactNode;
  disabled?: boolean;
};

const asFieldMap = (fields: Field[]) =>
  fields.reduce<Record<string, Field>>((fields, field) => (fields[field.id] = field) && fields, {});

export const shouldDelegateControl = (type: FieldType) => type === FieldType.List;

export function Builder({
  form,
  onChange,
  target,
  clearTarget,
  placeholder = <></>,
  disabled,
  ...props
}: FormEditorProperties & { target?: Widget; clearTarget?: () => void }) {
  const [layoutDraft, commitLayoutDraft] = useState(copy(form.layout));
  const [fieldsDraft, commitFieldsDraft] = useState(copy(asFieldMap(form.fields)));

  const [targetCell, setTargetCell] = useState<Cell>({ id: '' });

  useEffect(() => {
    if (!target) {
      return;
    }

    target.fields.forEach((f) => (fieldsDraft[f.id] = f));

    setTargetCell(target.layout);

    return () => {
      setTargetCell({ id: '' });
      if (clearTarget) clearTarget();
    };
  }, [target, fieldsDraft, clearTarget]);

  useEffect(() => {
    commitLayoutDraft(copy(form.layout));
    commitFieldsDraft(copy(asFieldMap(form.fields)));

    const cancelDrop = () => {
      commitLayoutDraft(copy(form.layout));
      commitFieldsDraft(copy(asFieldMap(form.fields)));

      setTargetCell({ id: '' });
    };

    document.addEventListener('drop', cancelDrop);

    return () => document.removeEventListener('drop', cancelDrop);
  }, [form]);

  const onDrag = useCallback(
    ({ target }: DragEvent) => {
      const cell = LayoutEngine.getCell(form.layout, (target as HTMLElement).id);
      if (cell) {
        setTargetCell(copy(cell));
      }
    },
    [form.layout]
  );

  const onRemoveCell = useCallback(
    (id: string) => {
      const engine = new LayoutEngine(layoutDraft);

      const cell = engine.get(id);
      if (!cell) {
        return;
      }

      engine.remove(id);
      getDependentFields(cell).map((s) => delete fieldsDraft[s]);

      commitLayoutDraft({ ...engine.layout });
      commitFieldsDraft({ ...fieldsDraft });

      onChange({ ...form, layout: engine.layout, fields: fieldsDraft });
    },
    [layoutDraft, fieldsDraft, form, onChange]
  );

  const onDragOver = useMemo(() => {
    let lastPosition = { x: 0, y: 0 };
    let lastPreview: PreviewPoint;

    const engine = new LayoutEngine(copy(form.layout));

    const tryPlaceFieldPreview = debounce((x: number, y: number) => {
      const lastFieldPreview = document.getElementById(FIELD_PREVIEW_ID)?.getBoundingClientRect();
      if (intersects(x, y, lastFieldPreview)) {
        return;
      }

      const fieldMap = asFieldMap(form.fields);

      const nearestCell = engine.getCellAt(x, y);
      if (nearestCell) {
        if (
          nearestCell.fieldId &&
          shouldDelegateControl(fieldMap[nearestCell.fieldId].type as FieldType)
        ) {
          return;
        }

        const position = engine.getRelativePositionTo(nearestCell, x, y);

        const preview = {
          nearestCell: nearestCell.id,
          axis: position?.axis,
          orientation: position?.orientation
        };

        if (equal(preview, lastPreview)) {
          return;
        }

        lastPreview = preview;
      }

      engine.remove(targetCell.id);
      const canCommit = engine.add(targetCell, x, y);
      if (!canCommit) {
        const lastField = engine.layout.children[engine.layout.children.length - 1]?.fieldId;

        if (lastField && shouldDelegateControl(fieldMap[lastField].type as FieldType)) {
          return;
        }

        engine.layout.children.push(targetCell);
      }

      commitLayoutDraft(copy(engine.layout));
    }, 0);

    return ({ clientX: x, clientY: y }: DragEvent) => {
      if (lastPosition.x === x && lastPosition.y === y) {
        return;
      }

      lastPosition = { x, y };

      tryPlaceFieldPreview(x, y);
    };
  }, [targetCell, form.layout, form.fields]);

  const onDrop = useCallback(() => {
    onChange({ ...form, layout: layoutDraft, fields: fieldsDraft });
    setTargetCell({ id: '' });
  }, [layoutDraft, onChange, fieldsDraft, form]);

  const onResize = useCallback(
    (id: string, span: number) => {
      const engine = new LayoutEngine(layoutDraft);

      const cell = engine.get(id);
      if (cell) {
        const { depth: _, ..._cell } = cell;
        engine.replace(cell.id, { ..._cell, span });
        onChange({ ...form, layout: engine.layout, fields: fieldsDraft });
      }
    },
    [form, layoutDraft, onChange, fieldsDraft]
  );

  const FieldEditorContainer = useCallback(
    ({ id, span, fieldId = '', ...props }) => (
      <FieldEditor
        span={span || 1}
        preview={id === targetCell.id}
        schema={fieldsDraft[fieldId]}
        target={target}
        onChange={(schema) => {
          fieldsDraft[fieldId] = schema;
          onChange({ ...form, fields: fieldsDraft });
        }}
        onResize={(s) => onResize(id, s)}
        onRemove={() => onRemoveCell(id)}
        disabled={disabled}
        {...props}
      />
    ),
    [onRemoveCell, onResize, fieldsDraft, targetCell.id, onChange, form, target, disabled]
  );

  return (
    <Zone
      onDragStart={onDrag}
      onDragOver={onDragOver}
      onDrop={onDrop}
      disabled={disabled}
      {...props}
    >
      {layoutDraft.children.length === 0 ? (
        placeholder
      ) : (
        <div className="flex flex-col pb-12">
          <Layout editable layout={layoutDraft} cell={FieldEditorContainer} />
        </div>
      )}
    </Zone>
  );
}

export function FormEditor({ form, onChange, disabled, ...props }: FormEditorProperties) {
  const [target, setTarget] = useState<Widget>();

  const onSelectWidget = useCallback((type: FieldType) => setTarget(getWidget(type)), []);

  const clearTarget = () => setTarget(undefined);

  return (
    <Container>
      <Editor>
        <div className="p-4 overflow-y-auto h-full bg-neutral-1000 border-r">
          <Widgets onDragStart={onSelectWidget} disabled={disabled} />
        </div>
        <ZoneContainer>
          <Builder
            target={target}
            form={form}
            onChange={onChange}
            clearTarget={clearTarget}
            placeholder={
              <FadeIn className="flex h-full flex-col space-y-8 items-center justify-center">
                <FormPlaceholder className="h-44" />
                <p>Drag and drop a widget to start building.</p>
              </FadeIn>
            }
            disabled={disabled}
            {...props}
          />
        </ZoneContainer>
      </Editor>
    </Container>
  );
}

export default FormEditor;
