import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  ChangeEvent,
  ChangeEventHandler,
  ComponentProps,
  FocusEventHandler,
  forwardRef,
  ReactEventHandler,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { useClickAway, useKey } from 'react-use';
import tw from 'twin.macro';
import { useDebouncedCallback } from 'use-debounce';

import { ChevronDown } from '../../icons';
import { Label } from '..';
import { Input, InputMetadataProps } from '../Input';
import Menu from './Menu';

declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export const createSyntheticEvent = <T extends Element, E extends Event>(
  event: E
): React.SyntheticEvent<T, E> => {
  let isDefaultPrevented = false;
  let isPropagationStopped = false;
  const preventDefault = () => {
    isDefaultPrevented = true;
    event.preventDefault();
  };
  const stopPropagation = () => {
    isPropagationStopped = true;
    event.stopPropagation();
  };

  return {
    nativeEvent: event,
    currentTarget: event.currentTarget as EventTarget & T,
    target: event.target as EventTarget & T,
    bubbles: event.bubbles,
    cancelable: event.cancelable,
    defaultPrevented: event.defaultPrevented,
    eventPhase: event.eventPhase,
    isTrusted: event.isTrusted,
    preventDefault,
    isDefaultPrevented: () => isDefaultPrevented,
    stopPropagation,
    isPropagationStopped: () => isPropagationStopped,
    persist: () => {},
    timeStamp: event.timeStamp,
    type: event.type
  };
};

export interface OptionType<ValueType> {
  label: string;
  value: ValueType;
  disabled?: boolean;
}

export type SelectElement<ValueType> = Omit<HTMLInputElement, 'value'> & {
  value?: ValueType;
  rawValue?: string;
};

export interface SelectValueProps<ValueType> {
  value?: ValueType;
  defaultValue?: ValueType;
  options: ValueType[] | ((searchTerm?: string) => Promise<ValueType[]>);
  onChange?: ChangeEventHandler<SelectElement<ValueType>>;
  onBlur?: FocusEventHandler<SelectElement<ValueType>>;
  onSelect?: ReactEventHandler<SelectElement<ValueType>>;
  label?: string;
  open?: boolean;
  optionLabel?: (value: ValueType) => string;
  optionDisabled?: (value: ValueType) => boolean;
  rawRef?: Ref<HTMLInputElement | null>;
  clearOnSelect?: boolean;
}

export type SelectProps<ValueType> = Omit<
  ComponentProps<'input'>,
  'value' | 'defaultValue' | 'onChange' | 'onBlur' | 'onSelect'
> &
  SelectValueProps<ValueType> &
  InputMetadataProps;

const setValue = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')?.set!;

const Element = styled.div`
  ${tw`relative`}

  ${css`
    :focus-within {
      .menu-button {
        ${tw`border-primary`}
      }
    }
  `}
`;

const Anchor = styled.div`
  ${tw`relative z-10`}
`;

const MenuButton = styled.div<{ error?: boolean; disabled: boolean }>`
  ${tw`absolute top-0 right-0 flex items-center justify-center h-full p-2 border-t border-b border-r rounded-tr-sm rounded-br-sm cursor-pointer bg-neutral-900 focus:outline-none`}
  ${(p) => (p.error ? tw`border-pink` : tw`border-neutral-500`)}
  ${(p) => (p.disabled ? tw`bg-neutral-600 cursor-not-allowed pointer-events-none` : null)}

  svg {
    ${tw`w-4 h-4`}
  }
`;

const defaultOptionLabel = <ValueType,>(value: ValueType) => {
  switch (typeof value) {
    case 'string':
    case 'boolean':
    case 'number':
      return value;
    default:
      return (value as any)?.name;
  }
};

function _Select<ValueType>(
  {
    error,
    instructions,
    placeholder,
    className,
    onChange,
    onSelect,
    onBlur,
    options: _options,
    value: propsValue,
    defaultValue,
    open,
    name,
    label,
    inline,
    optionLabel = defaultOptionLabel,
    optionDisabled,
    rawRef,
    clearOnSelect,
    ...props
  }: SelectProps<ValueType>,
  forwardRef: Ref<SelectElement<ValueType> | null>
) {
  // https://reactjs.org/docs/forms.html#controlled-components
  // https://reactjs.org/docs/uncontrolled-components.html
  // Can't change from controlled to uncontrolled. This is why react-hooks/exhaustive-deps is disabled.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const controlled = useMemo(() => typeof propsValue !== 'undefined', []);

  if (!controlled && typeof propsValue !== 'undefined') {
    throw new Error('Cannot change from uncontrolled to controlled input.');
  }

  if (controlled && typeof propsValue === 'undefined') {
    throw new Error('Cannot change from controlled to uncontrolled input.');
  }

  // Manage state of raw input either by an uncontrolled reference, or via a controlled
  // value based on an initializer from a propValue.
  const input = useRef<HTMLInputElement>(null);

  const controlledValue = useMemo(
    () => propsValue && optionLabel(propsValue),
    [optionLabel, propsValue]
  );

  const value =
    (controlled
      ? controlledValue
      : input.current?.value || (defaultValue && optionLabel(defaultValue))) || '';

  useEffect(() => {
    if (propsValue === null) {
      if (controlled) {
        setValue.call(input.current, '');
        input.current?.dispatchEvent(new Event('change', { bubbles: true }));
      }
    } else {
      if (!controlled) {
        return;
      }

      const label = propsValue ? optionLabel(propsValue) : undefined;
      if (!label) {
        return;
      }

      setValue.call(input.current, label);
    }
  }, [controlled, propsValue, optionLabel]);

  // Options can either by a list passed in directly, or an asynchronous function
  // that resolves to become a list of options.
  const [options, setOptions] = useState<OptionType<ValueType>[] | undefined>();
  const asynchronous = useMemo(() => !Array.isArray(_options), [_options]);

  useEffect(() => {
    if (Array.isArray(_options)) {
      setOptions(
        _options.map((value) => ({
          label: optionLabel(value),
          value,
          disabled: optionDisabled?.(value)
        }))
      );
    }
  }, [_options, optionLabel, optionDisabled]);

  // Option lookup is used to allow constant time access to an option instead of having
  // to scan a list to find it based on the matching label criteria.
  const optionLookup = useMemo(
    () =>
      options?.reduce<Record<string, ValueType>>(
        (lookup, { label, value }) => ({ ...lookup, [`${label}`.toLowerCase()]: value }),
        {}
      ),
    [options]
  );

  // Reference to container dismiss the menu when clicking outside of it.
  const component = useRef<HTMLDivElement>(null);
  useClickAway(component, () => setIsMenuOpen(open ?? false));

  // Provides the ability to pass a reference to the component, or even to the input itself
  // (using rawRef) for nuanced behavior management.
  useImperativeHandle(rawRef, () => input.current);
  useImperativeHandle(
    forwardRef,
    () => {
      if (!input.current) {
        return input.current;
      }

      return {
        ...input.current,
        value: optionLookup?.[value?.toLowerCase()]
      };
    },
    [optionLookup, value]
  );

  const [isMenuOpen, setIsMenuOpen] = useState(open ?? false);

  // Key shortcuts that dismiss the menu.
  useKey(
    'Escape',
    (e) => {
      e.preventDefault();
      setIsMenuOpen(open ?? false);
    },
    {},
    []
  );
  useKey('Tab', () => setIsMenuOpen(false), {}, []);

  // Manages the lifecycle of options it an asynchronous function prop.
  const resolve = useDebouncedCallback(
    async (searchTerm?: string, onCompleted?: (value?: ValueType) => void) => {
      if (Array.isArray(_options)) {
        onCompleted?.();
        return;
      }

      const showLoader = setTimeout(() => setOptions(undefined), 300);
      const options = await _options(searchTerm || '');
      clearTimeout(showLoader);

      const option = options?.find((o) => optionLabel(o) === searchTerm);

      if (!option || !optionDisabled?.(option)) {
        onCompleted?.(option);
      }

      setOptions(
        options?.map((value) => ({
          label: optionLabel(value),
          value,
          disabled: optionDisabled?.(value)
        }))
      );
    },
    300
  );

  // Extracts the onChange function for reusability in both the synchronous and
  // asynchronous use cases of a select.
  const emit = useCallback(
    (e: ChangeEvent<HTMLInputElement>, change: string, option?: ValueType) => {
      onChange?.({
        ...e,
        target: { ...e.target, name: name || '', value: option, rawValue: change },
        currentTarget: {
          ...e.currentTarget,
          name: name || '',
          value: option,
          rawValue: change
        }
      });
    },
    [name, onChange]
  );
  const Nudge = styled.span`
    font-size: 0.6rem;
    ${tw`ml-1 text-red-400`}
  `;
  return (
    <Element ref={component} className={className}>
      {!inline && (label || name) && (
        <Label inline={props.type === 'checkbox'}>
          {label || name} {props.required && <Nudge>* Required</Nudge>}
        </Label>
      )}
      <Input
        inline
        error={error}
        instructions={instructions}
        name={name}
        placeholder={placeholder}
        onChange={async (e) => {
          const change = e.target.value;

          if (!asynchronous) {
            emit(e, change, optionLookup?.[change.toLowerCase()]);
          } else {
            resolve(change, (option) => emit(e, change, option));
          }

          if (!change) {
            setIsMenuOpen(false);
            return;
          }

          if (!isMenuOpen) {
            setIsMenuOpen(true);
          }
        }}
        onBlur={(e) => {
          const option = optionLookup?.[value.toLowerCase()];

          onBlur?.({
            ...e,
            target: { ...e.target, name: name || '', value: option, rawValue: value },
            currentTarget: { ...e.currentTarget, name: name || '', value: option, rawValue: value }
          });
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && clearOnSelect) {
            setIsMenuOpen(false);
          }
        }}
        onFocus={() => setIsMenuOpen(true)}
        onClick={() => {
          setIsMenuOpen(true);
          resolve(input.current?.value);
        }}
        defaultValue={defaultValue ? optionLabel(defaultValue) : undefined}
        {...props}
        readOnly={!asynchronous}
        ref={input}
      >
        <MenuButton
          className="menu-button"
          error={error !== undefined && error.length > 0}
          disabled={props.disabled ?? false}
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
            if (!isMenuOpen) {
              input.current?.focus();
            }
          }}
        >
          <ChevronDown />
        </MenuButton>
      </Input>

      <Anchor>
        <div className="absolute w-full">
          <Menu
            open={isMenuOpen}
            options={options}
            selection={value}
            onSelect={(option) => {
              setValue.call(input.current, !clearOnSelect ? option.label : '');
              input.current?.dispatchEvent(new Event('change', { bubbles: true }));

              setIsMenuOpen(open ?? false);

              if (!onSelect) {
                return;
              }

              const event = createSyntheticEvent(
                new Event('select', { bubbles: true })
              ) as ChangeEvent<SelectElement<ValueType>>;

              onSelect({
                ...event,
                currentTarget: {
                  ...event.currentTarget,
                  value: option.value,
                  rawValue: option.label
                }
              });
            }}
          />
        </div>
      </Anchor>
    </Element>
  );
}

export const Select = forwardRef(_Select);

export default Select;
