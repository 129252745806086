import { Conference, TrackService, TrackTemplate } from '@gbhem/api';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LocationStoreTypes, useLocationStore, useTrackStore } from '../../providers';
import { OrderBy } from '../../utils/resource.helper';
import { Button, CardBody, CardFooter, ErrorText } from '..';
import { Input, Select, TextArea } from '../Input';
import { AdminLocationSelect } from '../Input/Select';
import { ModalProps } from '../Modal';
import { Modal } from '../Modal';

type TrackModalProps = Omit<ModalProps, 'onChange'> & {
  track?: TrackTemplate;
  onChange?: (track?: TrackTemplate) => void;
};

export function AddTrackModal({ ...props }: ModalProps) {
  const [selectedConference, setSelectedConference] = useState<Conference>();
  const [trackTemplateName, setTrackTemplateName] = useState<string>();
  const [trackTemplateDescription, setTrackTemplateDescription] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { create } = useTrackStore();
  const router = useHistory();

  const { conferences } = useLocationStore();

  return (
    <Modal name="Create Track" {...props}>
      {({ close }) => (
        <>
          <CardBody>
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
            Please make sure you are not recreating an existing track template. Browse the existing
            tracks before creating a new track.
            <AdminLocationSelect<Conference>
              name="Conference"
              options={Object.values(conferences)}
              locationType={LocationStoreTypes.Conference}
              onSelect={(e) => {
                setSelectedConference(e.currentTarget.value);
              }}
              placeholder="Select conference this track will belong to....."
              addSystem
            />
            <Input
              name="Name"
              type="text"
              placeholder="Add new track name to continue..."
              value={trackTemplateName}
              onChange={(e) => setTrackTemplateName(e.target.value)}
            />
            <TextArea
              name="Description"
              placeholder="Enter track description..."
              value={trackTemplateDescription}
              onChange={(e) => setTrackTemplateDescription(e.target.value)}
            />
          </CardBody>
          <CardFooter>
            <Button
              inverted
              onClick={() => {
                close();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!trackTemplateName || !trackTemplateDescription || !selectedConference}
              onClick={async () => {
                if (!trackTemplateName || !trackTemplateDescription || !selectedConference) {
                  return;
                }

                setErrorMessage(null);
                try {
                  const track = await create({
                    name: trackTemplateName.trim(),
                    description: trackTemplateDescription,
                    conferenceId: selectedConference.id
                  });
                  router.push(`/tracks/${track.id}`);
                  close();
                } catch (e: any) {
                  setErrorMessage(e.body.message);
                }
              }}
            >
              I am sure I am not recreating an existing track
            </Button>
          </CardFooter>
        </>
      )}
    </Modal>
  );
}

export function CopyTrackModal({ ...props }: ModalProps) {
  const [systemTrackTemplates, setSystemTrackTemplates] = useState<TrackTemplate[]>();
  const [selectedSystemTrack, setSelectedSystemTrack] = useState<TrackTemplate>();
  const [conferences, setConferences] = useState<Conference[]>();
  const [selectedConference, setSelectedConference] = useState<Conference>();
  const [trackTemplateName, setTrackTemplateName] = useState<string>();
  const [trackTemplateDescription, setTrackTemplateDescription] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const router = useHistory();

  const { query } = useTrackStore();
  const { conferences: locationConferences } = useLocationStore();

  useEffect(() => {
    setSystemTrackTemplates(
      query({
        conditions: [{ conference: null }],
        orderBy: OrderBy.Ascending
      })
    );

    setConferences(Object.values(locationConferences));
  }, [query, locationConferences]);

  return (
    <Modal name="Copy from Existing" {...props}>
      {({ close }) => (
        <>
          {(!conferences || !systemTrackTemplates) && <></>}

          {conferences?.length === 0 && (
            <div>
              There are no conferences assigned to this user. Need to add a conference for this user
              to copy a track.
            </div>
          )}

          {systemTrackTemplates?.length === 0 && (
            <div>
              There are no system tracks to make copies from. Need to create a system track to make
              copies.
            </div>
          )}

          {conferences &&
            conferences.length > 0 &&
            systemTrackTemplates &&
            systemTrackTemplates?.length > 0 && (
              <>
                {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
                <Select<TrackTemplate>
                  name="System Track"
                  options={(searchTerm: string | undefined) => {
                    if (!searchTerm) return;

                    return query(
                      {
                        conditions: [{ name: searchTerm }],
                        orderBy: OrderBy.Ascending,
                        limit: 10
                      },
                      systemTrackTemplates
                    );
                  }}
                  onSelect={(e) => {
                    setSelectedSystemTrack(e.currentTarget.value);
                  }}
                  optionLabel={(track) => `${track.name}`}
                  placeholder="Select system track to copy..."
                />
                <AdminLocationSelect<Conference>
                  name="Conference"
                  options={conferences}
                  locationType={LocationStoreTypes.Conference}
                  onSelect={(e) => {
                    setSelectedConference(e.currentTarget.value);
                  }}
                  placeholder="Select conference this track will belong to....."
                />
                <Input
                  type="text"
                  name="Name"
                  placeholder={'Enter track name...'}
                  value={trackTemplateName}
                  onChange={(e) => setTrackTemplateName(e.target.value)}
                />
                <TextArea
                  name="Description"
                  placeholder="Enter track description..."
                  value={trackTemplateDescription}
                  onChange={(e) => setTrackTemplateDescription(e.target.value)}
                />
                <CardFooter>
                  <Button inverted onClick={() => close()}>
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      !selectedSystemTrack ||
                      !selectedConference ||
                      !trackTemplateName ||
                      !trackTemplateDescription
                    }
                    onClick={async () => {
                      if (
                        !selectedSystemTrack ||
                        !selectedConference ||
                        !trackTemplateName ||
                        !trackTemplateDescription
                      ) {
                        return;
                      }
                      setErrorMessage(null);

                      try {
                        const copyTrack = await TrackService.copyTrack({
                          trackTemplateId: selectedSystemTrack.id,
                          conferenceId: selectedConference.id,
                          name: trackTemplateName.trim(),
                          description: trackTemplateDescription
                        });
                        router.push(`/tracks/${copyTrack.id}`);

                        await close();
                      } catch (e: any) {
                        setErrorMessage(e.body.message);
                      }
                    }}
                  >
                    Copy
                  </Button>
                </CardFooter>
              </>
            )}
        </>
      )}
    </Modal>
  );
}

export function ImportTrackModal({ ...props }: ModalProps) {
  const router = useHistory();
  const { onClose } = props;

  const [importOptions, setImportOptions] = useState<Conference[]>([]);
  const [targetOptions, setTargetOptions] = useState<Conference[]>([]);

  const [importConference, setImportConference] = useState<Conference>();
  const [importTrack, setImportTrack] = useState<TrackTemplate>();
  const [targetConference, setTargetConference] = useState<Conference>();
  const [trackTemplateName, setTrackTemplateName] = useState<string>();
  const [trackTemplateDescription, setTrackTemplateDescription] = useState<string>();

  const [newTrackId, setNewTrackId] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { query } = useTrackStore();
  const { conferences: locationConferences } = useLocationStore();
  const { getTrackTemplatesByConference, createImportTrackTemplate } = TrackService;

  useEffect(() => {
    setImportOptions(
      Object.values({
        ...{ '0': { id: 'system', name: 'System' } as Conference },
        ...locationConferences
      })
    );

    setTargetOptions(Object.values(locationConferences));
  }, [query, locationConferences]);

  return (
    <Modal
      name="Import from Existing"
      onClose={() => {
        if (!newTrackId && onClose) onClose();

        // this router push will only work if this modal continues to be used on the landing page
        // if the modal gets moved or repurposed somewhere this will need to be revisited due to the router library's functionality
        if (newTrackId) router.push(`/tracks/${newTrackId}`);
      }}
    >
      {({ close }) => (
        <>
          {!importOptions && <></>}

          {importOptions.length === 0 && (
            <div>
              There are no conferences assigned to your user. Need to add a conference for your user
              to import a track.
            </div>
          )}

          {importOptions.length > 0 && (
            <>
              <Select<Conference>
                name="Import from conference"
                options={importOptions}
                onSelect={(e) => {
                  setImportConference(e.currentTarget.value);
                }}
                placeholder="Select the conference you would like to import from..."
              />
              <Select<TrackTemplate>
                label="Track to Import"
                placeholder="Select the track you would like to import"
                options={async () =>
                  importConference ? await getTrackTemplatesByConference(importConference.id) : []
                }
                onSelect={(e) => setImportTrack(e.currentTarget.value)}
              />
              <Select<Conference>
                name="Import to conference"
                options={targetOptions}
                onSelect={(e) => {
                  setTargetConference(e.currentTarget.value);
                }}
                placeholder="Select the conference you would like to import into..."
              />
              <Input
                type="text"
                name="Name"
                placeholder={'Enter track name...'}
                value={trackTemplateName}
                onChange={(e) => setTrackTemplateName(e.target.value)}
              />
              <TextArea
                name="Description"
                placeholder="Enter track description..."
                value={trackTemplateDescription}
                onChange={(e) => setTrackTemplateDescription(e.target.value)}
              />
              <CardFooter>
                <Button inverted onClick={() => close()}>
                  Cancel
                </Button>
                <Button
                  disabled={
                    !targetConference ||
                    !importTrack ||
                    !importConference ||
                    !trackTemplateName ||
                    !trackTemplateDescription ||
                    isSubmitting
                  }
                  onClick={async () => {
                    if (
                      !targetConference ||
                      !importTrack ||
                      !trackTemplateName ||
                      !trackTemplateDescription
                    ) {
                      return;
                    }

                    setIsSubmitting(true);

                    try {
                      const newTrack = await createImportTrackTemplate(
                        importTrack.id,
                        targetConference.id,
                        { name: trackTemplateName.trim(), description: trackTemplateDescription }
                      );

                      if (newTrack) setNewTrackId(newTrack.id);
                    } catch (e) {
                      setIsSubmitting(false);

                      throw e;
                    }

                    setIsSubmitting(false);
                    close();
                  }}
                >
                  Import
                </Button>
              </CardFooter>
            </>
          )}
        </>
      )}
    </Modal>
  );
}

export function ConfirmDeactivateTrackModal({
  track,
  onConfirm,
  onChange: _,
  ...props
}: TrackModalProps & { track: TrackTemplate; onConfirm?: () => void }) {
  return (
    <Modal name="Confirm deactivation" {...props}>
      {({ close }) => (
        <>
          Are you sure you want to deactivate {track.name}?
          <CardFooter>
            <Button inverted onClick={() => close()}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await close();
                onConfirm?.();
              }}
            >
              Confirm
            </Button>
          </CardFooter>
        </>
      )}
    </Modal>
  );
}

export function ConfirmDeleteTrackModal({
  track,
  onConfirm,
  onChange: _,
  ...props
}: TrackModalProps & { track: TrackTemplate; onConfirm?: () => void }) {
  return (
    <Modal name="Confirm delete" {...props}>
      {({ close }) => (
        <>
          Are you sure you want to delete {track.name}?
          <br />
          <br />
          There will be no way to recover this track after completing this action.
          <CardFooter>
            <Button inverted onClick={() => close()}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await close();
                onConfirm?.();
              }}
            >
              Confirm
            </Button>
          </CardFooter>
        </>
      )}
    </Modal>
  );
}
