import { SVGProps } from 'react';

export function Bell(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.09859 8.82354C3.07245 13.0488 1.70141 19.5097 4.39517 26.2874L4.3951 26.2872C4.3951 26.2872 9.19861 36.809 6.33856 41.2025C6.20317 41.3572 4.77218 43.1151 5.42386 45.0044C6.08524 46.9208 9.17163 47.4042 11.9659 46.1995L42.2625 33.1425C45.0587 31.938 46.6676 29.4318 45.8864 27.5599C45.0763 25.6263 42.4684 25.5163 42.2003 25.5049C42.1939 25.5047 42.1888 25.5045 42.1851 25.5043C37.1225 24.6632 32.5123 14.0775 32.5123 14.0775C29.399 7.82943 23.443 4.15522 17.4094 4.27705C17.4022 4.24881 17.3951 4.22055 17.388 4.19228C17.3241 3.93795 17.2601 3.68299 17.1522 3.43586C15.8779 0.5352 12.4919 -0.789434 9.58929 0.484923C6.68669 1.7577 5.36592 5.13968 6.64028 8.04037C6.74775 8.29391 6.88875 8.50663 7.0329 8.72411C7.05476 8.75709 7.07669 8.79018 7.09859 8.82354ZM30.9286 49.0225C33.835 47.7503 35.1539 44.3623 33.8795 41.4597L33.8797 41.4595C33.5374 40.6822 33.0366 40.0285 32.4449 39.5025L22.8959 43.6892C22.8862 44.4859 23.0254 45.2981 23.3677 46.0716C24.64 48.9761 28.026 50.2969 30.9286 49.0225Z"
      />
    </svg>
  );
}
