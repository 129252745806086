import styled from '@emotion/styled';
import { FileService, FormInstance, FormInstanceFieldFile } from '@gbhem/api';
import { DragEvent, forwardRef, memo, PropsWithChildren, Ref } from 'react';
import tw from 'twin.macro';

import { Input, Select } from '../../components';
import { Field as FieldModel } from '../../models';
import { FieldType } from '../../models';
import { CalendarPicker } from '../CalendarPicker';
import { ListItemEditor } from '../FieldEditor';
import { FileUpload, Label, Radio, TextArea } from '../Input';
import { LinkEntry } from '../Input/LinkEntry';
import { TimePicker } from '../TimePicker/TimePicker';
import { Widget } from '.';
import List from './List';
import { PhoneNumber } from './PhoneNumber';

const TextDisplay = styled.div`
  ${tw`flex w-full  px-2 pt-1 pb-4 border border-neutral-500 rounded-sm outline-none text-sm bg-neutral-900 cursor-not-allowed`}
`;

export const Field = memo(
  forwardRef(
    (
      {
        type,
        name,
        properties,
        children,
        editable,
        target,
        form,
        createdAt: _,
        updatedAt: __,
        ...props
      }: PropsWithChildren<
        FieldModel & {
          editable?: boolean;
          target?: Widget;
          value?: any;
          onChange?: (value: any) => void;
          onDragStart?: (e: DragEvent) => void;
          form?: FormInstance;
        }
      >,
      ref: Ref<any>
    ) => {
      name = name || '...';
      name = `${name}${properties?.required ? '*' : ''}`;

      switch (type) {
        case FieldType.Paragraph:
          return (
            <div className="text-sm text-neutral-200 raw-html" {...props}>
              <div dangerouslySetInnerHTML={{ __html: properties.paragraph }} />
            </div>
          );

        case FieldType.Separator:
          return <hr className="my-4 border-neutral-600" {...props} />;

        case FieldType.Heading:
          return (
            <h2 className="font-semibold" {...props}>
              {name || 'Heading...'}
            </h2>
          );

        case FieldType.Link:
          return (
            <div {...props}>
              <p>{properties.instructions}</p>
              <a
                href={
                  properties.link
                    ? properties.link.includes('http')
                      ? properties.link
                      : `//${properties.link}`
                    : ''
                }
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-blue-600"
              >
                {name || 'Link...'}
              </a>
            </div>
          );

        case FieldType.Section:
          return (
            <div>
              <b>| {name}</b>
              {children}
            </div>
          );

        case FieldType.Select:
          return (
            <Select
              name={name}
              {...props}
              {...properties}
              options={properties.options || []}
              ref={ref}
            />
          );

        case FieldType.TextArea:
          return !form?.submitted ? (
            <TextArea name={name} {...props} {...properties} ref={ref} />
          ) : (
            <>
              <Label>{name}</Label>
              <TextDisplay>{props.value}</TextDisplay>
            </>
          );
        case FieldType.Date:
          return <CalendarPicker name={name} {...props} {...properties} ref={ref} />;

        case FieldType.Time:
          return <TimePicker name={name} {...props} {...properties} ref={ref} />;

        case FieldType.Radio:
          return (
            <Radio
              name={name}
              options={properties.options || []}
              {...props}
              {...properties}
              ref={ref}
            />
          );

        case FieldType.File:
          return (
            <FileUpload
              name={name}
              type={properties.type || 'Document'}
              files={props.value}
              onUpload={async (files) => {
                if (!form) {
                  return;
                }

                form.fieldFiles = [
                  ...(form.fieldFiles || []),
                  ...files.map(
                    (f) => ({ file: f, field: { id: props.id } } as FormInstanceFieldFile)
                  )
                ];

                props.onChange?.(files);
              }}
              onRemove={async (key) => {
                await FileService.removeFile(key);
                props.onChange?.([]);
              }}
              {...props}
              {...properties}
              ref={ref}
            />
          );

        case FieldType.PhoneNumber:
          return <PhoneNumber name={name} {...props} {...properties} ref={ref} />;

        case FieldType.List:
          if (editable) {
            return (
              <ListItemEditor
                name={name}
                type={type}
                target={target}
                {...props}
                properties={properties}
                onChange={(field) => props.onChange?.(field)}
              />
            );
          }

          return <List name={name} {...props} {...properties} />;

        case FieldType.LinkEntry:
          return (
            <LinkEntry
              form={form}
              name={name}
              properties={properties}
              ref={ref}
              value={props.value}
              updateValue={(val: any) => {
                props.onChange?.(val);
              }}
            />
          );

        case FieldType.Text:
        case FieldType.Number:
        case FieldType.Checkbox:
        default:
          return <Input type={type} name={name} {...props} {...properties} ref={ref} />;
      }
    }
  )
);

export default Field;
