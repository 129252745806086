import { Announcement, AnnouncementService } from '@gbhem/api';
import { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useAsync } from 'react-use';

import { AnnouncementsModal } from '..';

export function Announcements() {
  const [announcementsHasDisplayed, setAnnouncementsHasDisplayed] = useState(false);

  const [announcements, setAnnouncements] = useState<Announcement[]>([]);

  const [showAnnouncementsModal, hideAnnouncementsModal] = useModal(
    () => (
      <AnnouncementsModal
        name="Announcements"
        onClose={hideAnnouncementsModal}
        announcements={announcements}
      />
    ),
    [announcements]
  );

  useAsync(async () => {
    setAnnouncements(await AnnouncementService.getAnnouncements());
  }, []);

  useEffect(() => {
    if (announcementsHasDisplayed || announcements?.length === 0) return;
    showAnnouncementsModal();
    setAnnouncementsHasDisplayed(true);
  }, [announcements, announcementsHasDisplayed, showAnnouncementsModal]);

  return null;
}
