import styled from '@emotion/styled';
import { TrackTemplate, UserGroup, UserGroupService } from '@gbhem/api';
import { useEffect, useMemo, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import { Loader, Modal } from '../../components';
import { useAuthenticatedUser, useRoles } from '../../providers';
import { Button } from '../Button';
import { Plus } from '../icons';
import { InterviewerGroupsTable } from './InterviewerGroupsTable';
import { InterviewGroupSummary } from './InterviewGroupSummary';
import { ManageInterviewerGroup } from './ManageInterviewerGroup';

const GroupListContainer = styled.div`
  ${tw`my-8 mx-3 grid lg:grid-cols-3 gap-5`}
`;

interface InterviewerDashboardProperties {
  track: TrackTemplate;
}

export function InterviewerDashboard({ track }: InterviewerDashboardProperties) {
  const router = useHistory();
  const { url } = useRouteMatch();
  const { system } = useRoles();
  const user = useAuthenticatedUser();

  const [interviewerGroups, setInterviewerGroups] = useState<UserGroup[]>();
  const [editGroup, setEditGroup] = useState<UserGroup>();

  const interviewerRole = useMemo(() => system.find((r) => r.name === 'Interviewer'), [system]);
  const superadminRole = useMemo(() => system.find((r) => r.name === 'Superadmin'), [system]);

  const isSuperadmin = user.roles.some((r) => r.id === superadminRole?.id);
  const activeUserConferences = user.permissions.filter(
    (p) => p.active && p.entity === 'Conference'
  );

  useAsync(async () => {
    if (!interviewerRole) {
      return;
    }
    const groups = await UserGroupService.getUserGroupsByRole(interviewerRole.id);

    if (!isSuperadmin) {
      setInterviewerGroups(
        groups.filter(
          (g) => !g.conference || activeUserConferences.some((c) => c.entityId === g.conference?.id)
        )
      );
    } else {
      setInterviewerGroups(groups);
    }

    if (groups.length) setEditGroup(groups[0]);
  }, [track.id]);

  useEffect(() => {
    interviewerGroups?.length
      ? setEditGroup(interviewerGroups.find((g) => g.id === editGroup?.id) ?? interviewerGroups[0])
      : setEditGroup(undefined);
  }, [interviewerGroups, editGroup]);

  if (!interviewerGroups || !interviewerRole) {
    return <Loader />;
  }

  return (
    <>
      {interviewerGroups.length ? (
        <>
          <GroupListContainer>
            {interviewerGroups.map((iG) => (
              <InterviewGroupSummary
                key={iG.id}
                interviewGroup={iG}
                selected={editGroup?.id === iG.id}
                onSelect={() => setEditGroup(iG)}
                onEdit={() => {
                  setEditGroup(iG);
                  router.push(`${url}/edit`);
                }}
                onDelete={async () => {
                  await UserGroupService.remove(iG.id);

                  setInterviewerGroups([...interviewerGroups.filter((g) => g.id !== iG.id)]);
                }}
              />
            ))}
          </GroupListContainer>
          {editGroup?.users?.length ? (
            <InterviewerGroupsTable group={editGroup} />
          ) : (
            <div className="flex flex-col flex-1 space-y-4 justify-center items-center">
              <p>No users in this interview group.</p>
              <Button onClick={() => router.push(`${url}/edit`)}>
                <Plus /> Interviewer
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col flex-1 space-y-4 justify-center items-center">
          <p>No interviewer groups in this conference.</p>
          <Button onClick={() => router.push(`${url}/add`)}>
            <Plus /> Group
          </Button>
        </div>
      )}
      <Route exact path={`${url}/add`}>
        <Modal name="Add Interview Group" onClose={() => void router.push(url)}>
          {({ close }) => (
            <ManageInterviewerGroup
              close={close}
              onSubmit={async ({ name, description, conference, users }) => {
                const group = await UserGroupService.create({
                  name,
                  description,
                  conference,
                  users,
                  roles: [interviewerRole]
                });

                setInterviewerGroups([...interviewerGroups, group]);

                close();
              }}
            />
          )}
        </Modal>
      </Route>
      <Route exact path={`${url}/edit`}>
        <Modal name="Manage Interview Group" onClose={() => void router.push(url)}>
          {({ close }) => (
            <ManageInterviewerGroup
              close={close}
              editGroup={editGroup}
              onSubmit={async (updated) => {
                const group = await UserGroupService.update(updated.id, updated);

                setInterviewerGroups([
                  ...interviewerGroups.map((ig) => (ig.id === group.id ? group : ig))
                ]);

                close();
              }}
            />
          )}
        </Modal>
      </Route>
    </>
  );
}
