/**
 * TODO: Rename this enum.
 */
export enum DocumentHeaders {
  Phase = 'phase',
  Track = 'track'
}

/**
 * Track, phase, and step will probably need to link with their
 * corresponding ID, and not just a string value.
 */
export interface Document {
  id: string;
  fileName: string;
  timeStamp: Date;
  file: Blob;
  track: string;
  phase: string;
  step: string;
}
