import { SVGProps } from 'react';

export function Form(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={40}
      height={50}
      viewBox="0 0 40 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.44.574A1.958 1.958 0 0025.053 0H7.186A7.187 7.187 0 000 7.186v35.628A7.187 7.187 0 007.186 50h25.191a7.187 7.187 0 007.186-7.186V14.51c0-.521-.21-1.019-.577-1.386L26.44.574zm3.865 39.85H9.258a1.96 1.96 0 010-3.92h21.047a1.96 1.96 0 010 3.92zM9.258 32.394h21.047a1.96 1.96 0 000-3.92H9.258a1.96 1.96 0 000 3.92zm21.047-8.028H9.258a1.96 1.96 0 010-3.92h21.047a1.96 1.96 0 010 3.92zM27.04 6.71l.003 5.854 5.877.025-5.88-5.879z"
      />
    </svg>
  );
}

export default Form;
