import styled from '@emotion/styled';
import { UserGroup } from '@gbhem/api';
import { ComponentProps } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import {
  ArrowRight,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Dismissable,
  Modal,
  More,
  Option,
  OptionsMenu
} from '..';

const Container = styled(Card)`
  ${tw`flex p-6 rounded-none cursor-pointer`}
`;

const Header = styled(CardHeader)`
  ${tw`flex items-center justify-between mb-4 text-base font-normal cursor-pointer`}

  svg {
    ${tw`flex-shrink-0 h-4 w-6`}
  }
`;

const Title = styled.div`
  ${tw`flex items-center flex-1 text-xl font-bold gap-4`}
`;

const Body = styled(CardBody)`
  ${tw`flex items-start p-0`}
`;

const MoreOptions = styled(More)`
  ${tw`text-neutral-500`}
`;

const GoTo = styled(ArrowRight)``;

const LocationPill = styled.span`
  ${tw`text-xs rounded-full bg-tan text-neutral-100 py-1 px-2 select-none font-normal`}
`;

type InterviewGroupSummaryProperties = ComponentProps<'div'> & {
  interviewGroup: UserGroup;
  selected: boolean;
  onSelect: (id: string) => void;
  onEdit: () => void;
  onDelete: () => void;
};

export function InterviewGroupSummary({
  interviewGroup,
  onSelect,
  onEdit,
  onDelete,
  selected,
  ...props
}: InterviewGroupSummaryProperties) {
  const [showConfirmDeleteModal, hideConfirmDeleteModal] = useModal(
    () => (
      <Modal name="Confirm Delete Group" onClose={hideConfirmDeleteModal}>
        {({ close }) => (
          <>
            Are you sure you want to delete the {interviewGroup.name} group? This action cannot be
            undone.
            <CardFooter>
              <Button inverted onClick={() => close()}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  onDelete();
                  close();
                }}
              >
                Confirm
              </Button>
            </CardFooter>
          </>
        )}
      </Modal>
    ),
    [interviewGroup, onDelete]
  );

  return (
    <Container
      onClick={() => onSelect(interviewGroup.id)}
      className={selected ? 'border-4 border-primary' : 'opacity-75'}
      {...props}
    >
      <Header>
        <Title>
          {interviewGroup.name}
          <GoTo />
        </Title>
        <Dismissable control={<MoreOptions />}>
          <OptionsMenu>
            <Option onClick={onEdit}>Edit</Option>
            <Option className="text-pink" onClick={showConfirmDeleteModal}>
              Delete
            </Option>
          </OptionsMenu>
        </Dismissable>
      </Header>
      <Body>
        <div>{interviewGroup.description}</div>
        <LocationPill>
          {interviewGroup.conference ? interviewGroup.conference.name : 'No assigned location'}
        </LocationPill>
      </Body>
    </Container>
  );
}
