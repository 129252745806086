import './index.css';

import { ApiError, OpenAPI, SecurityService } from '@gbhem/api';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

const _fetch = fetch;
window.fetch = async (input: RequestInfo, init?: RequestInit | undefined): Promise<Response> => {
  try {
    return await _fetch.apply(this, [input, init]);
  } catch (error) {
    if (!(error instanceof ApiError) || error.status !== 401) {
      throw error;
    }

    const { url } = await SecurityService.logInSpa(window.location.pathname);
    window.location.assign(url);

    throw error;
  }
};

OpenAPI.WITH_CREDENTIALS = true;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
