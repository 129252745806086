import { Role, RoleService, User } from '@gbhem/api';
import { createContext, FC, useContext, useEffect, useState } from 'react';

import { Loader } from '../components';

const RoleContext = createContext<{
  system: Role[];
  hasTrackOverrideRole: (user: User, participant: User, conferenceId?: string) => boolean;
}>(undefined!);

export enum SystemRole {
  Superadmin = 'Superadmin',
  Bishop = 'Bishop',
  ConferenceAdmin = 'Conference Admin',
  DistrictAdmin = 'District Admin',
  DistrictSuperintendent = 'District Superintendent',
  Advisor = 'Advisor',
  Mentor = 'Mentor',
  Interviewer = 'Interviewer',
  Participant = 'Participant',
  User = 'User'
}

export const RoleProvider: FC = ({ children }) => {
  const [system, setSystemRoles] = useState<Role[]>();

  const getRoles = async () => {
    const res = await RoleService.getSystemRoles();
    setSystemRoles(res);
  };

  useEffect(() => {
    getRoles();
  }, []);

  if (!system) {
    return <Loader />;
  }

  const hasTrackOverrideRole = (user: User, participant: User, conferenceId?: string): boolean => {
    const isSuperAdmin = !!user.roles.find((r) =>
      [SystemRole.Superadmin.toString()].includes(r.name)
    );
    if (isSuperAdmin) return true;

    const isConferenceAdmin = !!user.roles.find((r) =>
      [SystemRole.ConferenceAdmin.toString()].includes(r.name)
    );
    const isDistrictAdmin = !!user.roles.find((r) =>
      [SystemRole.DistrictAdmin.toString()].includes(r.name)
    );
    const isDistrictSuperintendent = !!user.roles.find(
      (r) => SystemRole.DistrictSuperintendent.toString() === r.name
    );
    if (!isConferenceAdmin && !isDistrictAdmin && !isDistrictSuperintendent) return false;

    // If it is system track
    if (!conferenceId) return true;

    if (isConferenceAdmin) {
      return user.permissions.some(
        (permission) => permission.entity === 'Conference' && permission.entityId === conferenceId
      );
    }

    if (isDistrictAdmin || isDistrictSuperintendent) {
      return user.permissions.some(
        (permission) =>
          permission.entity === 'District' &&
          participant?.permissions.find(
            (participantPermission) => participantPermission.entityId === permission.entityId
          )
      );
    }

    return false;
  };

  return (
    <RoleContext.Provider value={{ system, hasTrackOverrideRole }}>{children}</RoleContext.Provider>
  );
};

export default RoleProvider;

export const useRoles = () => useContext(RoleContext);
