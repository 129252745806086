import styled from '@emotion/styled';
import { Conference, District, Jurisdiction, Permission, User, UserService } from '@gbhem/api';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import { Can, SystemRole, useAuthenticatedUser, useLocationStore } from '../../providers';
import { useUserRelationshipStore } from '../../providers/UserProfileProvider/UserRelationshipProvider';
import { AddPermissionsModal } from '../AddPermissionsModal';
import { Button } from '../Button';
import { CardFooter } from '../Card';
import { ErrorText } from '../ErrorText';
import { Cancel } from '../icons';
import { Input } from '../Input';
import { Modal, ModalProps } from '../Modal';

const Location = styled.div`
  ${tw`flex items-start border rounded-sm border-neutral-400 space-x-2`}

  svg {
    ${tw`w-3 h-3`}
  }
`;

interface LocationProps {
  user: User;
}

export interface LocationDefinition {
  type: string;
  entity: Conference | District | Jurisdiction;
  active: boolean;
  primary: boolean;
  permissionId: string;
}

export type LocationDetail = LocationDefinition & {
  type: string;
};

export class PermissionsMap {
  conference: Conference[] = [];
  district: District[] = [];
  jurisdiction: Jurisdiction[] = [];
}

type LocationPermissionModalsProps = ModalProps & {
  location?: Permission;
  locations: Permission[];
  user: User;
  onConfirm?: (affectedMentors: MentorMap[], affectedMentees: MentorMap[]) => Promise<void>;
  onAdd?: (location: Permission, isConstituent?: boolean) => void | Promise<void>;
};

interface MentorMap {
  target: User;
  permissions: Permission[];
}

const { deactivateLocationPermission, getLocationDetails } = UserService;

export function LocationPermissions({ user }: LocationProps) {
  const authenticatedUser = useAuthenticatedUser();
  const [locations, setLocations] = useState<Permission[]>(user.permissions || []);
  const [removeLocation, setRemoveLocation] = useState<Permission>();
  const [permissionsLocations, setPermissionsLocations] = useState<LocationDetail[]>([]);

  useAsync(async () => {
    if (user.id) {
      const locationDetails: LocationDetail[] = [];
      const locationMap = await getLocationDetails(user.id);

      Object.keys(locationMap).forEach((locType) => {
        locationMap[locType as keyof PermissionsMap].forEach((entities: LocationDefinition[]) => {
          locType = locType === 'episcopalarea' ? 'Episcopal Area' : locType;
          entities.forEach(({ entity, active, primary, permissionId }: LocationDefinition) => {
            locationDetails.push({
              type: `${locType.charAt(0).toUpperCase() + locType.slice(1)}`,
              entity,
              active,
              primary,
              permissionId
            });
          });
        });
      });

      setPermissionsLocations(locationDetails);
    }
  }, [user, locations]);

  const confirmRemove = async (affectedMentors: MentorMap[], affectedMentees: MentorMap[]) => {
    if (removeLocation) {
      await deactivateLocationPermission(removeLocation.id);
      const removed = locations.filter((location) => location.id !== removeLocation.id);
      setLocations(removed);

      const { removeMentors, removeMentees } = useUserRelationshipStore.getState();

      removeMentors(
        user,
        affectedMentors.map((mentorMap) => mentorMap.target)
      );

      removeMentees(
        user,
        affectedMentees.map((menteeMap) => menteeMap.target)
      );
    }
  };

  const [showConfirmDeactivateModal, hideConfirmDeactivateModal] = useModal(
    () => (
      <ConfirmDeactivateModal
        user={user}
        onClose={hideConfirmDeactivateModal}
        location={removeLocation}
        locations={locations}
        onConfirm={confirmRemove}
      />
    ),
    [removeLocation, locations]
  );

  const [showAddLocationModal, hideAddLocationModal] = useModal(
    () => <AddPermissionsModal onClose={hideAddLocationModal} users={[user]} />,
    [user]
  );

  const canEditPrimaryLocation = !!authenticatedUser.roles.find((r) =>
    [
      SystemRole.Superadmin.toString(),
      SystemRole.ConferenceAdmin.toString(),
      SystemRole.DistrictAdmin.toString()
    ].includes(r.name)
  );

  const _conferences = useLocationStore().conferences;
  const conferences = Object.values(_conferences);

  return (
    <div className="space-y-4">
      {conferences.length === 0 && (
        <ErrorText>
          <b>Warning:</b> Certain aspects of Passage are unusable without a conference!
        </ErrorText>
      )}
      <div className="flex space-x-4">
        {permissionsLocations?.map((l: LocationDetail) => (
          <Location key={l.entity.id}>
            <div
              className={`p-2 space-y-2 ${
                l.active ? 'bg-neutral-1000' : 'bg-neutral-800 text-neutral-500'
              }`}
            >
              <div className="flex justify-between">
                <span className="text-xs font-semibold">{l.type}</span>
                {l.active && (
                  <Can do="delete" on="Permission">
                    <Cancel
                      className="ml-2 mt-0.5 w-3 h-3 cursor-pointer"
                      onClick={() => {
                        let _remove: Permission | undefined = undefined;
                        locations.forEach((loc) => {
                          if (loc.entityId === l.entity.id) {
                            _remove = loc;
                            return;
                          }
                        });
                        if (_remove) setRemoveLocation(_remove);
                        showConfirmDeactivateModal();
                      }}
                    />
                  </Can>
                )}
              </div>
              <div className="text-sm flex items-center space-x-2">
                <span>
                  {l.entity.name}{' '}
                  {!l.active ? <span className="ml-1 text-neutral-500">(inactive)</span> : ''}
                </span>
              </div>
              <div className="text-sm flex items-center space-x-2">
                <Input
                  type="checkbox"
                  name="Primary"
                  checked={l.primary}
                  disabled={!canEditPrimaryLocation}
                  onChange={async () => {
                    if (!l.active) return;

                    const updatedLocations = await UserService.selectPrimaryLocationPermission({
                      permissionId: l.permissionId,
                      userId: user.id
                    });
                    // we only call set locations here because it triggers the useAsync hook to rehydrate locationsPermissions from the backend
                    setLocations(updatedLocations);
                  }}
                />
              </div>
            </div>
          </Location>
        ))}
        {permissionsLocations.length === 0 ? (
          <p>Please contact an admin to add a location to your profile.</p>
        ) : null}
      </div>
      <Can do="create" on="Permission">
        <Button
          className="ml-auto"
          onClick={() => {
            showAddLocationModal();
          }}
        >
          + Add Location
        </Button>
      </Can>
    </div>
  );
}

function ConfirmDeactivateModal({
  location,
  locations,
  onConfirm,
  user,
  ...props
}: LocationPermissionModalsProps) {
  const currentUser = useAuthenticatedUser();

  const remainingConferences = locations.filter(
    (l) => l.entityId !== location?.entityId && l.entity === 'Conference' && l.active
  );
  const canOverrideConferenceWarning: boolean = !!currentUser.roles.find(
    (r) => r.name === SystemRole.Superadmin
  );
  const { mentorAssignments, menteeAssignments } = useUserRelationshipStore();

  const mentorConferences = mentorAssignments.map((p) => ({
    target: p.mentor,
    permissions: p.mentor.permissions.filter(
      (permission) =>
        permission.entity === 'Conference' &&
        user.permissions.find((userPerm) => userPerm.entityId === permission.entityId)
    )
  }));

  const affectedMentors = mentorConferences.filter((mc) => mc.permissions.length === 1);

  const menteeConferences = menteeAssignments.map((p) => ({
    target: p.mentee,
    permissions: p.mentee.permissions.filter(
      (permission) =>
        permission.entity === 'Conference' &&
        user.permissions.find((userPerm) => userPerm.entityId === permission.entityId)
    )
  }));

  // TODO! this isn't working as expected - if a mentee has more than one permission
  // this isn't checking correctly if both permissions aren't matching
  const affectedMentees = menteeConferences.filter((mc) => mc.permissions.length === 1);

  return (
    <Modal name="Confirm deactivate" {...props}>
      {({ close }) =>
        location && (
          <>
            Are you sure you want to deactivate the {location.entity}?
            {(affectedMentors.length > 0 || affectedMentees.length > 0) && (
              <ErrorText>
                This action will unassign mentors and/or mentees from this user!
              </ErrorText>
            )}
            {remainingConferences.length === 0 && (
              <>
                <Can do="manage" on="all">
                  <ErrorText>
                    <b>Warning:</b> This action will make certain aspects of Passage unusable for
                    this user.
                  </ErrorText>
                </Can>
                {/* To Do: CASL Can
              {userAbility.cannot('manage', all) && (*/}
                {!currentUser.roles.find((r) => r.name === SystemRole.Superadmin) && (
                  <ErrorText>
                    <b>Warning:</b> Only Super Admins can remove the last conference from a user!
                  </ErrorText>
                )}
              </>
            )}
            <CardFooter>
              <Button inverted onClick={() => close()}>
                Cancel
              </Button>
              <Button
                disabled={remainingConferences.length === 0 && !canOverrideConferenceWarning}
                onClick={() => {
                  onConfirm?.(affectedMentors, affectedMentees);
                  close();
                }}
              >
                Confirm
              </Button>
            </CardFooter>
          </>
        )
      }
    </Modal>
  );
}

export default LocationPermissions;
