"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecurityService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class SecurityService {
    static authenticate(code, state) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/security/authenticate',
            query: {
                'code': code,
                'state': state,
            },
        });
    }
    static logIn(redirectUri) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/security/login',
            query: {
                'redirect-uri': redirectUri,
            },
        });
    }
    static logInSpa(redirectUri) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/security/login/spa',
            query: {
                'redirect-uri': redirectUri,
            },
        });
    }
    static logOut() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/security/logout',
        });
    }
}
exports.SecurityService = SecurityService;
