import styled from '@emotion/styled';
import { forwardRef, memo, Ref, useImperativeHandle, useRef } from 'react';
import tw from 'twin.macro';

import { Button } from '../Button';
import { Input, InputProps, Label } from './Input';

const RadioGroup = styled.div`
  ${tw`flex space-x-4`}
`;

const RadioButton = styled(Button)`
  ${tw`px-3 py-1 text-sm`}
`;

const Control = styled(Input)`
  input {
    ${tw`appearance-none`}
  }
`;

const Instructions = styled.div`
  ${tw`text-xs`}
`;

export const RadioInput = memo(
  forwardRef(({ ...props }: InputProps, ref: Ref<HTMLInputElement | null>) => {
    const radioRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => radioRef.current);

    return (
      <>
        <RadioButton
          inverted={!props.checked}
          onClick={() => radioRef.current?.click()}
          type="button"
        >
          <Control {...props} ref={radioRef} type="radio" inline hidden />
          {props.value}
        </RadioButton>
      </>
    );
  })
);

export const Radio = memo(
  ({
    name,
    instructions,
    options,
    value,
    onChange
  }: InputProps & { options: (number | string)[] }) => (
    <div className="space-y-2">
      <Label>{name}</Label>
      {instructions && <Instructions>{instructions}</Instructions>}
      <RadioGroup>
        {options?.map((o) => (
          <RadioInput
            key={o}
            type="radio"
            value={o || '...'}
            name={name}
            checked={value === o}
            onChange={onChange}
          />
        ))}
      </RadioGroup>
    </div>
  )
);

export default Radio;
