import styled from '@emotion/styled';
import tw from 'twin.macro';

const BadgeContainer = styled.button`
  ${tw`w-6 bg-red-500 h-6 rounded-full text-neutral-1000 flex items-center justify-center text-sm`}
`;

interface BadgeProps {
  count: number;
  onClick?: (e: any) => void;
}

export function Badge({ count, onClick }: BadgeProps) {
  return <BadgeContainer onClick={onClick ? onClick : () => {}}>{count}</BadgeContainer>;
}
