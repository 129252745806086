import styled from '@emotion/styled';
import { User } from '@gbhem/api';
import { ComponentProps } from 'react';
import { useHistory } from 'react-router-dom';
import tw from 'twin.macro';

import { Can, SystemRole, TrackTemplateInstanceCount, useAuthenticatedUser } from '../../providers';
import {
  ArrowRight,
  Card,
  CardHeader,
  Dismissable,
  More,
  Option,
  OptionsMenu,
  TrackPillData
} from '..';

const Container = styled(Card)`
  ${tw`flex p-4`}
`;

const Header = styled(CardHeader)`
  ${tw`flex items-center justify-between text-base font-normal cursor-pointer`}

  svg {
    ${tw`flex-shrink-0 h-1 w-1.5`}

    height: 1rem;
    width: 1rem;
  }
`;

const Title = styled.div`
  ${tw`flex items-center flex-1 text-lg font-bold space-x-2`}
`;

const MoreOptions = styled(More)`
  ${tw`text-neutral-500`}
`;

const GoTo = styled(ArrowRight)``;

type TrackProperties = ComponentProps<'div'> & {
  track: TrackTemplateInstanceCount;
  editable?: boolean;
  onDeactivate?: (track: TrackTemplateInstanceCount) => void;
  onDelete?: (track: TrackTemplateInstanceCount) => void;
};

export function TrackSummary({
  track,
  editable,
  onDeactivate,
  onDelete,
  ...props
}: TrackProperties) {
  const router = useHistory();

  const authenticatedUser: User = useAuthenticatedUser();
  const isSuperAdmin: boolean = !!authenticatedUser.roles.find((r) =>
    [SystemRole.Superadmin.toString()].includes(r.name)
  );

  if (!track.conference && !isSuperAdmin) {
    editable = false;
  }

  return (
    <Container {...props}>
      <div className="mb-1">
        <Header>
          <Title onClick={() => router.push(`/tracks/${track.id}`)}>
            <div>{track.name}</div>
            <GoTo />
          </Title>
          {editable ? (
            <Can do="update" on="TrackTemplate">
              <Dismissable control={<MoreOptions />}>
                <OptionsMenu>
                  <Can do="deactivate" on="TrackInstance">
                    <Option className="text-pink" onClick={() => onDeactivate?.(track)}>
                      Deactivate
                    </Option>
                    {track.count === 0 ? (
                      <Option className="text-red-800" onClick={() => onDelete?.(track)}>
                        Delete
                      </Option>
                    ) : null}
                  </Can>
                </OptionsMenu>
              </Dismissable>
            </Can>
          ) : null}
        </Header>
        <p className="text-sm">{track.description}</p>
      </div>
      <TrackPillData track={track} />
    </Container>
  );
}

export default TrackSummary;
