import { SVGProps } from 'react';

export function TriangleUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={43}
      viewBox="0 0 50 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M25.033 0c-.41-.01-.797.2-1.007.55L12.094 20.445.162 40.343a1.137 1.137 0 00.974 1.722h47.728a1.137 1.137 0 00.974-1.722L37.907 20.444 25.974.554c-.2-.334-.552-.54-.939-.554h-.002z" />
    </svg>
  );
}

export default TriangleUp;
