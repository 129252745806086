import { File, FileService } from '@gbhem/api';

import { FileUpload } from '..';

interface StepAttachmentsEditorProperties {
  attachments: File[];
  onChange: (attachments: File[]) => void;
}

export function StepAttachmentsEditor({ attachments, onChange }: StepAttachmentsEditorProperties) {
  return (
    <div className="space-y-4">
      <FileUpload
        name={'Upload Attachments'}
        instructions="Attach any files that help the individual assigned this step to complete it."
        type={'Any'}
        multiple
        files={attachments}
        disable={true}
        onUpload={onChange}
        onRemove={async (id: string) => {
          await FileService.removeUploadInstance(id);
          onChange(attachments.filter((a) => a.id !== id));
        }}
        hideHeader
        hideLabel
      />
    </div>
  );
}

export default StepAttachmentsEditor;
