import styled from '@emotion/styled';
import { MentorAssignment, User } from '@gbhem/api';
import { useEffect, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { Can } from '../../providers';
import { useUserRelationshipStore } from '../../providers/UserProfileProvider/UserRelationshipProvider';
import { Button } from '../Button';
import { Cancel, Plus } from '../icons';
import { Loader } from '../Loader';
import { AddMenteesModal } from '../MentorModals';
import { Modal, ModalProps } from '../Modal';

const MenteeContainer = styled.div`
  ${tw`flex flex-wrap flex-shrink items-start border rounded-sm border-neutral-400 space-x-2 mx-1 my-1`}

  max-height: 10rem;
  max-width: 26rem;
  min-height: 3rem;
  min-width: 26rem;

  svg {
    ${tw`w-3 h-3`}
  }
`;

const MenteeHeader = styled.div`
  ${tw`flex justify-between text-sm font-bold my-5 mx-4 w-full`}
`;

const MenteeContent = styled.p`
  ${tw`text-xs my-3 mx-2`}
`;

interface MenteesProps {
  mentor: User;
}

export const Mentees = ({ mentor }: MenteesProps) => {
  const { menteeAssignments } = useUserRelationshipStore();

  const removeRef = useRef<MentorAssignment>();
  const [shouldRemove, setShouldRemove] = useState<MentorAssignment>();

  const [showAddMenteeModal, hideAddMenteeModal] = useModal(() => (
    <AddMenteesModal onClose={hideAddMenteeModal} mentor={mentor} mentees={menteeAssignments!} />
  ));

  const [showRemoveMenteeModal, hideRemoveMenteeModal] = useModal(() =>
    removeRef.current?.mentee ? (
      <RemoveMenteeModal
        onClose={() => {
          setShouldRemove(undefined);
          hideRemoveMenteeModal();
        }}
        mentor={mentor}
        mentee={removeRef.current.mentee}
      />
    ) : null
  );

  useEffect(() => {
    removeRef.current = shouldRemove;
    if (shouldRemove) {
      showRemoveMenteeModal();
    }
  }, [shouldRemove, showRemoveMenteeModal]);

  if (!menteeAssignments) return <Loader />;

  return (
    <>
      <h2 className="font-bold my-4 flex flex-nowrap">Mentees</h2>
      {menteeAssignments.length === 0 && <p>This user is currently assigned to no mentees.</p>}
      <div className="flex justify-start flex-wrap">
        {menteeAssignments.map((menteeAssignment) => {
          const mentee = menteeAssignment.mentee;
          return (
            <MenteeContainer key={mentee.id}>
              <MenteeHeader>
                <h2 className="pr-4">{`${mentee.firstName} ${mentee.lastName}`}</h2>
                <Can do="delete" on="MentorAssignment">
                  <Cancel
                    className="ml-2 mt-0.5 w-3 h-3 cursor-pointer"
                    onClick={() => setShouldRemove(menteeAssignment)}
                  />
                </Can>
              </MenteeHeader>
              <div>
                <MenteeContent>
                  {menteeAssignment.mentee.email && (
                    <div>{`Email: ${menteeAssignment.mentee.email}`}</div>
                  )}
                  Assigned {new Date(menteeAssignment.createdAt || '').toLocaleDateString()}
                </MenteeContent>
              </div>
            </MenteeContainer>
          );
        })}
      </div>
      <Can do="create" on="MentorAssignment">
        <Button className="ml-auto my-3" onClick={showAddMenteeModal}>
          <Plus /> Assign
        </Button>
      </Can>
    </>
  );
};

type RemoveMenteeModalProps = ModalProps & {
  mentor: User;
  mentee: User;
};

const RemoveMenteeModal = ({ mentor, mentee, onClose }: RemoveMenteeModalProps) => {
  const { removeMentees } = useUserRelationshipStore();

  return (
    <Modal name="Confirm Delete" onClose={onClose}>
      {({ close }) => {
        return (
          <>
            <p>{`Are you sure you want to unassign ${mentee.firstName} as a mentee?`}</p>
            <div className="flex gap-4">
              <Button onClick={onClose} inverted>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  await removeMentees(mentor, [mentee]);
                  close();
                }}
              >
                Confirm
              </Button>
            </div>
          </>
        );
      }}
    </Modal>
  );
};
