import { MentorAssignment, User, UserService } from '@gbhem/api';
import { useState } from 'react';

import { useAuthenticatedUser } from '../../providers';
import { useUserRelationshipStore } from '../../providers/UserProfileProvider/UserRelationshipProvider';
import { ErrorText } from '..';
import { Button } from '../Button';
import { Select } from '../Input';
import { Modal, ModalProps } from '../Modal';

interface AddMentorProps {
  mentee: User;
  mentorAssignments?: MentorAssignment[];
  close: (force?: boolean | undefined) => Promise<void>;
}
export const AddMentor = ({ mentee, mentorAssignments, close }: AddMentorProps) => {
  const currentUser = useAuthenticatedUser();
  const { setMentorAssignments } = useUserRelationshipStore();

  const [selectedUser, setSelectedUser] = useState<User>();
  const [showSelectedError, setShowSelectedError] = useState(false);
  const canSelfAssign: boolean =
    currentUser.roles.some((r) => r.name === 'Mentor') &&
    currentUser.id !== mentee.id &&
    !mentorAssignments?.find((assign) => assign.mentor.id === currentUser.id) &&
    currentUser.permissions.some(
      (p) => p.active && mentee.permissions.some((mP) => mP.active && mP.entityId === p.entityId)
    );

  return (
    <>
      <Select<User>
        placeholder="Start typing mentor name..."
        options={async (searchTerm) => {
          if (!searchTerm) return;

          return await UserService.getMentorRelationshipOptions(mentee.id, true, {
            conditions: [
              { name: searchTerm, inactive: false },
              { firstName: searchTerm, inactive: false },
              { lastName: searchTerm, inactive: false }
            ],
            relations: ['roles', 'permissions'],
            limit: 15
          });
        }}
        optionLabel={(participant) =>
          `${participant.firstName} ${participant.lastName} (${participant.email})`
        }
        onSelect={(e) => {
          if (e.currentTarget.value) {
            setSelectedUser(e.currentTarget.value);
          }
        }}
      />
      <div className="flex justify-start">
        {showSelectedError && (
          <ErrorText>
            <b>Error</b>: No user selected <br />
          </ErrorText>
        )}
      </div>
      <div className="flex justify-start">
        <Button
          disabled={!selectedUser}
          onClick={async () => {
            if (selectedUser) {
              setShowSelectedError(false);
              await setMentorAssignments(selectedUser, mentee);
              close();
            } else {
              setShowSelectedError(true);
            }
          }}
        >
          Add Mentor
        </Button>
        {canSelfAssign && (
          <>
            <p className="px-2">- Or -</p>
            <Button
              onClick={async () => {
                await setMentorAssignments(currentUser, mentee);
                close();
              }}
            >
              Assign yourself
            </Button>
          </>
        )}
      </div>
    </>
  );
};

type AddMentorModalProps = ModalProps & {
  mentee: User;
  mentorAssignments?: MentorAssignment[];
};

export const AddMentorModal = ({ mentee, mentorAssignments, ...props }: AddMentorModalProps) => {
  return (
    <Modal name="Add Mentor" onClose={props.onClose} isClosable={true}>
      {({ close }) => (
        <>
          Add Mentor to {mentee.firstName} {mentee.lastName}
          <AddMentor mentee={mentee} mentorAssignments={mentorAssignments} close={close} />
        </>
      )}
    </Modal>
  );
};
