import styled from '@emotion/styled';
import { User } from '@gbhem/api';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router-dom';
import tw from 'twin.macro';

import { Can } from '../../providers';
import { Button } from '../Button';
import { Card } from '../Card';
import { ArrowRight } from '../icons';
import { Modal } from '../Modal';
import AddTrack from './AddTrack';

const TrackSection = styled(Card)`
  ${tw`px-3 border rounded-sm border-neutral-400 cursor-pointer`}
`;

const TrackHeader = styled.div`
  ${tw`flex`}

  svg {
    ${tw`w-6 h-6 ml-auto`}
  }
`;

const TrackBody = styled.div``;

const StatusPill = styled.span`
  ${tw`text-xs rounded-full bg-blue-200 text-neutral-100 font-bold py-1 px-2 select-none`}
  ${tw`mx-3`}
`;

interface TracksProps {
  user: User;
}

export function Tracks({ user }: TracksProps) {
  const router = useHistory();

  const [showAddTrackModal, hideAddTrackModal] = useModal(
    () => (
      <Modal name="Add Track" onClose={hideAddTrackModal}>
        {({ close }) => <AddTrack user={user} close={close} />}
      </Modal>
    ),
    [user]
  );

  return (
    <>
      <div className="space-y-4">
        <Can do="create" on="TrackInstance">
          <div className="flex justify-end">
            <Button onClick={showAddTrackModal}>+ Add Track</Button>
          </div>
        </Can>
        <div className="space-x-4">
          {user.tracks && user.tracks.length > 0 ? (
            <div className="flex flex-col gap-2">
              {user.tracks.map((t) => (
                <TrackSection
                  key={t.trackTemplate.id}
                  onClick={() => router.push(`/tracks/${t.trackTemplate.id}/assignments/${t.id}`)}
                >
                  <TrackHeader>
                    <div className="font-bold flex-1">
                      {t.trackTemplate.name}
                      <StatusPill>{t.status}</StatusPill>
                    </div>
                    <ArrowRight className="justify-self-end" />
                  </TrackHeader>
                  <TrackBody>
                    <p className="text-sm">{t.trackTemplate.description}</p>
                    <p className="text-sm">
                      {t.createdAt && (
                        <p className="text-sm mt-2">
                          Enrolled on {new Date(t.createdAt).toLocaleDateString()} at{' '}
                          {new Date(t.createdAt).toLocaleTimeString()}
                        </p>
                      )}
                    </p>
                  </TrackBody>
                </TrackSection>
              ))}
            </div>
          ) : (
            <p>{`${user.firstName} has no Tracks.`}</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Tracks;
