import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import tw from 'twin.macro';

import { DragHandle } from '../../components';
import { FieldType } from '../../models';

type OptionProps = {
  disabled?: boolean;
} & ComponentProps<'div'>;
const Option = ({ disabled, children, ...props }: OptionProps) => (
  <div
    {...props}
    draggable={!disabled}
    className="flex space-x-2 items-center bg-neutral-1000 p-3 border border-neutral-500 cursor-pointer"
  >
    <DragHandle className="text-neutral-500 flex-shrink-0" />
    <div className="flex-shrink-0 pr-6">{children}</div>
  </div>
);

const Group = styled.div`
  ${tw`space-y-2 mt-2`}
`;

interface WidgetsProperties {
  onDragStart: (type: FieldType) => void;
  disabled?: boolean;
}

export function Widgets({ disabled, onDragStart }: WidgetsProperties) {
  return (
    <div
      onDragStart={(e) => {
        const type = (e.target as HTMLElement).id as FieldType;
        onDragStart(type);
      }}
      className="space-y-4 text-xs"
    >
      <div>
        <b>Shortcuts</b>
        <Group>
          <Option id={FieldType.Address} disabled={disabled}>
            Address
          </Option>
        </Group>
      </div>

      <div>
        <b>Input</b>
        <Group>
          <Option id={FieldType.Text} disabled={disabled}>
            Text
          </Option>
          <Option id={FieldType.TextArea} disabled={disabled}>
            Text Area
          </Option>
          <Option id={FieldType.Number} disabled={disabled}>
            Number
          </Option>
          <Option id={FieldType.PhoneNumber} disabled={disabled}>
            Phone
          </Option>

          <Option id={FieldType.Date} disabled={disabled}>
            Date
          </Option>
          <Option id={FieldType.Time} disabled={disabled}>
            Time
          </Option>

          <Option id={FieldType.Radio} disabled={disabled}>
            Radio
          </Option>
          <Option id={FieldType.Checkbox} disabled={disabled}>
            Checkbox
          </Option>
          <Option id={FieldType.Select} disabled={disabled}>
            Select
          </Option>

          <Option id={FieldType.File} disabled={disabled}>
            File
          </Option>
          <Option id={FieldType.List} disabled={disabled}>
            List
          </Option>
          <Option id={FieldType.LinkEntry} disabled={disabled}>
            Link Entry
          </Option>
        </Group>
      </div>

      <div>
        <b>Information</b>
        <Group>
          <Option id={FieldType.Link} disabled={disabled}>
            Link
          </Option>
          <Option id={FieldType.Paragraph} disabled={disabled}>
            Paragraph
          </Option>
        </Group>
      </div>
      <div>
        <b>Layout</b>
        <Group>
          <Option id={FieldType.Heading} disabled={disabled}>
            Heading
          </Option>
          <Option id={FieldType.Separator} disabled={disabled}>
            Separator
          </Option>
        </Group>
      </div>
    </div>
  );
}

export default Widgets;
