import { District, Role, User, UserService } from '@gbhem/api';
import { useMemo, useState } from 'react';
import { useAsyncFn } from 'react-use';

import { useAuthenticatedUser, useRoles } from '../../providers';
import { filterByCurrentUserLocation } from '../../utils/user-location-filtering';
import { Button, ErrorText, Select } from '..';
import {
  LocationDefinition,
  LocationDetail,
  PermissionsMap
} from '../UserProfile/LocationPermissions';

interface Assignment {
  participant: User;
  assignee: User;
  role: Role;
}

interface AddParticipantProperties {
  onAdd: (assignment: Assignment) => void;
}

const { getLocationDetails, addLocationPermissions } = UserService;

export function AddParticipant({ onAdd }: AddParticipantProperties) {
  const user = useAuthenticatedUser();
  const { system } = useRoles();

  const advisorRole = useMemo(() => system.find((r) => r.name === 'Advisor'), [system]);

  const [advisor, setAdvisor] = useState<User>();
  const [participant, setParticipant] = useState<User>();

  const [hasDistrict, setHasDistrict] = useState<boolean>(true);
  const [adminDistricts, setAdminDistricts] = useState<LocationDetail[]>([]);
  const [addDistrict, setAddDistrict] = useState<LocationDetail>();

  const [, fetchAdminLocations] = useAsyncFn(async () => {
    const locationDetails: LocationDetail[] = [];
    const locationMap = await getLocationDetails(user.id);

    Object.keys(locationMap).forEach((locType) => {
      locationMap[locType as keyof PermissionsMap].forEach((entities: LocationDefinition[]) => {
        locType = locType === 'episcopalarea' ? 'Episcopal Area' : locType;
        entities.forEach(({ entity, active, primary, permissionId }: LocationDefinition) => {
          locationDetails.push({
            type: `${locType.charAt(0).toUpperCase() + locType.slice(1)}`,
            entity,
            active,
            primary,
            permissionId
          });
        });
      });
    });

    setAdminDistricts(locationDetails.filter((loc) => loc.type === 'District'));
  }, []);

  if (!advisorRole) {
    return <></>;
  }

  return (
    <form
      className="space-y-4"
      onSubmit={async (e) => {
        e.preventDefault();

        if (!advisor || !participant || (!hasDistrict && !addDistrict)) {
          return;
        }

        if (!hasDistrict && addDistrict) {
          await addLocationPermissions({
            userIds: [participant.id],
            entity: addDistrict.type,
            entityId: addDistrict.entity.id
          });
        }

        onAdd({
          participant,
          assignee: advisor,
          role: advisorRole
        });
      }}
    >
      <p>Add a participant to this track and assign them their first advisor.</p>
      <Select<User>
        name="Participant"
        placeholder="Participant who will be taking this track"
        instructions="Begin by typing full name of participant"
        options={async (searchTerm) => {
          if (!searchTerm) {
            return;
          }

          // separate object blocks act as ORs, conditions within object act as ANDs
          const userList = (
            await UserService.query({
              conditions: [
                {
                  name: searchTerm,
                  inactive: false
                },
                {
                  firstName: searchTerm,
                  inactive: false
                },
                {
                  lastName: searchTerm,
                  inactive: false
                }
              ],
              limit: 15
            })
          ).data;
          const optionList = await filterByCurrentUserLocation(userList, user);
          return (optionList || userList).sort((base: User, compare: User) =>
            base.lastName > compare.lastName ? 1 : base.lastName < compare.lastName ? -1 : 0
          );
        }}
        optionLabel={(participant) =>
          `${participant.firstName} ${participant.lastName} (${participant.email})`
        }
        onSelect={async (e) => {
          // this resets district logic on selecting a new user
          setHasDistrict(true);
          if (e.currentTarget.value) {
            setParticipant(e.currentTarget.value);
            const locations = await getLocationDetails(e.currentTarget.value.id);
            if (
              !locations ||
              !locations.district ||
              locations.district[0].filter((d: District) => d.active).length === 0
            ) {
              setHasDistrict(false);
              await fetchAdminLocations();
            }
          }
        }}
      />

      <Select<User>
        name="Advisor"
        placeholder="Advisor for this participant"
        instructions="Begin by typing full name of intended advisor"
        options={async (searchTerm) => {
          if (!searchTerm) {
            return;
          }

          const userList = (
            await UserService.query({
              conditions: [
                {
                  name: searchTerm,
                  inactive: false
                },
                {
                  firstName: searchTerm,
                  inactive: false
                },
                {
                  lastName: searchTerm,
                  inactive: false
                }
              ],
              relations: ['roles'],
              limit: 15
            })
          ).data.filter((u: User) => u.roles.find((r) => r.id === advisorRole.id));
          // filtering on Advisor role for query response in advisor Select
          const optionList = await filterByCurrentUserLocation(userList, user);
          return (optionList || userList).sort((base: User, compare: User) =>
            base.lastName > compare.lastName ? 1 : base.lastName < compare.lastName ? -1 : 0
          );
        }}
        optionLabel={(advisor) => `${advisor.firstName} ${advisor.lastName} (${advisor.email})`}
        onSelect={(e) => setAdvisor(e.currentTarget.value)}
      />
      {!hasDistrict ? (
        <div>
          <ErrorText className="pb-2">
            The selected participant needs to have a district added to their profile.
          </ErrorText>
          {adminDistricts && adminDistricts.length ? (
            <Select<LocationDefinition>
              className="grow"
              name="Location"
              options={adminDistricts}
              optionLabel={(location) => `${location.entity.name}`}
              onChange={(e) => setAddDistrict(e.target.value)}
            />
          ) : (
            <p>
              You currently do not have a district on your profile. Please contact an admin to
              resolve this issue to continue.
            </p>
          )}
        </div>
      ) : null}
      <Button type="submit" disabled={!advisor || !participant || (!hasDistrict && !addDistrict)}>
        Add Participant
      </Button>
    </form>
  );
}
