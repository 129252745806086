import {
  CreateTrackTemplateDTO,
  Queryable,
  TrackService,
  TrackTemplate,
  UserService
} from '@gbhem/api';
import create from 'zustand';

import { AppliedFilters, ConferenceFilter } from '../components';
import { OrderBy, orderBy, query } from '../utils/resource.helper';
import { immerMiddleware } from './utils';

export type TrackTemplateInstanceCount = TrackTemplate & { count?: number };

interface TrackStore extends TrackService {
  tracks: Record<string, TrackTemplateInstanceCount>;
  loading: boolean;
  filteredTracks: TrackTemplateInstanceCount[];
  filters: AppliedFilters;
  load: () => void;
  create: (track: CreateTrackTemplateDTO) => Promise<TrackTemplateInstanceCount>;
  // get: (
  //   id: string,
  //   relations?: RelationshipsOf<TrackTemplateInstanceCount>
  // ) => Promise<TrackTemplateInstanceCount>;
  update: (
    track: TrackTemplateInstanceCount,
    sync?: boolean
  ) => Promise<TrackTemplateInstanceCount>;
  deleteTrack: (id: string) => Promise<void>;
  query: (condtions: Queryable, values?: TrackTemplateInstanceCount[]) => any;
  applyFilter: (tracks: TrackTemplateInstanceCount[]) => void;
  setFilters: (filters: AppliedFilters) => void;
  deactivate: (
    track: TrackTemplateInstanceCount,
    sync?: boolean
  ) => Promise<TrackTemplateInstanceCount>;
}

export const useTrackStore = create<TrackStore>(
  immerMiddleware((set, get) => ({
    tracks: {},
    loading: false,
    filteredTracks: [],
    filters: {
      search: { value: false, active: false },
      activeTracks: { value: true, active: true },
      inactiveTracks: { value: false, active: false },
      conferences: { value: [], active: false }
    },
    load: async () => {
      set((state) => {
        state.loading = true;
      });
      const _tracks: TrackTemplateInstanceCount[] =
        await UserService.getCurrentUserTrackTemplates();
      const tracks = _tracks.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

      set((state) => {
        state.tracks = tracks.reduce<Record<string, TrackTemplateInstanceCount>>(
          (tracks, track) => {
            tracks[track.id] = track;

            return tracks;
          },
          {}
        );
      });

      set((state) => {
        state.loading = false;
      });
    },
    create: async (track: CreateTrackTemplateDTO) => {
      const createdTrack = await TrackService.createTrackTemplate(track);

      set((state) => {
        state.tracks[createdTrack.id] = createdTrack;
      });

      return createdTrack;
    },
    // get: async (id: string, relations?: RelationshipsOf<TrackTemplate>) => {
    //   const track = get().tracks[id];
    //   if (track && (relations as string[])?.every((r: any) => (track as any)[r!])) {
    //     return track;
    //   }

    //   set((state) => {
    //     state.loading = true;
    //   });
    //   track = await TrackService.findOne(id, relations);

    //   set((state) => {
    //     state.tracks[id] = track;

    //     state.loading = false;
    //   });

    //   return track;
    // },
    update: async (track: TrackTemplateInstanceCount, sync = true) => {
      const _count = track.count;
      delete track.count;

      if (sync) {
        track = await TrackService.update(track.id, track);
      }

      track.count = _count;

      set((state) => {
        state.tracks[track.id] = track;
      });

      return track;
    },
    deleteTrack: async (id: string) => {
      await TrackService.deleteTrackTemplate(id);

      set((state) => {
        const _tracks = { ...state.tracks };
        delete _tracks[id];
        state.tracks = _tracks;
      });

      const filtered: TrackTemplate[] = get().filteredTracks.filter(
        (track: TrackTemplate) => track.id !== id
      );

      set((state) => {
        state.filteredTracks = filtered;
      });

      get().applyFilter(get().filteredTracks);
    },
    query: (conditions: Queryable, values?: TrackTemplate[]) => {
      const tracks = get().tracks;

      const _tracks: TrackTemplate[] = values || Object.values(tracks);
      let results: TrackTemplate[] = query<TrackTemplate>(_tracks, conditions);

      if (!results) return [];

      if (conditions.orderBy) {
        // Probably need better way to pass a field to sort on.
        results = orderBy<TrackTemplate>(results, 'name', conditions);
      }

      return results;
    },
    applyFilter: (tracks: TrackTemplate[]) => {
      set((state) => {
        state.loading = true;
      });

      let _tracks: TrackTemplate[] = [...tracks];
      const { search, activeTracks, inactiveTracks, conferences } = get().filters;

      if (search.active) {
        _tracks = get().query(
          {
            conditions: [{ name: search.value }],
            orderBy: OrderBy.Ascending
          },
          _tracks
        );
      }

      if (activeTracks.active && !inactiveTracks.active) {
        _tracks = _tracks.filter((t) => t.disabled === false || t.disabled === null);
      }

      if (inactiveTracks.active && !activeTracks.active) {
        _tracks = _tracks.filter((t) => t.disabled === true);
      }

      if (conferences.active) {
        const activeConferences = [...(conferences.value as ConferenceFilter[])];
        const filteringTracks: TrackTemplate[] = [];

        activeConferences.forEach((ac) => {
          _tracks.forEach((t) => {
            if (ac.id === t.conference?.id || (ac.id === '0' && !t.conference)) {
              filteringTracks.push(t);
            }
          });
        });
        _tracks = [...filteringTracks];
      }

      set((state) => {
        state.filteredTracks = _tracks;
        state.loading = false;
      });
    },
    setFilters: (filters: AppliedFilters) => {
      set((state) => {
        state.filters = { ...filters };
      });
    },
    deactivate: async (track: TrackTemplate, sync = true) => {
      if (track.disabled) return track;

      if (sync) {
        track = await TrackService.deactivateTrackTemplate(track.id);
      }

      set((state) => {
        state.tracks[track.id] = track;
      });

      const filtered: TrackTemplate[] = get().filteredTracks.map((value: TrackTemplate) => {
        if (value.id === track.id) value = track;
        return value;
      });

      set((state) => {
        state.filteredTracks = filtered;
      });

      get().applyFilter(get().filteredTracks);

      return track;
    }
  }))
);

export default useTrackStore;
