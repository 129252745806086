export const TIME_IN_DAY: number = 60 * 60 * 24 * 1000;
export const TODAY: Date = new Date();
export const TODAY_STAMP: number =
  Date.now() - (Date.now() % TIME_IN_DAY) + new Date().getTimezoneOffset() * 1000 * 60;

export const TOTAL_COLS = 7;
export const TOTAL_ROWS = 6;

const TOTAL_DISPLAY_DAYS = 40;

export enum Months {
  January = 0,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December
}

export enum WeekDays {
  Sunday = 0,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export enum WeekDayAbbreviation {
  Sunday = 'S',
  Monday = 'M',
  Tuesday = 'T',
  Wednesday = 'W',
  Thursday = 'T',
  Friday = 'F',
  Saturday = 'S'
}

export enum Actions {
  ChangeCurrent = 'CHANGE_CURRENT',
  ChangeYear = 'CHANGE_YEAR',
  ChangeMonth = 'CHANGE_MONTH',
  ChangeAll = 'CHANGE_ALL'
}

export interface DateData {
  currentDay: number;
  day: number;
  month: number;
  timeStamp: number;
  dayStr: string;
}

export interface DateString {
  year: number;
  month: number;
  date: number;
}

/**
 * Creates a 6x7 Matrix for the (42)days of the month.
 *
 * {@param year} Gives the year as a number value.
 * {@param month} Gives the month as a number. The month will be -1. IE June is the 6th month, but will be 5.
 * {@rreturn} A matrix with all the days that will need to be displayed.
 */
export const monthlyDetails = (year: number, month: number) => {
  //First Day for the month.
  const firstDay = new Date(year, month).getDay();
  //Number of days in the month.
  const totalDays = calculateNumberOfDays(year, month);
  //Return Matrix
  const monthData: DateData[] = [];
  //Will store all the current data for a single day. This is what will be stored
  //in the array to be published.
  let curr: DateData;
  //Will be used to get the day of the week.
  //0-6 Sunday = 0 ... Saturday = 6
  let index = 0;

  //Creating the matrix.
  for (let i = 0; i < TOTAL_ROWS; i++) {
    for (let j = 0; j < TOTAL_COLS; j++) {
      //Information needed to get the information for single day, and store
      //it back into the matrix.
      curr = dayDetails({
        index,
        totalDays,
        firstDay,
        year,
        month
      });
      monthData.push(curr);
      index++;
    }
  }
  return monthData;
};

/**
 * Creates an Object of DateData to be stored in the matrix for the calendar.
 * This will give all the information needed for that day.
 * Called from the {@func monthlyDetails()}
 *
 * {@param args an Object that holds {index, totalDays, firstDay, year, month}}
 */
const dayDetails = ({ ...args }) => {
  //Gives the days of the matrix. Negative values will be previous month.
  // 0 will be the start of the current month.
  const date: number = args.index - args.firstDay;
  //Gives the day of the week.
  const dayNumber: number = args.index % 7;
  //Check to handle going back in time. Jaunary to December.
  const prevMonth: number = args.month - 1 < 0 ? 11 : args.month - 1;
  //Check to handle going back in time. Swap year -1 if going January to December.
  const prevYear: number = args.month - 1 < 0 ? args.year - 1 : args.year;
  //Get total days for the previous month.
  const prevMonthDays: number = calculateNumberOfDays(prevYear, prevMonth);

  //Gets the day number to be displayed.
  // if the {@date} is less than 0, it will be previous month, so we need to get the last days of that month.
  // Otherwise we add one to the {@args.totalDays} to get the normal day.
  const _date: number = (date < 0 ? prevMonthDays + date : date % args.totalDays) + 1;
  //-1 for previous month, 0 for current month, 1 for future month.
  const month: number = date < 0 ? -1 : date >= args.totalDays ? 1 : 0;
  //Creates a unique TimeStamp for the day. This is what gives us the unique values.
  const timeStamp: number = new Date(args.year, args.month + month, _date).getTime();
  //Return back to be stored in the matrix.
  const day: DateData = {
    currentDay: _date,
    day: dayNumber,
    month: month,
    timeStamp: timeStamp,
    dayStr: WeekDays[dayNumber]
  };
  return day;
};

/**
 * Gets the total days for each month.
 */
export const calculateNumberOfDays = (year: number, month: number) => {
  return TOTAL_DISPLAY_DAYS - new Date(year, month, 40).getDate();
};

/**
 * Parses a TimeStamp to give a YYYY-MM-DD display.
 */
export const dateFromTimeStamp = (timeStamp: number) => {
  const dateObj: Date = new Date(timeStamp);
  const month: number = dateObj.getMonth() + 1;
  const date: number = dateObj.getDate();

  return `${dateObj.getFullYear()}-${zeroPad(month, 2)}-${zeroPad(date, 2)}`;
};

/**
 * Parse a date string into an object with the components broken out.
 */
export const dateFromDateStr = (dateStr: string) => {
  const dateArr = dateStr.split('-').map((v) => parseInt(v, 10));
  if (dateArr.length < 3) return null;
  const ret: DateString = {
    year: dateArr[0],
    month: dateArr[1],
    date: dateArr[2]
  };
  return ret;
};

/**
 * Adds a 0 to the front of a day or month if it is needed.
 * IE June is 6, so we will make it 06 to be used for the display.
 */
const zeroPad = (value: number, length: number) => {
  return `${value}`.padStart(length, '0');
};
