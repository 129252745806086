import styled from '@emotion/styled';
import tw, { TwStyle } from 'twin.macro';

export enum BoldHeaderOptions {
  Xs = 'xs',
  Sm = 'sm',
  Bs = 'bs',
  Lg = 'lg',
  Xl = 'xl',
  Xxl = 'xxl',
  Xxxl = 'xxxl',
  None = 'none'
}

const sizes: TwStyle = {
  //.75rem
  xs: tw`font-bold text-xs`,
  //.875rem
  sm: tw`font-bold text-sm`,
  //1rem
  bs: tw`font-bold text-base`,
  //1.125rem
  lg: tw`font-bold text-lg`,
  //1.25rem
  xl: tw`font-bold text-xl`,
  //1.5rem
  xxl: tw`font-bold text-2xl`,
  //1.875rem
  xxxl: tw`font-bold text-3xl`
};

const margins: TwStyle = {
  //No margin
  none: tw`my-0`,
  //10px / .625rem
  xs: tw`my-2.5`,
  //16px / 1rem
  sm: tw`my-4`,
  //20px / 1.25rem
  bs: tw`my-5`,
  //25px / 1.5rem
  lg: tw`my-6`,
  //32px / 2rem
  xl: tw`my-8`,
  //40px / 2.5rem
  xxl: tw`my-10`
};

const Instructions = styled.label`
  ${tw`text-sm`}
`;

const Header = styled.h1(({ fontSize, marginSize }: any) => [margins[marginSize], sizes[fontSize]]);

type HeaderProps = {
  name: string;
  fontSize: BoldHeaderOptions;
  marginSize: BoldHeaderOptions;
  instructions?: string;
};

/**
 * {@param value} String value to be used as the header text.
 * {@param size} String value to represent the size of the text.
 *
 * The values that can be used for size can be found here:
 */
export function BoldHeader({ name, fontSize, marginSize, instructions }: HeaderProps) {
  return (
    <div>
      <Header fontSize={fontSize} marginSize={marginSize}>
        {name}
      </Header>
      {instructions && <Instructions>{instructions}</Instructions>}
    </div>
  );
}

export default BoldHeader;
