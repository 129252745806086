import styled from '@emotion/styled';
import { TrackRoles } from '@gbhem/api';
import { ComponentProps } from 'react';
import { useHistory } from 'react-router-dom';
import tw from 'twin.macro';

import { ArrowRight, Card, CardBody, CardHeader } from '..';

const Container = styled(Card)`
  ${tw`flex p-6`}
`;

const Header = styled(CardHeader)`
  ${tw`flex items-center justify-between mb-4 text-base font-normal cursor-pointer`}

  svg {
    ${tw`flex-shrink-0`}

    height: 1rem;
    width: 1.5rem;
  }
`;

const Title = styled.div`
  ${tw`flex items-center flex-1 text-xl font-bold gap-4`}
`;

const Body = styled(CardBody)`
  ${tw`flex items-start p-0`}
`;

const GoTo = styled(ArrowRight)``;

const RolePill = styled.div`
  ${tw`flex items-center px-3 border font-bold rounded-full cursor-pointer bg-secondary border-secondary space-x-2`}
`;

type TrackProperties = ComponentProps<'div'> & {
  track: TrackRoles;
};

export function TrackRoleSummary({ track, ...props }: TrackProperties) {
  const router = useHistory();

  return (
    <Container {...props}>
      <Header>
        {
          // TODO (eliot) change url to /participant
        }
        <Title onClick={() => router.push(`/tracks/${track.id}/participants`)}>
          {track.name}
          <GoTo />
        </Title>
      </Header>
      <Body>
        {track.roles.map((roleName) => (
          <RolePill key={roleName}>{roleName}</RolePill>
        ))}
      </Body>
    </Container>
  );
}

export default TrackRoleSummary;
