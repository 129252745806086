import { Queryable } from '@gbhem/api';

export enum OrderBy {
  Ascending = 'ASC',
  Descending = 'DESC'
}

/**
 * This function is built to give our front-end the ability to search data without needing to query the API.
 * It is built to mirror the query function we have in the back-end.
 *
 * We check each value passed in against the conditions that are passed in.
 * The conditions are based around Queryable.
 * In this function we focus on the 'limit' and 'conditions' fields from Queryable.
 *
 * The input for each condition object needs to follow a specific format:
 * conditions: [{ name: 'foo', id: 'bar' }, { disabled: true }]
 *
 * The above conditions are saying, we want to find values where:
 * The name field of an object is equal to 'foo' AND the id field of the object is 'bar' OR the object has a field where disabled is true.
 *
 * Multiple objects in the array are considered an OR statement.
 * Objects with multiple fields in the same object are considered an AND statement.
 */
export const query = <T>(values: T[], conditions: Queryable): T[] => {
  const LIMIT: number | undefined = conditions.limit;
  const DATASET_MAX: number = values.length;

  const results: T[] = [];

  for (let i = 0; i < DATASET_MAX; i++) {
    if (LIMIT && results.length >= LIMIT) break;

    if (conditions.conditions) {
      // conditionOrCheck() is the start of the compare process to check a value against all conditions.
      // If valid, we add it to the results.
      if (conditionOrCheck<T>(conditions.conditions, values[i])) {
        if (LIMIT && results.length < LIMIT) {
          results.push(values[i]);
          if (results.length === LIMIT) break; // Limit reached check
        } else {
          // No limits, add everything
          results.push(values[i]);
        }
      }
      // No condtions passed, so just fill results.
    } else {
      if (LIMIT && results.length < LIMIT) {
        results.push(values[i]);
        if (results.length === LIMIT) break;
      } else {
        results.push(values[i]);
      }
    }
  }

  return results;
};

export const orderBy = <T>(values: T[], field: keyof T, conditions: Queryable) => {
  if (conditions.orderBy === OrderBy.Ascending) {
    return values.sort((a, b) => (a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0));
  }

  if (conditions.orderBy === OrderBy.Descending) {
    return values.sort((a, b) => (b[field] > a[field] ? 1 : b[field] < a[field] ? -1 : 0));
  }

  return values;
};

/**
 * This loop acts as the OR check for each condition in the array.
 * If a term passes the condition check, we can return true because of OR logic.
 *
 * The way we know if a term is valid is by passing it to conditionAndCheck().
 * This function just allows us to pass the term into multiple different conditions.
 */
const conditionOrCheck = <T>(conditions: any[], term: T): boolean => {
  let isValid: boolean = false;

  for (let i = 0; i < conditions.length; i++) {
    // conditionAndCheck() is the actual compare process on whether or not a value passes a single set of conditions.
    if (conditionAndCheck(conditions[i], term)) {
      isValid = true;
      break;
    }
  }

  return isValid;
};

/**
 * This loop acts as the AND check for a single condition.
 *
 * This function will compare a single term to all the requirements in a condition.
 * If a term does not pass each requirements, it will fail and return false.
 *
 * Each requirement follows the format of:
 * { name: 'foo' }
 * where name is the field we want to compare, and 'foo' being the value we want to compare.
 */
const conditionAndCheck = <T>(conditions: any, term: T): boolean => {
  let isValid: boolean = true;
  let valueToCheck: any = null;
  let valueToCompareAgainst: any = null;

  const requirements = Object.keys(conditions);

  for (let i = 0; i < requirements.length; i++) {
    // Sets the value that we want to check to see is valid.
    valueToCheck = term[requirements[i] as keyof T];
    // Sets the value we want to compare against.
    valueToCompareAgainst = conditions[requirements[i]];

    // We want to check strings separately to see if they contain values, and not exact match
    if (typeof valueToCheck === 'string') {
      const isMatch: boolean = String(valueToCheck)
        .toLocaleLowerCase()
        .includes(String(valueToCompareAgainst).toLocaleLowerCase());
      if (!isMatch) {
        isValid = false;
        break;
      }
    } else {
      // Check for all other primitive types.
      if (valueToCheck !== valueToCompareAgainst) {
        isValid = false;
        break;
      }
    }
  }

  return isValid;
};
