import {
  AdminService,
  Conference,
  ConferenceService,
  District,
  DistrictService,
  Permission,
  Role,
  RoleService,
  TrackService,
  User,
  UserService
} from '@gbhem/api';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Column } from 'react-table';
import { useAsync, useAsyncFn } from 'react-use';

import { Can, useAuthenticatedUser, useRoles } from '../../providers';
import {
  Button,
  CardFooter,
  ChevronDown,
  ColumnLink,
  Dismissable,
  ErrorText,
  Input,
  Loader,
  Modal,
  Option,
  OptionsMenu,
  Select,
  SelectFilter,
  SubNavbar,
  Table
} from '..';
import { AddPermissionsModal } from '../AddPermissionsModal';
import { CardBody } from '../Card';
import { UserSignUp } from '../UserSignUp/UserSignUp';

type ExpandedUser = User & {
  conferences?: Conference[];
  districts?: District[];
  createdAtDates?: string[];
};

export function UsersTable() {
  const authUser = useAuthenticatedUser();
  const { system } = useRoles();

  const triggerReload = useRef<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>();

  const [assignableRoles, setAssignableRoles] = useState<Role[]>();
  const [selectedUsersRoles, setSelectedUsersRoles] = useState<Role[]>();

  const [selectedRole, setSelectedRole] = useState<Role>();

  const [disableRoleButton, setDisableRoleButton] = useState(true);

  const [showAssignRoleModal, hideAssignRoleModal] = useModal(
    () => (
      <Modal name="Assign Role to Users" onClose={() => onCloseRoleModal(hideAssignRoleModal)}>
        {({ close }) => (
          <>
            <Select
              name="Role"
              placeholder="Select role..."
              options={assignableRoles || []}
              onChange={(e) => setSelectedRole(e.target.value)}
            />
            {selectedUsers?.map((user) => (
              <div key={user.id}>
                {user.firstName} {user.lastName}
              </div>
            ))}
            <div>
              <Button
                disabled={!selectedRole}
                onClick={async () => {
                  await submitAssignRole();
                  close();
                }}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </Modal>
    ),
    [selectedUsers, assignableRoles, setSelectedRole, selectedRole]
  );

  const [showRemoveRoleModal, hideRemoveRoleModal] = useModal(() => {
    // remove duplicate roles from list of selected users' roles
    // and remove any roles that are not an assignable role
    const roleIds = selectedUsersRoles?.map((r) => r.id);
    const uniqueRoles = selectedUsersRoles?.filter(
      (r, index) =>
        !roleIds?.includes(r.id, index + 1) &&
        assignableRoles?.some((assignableRole) => assignableRole.id === r.id)
    );

    return (
      <Modal name="Remove Role From Users" onClose={() => onCloseRoleModal(hideRemoveRoleModal)}>
        {({ close }) => (
          <>
            <Select
              name="Role"
              placeholder="Select role..."
              options={uniqueRoles || []}
              onChange={(e) => setSelectedRole(e.target.value)}
            />
            {selectedUsers?.map((user) => (
              <div key={user.id}>
                {user.firstName} {user.lastName}
              </div>
            )) || []}
            <div>
              <Button
                disabled={!selectedRole}
                onClick={async () => {
                  await submitRemoveRole();
                  if (selectedRole?.name === 'Mentor') {
                    await UserService.removeMentees(selectedUsers?.map((u) => u.id) || []);
                  }
                  close();
                }}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </Modal>
    );
  }, [selectedUsers, selectedUsersRoles, setSelectedRole, selectedRole]);

  const onCloseRoleModal = (hideModal: () => void) => {
    hideModal();
    setSelectedRole(undefined);
  };

  const [showAssignLocationModal, hideAssignLocationModal] = useModal(
    () => (
      <AddPermissionsModal
        users={selectedUsers || []}
        onClose={() => {
          hideAssignLocationModal();
        }}
      />
    ),
    [selectedUsers]
  );

  const [showAssignChurchMembershipModal, hideChurchMembershipModal] = useModal(
    () => (
      <AddPermissionsModal
        users={selectedUsers || []}
        isAddingChurchMembership={true}
        onClose={() => {
          hideChurchMembershipModal();
        }}
      />
    ),
    [selectedUsers, setSelectedUsers]
  );

  const [showConfirmRemoveUsers, hideConfirmRemoveUsers] = useModal(
    () => (
      <Modal
        name="Confirm User Deactivation"
        onClose={() => onCloseRoleModal(hideConfirmRemoveUsers)}
      >
        {({ close }) => {
          const invalidUser: boolean =
            selectedUsers?.some((user: User) => user.id === authUser.id) || false;
          return (
            <>
              <CardBody>
                {`Are you sure you wish to deactivate the following ${
                  selectedUsers && selectedUsers?.length > 1 ? 'users' : 'user'
                }?`}
                <ul>
                  {selectedUsers?.map((u) => (
                    <li key={u.id}>{`${u.firstName} ${u.lastName}`}</li>
                  ))}
                </ul>
                {invalidUser ? <ErrorText>Cannot deactivate your own account. </ErrorText> : null}
              </CardBody>
              <CardFooter>
                <Button inverted onClick={() => close()}>
                  Cancel
                </Button>
                <Button
                  onClick={async () => {
                    if (selectedUsers) {
                      await AdminService.deactivateUser(selectedUsers?.map((u) => u.id));
                      triggerReload.current = true;
                    }
                    close();
                  }}
                  disabled={invalidUser}
                >
                  Confirm
                </Button>
              </CardFooter>
            </>
          );
        }}
      </Modal>
    ),
    [selectedUsers]
  );

  const [showUserSignUp, hideUserSignUp] = useModal(
    () => (
      <Modal name="Add New User" onClose={() => onCloseRoleModal(hideUserSignUp)}>
        {({ close }) => <UserSignUp onClose={close} />}
      </Modal>
    ),
    []
  );

  const [showConfirmReinvite, hideConfirmReinvite] = useModal(
    () => (
      <Modal name="Confirm Reinvite User" onClose={() => hideConfirmReinvite()}>
        {({ close }) => (
          <>
            <CardBody>
              {`Are you sure you wish to reinvite the following ${
                selectedUsers && selectedUsers?.length > 1 ? 'users' : 'user'
              }?`}
              <ul>
                {selectedUsers?.map((u) => (
                  <li key={u.id}>{`${u.firstName} ${u.lastName}`}</li>
                ))}
              </ul>
            </CardBody>
            <CardFooter>
              <Button inverted onClick={() => close()}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  if (selectedUsers) {
                    await AdminService.reinviteUser(selectedUsers?.map((u) => u.id));
                    triggerReload.current = true;
                  }
                  close();
                }}
              >
                Confirm
              </Button>
            </CardFooter>
          </>
        )}
      </Modal>
    ),
    [selectedUsers]
  );

  useAsync(async () => setAssignableRoles(await RoleService.getAssignableRoles()), []);

  useEffect(
    () => setDisableRoleButton(selectedUsers && selectedUsers?.length > 0 ? false : true),
    [selectedUsers]
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
            <Input type="checkbox" {...getToggleAllPageRowsSelectedProps()} />
          ),
          accessor: 'id',
          id: 'checkbox',
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row }) => <Input type="checkbox" {...row.getToggleRowSelectedProps()} />
        },
        {
          Header: 'NAME',
          accessor: 'firstName',
          id: 'name',
          disableSortBy: true,
          Cell: ({ row: { original: value } }) => (
            <ColumnLink to={`/users/${value.id}`}>
              {value.firstName} {value.lastName}
            </ColumnLink>
          )
        },
        {
          Header: 'EMAIL',
          accessor: 'email',
          id: 'email',
          Cell: ({ value }) => value
        },
        {
          Header: 'Roles',
          accessor: 'roles',
          id: 'roles',
          disableSortBy: true,
          Filter: SelectFilter(system || []),
          Cell: ({ value }) => value?.map((v: Role) => v.name).join(', ') || ''
        },
        {
          Header: 'Status',
          accessor: 'inactive',
          id: 'inactive',
          disableSortBy: false,
          disableFilters: true,
          Cell: ({ value }) => (value ? 'Inactive' : 'Active')
        },
        {
          Header: 'Conference',
          accessor: 'conferences',
          id: 'conferences',
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ value }) => {
            return value?.map((v: Conference) => v.name).join(', ') || '';
          }
        },
        {
          Header: 'District',
          accessor: 'districts',
          id: 'districts',
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ value }) => {
            return value?.map((v: District) => v.name).join(', ') || '';
          }
        },
        {
          Header: 'Created At',
          accessor: 'createdAt',
          id: 'createdAt',
          disableSortBy: false,
          disableFilters: true,
          Cell: ({ value }) => {
            const date = value?.split('T')[0];
            return `${date}` || '';
          }
        }
      ] as Column<ExpandedUser>[],

    [system]
  );

  const [, submitAssignRole] = useAsyncFn(async () => {
    if (!selectedRole || !selectedUsers?.length) {
      return;
    }

    const updatedUsers = await RoleService.addRoleToUsers({
      userIds: selectedUsers.map((u) => u.id),
      roleId: selectedRole.id
    });

    if (!updatedUsers.length) {
      return;
    }

    triggerReload.current = true;
  }, [selectedRole, selectedUsers, hideAssignRoleModal]);

  const openRemoveRoleModal = useCallback(() => {
    if (selectedUsers?.length) {
      const roles = selectedUsers.flatMap((u) => u.roles);
      setSelectedUsersRoles(roles);
      showRemoveRoleModal();
    }
  }, [selectedUsers, showRemoveRoleModal]);

  const [, submitRemoveRole] = useAsyncFn(async () => {
    if (!selectedRole || !selectedUsers?.length) {
      return;
    }

    const updatedUsers = await RoleService.removeRoleFromUsers({
      userIds: selectedUsers.map((u) => u.id),
      roleId: selectedRole.id
    });

    if (!updatedUsers.length) {
      return;
    }

    if (selectedRole?.name === 'Advisor') {
      await TrackService.removeAdvisorFromInstances(
        selectedUsers.map((selectedUser) => selectedUser.id)
      );
    }

    triggerReload.current = true;
  }, [selectedRole, selectedUsers, hideRemoveRoleModal]);

  if (!assignableRoles) {
    return <Loader message="Loading users..." />;
  }

  return (
    <>
      <SubNavbar className="p-4">
        <div className="font-bold">Users</div>
        <Dismissable
          control={
            <Button disabled={disableRoleButton}>
              Actions <ChevronDown style={{ pointerEvents: 'none' }} className="h-3 w-3" />
            </Button>
          }
        >
          <OptionsMenu>
            <Can do="update" on="Role">
              <Option onClick={showAssignRoleModal}>Assign role</Option>
            </Can>
            <Can do="update" on="Role">
              <Option onClick={openRemoveRoleModal}>Remove role</Option>
            </Can>
            <Can do="create" on="Permission">
              <Option onClick={showAssignLocationModal}>Assign location</Option>
            </Can>
            <Can do="create" on="ChurchMembership">
              <Option onClick={showAssignChurchMembershipModal}>Assign membership</Option>
            </Can>
            <Can do="deactivate" on="User">
              <Option onClick={showConfirmRemoveUsers}>Deactivate user</Option>
            </Can>
            <Can do="create" on="User">
              <Option onClick={showConfirmReinvite}>Reinvite user</Option>
            </Can>
          </OptionsMenu>
        </Dismissable>
        <Can do="create" on="User">
          <Button onClick={() => showUserSignUp()}>Add User</Button>
        </Can>
      </SubNavbar>
      <Table
        columns={columns}
        relations={['roles', 'permissions']}
        loader={async ({ page, size, filters, orderBy, relations }) => {
          const query = await UserService.getUsersByCurrentUser({
            conditions: filters,
            limit: size,
            offset: page * size,
            relations,
            orderBy
          });

          triggerReload.current = false;

          // Collect all user ids
          const userIds = query.data.map((user: User) => user.id);
          const userPermissions = await UserService.getUsersPermissions(userIds);

          const conferenceIds = new Set<string>();
          const districtIds: string[] = [];
          query.data.forEach((user: User, index: number) => {
            user.permissions = [];
            userPermissions.forEach((permission: Permission) => {
              // Add permissions to user.
              if (permission.user.id === user.id) {
                user.permissions.push(permission);
              }
              // Add permissions to conference set
              if (permission.entity === 'Conference') {
                conferenceIds.add(permission.entityId);
              }

              if (permission.entity === 'District') {
                districtIds.push(permission.entityId);
              }
            });
            // Set user permissions
            query.data[index] = user;
          });

          // Fetch all the conference information for all the conferences.
          const conferences = await ConferenceService.getConferences([...conferenceIds]);

          const districts = await DistrictService.getDistrictsByIds(districtIds);
          // Modify the query.data to allow for the new conference column.
          const _data = query.data.map((user: ExpandedUser) => {
            user.conferences = [];
            conferences.forEach((conference) => {
              //Check to see if the current conference exists in the users permissions.
              const hasConference = user.permissions.filter(
                (permission) => permission.entityId === conference.id && permission.active
              );

              // If the user has the permission
              // Add it to the conferences field.
              if (hasConference && hasConference.length > 0 && user.conferences) {
                user?.conferences.push(conference);
              }
            });

            user.districts = [];
            districts.forEach((district) => {
              const hasDistrict = user.permissions.filter(
                (permission) => permission.entityId === district.id
              );

              if (hasDistrict && hasDistrict.length && user.districts)
                user.districts.push(district);
            });
            return user;
          });
          query.data = _data;

          return query;
        }}
        reload={triggerReload.current}
        onSelect={setSelectedUsers}
        differentColor={(u: User) => !!u.inactive}
      />
    </>
  );
}
