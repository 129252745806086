import {
  DetailedHTMLProps,
  DragEvent as SyntheticDragEvent,
  forwardRef,
  HTMLAttributes,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';

const disableNativeDrag = (e: DragEvent | SyntheticDragEvent<HTMLDivElement>) => {
  e.preventDefault();
  e.stopPropagation();
};

type DropAreaProps = {
  disabled?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const DropArea = forwardRef(
  ({ disabled, ...props }: DropAreaProps, ref: Ref<HTMLDivElement | null>) => {
    const dropElement = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => dropElement.current);

    const depth = useRef(0);

    useEffect(() => {
      document.addEventListener('dragover', disableNativeDrag);
      document.addEventListener('drop', disableNativeDrag);

      return () => {
        document.removeEventListener('dragover', disableNativeDrag);
        document.removeEventListener('drop', disableNativeDrag);
      };
    }, []);

    return (
      <>
        {!disabled ? (
          <div
            {...props}
            ref={dropElement}
            onDragEnter={(e) => {
              disableNativeDrag(e);
              depth.current++;

              props.onDragEnter?.(e);
            }}
            onDragOver={(e) => {
              disableNativeDrag(e);
              props.onDragOver?.(e);
            }}
            onDragLeave={(e) => {
              disableNativeDrag(e);
              depth.current--;

              if (depth.current === 0) {
                props.onDragLeave?.(e);
              }
            }}
            onDrop={(e) => {
              disableNativeDrag(e);
              depth.current = 0;

              props.onDrop?.(e);
            }}
          />
        ) : (
          <div
            {...props}
            onDragStart={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
            onDragOver={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
            onDrop={(e) => e.preventDefault()}
          />
        )}
      </>
    );
  }
);

export default DropArea;
