import { Policy, Role } from '@gbhem/api';
import { useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { Cancel, Plus } from '..';
import { Button } from '../Button';
import { Input } from '../Input';
import subjectHierarchy, { SubjectHierarchyDefinition } from './subjectHierarchy';

interface RoleProperties {
  role?: Role;
  onSubmit: (role: Role) => void;
}

export function RoleEditor({ role, onSubmit }: RoleProperties) {
  const {
    control,
    register,
    formState: { isDirty, isValid },
    reset,
    handleSubmit
  } = useForm({
    mode: 'all',
    defaultValues: role
      ? (role as { id: string; name: string; policies: Policy[] })
      : {
          name: '',
          policies: [{}]
        }
  });

  const { fields: policies, append, remove } = useFieldArray({ control, name: 'policies' });

  return (
    <form onSubmit={handleSubmit(onSubmit as any)} className="space-y-4">
      <Input {...register('name', { required: true })} />
      <div className="space-y-4">
        <div className="flex flex-col flex-wrap lg:flex-row lg:space-x-4">
          {policies.map((p, i) => (
            <Controller
              key={p.id}
              name={`policies.${i}`}
              rules={{
                required: true,
                validate: (p) => !!(p.action?.length && p.subject?.length)
              }}
              render={({ field: { value, onChange } }) => (
                <PolicyEditor
                  key={p.id}
                  value={value}
                  onChange={onChange}
                  onDelete={() => remove(i)}
                />
              )}
              control={control}
            />
          ))}
        </div>
        <Button inverted type="button" onClick={() => append({})}>
          <Plus /> Add Policy
        </Button>
      </div>
      <div className="flex space-x-2">
        <Button type="submit" disabled={!(isDirty && isValid)}>
          Save
        </Button>
        <Button
          inverted
          type="reset"
          disabled={!isDirty}
          onClick={() =>
            reset({
              name: role?.name || '',
              policies: role?.policies || [{}]
            })
          }
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}

interface PolicyProperties {
  value: Policy;
  onChange: (policy: Policy) => void;
  onDelete?: () => void;
}

const actionOptions = ['create', 'read', 'update', 'delete'] as const;

export function PolicyEditor({ value: policy, onChange, onDelete }: PolicyProperties) {
  const [actions, setActions] = useState(
    !policy.action ? [] : Array.isArray(policy.action) ? policy.action : [policy.action]
  );
  const allowsAllActions = useMemo(
    () => actionOptions.every((o) => actions.includes(o)),
    [actions]
  );

  return (
    <div className="space-y-4 flex-1">
      <div className="space-y-2">
        <div className="flex items-center">
          <div>Policy</div>
          <Cancel className="ml-auto h-3 w-3 text-neutral-500 cursor-pointer" onClick={onDelete} />
        </div>
        <div className="font-semibold text-sm">Actions</div>
        <div className="flex space-x-2">
          <Input
            type="checkbox"
            name="all"
            checked={allowsAllActions}
            onChange={() => {
              const _actions = allowsAllActions ? [] : [...actionOptions];

              setActions(_actions);
              onChange({ ...policy, action: _actions });
            }}
          />
          <div className="bg-primary w-0.5 flex" />
          {actionOptions.map((action) => (
            <Input
              key={action}
              name={action}
              type="checkbox"
              checked={actions.includes(action)}
              onChange={() => {
                const _actions = actions.includes(action)
                  ? actions.filter((a) => a !== action)
                  : [...actions, action];

                setActions(_actions);
                onChange({ ...policy, action: _actions });
              }}
            />
          ))}
        </div>
      </div>
      <div>
        <div className="font-semibold text-sm">Subjects</div>
        <PolicySubjectEditor value={policy} onChange={onChange} />
      </div>
    </div>
  );
}

function getHierarchy(hierarchy: SubjectHierarchyDefinition[]): string[] {
  return hierarchy.flatMap(({ subject, children }) => [subject, ...getHierarchy(children || [])]);
}

type SubjectHierarchyProperties = SubjectHierarchyDefinition & {
  hierarchy: string[];
  onChange: (hierarchy: string[]) => void;
};

function SubjectHierarchy({
  subject,
  alias,
  children,
  hierarchy,
  onChange
}: SubjectHierarchyProperties) {
  return (
    <div className={`flex flex-col mt-2 ${children?.length ? 'pl-1 border-l-2' : ''}`}>
      <Input
        name={alias || subject}
        type="checkbox"
        checked={hierarchy.includes(subject)}
        onChange={() => {
          onChange(
            hierarchy.includes(subject)
              ? hierarchy.filter((s) => s !== subject)
              : [
                  ...hierarchy,
                  ...getHierarchy([{ subject, children }]).filter((h) => !hierarchy.includes(h))
                ]
          );
        }}
      />
      <div className="pl-3">
        {children?.map((c) => (
          <SubjectHierarchy key={c.subject} {...c} hierarchy={hierarchy} onChange={onChange} />
        ))}
      </div>
    </div>
  );
}

export function PolicySubjectEditor({ value: policy, onChange }: PolicyProperties) {
  const [subjects, setSubjects] = useState(
    !policy.subject ? [] : Array.isArray(policy.subject) ? policy.subject : [policy.subject]
  );

  return (
    <div className="flex flex-col space-y-4">
      {subjectHierarchy.map((s) => (
        <SubjectHierarchy
          key={s.subject}
          {...s}
          hierarchy={subjects}
          onChange={(subject) => {
            setSubjects(subject);
            onChange({ ...policy, subject });
          }}
        />
      ))}
    </div>
  );
}

export default RoleEditor;
