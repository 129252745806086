import styled from '@emotion/styled';
import {
  File,
  FileService,
  FormInstance,
  FormService,
  RoleService,
  StepActionInstance,
  StepActionTemplate,
  StepContentInstance,
  StepContentTemplate,
  StepInstance as StepInstanceModel,
  StepService,
  TaskInstance,
  TrackService,
  UserService
} from '@gbhem/api';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import {
  Button,
  Cancel,
  Card,
  CheckmarkThick,
  ChevronLeft,
  FilePreviews,
  FileUpload,
  Form,
  Input,
  Loader,
  Locked,
  Select,
  StepStatus,
  SubNavbar,
  SubNavLink
} from '../../components';
import { UploadType } from '../../components/UploadEditor';
import { SystemRole, useAuthenticatedUser, useRoles } from '../../providers';
import {
  appendInnerField,
  buildStringIds,
  buildStringIdsByKey,
  createEntityObject
} from '../../utils';

const Container = styled.div`
  ${tw`overflow-y-auto h-full p-4`}
`;

const Element = styled(Card)`
  ${tw`relative p-0`}
`;

const Section = styled.div`
  ${tw`space-y-4`}
`;

const StepDescriptionContainer = styled.div`
  a {
    ${tw`text-gray-900 underline`}
  }
`;

export function StepHeader({ instance }: StepInstanceProperties) {
  return (
    <SubNavbar className="sticky top-0 z-10">
      <SubNavLink
        to={`/tracks/${instance.trackInstance.trackTemplate.id}/assignments/${instance.trackInstance.id}`}
      >
        <ChevronLeft /> <span>Track</span>
      </SubNavLink>
      <StepLabel instance={instance} />
    </SubNavbar>
  );
}

function StepLabel({ instance }: StepInstanceProperties) {
  return (
    <div>
      <div className="flex items-center space-x-2">
        <div className="font-semibold">{instance.stepTemplate.name}</div>
        <div className="flex text-xs space-x-2 bg-purple font-semibold px-2 py-1 rounded-full relative">
          <span>{instance.status}</span>
        </div>
      </div>
      <div className="text-sm text-neutral-300">{instance.trackInstance.trackTemplate.name}</div>
    </div>
  );
}

function SectionHeader({ header, description }: { header: string; description: string }) {
  return (
    <div className="px-4">
      <div className="text-lg font-semibold">{header}</div>
      <p className="text-sm text-neutral-200">{description}</p>
    </div>
  );
}

function useStepComponentRole(
  step: StepInstanceModel,
  component: StepContentTemplate | StepActionTemplate
) {
  const { system, hasTrackOverrideRole } = useRoles();
  const user = useAuthenticatedUser();

  const ParticipantRole = system.find((r) => r.name === SystemRole.Participant);
  const isParticipant = step.trackInstance.participant.id === user.id;

  const assignment = step.trackInstance.assignees.find((a) => a.assignee.id === user.id);
  const conferenceId =
    step.trackInstance.trackTemplate.conference !== null
      ? createEntityObject(step.trackInstance.trackTemplate.conference)
      : undefined;

  const isResponsible =
    (isParticipant && component.role.id === ParticipantRole?.id) ||
    assignment?.role.id === component.role.id ||
    hasTrackOverrideRole(user, step.trackInstance.participant, conferenceId?.id);

  return {
    isParticipant,
    isResponsible,
    assignment
  };
}

interface StepInstanceProperties {
  instance: StepInstanceModel;
}

export function IfInstanceAvailable({
  instance,
  children
}: PropsWithChildren<StepInstanceProperties>) {
  if (instance.status === StepStatus.Unavailable) {
    return (
      <div className="p-12 text-xl text-center flex flex-col items-center space-y-4">
        <div className="p-4 text-center rounded-full bg-secondary opacity-80">
          <Locked className="h-16 w-16" />
        </div>
        <p>
          This step is not yet available. Please return to your{' '}
          <Link
            className="underline text-primary"
            to={`/tracks/${instance.trackInstance.trackTemplate.id}/assignments/${instance.trackInstance.id}`}
          >
            Track.
          </Link>
        </p>
      </div>
    );
  }

  return <>{children}</>;
}

export function IfHasItems<ItemType, ItemListType extends Array<ItemType>>({
  items,
  children
}: PropsWithChildren<{ items: ItemListType }>) {
  if (!items.length) {
    return null;
  }

  return <>{children}</>;
}

export function StepInstance() {
  const { id } = useParams<{ id: string }>();

  const { system, hasTrackOverrideRole } = useRoles();

  const user = useAuthenticatedUser();

  const [instance, setInstance] = useState<StepInstanceModel>();
  const [changeStatus, setChangeStatus] = useState(false);
  const [isAdvisor, setIsAdvisor] = useState<boolean>();
  const [isParticipant, setIsParticipant] = useState<boolean>();

  useAsync(async () => {
    const _stepInstance = await StepService.getStepInstance(id);
    const [_stepTemplate, _contentInstances, _actionInstances, _trackInstance] = await Promise.all([
      StepService.getStepTemplate(String(_stepInstance.stepTemplate)),
      StepService.getStepContentInstances(buildStringIds(_stepInstance.stepContentInstances)),
      StepService.getStepActionInstances(buildStringIds(_stepInstance.stepActionInstances)),
      TrackService.getTrackInstance(createEntityObject(_stepInstance.trackInstance).id)
    ]);
    const [
      _contentTemplate,
      _actionTemplates,
      _participant,
      _participantPermissions,
      _assignees,
      _formInstance,
      _attachments,
      _taskInstances,
      _uploadInstances,
      _actionInstancesUser
    ] = await Promise.all([
      StepService.getStepContentTemplates(
        buildStringIds(_contentInstances.map((sci) => sci.stepContentTemplate))
      ),
      StepService.getStepActionTemplates(
        buildStringIds(_actionInstances.map((sat) => sat.stepActionTemplate))
      ),
      UserService.getUser(createEntityObject(_trackInstance.participant).id),
      UserService.getUserPermissions(createEntityObject(_trackInstance.participant).id),
      TrackService.getTrackAssignees(buildStringIds(buildStringIds(_trackInstance.assignees))),
      FormService.getFormInstances(buildStringIdsByKey(_contentInstances, 'formInstance')),
      FileService.getFileInstances(buildStringIds(buildStringIds(_stepTemplate.attachments))),
      StepService.getTaskInstances(buildStringIdsByKey(_contentInstances, 'taskInstance')),
      FileService.getUploadInstances(buildStringIdsByKey(_contentInstances, 'uploadInstance')),
      UserService.getUsers(buildStringIdsByKey(_actionInstances, 'user'))
    ]);

    const [_formVersions, _stepActionRoles, _trackTemplate] = await Promise.all([
      FormService.getCurrentForms(_formInstance.map((fI) => fI.version.id)),
      RoleService.getRoles(buildStringIdsByKey(_actionTemplates, 'role')),
      TrackService.getTrackTemplate(createEntityObject(_trackInstance.trackTemplate).id)
    ]);

    const stepTemplates = appendInnerField(_actionTemplates, _stepActionRoles, 'role');
    const formInstances = appendInnerField(_formInstance, _formVersions, 'version');
    const actionInstacesWithUsers = appendInnerField(
      _actionInstances,
      _actionInstancesUser,
      'user'
    );
    const actionInstances = appendInnerField(
      actionInstacesWithUsers,
      stepTemplates,
      'stepActionTemplate'
    );

    _contentInstances.forEach((instance, index) => {
      const ct = _contentTemplate.filter(
        (template) => template.id === createEntityObject(instance.stepContentTemplate).id
      );
      const fi = formInstances.filter(
        (form) => instance.formInstance && form.id === createEntityObject(instance.formInstance).id
      );
      const ti = _taskInstances.filter(
        (task) => instance.taskInstance && task.id === createEntityObject(instance.taskInstance).id
      );
      const ui = _uploadInstances.filter(
        (upload) =>
          instance.uploadInstance && upload.id === createEntityObject(instance.uploadInstance).id
      );
      if (ct && ct.length > 0) _contentInstances[index].stepContentTemplate = ct[0];
      if (fi && fi.length > 0) _contentInstances[index].formInstance = fi[0];
      if (ti && ti.length > 0) _contentInstances[index].taskInstance = ti[0];
      if (ui && ui.length > 0) _contentInstances[index].uploadInstance = ui[0];
    });

    _trackInstance.participant = _participant;
    _trackInstance.participant.permissions = _participantPermissions;
    _trackInstance.assignees = _assignees;
    _trackInstance.trackTemplate = _trackTemplate;

    _stepTemplate.attachments = _attachments;
    _stepTemplate.stepContentTemplates = _contentTemplate;
    _stepTemplate.stepActionTemplates = _actionTemplates;

    _stepInstance.stepActionInstances = actionInstances;
    _stepInstance.stepContentInstances = _contentInstances;
    _stepInstance.trackInstance = _trackInstance;
    _stepInstance.stepTemplate = _stepTemplate;

    setInstance(_stepInstance);
    setIsParticipant(_stepInstance.trackInstance.participant.id === user.id);

    const advisorRole = system.find((r) => r.name === SystemRole.Advisor);

    if (advisorRole) {
      setIsAdvisor(
        _stepInstance.trackInstance.assignees.some(
          (a) => a.role.id === advisorRole.id && a.assignee.id === user.id
        )
      );
    }
  }, [id, system]);

  useAsync(async () => {
    if (!changeStatus) return;
    const _stepInstance = instance;
    const status = (await StepService.getStepInstance(id)).status;
    if (_stepInstance) _stepInstance.status = status;
    setInstance(_stepInstance);
    setChangeStatus(false);
  }, [changeStatus]);

  const _hasTrackOverrideRole = useMemo(
    () =>
      instance
        ? hasTrackOverrideRole(
            user,
            instance.trackInstance.participant,
            instance.trackInstance.trackTemplate.conference
              ? createEntityObject(instance?.trackInstance.trackTemplate.conference).id
              : undefined
          )
        : false,
    [hasTrackOverrideRole, user, instance]
  );

  const allContentComplete = useMemo(
    () =>
      instance?.stepContentInstances.every(
        (c) => c.taskInstance?.completed || c.formInstance?.submitted || c.uploadInstance?.submitted
      ),
    [instance]
  );

  if (!instance || changeStatus) {
    return <Loader message="Loading step..." />;
  }

  return (
    <>
      <StepHeader instance={instance} />
      <Container>
        <Element>
          <div className="pt-4 px-4 pb-2">
            <div className="flex items-center justify-between space-x-2">
              <div className="font-semibold">{instance.stepTemplate.name}</div>
              <div>
                {!isParticipant && (isAdvisor || _hasTrackOverrideRole) && (
                  <Select
                    name="Status"
                    options={[StepStatus.Ready, StepStatus.PendingReview, StepStatus.Completed]}
                    onChange={async (e) => {
                      if (e.target.value) {
                        await StepService.overrideStepInstanceStatus(
                          instance.id,
                          e.target.value.replace(' ', '')
                        );
                        setChangeStatus(true);
                      }
                    }}
                    value={instance.status}
                  />
                )}
              </div>
            </div>
            <div className="text-sm text-neutral-300">
              {instance.trackInstance.trackTemplate.name}
            </div>
            {instance.stepTemplate.description ? (
              <StepDescriptionContainer
                className="text-neutral-200 text-lg"
                dangerouslySetInnerHTML={{ __html: instance.stepTemplate.description }}
              />
            ) : null}
          </div>
          <hr />

          <Section>
            <IfInstanceAvailable instance={instance}>
              <Section>
                <IfHasItems items={instance.stepTemplate.attachments}>
                  <div className="space-y-2">
                    <SectionHeader header="Attachments" description="Click to download" />
                    <FilePreviews files={instance.stepTemplate.attachments} />
                  </div>
                  <hr />
                </IfHasItems>

                <IfHasItems items={instance.stepContentInstances}>
                  <Section>
                    <SectionHeader
                      header="Content"
                      description="Complete or review each of the content items listed below."
                    />

                    <Section>
                      {instance.stepContentInstances.map((contentInstance) => {
                        return (
                          <div
                            className="bg-neutral-900 p-4"
                            key={contentInstance.stepContentTemplate.id}
                          >
                            <ContentContainer
                              step={instance}
                              content={contentInstance.stepContentTemplate}
                              instance={instance.stepContentInstances.find(
                                (i) =>
                                  i.stepContentTemplate.id ===
                                  contentInstance.stepContentTemplate.id
                              )}
                              onSubmit={async (contentInstanceId, contentInstance) => {
                                const updates = await StepService.updateContentInstance(
                                  contentInstanceId,
                                  contentInstance
                                );

                                setInstance({
                                  ...instance,
                                  stepContentInstances: instance.stepContentInstances.map((c) =>
                                    c.id === updates.id ? updates : c
                                  )
                                });
                              }}
                              onUnsubmit={async (contentInstanceId, contentInstance) => {
                                const updates = await StepService.updateContentInstance(
                                  contentInstanceId,
                                  contentInstance
                                );

                                setInstance({
                                  ...instance,
                                  stepContentInstances: instance.stepContentInstances.map((c) =>
                                    c.id === updates.id ? updates : c
                                  )
                                });
                              }}
                            />
                          </div>
                        );
                      })}
                    </Section>
                  </Section>
                </IfHasItems>
              </Section>

              <IfHasItems items={instance.stepActionInstances}>
                <SectionHeader
                  header="Actions"
                  description="Complete or review each of the action items listed below."
                />
                <Section>
                  <div
                    className={`p-4 pb-8 space-y-4 bg-neutral-900 ${
                      allContentComplete ? '' : 'pointer-events-none cursor-not-allowed'
                    }`}
                  >
                    {instance.stepActionInstances.map((actionInstance) => (
                      <Action
                        key={actionInstance.id}
                        step={instance}
                        action={actionInstance.stepActionTemplate}
                        instance={actionInstance}
                        disabled={
                          !user.roles.find(
                            (r) => r.id === actionInstance.stepActionTemplate.role.id
                          ) && !_hasTrackOverrideRole
                        }
                        onChange={async (actionInstanceId, approved) => {
                          const updates = await StepService.updateActionInstance(
                            actionInstanceId,
                            approved
                          );

                          setInstance({
                            ...instance,
                            stepActionInstances: instance.stepActionInstances.map((a) =>
                              a.id === actionInstanceId ? updates : a
                            )
                          });
                        }}
                      />
                    ))}
                  </div>
                </Section>
              </IfHasItems>
            </IfInstanceAvailable>
          </Section>
        </Element>
      </Container>
    </>
  );
}

type UploadInstanceData = {
  addFiles?: File[];
  removeFileId?: string;
  submitted?: boolean;
};

interface ContentProperties {
  step: StepInstanceModel;
  content: StepContentTemplate;
  instance?: StepContentInstance;
  onSubmit: (
    contentInstanceId: string,
    content: {
      instance: FormInstance | TaskInstance | UploadInstanceData;
      instanceType: 'Task' | 'Upload' | 'Form';
    }
  ) => Promise<void>;
  onUnsubmit: (
    contentInstanceId: string,
    content: {
      instance: FormInstance | TaskInstance | UploadInstanceData;
      instanceType: 'Task' | 'Upload' | 'Form';
    }
  ) => Promise<void>;
}

function ContentContainer({ step, content, instance, onSubmit, onUnsubmit }: ContentProperties) {
  const user = useAuthenticatedUser();
  const { hasTrackOverrideRole } = useRoles();
  const isSubmitted =
    instance?.taskInstance?.completed ||
    instance?.formInstance?.submitted ||
    instance?.uploadInstance?.submitted;

  const _hasTrackOverrideRole = useMemo(
    () =>
      hasTrackOverrideRole(
        user,
        step.trackInstance.participant,
        step.trackInstance.trackTemplate.conference
          ? createEntityObject(step.trackInstance.trackTemplate.conference).id
          : undefined
      ),
    [hasTrackOverrideRole, user, step]
  );

  const { isResponsible } = useStepComponentRole(step, content);

  if (!instance) {
    return <></>;
  }

  if (!isSubmitted && !isResponsible) {
    return (
      <>
        <div className="font-semibold">{content.name}</div>
        <div className="text-neutral-300 text-lg py-4 flex space-x-2 items-center">
          <div className="bg-neutral-900 border rounded-full p-2 h-8 w-8 flex items-center justify-center select-none">
            <b>!</b>
          </div>
          <p>
            Currently in progress by <b>{content.role.name}</b>
          </p>
        </div>
      </>
    );
  }

  if (isSubmitted && !isResponsible && content.isPrivate && !_hasTrackOverrideRole) {
    if (
      content.approvedRoles.length &&
      !content.approvedRoles.find((r) => user.roles.some((uR) => uR.id === r.id))
    ) {
      return (
        <>
          <div className="font-semibold">{content.name}</div>
          <div className="text-neutral-300 text-lg py-4 flex space-x-2 items-center">
            <div className="bg-neutral-900 border rounded-full p-2 h-8 w-8 flex items-center justify-center select-none">
              <b>!</b>
            </div>
            <p>This content contains sensitive data that you do not have permission to view.</p>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <div className="font-semibold">{content.name}</div>
      <div
        className={`${
          instance.formInstance?.submitted || instance.taskInstance?.completed
            ? 'cursor-not-allowed pointer-events-none'
            : ''
        }`}
      >
        <Content
          step={step}
          content={content}
          instance={instance}
          onSubmit={onSubmit}
          onUnsubmit={async (contentInstanceId, contentInstance) => {
            await onUnsubmit(contentInstanceId, contentInstance);
          }}
        />
      </div>
    </>
  );
}

function Content({
  content,
  instance: _instance,
  onSubmit,
  onUnsubmit
}: ContentProperties & { instance: StepContentInstance }) {
  const [instance, setInstance] = useState(_instance);

  useEffect(() => setInstance(_instance), [_instance]);

  useEffect(() => {
    switch (content.type) {
      case 'Task':
        if (!instance.taskInstance) {
          setInstance({
            ...instance,
            taskInstance: { completed: false }
          } as StepContentInstance);
        }
        return;

      case 'Upload':
        if (!instance.uploadInstance) {
          setInstance({
            ...instance,
            uploadInstance: { submitted: false }
          } as StepContentInstance);
        }
        return;

      case 'Form':
        if (!instance.formInstance) {
          setInstance({
            ...instance,
            formInstance: { data: {}, submitted: false, version: content.formTemplate?.current }
          } as StepContentInstance);
        }
        return;

      default:
        break;
    }
  }, [content, instance]);

  switch (content.type) {
    case 'Task':
      // eslint-disable-next-line no-case-declarations
      const task = instance.taskInstance;

      if (!content.taskTemplate) {
        return (
          <div>Something must have gone wrong... We can't find the content for this task.</div>
        );
      }

      if (!task) {
        return <></>;
      }

      return (
        <Section>
          <p className="text-sm text-neutral-200">{content.taskTemplate.description}</p>
          <div className="flex space-x-4">
            <Input
              type="checkbox"
              checked={task.completed}
              onChange={(e) => {
                onSubmit(instance.id, {
                  instance: { ...task, completed: e.target.checked },
                  instanceType: 'Task'
                });
                setInstance({
                  ...instance,
                  taskInstance: { ...task, completed: e.target.checked }
                });
              }}
            />
            <div>{content.taskTemplate.name}</div>
          </div>
        </Section>
      );
    case 'Upload':
      if (!content.uploadTemplate) {
        return (
          <div>Something must have gone wrong... We can't find the content for this upload.</div>
        );
      }

      if (!instance.uploadInstance) {
        return <></>;
      }

      return (
        <FileUpload
          name={content.uploadTemplate!.name}
          type={content.uploadTemplate!.type as UploadType}
          multiple={content.uploadTemplate!.multiple}
          files={(instance.uploadInstance?.uploadFiles || []).map((u) => u.file)}
          disable={instance.uploadInstance.submitted}
          submitted={instance.uploadInstance.submitted}
          instance={instance.uploadInstance}
          onUpload={(files) => {
            onSubmit(instance.id, {
              instance: {
                addFiles: files
              },
              instanceType: 'Upload'
            });
          }}
          onRemove={async (id: string) => {
            await FileService.removeUploadInstance(id);

            const removeUpload = instance.uploadInstance?.uploadFiles?.find(
              (f) => f.file.key === id
            );

            onSubmit(instance.id, {
              instance: {
                removeFileId: removeUpload?.id
              },
              instanceType: 'Upload'
            });
          }}
          onSubmit={() => {
            onSubmit(instance.id, {
              instance: {
                submitted: true
              },
              instanceType: 'Upload'
            });
          }}
          onUnsubmit={() => {
            onUnsubmit(instance.id, {
              instance: {
                submitted: false
              },
              instanceType: 'Upload'
            });
          }}
        />
      );
    case 'Form':
      if (!content.formTemplate) {
        return (
          <div>Something must have gone wrong... We can't find the content for this form.</div>
        );
      }

      if (!instance.formInstance) {
        return <></>;
      }

      return (
        <Form
          form={content.formTemplate}
          layout={instance.formInstance.version.layout}
          fields={instance.formInstance.version.fields}
          instance={instance.formInstance}
          submitted={instance.formInstance.submitted}
          onSubmit={async ({ data, submitted, fieldFiles }) => {
            if (!instance.formInstance) {
              return;
            }

            const formInstance = {
              id: instance.formInstance.id,
              version: {
                id: instance.formInstance.version.id
              },
              data,
              submitted,
              fieldFiles
            } as FormInstance;

            await onSubmit(instance.id, { instance: formInstance, instanceType: 'Form' });
          }}
          onUnsubmit={(formInstance: FormInstance) => {
            onUnsubmit(instance.id, {
              instance: { ...formInstance, submitted: false },
              instanceType: 'Form'
            });
          }}
        />
      );
  }
}

interface ActionProperties {
  step: StepInstanceModel;
  action: StepActionTemplate;
  instance: StepActionInstance;
  disabled?: boolean;
  onChange: (actionInstanceId: string, approved: boolean) => void;
}

function Action({ step, action, instance, disabled, onChange }: ActionProperties) {
  const { isResponsible } = useStepComponentRole(step, action);

  return (
    <div
      className={`space-y-2 ${instance.approved === null && !isResponsible ? 'opacity-50' : ''}`}
    >
      <div className="text-sm flex space-x-3">
        <div
          className="bg-neutral-900 border rounded-full p-2 h-8 w-8 flex items-center
          justify-center select-none"
        >
          {instance.approved === null && <div className="-mt-2.5 text-lg">...</div>}
          {instance.approved === true && <CheckmarkThick className="h-4 w-4 text-green-700" />}
          {instance.approved === false && <Cancel className="h-4 w-4 text-red-700" />}
        </div>
        <div>
          <div className="text-xs">
            <span className="font-semibold">{action.role.name}</span> -{' '}
            <span className="text-neutral-400">{action.stepActionType}</span>
          </div>
          <div>{action.name}</div>
          {instance.approved !== null && instance.user && (
            <div className="mt-2 text-xs">
              reviewed by {instance.user?.firstName} {instance.user?.lastName}{' '}
              <span className="text-neutral-300">
                {instance.updatedAt &&
                  new Date(instance.updatedAt).toLocaleTimeString([], {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
              </span>
            </div>
          )}
        </div>
        <div className="pl-4">
          {isResponsible && (
            <div className="flex space-x-2">
              <Button onClick={() => onChange(instance.id, true)} disabled={disabled}>
                <CheckmarkThick className="h-3 w-3" />
                {action.stepActionType === 'Approval' ? 'Approve' : 'Sign'}
              </Button>
              <Button onClick={() => onChange(instance.id, false)} inverted disabled={disabled}>
                <Cancel className="h-3 w-3" />
                Deny
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StepInstance;
