import styled from '@emotion/styled';
import { File, FileService } from '@gbhem/api';
import { useMemo, useState } from 'react';
import { Column } from 'react-table';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import { ColumnLink, dateTableFormat, Download, Table } from '..';
import { Button } from '../Button';

const Container = styled.div`
  ${tw`flex w-full h-full`}
`;

const DocumentsSection = styled.div`
  ${tw`bg-neutral-1000 w-full mt-5 mx-24 mb-24 p-10`}

  table {
    ${tw`w-full`}
  }

  tr {
    ${tw`border-b border-neutral-600`}
    svg {
      ${tw`w-4 h-4`}
    }
  }

  th {
    ${tw`text-xs font-bold text-neutral-300 pb-4`}
  }

  th.sortable {
    svg {
      ${tw`w-2 h-2`}
    }
  }

  td {
    ${tw`text-sm font-normal`}
  }
`;

interface DocumentsTableProperties {
  userId: string;
}

export function DocumentsTable({ userId }: DocumentsTableProperties) {
  const [documents, setDocuments] = useState<File[]>([]);
  const { downloadFile, getUserAssociatedFiles } = FileService;

  useAsync(async () => setDocuments(await getUserAssociatedFiles(userId)), []);

  const columns = useMemo(
    () =>
      [
        {
          Header: 'TITLE',
          accessor: 'name',
          id: 'name',
          disableSortBy: true,
          Cell: ({ value }) => value
        },
        {
          Header: 'DATE',
          accessor: 'createdAt',
          id: 'createdAt',
          Cell: ({ value }) => String(dateTableFormat(value!))
        },
        //
        // {
        //   Header: location.toLocaleUpperCase(),
        //   accessor: 'content.step.track',
        //   id: 'content.step.track',
        //   disableSortBy: true,
        //   Cell: ({ row }: any) => {
        //     const track = row.original.content.step.track;
        //     return (
        //       <ColumnLink to={`/tracks/${track.track.id}/assignments/${track.id}`}>
        //         {track.track.name}
        //       </ColumnLink>
        //     );
        //   }
        // },
        {
          Header: 'STEP',
          accessor: 'uploadFile',
          id: 'uploadFile',
          disableSortBy: true,
          Cell: ({ value }) => {
            const stepContentInstance = value.uploadInstance.stepContentInstance;
            const stepInstance = stepContentInstance.stepInstance;
            return (
              <ColumnLink to={`/step/${stepInstance.id}`}>
                {stepInstance.stepTemplate.name}
              </ColumnLink>
            );
          }
        },
        {
          Header: '',
          accessor: 'key',
          id: 'download',
          disableSortBy: true,
          Filter: undefined,
          Cell: ({ value }) => {
            return (
              <div className="flex justify-center">
                <Button
                  onClick={async () => (window.location.href = await downloadFile(value))}
                  inverted
                >
                  <Download /> Download
                </Button>
              </div>
            );
          }
        }
      ] as Column<File>[],
    [downloadFile]
  );

  return (
    <Container>
      <DocumentsSection>
        {documents ? <Table columns={columns} data={documents} /> : null}
      </DocumentsSection>
    </Container>
  );
}

export default DocumentsTable;
