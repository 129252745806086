import styled from '@emotion/styled';
import { ComponentProps, FC } from 'react';
import tw from 'twin.macro';

import { ChevronDown as ChevronDownIcon, ChevronUp as ChevronUpIcon } from '../icons';

const ChevronDown = styled(ChevronDownIcon)<{ disabled?: boolean }>`
  ${(p) => (p.disabled ? tw`pointer-events-none text-neutral-700` : '')}
`;

const ChevronUp = styled(ChevronUpIcon)<{ disabled?: boolean }>`
  ${(p) => (p.disabled ? tw`pointer-events-none text-neutral-700` : '')}
`;

type OrderedListProps<T> = Omit<ComponentProps<'div'>, 'onChange'> & {
  list: T[];
  children: FC<{ item: T; index: number }>;
  onChange?: (list: T[]) => void;
};

const List = styled.div`
  ${tw`flex flex-1 flex-col`}
`;

const Controls = styled.div`
  ${tw`flex flex-col items-center justify-center mt-1`}

  svg {
    ${tw`invisible cursor-pointer transform hover:scale-150 transition-all duration-75`}

    height: 1rem;
    width: 1rem;
  }

  :hover {
    svg {
      ${tw`visible`}
    }
  }
`;

const Item = styled.div`
  ${tw`flex items-start flex-1 gap-4`}
`;

const Content = styled.div`
  ${tw`flex flex-1`}
`;

export function OrderedList<T>({ list, onChange, children }: OrderedListProps<T>) {
  const swapStepOrder = (item: T, direction: 'back' | 'forward') => {
    const i1 = list.findIndex((i) => item === i);

    if ((i1 === 0 && direction === 'back') || (i1 === list.length - 1 && direction === 'forward')) {
      return;
    }

    const i2 = i1 + (direction === 'forward' ? 1 : -1);

    list[i1] = list[i2];
    list[i2] = item;

    onChange?.([...list]);
  };

  if (!list.length) {
    return null;
  }

  return (
    <List>
      {list.map((item, i) => (
        <Item key={(item as any).id || i}>
          <Controls>
            <ChevronUp onClick={() => swapStepOrder(item, 'back')} disabled={i === 0} />
            <b className="flex-initial text-neutral-500">{i + 1}</b>
            <ChevronDown
              onClick={() => swapStepOrder(item, 'forward')}
              disabled={i === list.length - 1}
            />
          </Controls>
          <Content>{children({ item, index: i })}</Content>
        </Item>
      ))}
    </List>
  );
}

export default OrderedList;
