import { SVGProps } from 'react';

export function Ready(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 50 50"
      fill="none"
      {...props}
      className={`${props.className} fill-current`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M49.2911 14.0985L39.6137 23.776L36.1959 20.3547L41.7434 14.8072H35.484C31.8482 14.8072 28.361 16.2527 25.7904 18.8237C23.2198 21.3947 21.7739 24.8819 21.7739 28.5173V36.5818H16.9352V28.5173C16.9352 23.5963 18.8883 18.8806 22.3693 15.4027C25.8475 11.9218 30.5631 9.96863 35.4838 9.96863H41.7432L36.1957 4.41798L39.6137 1L49.2911 10.6774C50.2331 11.6225 50.2331 13.1535 49.2911 14.0985ZM34.6774 49.4848H8.87097C3.97242 49.4848 0 45.5124 0 40.6139V14.8074C0 9.90887 3.97242 5.93645 8.87097 5.93645H18.5484V10.7752H8.87097C6.64379 10.7752 4.83871 12.5802 4.83871 14.8074V40.6139C4.83871 42.841 6.64379 44.6461 8.87097 44.6461H34.6774C35.7454 44.6461 36.7724 44.2209 37.5284 43.4648C38.2844 42.7088 38.7097 41.6818 38.7097 40.6139V30.9365H43.5484V40.6139C43.5484 45.5124 39.576 49.4848 34.6774 49.4848Z" />
    </svg>
  );
}

export default Ready;
