import styled from '@emotion/styled';
import { User } from '@gbhem/api';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

interface MergedPillProps {
  user: User;
}

export const MergedPill = ({ user }: MergedPillProps) => {
  const PillContainer = styled.div`
    ${tw`my-2 h-auto bg-gray-100 w-64 rounded-xl`}
  `;

  const PillText = styled.p`
    ${tw`text-base text-center mx-2 my-2`}
  `;

  const { mergedUserId } = user;

  const isMerged = !!mergedUserId;

  return (
    <>
      {isMerged ? (
        <PillContainer>
          <Link to={`/users/${mergedUserId}`}>
            <PillText>
              <>
                <b>
                  <span className="text-yellow-600">This user has been merged</span>
                </b>
              </>
            </PillText>
          </Link>
        </PillContainer>
      ) : (
        <></>
      )}
    </>
  );
};
