import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { FadeIn } from '../animations';
import { Button } from '../Button';
import { Checkmark, Remove } from '../icons';

const Container = styled(FadeIn)`
  ${tw`flex w-1/2 h-full mx-auto`}
`;

const IconContainer = styled.div`
  margin-right: 10px;
`;

const EmailVerificationContainer = styled.div`
  ${tw`w-full py-8 px-12 m-8 bg-neutral-1000`}

  height: fit-content;
`;

const ActionButton = styled(Button)`
  ${tw`justify-end`};
  svg {
    ${tw`w-4 h-4`}
  }
`;

const ActionLink = styled(Link)`
  ${tw`flex justify-end`};
`;

const HorizontalRule = styled.hr`
  ${tw`my-10 border-t border-neutral-500`}
`;

export function UserEmailVerification() {
  const search = useLocation().search;
  const isVerified = new URLSearchParams(search).get('isVerified');
  const userId = new URLSearchParams(search).get('user');

  const link = `/users/${userId}`;

  return (
    <Container>
      <EmailVerificationContainer>
        {isVerified ? (
          <div className="flex space-x-2">
            <IconContainer>
              <Checkmark color="green" height={20} width={20} />
            </IconContainer>
            Email has been verified.
          </div>
        ) : (
          <div className="flex space-x-2">
            <IconContainer>
              <Remove color="red" height={20} width={20} />
            </IconContainer>
            Email could not be verified.
          </div>
        )}
        <HorizontalRule />
        {userId && (
          <ActionLink to={link}>
            <ActionButton>Return to Profile</ActionButton>
          </ActionLink>
        )}
      </EmailVerificationContainer>
    </Container>
  );
}
