import { Input } from '..';
import { FieldSchemaPropertiesEditorProperties } from '.';

export function LinkEditor({ properties, onChange }: FieldSchemaPropertiesEditorProperties) {
  return (
    <div>
      <Input
        name="Link"
        value={properties.link}
        placeholder="Enter the link address here... e.g. https://gbhem.org"
        onChange={(e) => onChange({ link: e.target.value })}
      />
    </div>
  );
}

export default LinkEditor;
