import styled from '@emotion/styled';
import tw from 'twin.macro';

export const Card = styled.div`
  ${tw`bg-neutral-1000 flex flex-col p-2 gap-2 shadow-md rounded-md`}
`;

export const CardHeader = styled.div`
  ${tw`flex gap-4 font-bold text-xl`}
`;

export const CardBody = styled.div`
  ${tw`flex flex-col gap-4`}
`;

export const CardFooter = styled.div`
  ${tw`flex gap-4`}
`;

export default Card;
