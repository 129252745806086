import { Input } from '..';
import { FieldSchemaPropertiesEditorProperties } from '.';

export function TextAreaEditor({ properties, onChange }: FieldSchemaPropertiesEditorProperties) {
  return (
    <div>
      <Input
        type="number"
        name="max length"
        value={properties.maxLength}
        onChange={(e) => onChange({ ...properties, maxLength: e.target.value })}
      />
    </div>
  );
}

export default TextAreaEditor;
