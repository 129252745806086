import { FormService, FormTemplate } from '@gbhem/api';
import { useState } from 'react';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Column } from 'react-table';
import { useAsync } from 'react-use';

import {
  Button,
  ChevronLeft,
  ColumnLink,
  Edit,
  FormEditor,
  Input,
  Loader,
  Plus,
  SubNavbar,
  SubNavLink,
  Table
} from '../../components';
import { Can, SystemRole, useAuthenticatedUser } from '../../providers';

const columns = [
  {
    Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
      <Input type="checkbox" {...getToggleAllPageRowsSelectedProps()} />
    ),
    accessor: 'id',
    id: 'checkbox',
    Filter: undefined,
    disableSortBy: true,
    disableFilters: true,
    Cell: ({ row }) => <Input type="checkbox" {...row.getToggleRowSelectedProps()} />
  },
  {
    Header: 'NAME',
    accessor: 'name',
    id: 'name'
  },
  {
    Header: 'System Form',
    accessor: 'system',
    id: 'system',
    disableFilters: true,
    Cell: ({ value }) => (value ? 'Yes' : 'No')
  },
  {
    Header: 'EDIT',
    accessor: 'id',
    Filter: undefined,
    disableSortBy: true,
    Cell: ({ value }) => (
      <ColumnLink to={`/forms/${value}`}>
        <Edit />
      </ColumnLink>
    )
  }
] as Column<FormTemplate>[];

export function Form() {
  const { id } = useParams<{ id: string }>();
  const [form, setForm] = useState<FormTemplate>();

  const authUser = useAuthenticatedUser();
  const isSuperAdmin = authUser.roles.find((r) =>
    [SystemRole.Superadmin.toString()].includes(r.name)
  );

  const canEditSystem: boolean = (form?.system && !isSuperAdmin) || false;

  useAsync(async () => {
    const form = await FormService.findOne(id, ['current']);

    setForm({
      ...form,
      current: form.current || { layout: { id: '', children: [] }, fields: [] }
    });
  }, []);

  if (!form) {
    return <Loader />;
  }

  return (
    <div className="h-full flex flex-col">
      <SubNavbar>
        <SubNavLink exact to={`/forms`}>
          <ChevronLeft /> <span>Forms</span>
        </SubNavLink>
      </SubNavbar>
      <div className="bg-neutral-1000 p-4 pb-6 flex space-x-2">
        <div className="w-full flex space-x-2 items-center">
          <div
            className="text-xs border rounded-full h-8 w-8 font-semibold flex items-center
            justify-center"
          >
            v{form.current?.version || 1}
          </div>
          <div className="w-full">
            <Input
              className="w-full"
              placeholder="Type form name here..."
              value={form.name}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
              disabled={canEditSystem}
            />
            {!form.name || form.name === 'Untitled' ? (
              <span className="text-xs mt-1 absolute text-neutral-500">
                Give your form a meaningful name in order to save it.
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {canEditSystem ? (
        <div className="flex bg-neutral-1000 justify-center">
          <p className=" text-pink"> System forms cannot be edited. </p>
        </div>
      ) : null}
      <div className="flex bg-neutral-1000  pb-4 space-x-2 place-content-between">
        <div className="pl-14">
          {form.name && form.name !== 'Untitled' ? (
            <Can do="manage" on="all">
              <Input
                type="checkbox"
                name="System Form"
                checked={form.system}
                onChange={async (e) => {
                  setForm(
                    await FormService.update(form.id, {
                      ...form,
                      system: e.target.checked,
                      version: form.current
                    })
                  );
                }}
              />
            </Can>
          ) : null}
        </div>
        <div>
          <Button
            className="h-8 mt-5 mr-4"
            disabled={!form.name || form.name === 'Untitled' || canEditSystem}
            onClick={async () =>
              setForm(await FormService.update(form.id, { ...form, version: form.current }))
            }
          >
            Save
          </Button>
        </div>
      </div>
      <hr />
      <FormEditor
        form={form.current}
        disabled={canEditSystem}
        onChange={(changes) =>
          Object.assign(form.current, {
            ...changes,
            fields: Object.values(changes.fields)
          })
        }
      />
    </div>
  );
}

export function FormsTable() {
  const router = useHistory();

  return (
    <>
      <div>
        <SubNavbar className="p-4">
          <div className="font-bold">Forms</div>
          <Button
            onClick={async () => {
              const form = await FormService.create({ name: 'Untitled', system: false });
              router.push(`/forms/${form.id}`);
            }}
          >
            <Plus /> Add
          </Button>
        </SubNavbar>
      </div>
      <Table
        columns={columns}
        loader={async ({ page, size, filters, orderBy, relations }) => {
          const query = await FormService.query({
            conditions: filters,
            limit: size,
            offset: page * size,
            relations,
            orderBy
          });

          return query;
        }}
      />
    </>
  );
}

export function Forms() {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/:id`}>
        <Form />
      </Route>
      <Route>
        <FormsTable />
      </Route>
    </Switch>
  );
}

export default Forms;
