import { TrackTemplate } from '@gbhem/api';
import { useState } from 'react';

import { Button } from '../Button';
import { CardBody, CardFooter } from '../Card';
import { Input } from '../Input';

interface TrackModalInputs {
  name: string;
  description?: string;
  titleErr?: string;
  descriptionErr?: string;
}

enum TrackModalErrors {
  Title = 'Please enter a title.',
  Description = 'Please enter a description.',
  Both = 'Please enter a title and description.'
}

type ModalProps = TrackTemplate & { onClose: () => void };

export function TrackModal({ id, name, onClose }: ModalProps) {
  //State
  const [trackModalConfig, setTrackModalConfig] = useState<TrackModalInputs>({
    name: id === '5' ? '' : name,
    description: '',
    titleErr: '',
    descriptionErr: ''
  });

  const handleModelSave = () => {
    if (validator()) onClose();
    //TODO Add save code here.
  };

  const validator = () => {
    try {
      validateUserInput();
    } catch (err) {
      return false;
    }
    return true;
  };

  const validateUserInput = () => {
    if (trackModalConfig.name.length === 0 && trackModalConfig.description?.length === 0) {
      updateModalErrors(TrackModalErrors.Title, TrackModalErrors.Description);
      throw new Error(TrackModalErrors.Both);
    }
    if (trackModalConfig.description?.length === 0) {
      updateModalErrors('', TrackModalErrors.Description);
      throw new Error(TrackModalErrors.Description);
    }
    if (trackModalConfig.name.length === 0) {
      updateModalErrors(TrackModalErrors.Title, '');
      throw new Error(TrackModalErrors.Title);
    }
  };

  const updateModalErrors = (titleErr: string, descriptionErr: string) => {
    setTrackModalConfig({
      ...trackModalConfig,
      ...{
        titleErr,
        descriptionErr
      }
    });
  };

  return (
    <>
      <CardBody>
        <p>Please make sure you are not recreating an existing track template.</p>
        <Input
          type="text"
          name="Track Name"
          placeholder={id === '5' ? 'Enter Track Name...' : ''}
          value={trackModalConfig.name}
          onChange={(e) =>
            setTrackModalConfig({
              ...trackModalConfig,
              ...{ name: e.target.value }
            })
          }
          error={trackModalConfig.titleErr}
          disabled={id !== '5' ? true : false}
        />
        <Input
          type="text"
          name="Track Description"
          placeholder="Enter Track Description..."
          value={trackModalConfig?.description}
          onChange={(e) =>
            setTrackModalConfig({
              ...trackModalConfig,
              ...{ description: e.target.value }
            })
          }
          error={trackModalConfig.descriptionErr}
        />
      </CardBody>
      <CardFooter>
        <Button onClick={onClose} inverted>
          Cancel
        </Button>
        <Button onClick={handleModelSave}>Save</Button>
      </CardFooter>
    </>
  );
}

export default TrackModal;
