import { UploadEditor, UploadEditorFields } from '../UploadEditor';
import { FieldSchemaPropertiesEditorProperties } from '.';

export function FieldUploadEditor({ properties, onChange }: FieldSchemaPropertiesEditorProperties) {
  const fieldProperties = {
    properties,
    onChange
  };

  return (
    <div className="flex flex-col space-y-2 my-4">
      <UploadEditor fields={fieldProperties as UploadEditorFields} />
    </div>
  );
}

export default FieldUploadEditor;
