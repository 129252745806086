import { Church, ChurchService } from '@gbhem/api';
import { Column } from 'react-table';

import { Table } from '../../components';

const columns = [
  {
    Header: 'NAME',
    accessor: 'name',
    id: 'name',
    Cell: ({ value }) => value
  },
  {
    Header: 'DISTRICT',
    accessor: 'district',
    id: 'district',
    Cell: ({ value }) => value.name
  }
] as Column<Church>[];

export function Churches() {
  const initialPageSize = 10;
  const initialPageIndex = 0;

  return (
    <Table
      columns={columns}
      data={[]}
      pageIndex={initialPageIndex}
      pageSize={initialPageSize}
      count={0}
      loader={async (props) => {
        const quickSearch = await ChurchService.quickSearch(
          'Church',
          props.size,
          props.page * props.size,
          ['district'],
          props.isQuickSearch,
          props.orderBy,
          props.filters
        );

        return { data: quickSearch.data, count: quickSearch.count };
      }}
    />
  );
}

export default Churches;
