import styled from '@emotion/styled';
import tw from 'twin.macro';

import { blinkAnimation, LoaderIcon } from '..';

interface LoaderProps {
  message?: string;
}

const Container = styled.div`
  ${tw`flex flex-col h-full w-full items-center justify-center bg-neutral-800`}
`;

const Ellipse = styled.div`
  ${blinkAnimation(1.5)}
  ${tw`flex items-center justify-center p-10 mb-12`}
`;

export function Loader({ message }: LoaderProps) {
  return (
    <Container>
      <Ellipse>
        <LoaderIcon />
      </Ellipse>
      <b className="h-4">{message}</b>
    </Container>
  );
}

export default Loader;
