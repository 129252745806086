import styled from '@emotion/styled';
import { Conference, User, UserGroup, UserService } from '@gbhem/api';
import { useMemo, useState } from 'react';
import { useAsync, useEffectOnce } from 'react-use';
import tw from 'twin.macro';

import {
  LocationStoreTypes,
  useAuthenticatedUser,
  useLocationStore,
  useRoles
} from '../../providers';
import { OrderBy } from '../../utils/resource.helper';
import { filterByCurrentUserLocation } from '../../utils/user-location-filtering';
import { Button } from '../Button';
import { CardFooter } from '../Card';
import { Cancel, Plus } from '../icons';
import { Input, Select, TextArea } from '../Input';

const Header = styled.h3`
  ${tw`font-semibold`}
`;

interface ManageInterviewerGroupProperties {
  onSubmit: (group: UserGroup) => void;
  close: (force?: boolean | undefined) => void;
  editGroup?: UserGroup;
}

export function ManageInterviewerGroup({
  onSubmit,
  close,
  editGroup
}: ManageInterviewerGroupProperties) {
  const [group, setGroup] = useState<UserGroup>(
    editGroup ?? { id: '', name: '', description: '', conference: null, users: [] }
  );
  const [interviewers, setInterviewers] = useState<User[]>();

  useAsync(async () => {
    setInterviewers(await UserService.getActiveInterviewers());
  }, []);

  const { system } = useRoles();

  const currentUser = useAuthenticatedUser();

  const interviewerRole = useMemo(() => system.find((r) => r.name === 'Interviewer'), [system]);
  const superadminRole = useMemo(() => system.find((r) => r.name === 'Superadmin'), [system]);
  const isSuperAdmin = useMemo(
    () => !!currentUser.roles.find((r) => r.id === superadminRole?.id),
    [currentUser, superadminRole]
  );

  const { conferences, query } = useLocationStore();

  const conferenceDefaultValue = useMemo(
    () => editGroup?.conference ?? (isSuperAdmin ? undefined : Object.values(conferences)[0]),
    [conferences, editGroup, isSuperAdmin]
  );

  useEffectOnce(
    () => conferenceDefaultValue && setGroup({ ...group, conference: conferenceDefaultValue })
  );

  if (!interviewerRole) {
    return <></>;
  }

  // ? implement use forms instead ¿
  const handleTextChange = (key: keyof Pick<UserGroup, 'name' | 'description'>, value: string) => {
    setGroup({
      ...group,
      [key]: value
    });
  };

  const handleConferenceChange = (conference: Conference) => setGroup({ ...group, conference });

  if (!interviewers) {
    return <></>;
  }

  if (!interviewers.length) {
    return (
      <div className="flex flex-col flex-1 space-y-4 justify-center items-center">
        <p>Sorry, no available users with Interviewer role for the current conference.</p>
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (!group) {
          return;
        }

        onSubmit({ ...group, users: group.users.filter((u) => u.id) });
      }}
    >
      <div className="flex flex-col gap-3">
        <div className="space-y-4">
          <div className="flex flex-col">
            <Input
              autoFocus
              label="Name"
              type="text"
              name="name"
              value={group.name}
              onChange={(e) => handleTextChange('name', e.target.value)}
            />
            <TextArea
              label="Description"
              name="description"
              value={group.description}
              onChange={(e) => handleTextChange('description', e.target.value)}
            />
            <Select<Conference>
              defaultValue={conferenceDefaultValue}
              label="Conference"
              options={
                isSuperAdmin
                  ? (searchTerm: string | undefined) => {
                      if (!searchTerm) return;
                      return query(
                        {
                          conditions: [{ name: searchTerm }],
                          orderBy: OrderBy.Ascending,
                          limit: 10
                        },
                        LocationStoreTypes.Conference
                      );
                    }
                  : Object.values(conferences) || []
              }
              placeholder="Select conference for track..."
              onChange={(e) => e.target.value && handleConferenceChange(e.target.value)}
            />
          </div>

          {interviewers.length ? (
            <>
              <Header>Manage Interviewers</Header>
              <div className="space-y-2">
                {group.users.map((user, index) => (
                  <div key={user.id} className="flex space-x-4 items-center">
                    <Select<User>
                      className="flex-1"
                      placeholder="Begin by typing full name of the interviewer..."
                      options={async (searchTerm) => {
                        if (!searchTerm) {
                          return;
                        }
                        const interviewerList = (
                          await UserService.query({
                            conditions: [
                              {
                                name: searchTerm,
                                inactive: false
                              },
                              {
                                firstName: searchTerm,
                                inactive: false
                              },
                              {
                                lastName: searchTerm,
                                inactive: false
                              }
                            ],
                            relations: ['roles'],
                            limit: 15
                          })
                        ).data.filter((u: User) =>
                          u.roles.find((r) => r.id === interviewerRole.id)
                        );
                        const optionsList = await filterByCurrentUserLocation(
                          interviewerList,
                          currentUser
                        );
                        return (optionsList || interviewerList)
                          .filter(
                            (u: User) =>
                              u.id === user.id || !group.users.find((uGU) => uGU.id === u.id)
                          )
                          .sort((base: User, compare: User) =>
                            base.lastName > compare.lastName
                              ? 1
                              : base.lastName < compare.lastName
                              ? -1
                              : 0
                          );
                      }}
                      optionLabel={(interviewer) =>
                        `${interviewer?.firstName} ${interviewer?.lastName} (${interviewer.email})`
                      }
                      value={interviewers.find((i) => i.id === user.id)}
                      onSelect={(e) => {
                        if (!e.currentTarget.value) {
                          return;
                        }

                        group.users[index] = e.currentTarget.value;
                        setGroup({ ...group, users: [...group.users] });
                      }}
                    />
                    <Cancel
                      className="h-3 w-3 text-primary-300 cursor-pointer"
                      onClick={() => {
                        setGroup({
                          ...group,
                          users: group.users.filter((u) => u.id !== user.id)
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
              <Button
                inverted
                type="button"
                disabled={!!group.users.find((u) => u.id === '')}
                onClick={() => {
                  setGroup({
                    ...group,
                    users: [...group.users, { id: '' } as User]
                  });
                }}
              >
                Add Interviewer <Plus />
              </Button>
            </>
          ) : null}
        </div>
        <CardFooter>
          <Button type="submit">Save</Button>
          <Button inverted type="reset" onClick={() => close()}>
            Cancel
          </Button>
        </CardFooter>
      </div>
    </form>
  );
}
