"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class NotificationService {
    static addPublish() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/notification/publish',
        });
    }
    static addSubscribe() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/notification/subscribe',
        });
    }
    static getUserNotifications(limit, offset) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/notification/user-notifications',
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    static updateUserNotificationIsRead(id, isread) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/notification/{id}/isread/{isread}',
            query: {
                'id': id,
                'isread': isread,
            },
        });
    }
    static markAllAsRead(userId, isread) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PUT',
            url: '/api/notification/user/{userId}/bulk-isread/{isread}',
            query: {
                'userId': userId,
                'isread': isread,
            },
        });
    }
    static triggerEmailNotifications() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/notification/trigger-email-notifications',
        });
    }
    static confirmEmailVerificationUnauthed(data) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/notification/confirm-email-unauthed',
            query: {
                'data': data,
            },
        });
    }
    static confirmEmailVerification(data) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/notification/confirm-email',
            query: {
                'data': data,
            },
        });
    }
}
exports.NotificationService = NotificationService;
