import { SVGProps } from 'react';

export function Upload(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      className="fill-current"
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M49.8119 36.282V47.2658C49.8119 47.4855 49.7802 47.6881 49.7338 47.8761C49.5141 48.9552 48.5619 49.7657 47.4364 49.7657H2.54641C1.34279 49.7657 0.344285 48.8428 0.217285 47.671C0.185547 47.5294 0.185547 47.4049 0.185547 47.2486V36.2649C0.185547 34.9685 1.24755 33.9211 2.5293 33.9211C3.16892 33.9211 3.76467 34.1872 4.18455 34.6096C4.60691 35.032 4.87305 35.6252 4.87305 36.2649V45.0611H45.1393V36.2649C45.1393 34.9685 46.2013 33.9211 47.483 33.9211C48.1227 33.9211 48.7184 34.1872 49.1383 34.6096C49.546 35.0466 49.8119 35.6399 49.8119 36.282Z" />
      <path d="M38.5157 12.61L26.9994 1.09377C26.9848 1.06203 26.953 1.04739 26.9359 1.0303C26.5136 0.607937 25.9667 0.341797 25.4198 0.263672C25.3734 0.263672 25.327 0.249027 25.2782 0.249027C25.1854 0.234379 25.0902 0.234375 24.9974 0.234375L24.7313 0.249027C24.6849 0.249027 24.6386 0.263672 24.5897 0.263672C24.0282 0.341797 23.496 0.607914 23.0736 1.0303C23.059 1.04495 23.0272 1.07668 23.0101 1.09377L11.4939 12.61C10.9617 13.1422 10.698 13.8454 10.698 14.5485C10.698 15.2516 10.9641 15.9548 11.4939 16.487C12.5559 17.549 14.2918 17.549 15.3684 16.487L22.2896 9.56577L22.297 34.922C22.297 36.421 23.5323 37.6564 25.0313 37.6564C25.7808 37.6564 26.4693 37.3439 26.9698 36.8605C27.4703 36.36 27.7657 35.6886 27.7657 34.922V9.56327L34.687 16.4845C35.749 17.5465 37.4848 17.5465 38.5615 16.4845C39.5771 15.4054 39.5777 13.672 38.5157 12.61Z" />
    </svg>
  );
}

export default Upload;
