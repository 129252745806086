import styled from '@emotion/styled';
import { File, FileService, UploadInstance } from '@gbhem/api';
import {
  DragEvent as SyntheticDragEvent,
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useAsyncFn } from 'react-use';
import tw from 'twin.macro';

import { Button, Cancel } from '..';
import { CardBody } from '../Card';
import { UploadType } from '../UploadEditor';
import { InputMetadataProps } from './Input';

const UploadButton = styled.label<{ disabled?: boolean }>`
  ${(p) => (!p.disabled ? tw`bg-primary cursor-pointer` : tw`bg-neutral-600 cursor-not-allowed`)}
  ${tw`px-4 py-2 rounded-sm text-neutral-1000`}
`;

const Name = styled.div`
  ${tw`mb-2 text-lg font-bold`}
`;

const Header = styled.div`
  ${tw`text-lg`}
`;

const Previews = styled.div<{ isMedia?: boolean }>`
  ${tw`flex space-x-2`}
`;

const FilePreviewView = styled.div`
  ${tw`flex flex-wrap flex-row items-center text-sm py-1 px-3 bg-secondary rounded-full cursor-pointer space-x-2`}

  svg {
    ${tw`w-2.5 h-2.5`}
  }
`;

const UploadDropArea = styled(CardBody)<{ isDragging?: boolean }>`
  ${tw`items-center m-2 border-2 border-dashed border-primary p-4`}
  ${(p) => (p.isDragging ? tw`bg-neutral-600` : '')}
`;

const FileInput = styled.input`
  ${tw`hidden`}
`;

const fileInstructionsToFileTypesMap: Record<UploadType, string> = {
  Audio: 'Audio files only (.aif, .mp3, .mpa , .wav, .wma)',
  Document: 'Text documents only (.docx, .doc, .txt, .pdf, .xls, .xlsm, .xlsx, .ppt, .pptx)',
  Image: 'Image files only (.bmp, .jpeg, .jpg, .png)',
  // TODO(jesse@fortyau.com) - re-enable this type when instructed.
  //Video: 'Video files only (.avi, .m4v, .mov, .mp4, .mpg, .mpeg, .wmv)',
  Any: 'Any file type'
};

export const fileCategoryToDipslayedFileTypesMap: Record<UploadType, string> = {
  Audio: '.aif, .mp3, .mpa , .wav, .wma',
  Document: '.docx, .doc, .txt, .pdf, .xls, .xlsm, .xlsx, .ppt, .pptx',
  Image: '.bmp, .jpeg, .jpg, .png',
  //Video: '.avi, .m4v, .mov, .mp4, .mpg, .mpeg, .wmv',
  Any: '*'
};

const fileHeaderToFileTypesMap: Record<UploadType, (muliple?: boolean) => string> = {
  Audio: () => 'Upload Audio',
  Document: (multiple?: boolean) => (multiple ? 'Upload Documents' : 'Upload Document'),
  Image: (multiple?: boolean) => (multiple ? 'Upload Images' : 'Upload Image'),
  //Video: (multiple?: boolean) => (multiple ? 'Upload Videos' : 'Upload Video'),
  Any: () => 'Upload anything'
};

export type FileUploadProperties = {
  name: string;
  type: UploadType;
  multiple?: boolean;
  onUpload?: (files: File[]) => void;
  onRemove?: (id: string) => Promise<void>;
  onSubmit?: () => void;
  files?: File[];
  disable?: boolean;
  hideHeader?: boolean;
  hideLabel?: boolean;
  submitted?: boolean;
  instance?: UploadInstance;
  onUnsubmit?: () => void;
} & InputMetadataProps;

export const FileUpload = forwardRef<HTMLInputElement, FileUploadProperties>(
  (
    {
      name,
      type,
      multiple,
      onUpload,
      instructions,
      files,
      onRemove,
      onSubmit,
      disable,
      hideHeader,
      hideLabel,
      submitted,
      instance,
      onUnsubmit
    },
    forwardRef: Ref<HTMLInputElement | null>
  ) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(forwardRef, () => fileInputRef.current);

    const [_files, setFiles] = useState<File[]>(files || []);
    const [isDisabled, setIsDisabled] = useState<boolean>(disable ?? false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(submitted ?? false);

    useEffect(() => setIsSubmitted(submitted ?? false), [submitted]);

    useEffect(() => setFiles(files || []), [files]);

    const submit = () => {
      onSubmit?.();
      setIsDisabled(true);
      setIsSubmitted(true);
    };

    const unSubmit = () => {
      onUnsubmit?.();
      setIsSubmitted(false);
      setIsDisabled(false);
    };

    const cleanFilename = (fileName: string) => {
      return fileName.replace(/[@#$%^&\s+=?,"~`{}[\]/<>;:|\\/]/g, '_');
    };

    const [, uploadFiles] = useAsyncFn(
      async (unsavedFiles: FileList | null) => {
        if (!unsavedFiles || disable) {
          return;
        }

        // TODO: We should probably abstract this API call out of this component
        // so that it can be performed after both uploads and form submissions.
        // This is fine for now, but if ever there is a failure of a form submission,
        // the connection between the files and the submission will be lost.
        const toUpload = [...Array.from(unsavedFiles)];
        const formData = new FormData();
        toUpload.forEach((file) => formData.append('documents[]', file, cleanFilename(file.name)));

        const files: File[] = await (
          await fetch(`/api/file/files`, { method: 'POST', body: formData })
        ).json();

        onUpload?.(files);

        setFiles([..._files, ...files]);
      },
      [_files, onUpload]
    );

    const dropDepth = useRef(0);
    const [canDrop, setCanDrop] = useState(false);

    const disableNativeDragBehavior = (e: DragEvent | SyntheticDragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };

    useEffect(() => {
      document.addEventListener('dragover', disableNativeDragBehavior);
      document.addEventListener('drop', disableNativeDragBehavior);

      return () => {
        document.removeEventListener('dragover', disableNativeDragBehavior);
        document.removeEventListener('drop', disableNativeDragBehavior);
      };
    }, []);

    return (
      <>
        <Name>{name}</Name>
        {!hideHeader && <Header>{fileHeaderToFileTypesMap[type as UploadType](multiple)}</Header>}
        {instructions ? <p>{instructions}</p> : null}
        <UploadDropArea
          className="items-center border-2 border-dashed border-band-primary"
          isDragging={canDrop}
          onDragEnter={(e) => {
            disableNativeDragBehavior(e);
            dropDepth.current += 1;

            if (!canDrop) {
              setCanDrop(true);
            }
          }}
          onDragOver={(e) => disableNativeDragBehavior(e)}
          onDragLeave={(e) => {
            disableNativeDragBehavior(e);
            dropDepth.current -= 1;

            if (dropDepth.current === 0) {
              setCanDrop(false);
            }
          }}
          onDrop={(e) => {
            disableNativeDragBehavior(e);
            uploadFiles(e.dataTransfer.files);

            dropDepth.current = 0;
            setCanDrop(false);
          }}
        >
          <UploadButton disabled={isDisabled}>
            <FileInput
              disabled={isDisabled}
              ref={fileInputRef}
              type="file"
              accept={fileCategoryToDipslayedFileTypesMap[type]}
              onChange={(e) => uploadFiles(e.target.files)}
              onClick={() => {
                fileInputRef.current!.value = '';
              }}
              multiple={multiple}
            />
            Browse Files
          </UploadButton>
          <p className="text-xs text-neutral-500">or drag and drop to upload</p>
          {!hideLabel && <p>{fileInstructionsToFileTypesMap[type]}</p>}
        </UploadDropArea>

        <FilePreviews
          files={_files}
          removable={!isDisabled}
          onClick={async (file) => await onRemove?.(file.key)}
        />

        {isSubmitted && onSubmit && (
          <>
            <div className="text-neutral-300 mt-3">
              Submitted{' '}
              {instance?.updatedAt &&
                new Date(instance?.updatedAt).toLocaleTimeString([], {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
            </div>
            <Button onClick={unSubmit} type="submit">
              Unsubmit
            </Button>
          </>
        )}

        {!isSubmitted && onSubmit && (
          <div className="mt-5">
            <Button disabled={_files.length === 0} onClick={submit} type="submit">
              Submit
            </Button>
          </div>
        )}
      </>
    );
  }
);

interface FilePreviewsProperties {
  files: File[];
  onClick?: (file: File) => void;
  removable?: boolean;
}

export function FilePreviews({ files, onClick, removable = true }: FilePreviewsProperties) {
  return (
    <Previews>
      {files.map((file) => (
        <FilePreviewView key={file.key}>
          <div
            className="underline underline-offset-1"
            onClick={async () =>
              window.open(
                file.source === 'internal' ? await FileService.downloadFile(file.key) : file.url,
                '_blank'
              )
            }
          >
            {file.name}
          </div>
          {onClick && removable && <Cancel onClick={() => onClick(file)} />}
        </FilePreviewView>
      ))}
    </Previews>
  );
}

export default FileUpload;
