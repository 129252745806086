import { SVGProps } from 'react';

export function Signature(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={48}
      height={45}
      viewBox="0 0 48 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M2.125 44.438c.059 0 10.815-1.191 10.815-1.191.353-.038.685-.196.937-.448L42.25 14.42a8.247 8.247 0 002.063-7.934A8.248 8.248 0 0038.516.688a8.247 8.247 0 00-7.934 2.062L2.203 31.13c-.252.251-.41.581-.45.937L.574 42.703c-.054.471.112.942.446 1.278.301.283.694.445 1.106.457zM40.041 4.96a5.134 5.134 0 010 7.25l-1.262 1.261-7.25-7.25 1.262-1.261a5.137 5.137 0 017.25 0zM4.801 32.95l24.52-24.52 7.25 7.25L12.05 40.2l-8.156.906.906-8.157zM45.875 41.313H22.437a1.562 1.562 0 100 3.124h23.438a1.562 1.562 0 100-3.124z" />
    </svg>
  );
}

export default Signature;
