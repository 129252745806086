import { SVGProps } from 'react';

export function Edit(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M1 41.295A8.706 8.706 0 009.705 50h31.277a8.706 8.706 0 008.705-8.705V31.222a2.486 2.486 0 00-2.487-2.487 2.486 2.486 0 00-2.487 2.487v10.073c0 2.06-1.671 3.73-3.73 3.73H9.704a3.732 3.732 0 01-3.73-3.73V10.018c0-2.06 1.67-3.731 3.73-3.731h10.073A2.486 2.486 0 0022.265 3.8a2.486 2.486 0 00-2.487-2.487H9.705A8.706 8.706 0 001 10.018v31.277z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.856 2.08a7.21 7.21 0 00-10.134 0L14.405 25.398c-.301.31-.518.697-.622 1.117l-1.992 7.027a3.732 3.732 0 003.607 4.727l.996-.124 7.087-1.99v-.002c.423-.104.807-.32 1.12-.622l23.254-23.317a7.21 7.21 0 000-10.134zm-3.544 3.544a2.237 2.237 0 010 3.11h-.063l-3.11-3.11h.064a2.237 2.237 0 013.109 0zM17.14 32.733l4.353-1.18 19.212-19.276-3.11-3.11-19.211 19.276-1.244 4.29z"
      />
    </svg>
  );
}

export default Edit;
