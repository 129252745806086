import { Cell, Layout } from '@gbhem/api';
import { v4 as uuid } from 'uuid';

export type DepthCell = Cell & {
  depth: number;
};

export type CellDimension = {
  top: number;
  left: number;
  right: number;
  bottom: number;
};

export type CellDimensions = Record<string, CellDimension>;

export type Axis = 'horizontal' | 'vertical';
export type Orientation = 'before' | 'after';

export type RelativePosition = {
  axis: Axis;
  orientation: Orientation;
};

export function intersects(x: number, y: number, dimensions?: CellDimension) {
  return (
    dimensions &&
    x >= dimensions.left &&
    x <= dimensions.right &&
    y >= dimensions.top &&
    y <= dimensions.bottom
  );
}

export class LayoutEngine {
  constructor(public layout: Layout) {}

  private getCells(cell: Cell = this.layout): Cell[] {
    return [cell, ...(cell.children || []).flatMap((i) => this.getCells(i))];
  }

  private _dimensions?: CellDimensions;
  get dimensions() {
    if (!this._dimensions) {
      this._dimensions = this.getCells().reduce((position, { id }) => {
        const element = document.getElementById(id);
        if (!element) {
          return position;
        }

        const { top, left, right, bottom } = element.getBoundingClientRect();

        position[id] = {
          top,
          left,
          right,
          bottom
        };

        return position;
      }, {} as CellDimensions);
    }

    return this._dimensions;
  }

  static getCell(parent: Cell, id: string, depth = 0): DepthCell | undefined {
    if (parent.id === id) {
      return {
        ...parent,
        depth
      };
    }

    if (!parent.children) {
      return undefined;
    }

    return parent.children.map((c) => LayoutEngine.getCell(c, id, depth + 1)).filter((v) => v)[0];
  }

  get(id: string) {
    return LayoutEngine.getCell(this.layout, id);
  }

  private static getParentCell(cell: Cell, id: string): Layout | undefined {
    if (!cell.children) {
      return undefined;
    }

    if (cell.children.find((c) => c.id === id)) {
      return cell as Layout;
    }

    return cell.children.map((c) => LayoutEngine.getParentCell(c, id)).filter((p) => p)[0];
  }

  getParentOf(id: string): Layout {
    return LayoutEngine.getParentCell(this.layout, id) || this.layout;
  }

  getCellAt(x: number, y: number) {
    const [cell] = Object.entries(this.dimensions)
      .filter(
        ([_, { top, left, right, bottom }]) => x >= left && x <= right && y >= top && y <= bottom
      )
      .map(([id]) => this.get(id))
      .sort((c1, c2) => (c2?.depth || 0) - (c1?.depth || 0));

    return cell;
  }

  getRelativePositionTo(cell: Cell, x: number, y: number): RelativePosition | undefined {
    const dimensions = this.dimensions[cell.id];

    if (!dimensions) {
      return undefined;
    }

    const { left, top, right, bottom } = dimensions;

    const width = Math.abs(right - left);
    const height = Math.abs(bottom - top);

    const position = [
      {
        axis: 'horizontal',
        orientation: 'before',
        top,
        left,
        right: left + width * 0.2,
        bottom
      },
      {
        axis: 'horizontal',
        orientation: 'after',
        top,
        left: left + width * 0.8,
        right,
        bottom
      },
      {
        axis: 'vertical',
        orientation: 'before',
        top,
        left: left + width * 0.2,
        right: left + width * 0.8,
        bottom: top + height * 0.3
      },
      {
        axis: 'vertical',
        orientation: 'after',
        top: top + height * 0.7,
        left: left + width * 0.2,
        right: left + width * 0.8,
        bottom
      }
    ].find((v) => x >= v.left && x <= v.right && y >= v.top && y <= v.bottom);

    if (!position) {
      return {
        axis: 'vertical',
        orientation: 'after'
      };
    }

    return {
      axis: position.axis,
      orientation: position.orientation
    } as RelativePosition;
  }

  addBefore(id: string, cell: Cell): void {
    const parent = this.getParentOf(id);
    const targetIndex = parent.children.findIndex((c) => c.id === id);
    if (targetIndex === -1) {
      return;
    }

    parent.children.splice(targetIndex, 0, cell);
  }

  addAfter(id: string, cell: Cell): void {
    const parent = this.getParentOf(id);
    const targetIndex = parent.children.findIndex((c) => c.id === id);
    if (targetIndex === -1) {
      return;
    }

    parent.children.splice(targetIndex + 1, 0, cell);
  }

  add(cell: Cell, x: number, y: number) {
    if (this.layout.children.length === 0) {
      this.layout.children = [cell];

      return true;
    }

    const cellAt = this.getCellAt(x, y);
    if (!cellAt || cellAt.id === cell.id) {
      return false;
    }

    const position = this.getRelativePositionTo(cellAt, x, y);
    if (!position) {
      return false;
    }

    const { depth, ...nearestCell } = cellAt;
    const { axis, orientation } = position;

    const isOnExpectedAxis =
      (depth % 2 === 1 && axis === 'vertical') || (depth % 2 === 0 && axis === 'horizontal');

    if (!isOnExpectedAxis) {
      this.replace(nearestCell.id, {
        id: uuid(),
        children: orientation === 'before' ? [cell, nearestCell] : [nearestCell, cell]
      });

      return true;
    }

    if (orientation === 'before') {
      this.addBefore(nearestCell.id, cell);
    } else {
      this.addAfter(nearestCell.id, cell);
    }

    return true;
  }

  replace(id: string, cell: Cell): void {
    const parent = this.getParentOf(id);
    const targetIndex = parent.children.findIndex((c) => c.id === id);
    if (targetIndex === -1) {
      return;
    }

    parent.children.splice(targetIndex, 1, cell);
  }

  remove(id: string): void {
    const parent = this.getParentOf(id);
    const targetIndex = parent.children.findIndex((c) => c.id === id);

    if (targetIndex === -1) {
      return;
    }

    parent.children.splice(targetIndex, 1);

    if (parent.children.length === 1) {
      const grandParent = this.getParentOf(parent.id);
      const parentIndex = grandParent.children.findIndex((c) => c.id === parent.id);

      grandParent.children.splice(parentIndex, 1, parent.children[0]);
    }

    if (parent.children.length === 0) {
      this.remove(parent.id);
    }
  }
}

export default LayoutEngine;
