import { SVGProps } from 'react';

export function DragHandle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M6.25 2.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM6.25 7.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM6.25 12.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM11.25 2.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM11.25 7.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM11.25 12.5a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z" />
    </svg>
  );
}

export default DragHandle;
