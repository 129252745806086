import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import { NavLink } from 'react-router-dom';
import tw from 'twin.macro';

const Bar = styled.div`
  ${tw`flex px-6 space-x-4 bg-neutral-1000 items-center border-b`}
`;

export const SubNavLink = styled(NavLink)`
  ${tw`px-2 py-4 capitalize border-b-4 border-transparent duration-300 text-secondary flex items-center space-x-2`}

  &.active {
    ${tw`text-primary border-primary`}
  }

  svg {
    ${tw`h-4 w-4`}
  }
`;

export const SubNavbar = ({ children, ...props }: ComponentProps<'div'>) => (
  <Bar {...props}>{children}</Bar>
);
