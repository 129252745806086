import * as React from 'react';

export function TriangleDown(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={43}
      viewBox="0 0 50 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M24.967 43c.41.01.797-.2 1.007-.55l11.932-19.894L49.84 2.657a1.137 1.137 0 00-.975-1.722H1.136a1.136 1.136 0 00-.974 1.722l11.932 19.899 11.932 19.89c.2.334.552.54.939.553h.002z" />
    </svg>
  );
}

export default TriangleDown;
