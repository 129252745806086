import { UploadTemplate } from '@gbhem/api';

import { UploadEditor, UploadEditorFields } from '../UploadEditor';

export interface UploadEditorProperties {
  specification: UploadTemplate;
  readOnly?: boolean;
  onChange: (specification: UploadTemplate) => void;
}

export function StepUploadEditor({
  specification,
  readOnly = false,
  onChange
}: UploadEditorProperties) {
  const fieldSpecifications = {
    specification,
    onChange
  };
  return (
    <div className="flex flex-col space-y-4">
      <UploadEditor fields={fieldSpecifications as UploadEditorFields} readOnly={readOnly} />
    </div>
  );
}

export default StepUploadEditor;
