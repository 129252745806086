export interface SubjectHierarchyDefinition {
  subject: string;
  alias?: string;
  children?: SubjectHierarchyDefinition[];
}

export const subjectHierarchy: SubjectHierarchyDefinition[] = [
  {
    subject: 'Permission'
  },
  {
    subject: 'Role'
  },
  {
    subject: 'Jurisdiction',
    children: [
      {
        subject: 'EpiscopalArea',
        alias: 'Episcopal Area',
        children: [
          {
            subject: 'Conference',
            children: [
              {
                subject: 'District',
                children: [
                  {
                    subject: 'Church',
                    children: [
                      {
                        subject: 'ChurchMembership',
                        alias: 'Church Membership',
                        children: [
                          {
                            subject: 'User',
                            children: [{ subject: 'UserComment', alias: 'Comment' }]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                subject: 'Track',
                children: [
                  {
                    subject: 'Phase',
                    children: [
                      {
                        subject: 'Step'
                      }
                    ]
                  },
                  {
                    subject: 'TrackInstance',
                    alias: 'Assignments',
                    children: [
                      {
                        subject: 'TrackAssignment',
                        alias: 'Advisors / Interviewers'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];

export default subjectHierarchy;
