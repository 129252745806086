import { SVGProps } from 'react';

export function Remove(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} stroke-current`}
    >
      <path d="M6 9.06c0-.231.138-.42.309-.42h5.382c.17 0 .309.189.309.42 0 .233-.138.42-.309.42H6.31c-.17 0-.309-.187-.309-.42z" />
      <circle cx={9} cy={9} r={8.5} />
    </svg>
  );
}

export default Remove;
