import styled from '@emotion/styled';
import { TrackAssignment, UserGroup, UserGroupService } from '@gbhem/api';
import copy from 'fast-copy';
import { useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import { useRoles } from '../../providers/RoleProvider';
import { Button } from '../Button';
import { CardFooter } from '../Card';
import { Select } from '../Input';
import { Modal, ModalProps } from '../Modal';
import { Participant } from '.';
import { Assignments } from './Assignments';

const Header = styled.h3`
  ${tw`font-semibold`}
`;

type EditTrackAssignmentProperties = Omit<ModalProps, 'onSubmit' | 'onChange'> & {
  participant: Participant;
  onSubmit: (participant: Participant) => Promise<void>;
};

export function EditParticipant({
  participant: _participant,
  onSubmit,
  onClose,
  ...props
}: EditTrackAssignmentProperties) {
  const [participant, setParticipant] = useState(copy(_participant));
  const { system } = useRoles();

  const [interviewGroups, setInterviewGroups] = useState<UserGroup[]>();

  const interviewGroupsOptions = useMemo(() => interviewGroups || [], [interviewGroups]);

  const advisorRole = useMemo(() => system.find((r) => r.name === 'Advisor'), [system]);
  const interviewerRole = useMemo(() => system.find((r) => r.name === 'Interviewer'), [system]);

  useAsync(async () => {
    if (interviewerRole) {
      // TODO add a way to get interview groups by current user or track's context
      setInterviewGroups(await UserGroupService.getUserGroupsByRole(interviewerRole.id));
    }
  }, [interviewerRole]);

  if (!interviewGroups) {
    return <></>;
  }

  if (!advisorRole || !interviewerRole) {
    return <></>;
  }

  return (
    <Modal
      name="Manage Participant"
      onClose={onClose}
      closeMessage="Are you sure you want to cancel assigning participants to an advisor? All your progress will be lost and this cannot be undone."
      {...props}
    >
      {({ close }) => (
        <div className="flex flex-col gap-3">
          <div className="space-y-4">
            <div className="flex justify-between">
              {participant.firstName} {participant.lastName}
            </div>
            <>
              <Header>Advisors</Header>
              <Assignments
                role={advisorRole}
                assignments={participant.assignments}
                onChange={(assignments) => setParticipant({ ...participant, assignments })}
              />
            </>

            <div className="space-y-2">
              <Header>Interviewers</Header>
              <div className="text-xs text-neutral-500">
                Click to assign a single interviewer, or assign multiple interviewers at once by
                selecting a user group below.
              </div>
              <Assignments
                role={interviewerRole}
                assignments={participant.assignments}
                onChange={(assignments) => setParticipant({ ...participant, assignments })}
              />
            </div>

            {interviewGroups.length ? (
              <div className="space-y-2">
                <div className="text-md ">Add Interview Group</div>
                <div className="space-y-1">
                  <div className="flex space-x-4 items-center">
                    <Select
                      className="flex-1"
                      placeholder="Select Group..."
                      options={interviewGroupsOptions.filter((iGO) =>
                        iGO.users.some(
                          (u) =>
                            !participant.assignments.find(
                              (a) => a.assignee.id === u.id && a.role.id === interviewerRole.id
                            )
                        )
                      )}
                      onChange={(e) => {
                        if (!e.target.value) {
                          return;
                        }

                        setParticipant({
                          ...participant,
                          assignments: [
                            ...participant.assignments,
                            ...e.target.value.users
                              .filter(
                                (u) => !participant.assignments.find((a) => a.assignee.id === u.id)
                              )
                              .map(
                                (u) =>
                                  ({
                                    assignee: u,
                                    role: interviewerRole
                                  } as TrackAssignment)
                              )
                          ]
                        });
                      }}
                    />
                  </div>
                  <div className="text-xs text-neutral-500">
                    Select from existing user groups to assign multiple interviewers at once.
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <CardFooter>
            <Button
              type="submit"
              onClick={async () => {
                await onSubmit({
                  ...participant,
                  assignments: [...participant.assignments.filter((a) => a.assignee.id)]
                });
                close(true);
              }}
            >
              Save
            </Button>
            <Button inverted type="reset" onClick={() => close()}>
              Cancel
            </Button>
          </CardFooter>
        </div>
      )}
    </Modal>
  );
}

export default EditParticipant;
