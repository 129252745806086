import { SVGProps } from 'react';

export const CheckmarkThick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`${props.className} fill-current`}
  >
    <path d="M20.205 45.28c-1.31 0-2.625-.526-3.672-1.31L1.97 31.506c-2.362-1.97-2.625-5.512-.655-7.874s5.512-2.625 7.874-.655l10.236 8.792L40.155 6.97c1.97-2.362 5.513-2.625 7.875-.655s2.625 5.512.655 7.874L24.403 43.31c-.918 1.18-2.361 1.836-3.805 1.97h-.393Z" />
  </svg>
);

export default CheckmarkThick;
