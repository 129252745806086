import { MentorAssignment, Tag, User, UserService } from '@gbhem/api';
import create from 'zustand';

export interface UserRelationshipStore {
  tagOptions: Tag[];
  mentorAssignments: MentorAssignment[];
  menteeAssignments: MentorAssignment[];
  loading: boolean;

  // tags relationships
  setTagOptions: (Tags: Tag[]) => void;
  searchTags: (queryString: string | undefined, existingTags: Tag[]) => Tag[];

  // mentor/mentee relationships
  setMentorAssignments: (mentor: User, mentee: User) => Promise<void>;
  setMenteeAssignments: (mentor: User, mentees: User[]) => Promise<void>;
  removeMentors: (user: User, mentors: User[]) => Promise<void>;
  removeMentees: (user: User, mentees: User[]) => Promise<void>;

  // Sets up variables in state
  initializeState: (userId: string) => Promise<void>;
}

export const useUserRelationshipStore = create<UserRelationshipStore>((set, get) => ({
  loading: false,

  // tags relationships
  tagOptions: [],
  setTagOptions: (tagOptions: Tag[]) => {
    set(() => ({ tagOptions: tagOptions }));
  },
  searchTags: (queryString: string | undefined, existingTags: Tag[]) => {
    const unassignedTags = get().tagOptions.filter(
      (tag) => !existingTags.find((eTag) => eTag.id === tag.id)
    );

    if (!queryString) return unassignedTags;

    return unassignedTags.filter((tag) =>
      tag.name.toLowerCase().includes(queryString.toLowerCase())
    );
  },

  // mentor/mentee relationships
  mentorAssignments: [],
  menteeAssignments: [],
  setMenteeAssignments: async (mentor: User, mentees: User[]) => {
    const update = await UserService.assignUsersToMentor(
      mentor.id,
      mentees.map((m) => m.id)
    );

    set((state) => ({ menteeAssignments: [...state.menteeAssignments, ...update] }));
  },
  setMentorAssignments: async (mentor: User, mentee: User) => {
    const update = await UserService.assignUsersToMentor(mentor.id, [mentee.id]);

    set((state) => ({ mentorAssignments: [...state.mentorAssignments, ...update] }));
  },
  removeMentors: async (user: User, mentors: User[]) => {
    const userId: string = user.id;
    const mentorIds: string[] = mentors.map((mentor) => mentor.id);
    await UserService.unassignMentorsFromUser(userId, mentorIds);
    set((state) => ({
      mentorAssignments: [...state.mentorAssignments.filter((um) => !mentors.includes(um.mentor))]
    }));
  },
  removeMentees: async (user: User, mentees: User[]) => {
    const userId: string = user.id;
    const menteeIds: string[] = mentees.map((mentee) => mentee.id);
    await UserService.unassignMenteesFromUser(userId, menteeIds);
    set((state) => ({
      menteeAssignments: [...state.menteeAssignments.filter((um) => !mentees.includes(um.mentee))]
    }));
  },

  initializeState: async (userId: string) => {
    set(() => ({ loading: true }));

    const menteeAssignments = await UserService.getUserMentees(userId);
    const mentorAssignments = await UserService.getUserMentors(userId);
    const tagOptions = await UserService.searchTags('');

    set(() => ({
      tagOptions,
      menteeAssignments,
      mentorAssignments
    }));

    set(() => ({ loading: false }));
  }
}));
