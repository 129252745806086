import styled from '@emotion/styled';
import { Conference, TrackService, TrackTemplate } from '@gbhem/api';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import {
  Can,
  LocationStoreTypes,
  useAuthenticatedUser,
  useLocationStore,
  useRoles
} from '../../providers';
import { OrderBy } from '../../utils/resource.helper';
import { Accordion, Button, Edit, ErrorText, Input, Select, TextArea, TrackPillData } from '..';

const Details = styled.div`
  ${tw`sticky top-0 z-10 flex flex-col px-6 border-t border-b shadow-md border-neutral-500 bg-neutral-1000`}

  svg {
    ${tw`invisible`}
  }
`;

const Header = styled.div`
  ${tw`flex items-center gap-2`}

  svg {
    ${tw`visible text-neutral-400`}

    height: 1rem;
    width: 1rem;
  }
`;

const Description = styled.div`
  ${tw`text-neutral-700 text-sm font-light mb-2`}
`;

const Footer = styled.div`
  ${tw`flex px-2 pb-6`}
`;

const Fields = styled.div`
  ${tw`flex flex-col flex-1 px-2 mb-6 gap-4`}
`;

interface TrackDetailsProperties {
  track: TrackTemplate;
}

export function TrackDetails({ track: _track }: TrackDetailsProperties) {
  const [track, setTrack] = useState(_track);
  const [isSystemTrackWithCopies, setIsSystemTrackWithCopies] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const user = useAuthenticatedUser();

  const superadminRole = useRoles().system.find((r) => r.name === 'Superadmin');
  const userIsAdmin = user.roles.find((r) => r.id === superadminRole?.id);

  useAsync(async () => {
    setIsSystemTrackWithCopies((await TrackService.isSystemTrackWithCopies(track.id)).value);
  });

  const { conferences, query, system } = useLocationStore();

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
    setValue
  } = useForm({
    defaultValues: {
      name: track.name || '',
      description: track.description || '',
      conference: track.conference || undefined
    },
    mode: 'all'
  });
  // TODO (eliot) come up with a better way to protect this drop/header/edit form than with the Can
  return (
    <Details>
      <Accordion
        isOpen={!track.name}
        disabled={!userIsAdmin && !track.conference}
        header={
          <>
            <Header>
              {`${track.name}` || '...'}
              {(userIsAdmin || track.conference) && (
                <Can do="update" type="TrackTemplate" on={track}>
                  <Edit />
                </Can>
              )}
            </Header>
            <Description>{track.description}</Description>
            <TrackPillData track={track} />
          </>
        }
      >
        {({ close }) => (
          <Can do="update" type="TrackTemplate" on={track}>
            <form
              onSubmit={handleSubmit(async (changes) => {
                setErrorMessage(null);

                if (!changes.name || !changes.conference) {
                  return;
                }

                try {
                  await TrackService.updateTrackTemplate(track.id, {
                    name: changes.name,
                    description: changes.description,
                    conferenceId: changes.conference.id
                  });
                  setTrack({ ...track, ...changes });
                  reset({ ...track, ...changes });
                  close();
                } catch (e: any) {
                  setErrorMessage(e.body.message);
                }
              })}
            >
              {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
              <Fields>
                <Input
                  autoFocus={!track.name}
                  placeholder="Enter track name here..."
                  // @ts-ignore
                  {...register('name', {
                    required: true,
                    minLength: 2,
                    onChange: (e) => setValue('name', e.target.value.trimStart()),
                    onBlur: (e) => setValue('name', e.target.value.trimEnd())
                  })}
                />
                <TextArea
                  placeholder="Enter a meaningful track description here..."
                  {...register('description', {
                    minLength: 2,
                    onChange: (e) => setValue('description', e.target.value.trimStart()),
                    onBlur: (e) => setValue('description', e.target.value.trimEnd())
                  })}
                />
                <Select<Conference>
                  disabled={isSystemTrackWithCopies || (!track?.conference?.id && !userIsAdmin)}
                  defaultValue={track.conference || Object.values(conferences)[0]}
                  options={
                    userIsAdmin
                      ? (searchTerm: string | undefined) => {
                          if (!searchTerm) return;
                          let results = query(
                            {
                              conditions: [{ name: searchTerm }],
                              orderBy: OrderBy.Ascending,
                              limit: 10
                            },
                            LocationStoreTypes.Conference
                          );

                          if (results) {
                            results = [...[system], ...results];
                          }
                          return results;
                        }
                      : Object.values(conferences) || []
                  }
                  placeholder="Select conference for track..."
                  {...register('conference', {
                    onChange: (e) => {
                      setValue('conference', e.target.value);
                    }
                  })}
                />
                {isSystemTrackWithCopies && (
                  <ErrorText>
                    Cannot change conference unless all copies of this track are removed
                  </ErrorText>
                )}
              </Fields>
              <Footer>
                <Button disabled={!isDirty || !isValid}>Save</Button>
              </Footer>
            </form>
          </Can>
        )}
      </Accordion>
    </Details>
  );
}

export default TrackDetails;
