import { SVGProps } from 'react';

export function Time(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M25 0A24.991 24.991 0 0 0 7.324 7.324a24.99 24.99 0 0 0 0 35.352 24.99 24.99 0 0 0 35.352 0 24.99 24.99 0 0 0 0-35.352A24.99 24.99 0 0 0 25 0Zm8.85 33.85a2.491 2.491 0 0 1-1.774.724 2.497 2.497 0 0 1-1.777-.724l-7.075-7.076h.002A2.5 2.5 0 0 1 22.499 25V15A2.5 2.5 0 0 1 25 12.5 2.5 2.5 0 0 1 27.5 15v8.974l6.35 6.326a2.5 2.5 0 0 1 0 3.55Z" />
    </svg>
  );
}

export default Time;
