import { FormService, FormTemplate } from '@gbhem/api';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useModal } from 'react-modal-hook';
import { useEffectOnce, useUnmount } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import { Form } from '../Form';
import { Select } from '../Input';
import { Modal } from '../Modal';

interface FormSelectionEditorProperties {
  form?: FormTemplate;
  readOnly?: boolean;
  onChange: (form?: FormTemplate) => void;
}

function FormSelectionEditor({ form, readOnly = false, onChange }: FormSelectionEditorProperties) {
  const [selected, setSelected] = useState(form ?? null);

  const {
    register,
    unregister,
    clearErrors,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [formSelectName, setFormSelectName] = useState('Form Select Name');

  useEffectOnce(() => {
    if (!readOnly) {
      setFormSelectName(uuidv4());
    }
  });

  useEffect(() => {
    setValue(formSelectName, form?.name ?? '');
    clearErrors(formSelectName);
  }, [formSelectName, form?.name, setValue, clearErrors]);

  const [showFormPreview, hideFormPreview] = useModal(
    () =>
      selected && (
        <Modal
          name={`${selected.name} Form Preview`}
          onClose={hideFormPreview}
          container="form-preview"
        >
          <div className="relative p-4">
            <Form form={selected} />
          </div>
        </Modal>
      ),
    [selected]
  );

  useUnmount(() => {
    clearErrors(formSelectName);
    unregister(formSelectName);
  });

  return (
    <>
      <Select<FormTemplate>
        {...register(formSelectName, { required: { value: true, message: 'required' } })}
        label="Form"
        required={true}
        error={errors[formSelectName]?.message}
        placeholder="Search for the form to be completed in this step..."
        disabled={readOnly}
        options={async (searchTerm) => {
          if (!searchTerm) {
            return;
          }

          const { data } = await FormService.query({
            conditions: { name: searchTerm },
            limit: 10
          });

          return data;
        }}
        defaultValue={selected || undefined}
        onChange={async (e) => {
          if (!e.target.value) {
            setSelected(null);
            onChange(undefined);
          } else {
            clearErrors(formSelectName);
          }
        }}
        onSelect={async (e) => {
          if (!e.currentTarget.value) {
            return;
          }
          clearErrors(formSelectName);
          const form = await FormService.findOne(e.currentTarget.value.id, ['versions']);
          setSelected(form);
          onChange(form);
        }}
      />
      {selected && (
        <div
          className="cursor-pointer text-primary text-xs underline"
          onClick={async () => {
            setSelected(await FormService.findOne(selected.id, ['versions']));
            showFormPreview();
          }}
        >
          Preview
        </div>
      )}
      <p className="text-xs text-neutral-500">
        Can't find the form you're looking for?{' '}
        <span
          className="underline text-primary cursor-pointer z-10"
          onClick={async () => {
            const form = await FormService.create({ name: 'Untitled', system: false });

            window.location.href = `/forms/${form.id}`;
          }}
        >
          Create one.
        </span>
      </p>
      <div id="form-preview" />
    </>
  );
}

export default FormSelectionEditor;
