import styled from '@emotion/styled';
import { Field, FormVersion } from '@gbhem/api';
import tw from 'twin.macro';

import { Builder, Input } from '..';
import { Widget } from '../Field';
import { FieldSchemaEditorProperties, FieldSchemaPropertiesEditorProperties } from '.';

const Container = styled.div`
  ${tw`border border-dashed border-neutral-600 p-2`}
`;

export function ListItemEditor({
  properties,
  onChange,
  target,
  ...props
}: FieldSchemaEditorProperties & { target?: Widget }) {
  return (
    <>
      <b>{props.name}</b>
      <Container>
        <Builder
          target={target}
          form={
            (properties.template || {
              layout: { id: '', children: [] },
              fields: []
            }) as FormVersion
          }
          onChange={(changes) => {
            onChange({
              ...props,
              properties: {
                ...properties,
                template: {
                  ...changes,
                  fields: Object.values(changes.fields) as Field[]
                }
              }
            });
          }}
          placeholder={
            <div className="p-4">
              Drag and drop fields here to build the template for one item in the list.
            </div>
          }
        />
      </Container>
    </>
  );
}

export function ListEditor({ properties, onChange }: FieldSchemaPropertiesEditorProperties) {
  return (
    <div className="space-x-2 flex">
      <Input
        name="min"
        placeholder="Min items"
        type="number"
        value={properties.required ? properties.min || 1 : ''}
        onChange={(e) =>
          onChange({
            ...properties,
            min: Math.min(parseInt(e.target.value, properties.max)),
            required: parseInt(e.target.value) > 0
          })
        }
      />
      <Input
        name="max"
        type="number"
        placeholder="Max items"
        value={properties.max}
        onChange={(e) =>
          onChange({
            ...properties,
            max: Math.max(parseInt(e.target.value), properties.min)
          })
        }
      />
    </div>
  );
}

export default ListEditor;
