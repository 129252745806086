import styled from '@emotion/styled';
import { ChangeEvent, forwardRef, memo, useCallback, useState } from 'react';
import tw from 'twin.macro';

import { Input, InputProps, Label } from '../Input';

const Element = styled.div<{ hidden?: boolean; type?: string; instructions?: string }>`
  ${tw`gap-1`}
  ${(p) => (p.hidden ? tw`hidden` : '')}
  ${(p) => (p.instructions ? tw`py-1 relative -top-6` : '')}
`;

const Instructions = styled.div`
  ${tw`text-sm`}
`;

export const PhoneNumberInput = memo(
  forwardRef<
    HTMLInputElement,
    InputProps & {
      onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    }
  >(({ value, placeholder, ...props }, ref) => (
    <>
      <Input
        {...props}
        ref={ref}
        placeholder={placeholder}
        value={value}
        onChange={(e) => props.onChange(e)}
      />
    </>
  ))
);

export function PhoneNumber({ instructions, error, placeholder, onChange, ...props }: InputProps) {
  const [phone, setPhone] = useState<string>(props.value ? String(props.value) : '');
  let errors: string[] = [];
  if (error?.length) {
    errors = Array.isArray(error) ? error : [error];
  }

  const handlePhoneOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const isBackSpace = (e as any).nativeEvent.inputType === 'deleteContentBackward';
      const newPhoneValue = formatPhoneNumber(value, isBackSpace);
      setPhone(newPhoneValue);
      e.target.value = newPhoneValue;
      onChange?.({ ...e });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  const formatPhoneNumber = (value: string, isBackSpace: boolean) => {
    if (!value) {
      return value;
    }

    const regex_nondigit = /\D$/;
    let phoneNumber = value;
    const newPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
    const oldPhoneNumber = phone.replace(/[^\d]/g, '');
    let newPhoneValue = '';
    if (isBackSpace && value.match(regex_nondigit) && oldPhoneNumber === newPhoneNumber) {
      phoneNumber = value.substring(0, value.length - 1);
    }
    const newPhoneLength = newPhoneNumber.length;

    if (newPhoneLength > 6 && newPhoneLength < 11) {
      newPhoneValue = `${newPhoneNumber.slice(0, 3)}-${newPhoneNumber.slice(
        3,
        6
      )}-${newPhoneNumber.substring(6)}`;
    } else if (newPhoneLength > 3 && newPhoneLength < 7) {
      newPhoneValue = `${newPhoneNumber.slice(0, 3)}-${newPhoneNumber.substring(3)}`;
    } else if (newPhoneLength < 4) {
      newPhoneValue = `${newPhoneNumber.substring(0)}`;
    } else if (newPhoneLength > 10 && newPhoneLength < 14) {
      const last10 = newPhoneNumber.substring(newPhoneLength - 10);
      const cc = newPhoneNumber.substring(0, newPhoneLength - 10);
      newPhoneValue = `+${cc} ${last10.slice(0, 3)}-${last10.slice(3, 6)}-${last10.substring(6)}`;
    } else {
      newPhoneValue = phone;
    }

    return newPhoneValue;
  };

  return (
    <Element instructions={instructions}>
      <Label>
        {`${props.name}${props.required ? '*' : ''}`}
        {instructions && <Instructions>{instructions}</Instructions>}
      </Label>

      <PhoneNumberInput
        value={formatPhoneNumber(phone, false)}
        placeholder={placeholder}
        onChange={handlePhoneOnChange}
        error={errors}
      />
    </Element>
  );
}

export default PhoneNumber;
