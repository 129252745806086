import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useEffectOnce, useUnmount } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import { FieldSchemaPropertiesEditorProperties } from '../FieldEditor';
import { Input, Select, TextArea } from '../Input';
import { UploadEditorProperties } from '../StepEditor';

export const types = [
  'Audio',
  'Document',
  'Image',
  // TODO(jesse@fortyau.com) - re-enable this type when instructed.
  //'Video',
  'Any'
] as const;
export type UploadType = typeof types[number];

export type UploadEditorFields = UploadEditorProperties & FieldSchemaPropertiesEditorProperties;

interface UploadEditorViewProps {
  fields: UploadEditorFields;
  readOnly?: boolean;
}

export function UploadEditor({ fields, readOnly = false }: UploadEditorViewProps) {
  const uploadProps = fields.properties ? fields.properties : fields.specification;
  const context = useFormContext() || undefined;
  const {
    register,
    unregister,
    clearErrors,
    setValue,
    formState: { errors }
  } = context || { formState: {} };

  const [nameField, setNameField] = useState('Name');
  const [instructionsField, setInstructionsField] = useState('Instructions');

  useEffectOnce(() => {
    setValue?.(nameField, fields.specification.name ?? '');
    setValue?.(instructionsField, fields.specification.instructions ?? '');

    clearErrors?.([nameField, instructionsField]);

    if (!readOnly) {
      setNameField(uuidv4());
      setInstructionsField(uuidv4());
    }

    if (!uploadProps.type) {
      uploadProps.type = 'Any';
      fields.onChange({ ...uploadProps, type: 'Any' });
    }
  });

  useUnmount(() => {
    clearErrors?.([nameField, instructionsField]);
    unregister?.([nameField, instructionsField]);
  });

  return (
    <>
      {fields.specification ? (
        <>
          <Input
            label="Name"
            required={true}
            {...register?.(nameField, { required: { value: true, message: 'required' } })}
            disabled={readOnly}
            placeholder="Give the requested upload a meaningful name..."
            value={uploadProps.name}
            error={errors[nameField]?.message}
            onChange={(e) => {
              if (e.target.value && e.target.value.length > 0) {
                clearErrors?.(nameField);
              }
              fields.onChange({ ...uploadProps, name: e.target.value });
            }}
          />
        </>
      ) : null}

      {fields.specification ? (
        <Input
          type="checkbox"
          name="Multiple?"
          disabled={readOnly}
          checked={uploadProps.multiple}
          onChange={(e) => fields.onChange({ ...uploadProps, multiple: e.target.checked })}
        />
      ) : null}

      <Select
        name="Type"
        instructions="The type of file expected to be uploaded. Documents and Transcripts will allow typical
        document formats (.docx, .txt), Media will allow typical image formats (.jpg, .png.)"
        options={[
          'Audio',
          'Document',
          'Image',
          // TODO(jesse@fortyau.com) - re-enable this type when instructed.
          //'Video',
          'Any'
        ]}
        disabled={readOnly}
        value={uploadProps.type || ''}
        onChange={(e) => {
          fields.onChange({ ...uploadProps, type: e.target.value! });
        }}
      />

      {fields.specification ? (
        <>
          <TextArea
            required={true}
            {...(register &&
              register?.(instructionsField, { required: { value: true, message: 'required' } }))}
            label="Instructions"
            placeholder="Type specific instructions here..."
            value={uploadProps.instructions}
            disabled={readOnly}
            error={errors[instructionsField]?.message}
            onChange={(e) => {
              if (e.target.value && e.target.value.length > 0) {
                clearErrors?.(instructionsField);
              }
              fields.onChange({ ...uploadProps, instructions: e.target.value });
            }}
          />
        </>
      ) : null}
    </>
  );
}

export default UploadEditor;
