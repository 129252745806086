import { StepTemplate } from 'packages/api/dist/openapi';

export const buildStringIds = <T>(entity: T[]) => {
  const ids: string[] = [];
  entity.forEach((e) => {
    if (e && e !== null) {
      ids.push(String(e));
    }
  });
  return ids;
};

export const buildStringIdsByKey = <T>(entity: T[], field: keyof T) => {
  const ids: string[] = [];
  entity.forEach((e) => {
    if (e[field] && e[field] !== null) {
      ids.push(String(e[field]));
    }
  });
  return ids;
};

export const buildStringIdsNestedArrays = (entity: any[], field: any) => {
  const ids: string[] = [];
  entity.forEach((e) => {
    if (Array.isArray(e[field])) {
      e[field].forEach((fEle: any) => {
        ids.push(String(fEle));
      });
    }
  });
  return ids;
};

export const buildPhasePrereqs = (steps: StepTemplate[]) => {
  //loop over each step
  steps.forEach((step, stepIndex) => {
    // if a step has prereqs, we loop over each of the prereqs.
    step.prerequisites.forEach((prereq, prereqIndex) => {
      // We now need to find the id of the prereq to insert the step information into the prereq array
      const prereqStep = getStep(steps, String(prereq));
      if (prereqStep) {
        steps[stepIndex].prerequisites[prereqIndex] = prereqStep;
      }
    });
  });
  return steps;
};

export const createEntityObject = <T>(entity: T) => {
  return { id: String(entity) };
};

const getStep = (steps: StepTemplate[], id: string) => {
  return steps.filter((step) => step.id === id)[0];
};

export const appendInnerField = <T extends { id: string }>(
  parent: T[],
  child: any[],
  field: keyof T
) => {
  parent.forEach((instance, index) => {
    const found = child.filter((f) => f.id === createEntityObject(instance[field]).id);
    if (found && found.length > 0) parent[index][field] = found[0];
  });
  return parent;
};
