import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import tw from 'twin.macro';

import { FadeIn, Select } from '..';
import { Button } from '../Button';
import { ArrowLeft, ArrowRight } from '../icons';

const Container = styled(FadeIn)`
  ${tw`flex text-sm font-normal w-full my-4 pb-4`}
`;

const PaginationSection = styled.div`
  ${tw`flex items-center justify-center flex-grow`}
`;

const ChangeButton = styled(Button)`
  ${tw`mx-4 text-sm font-normal border-0 p-0 border-b rounded-none bg-transparent active:shadow-none cursor-pointer`}

  svg {
    ${tw`w-3 h-3`}
  }

  :disabled {
    ${tw`bg-transparent border-transparent cursor-not-allowed text-neutral-600`}
  }
`;

const Pages = styled.div`
  ${tw`flex text-sm font-normal cursor-pointer text-neutral-300`}

  .active {
    ${tw`font-bold text-pink-500 cursor-default`}
  }
`;

const Page = styled.div`
  ${tw`mx-1`}
`;

type PaginationProps = {
  total: number;
  currentPage: number;
  pageSize: number;
  nextPage: () => void;
  previousPage: () => void;
  gotoPage: (page: number) => void;
  onChangeSize: (size: number) => void;
};

export function TablePagination({
  total,
  currentPage,
  pageSize,
  nextPage,
  previousPage,
  gotoPage,
  onChangeSize
}: PaginationProps) {
  const [visiblePages, setVisiblePages] = useState<number[]>([]);

  const pages = useMemo(() => Math.ceil(total / pageSize), [total, pageSize]);

  useEffect(() => {
    if (pages < 7) {
      setVisiblePages([1, 2, 3, 4, 5, 6].filter((p) => p <= pages));
      return;
    }

    if (currentPage % 5 >= 0 && currentPage > 4 && currentPage + 2 < pages) {
      setVisiblePages([1, currentPage - 1, currentPage, currentPage + 1, pages]);
    } else if (currentPage % 5 >= 0 && currentPage > 4 && currentPage + 2 >= pages) {
      setVisiblePages([1, pages - 3, pages - 2, pages - 1, pages]);
    } else {
      setVisiblePages([1, 2, 3, 4, 5, pages]);
    }
  }, [pages, currentPage, pageSize]);

  return (
    <Container>
      <div className="flex items-baseline space-x-2 absolute">
        <div>
          <b>{total}</b> results
        </div>
        <Select
          options={[10, 25, 50]}
          className="w-20 text-xs h-auto"
          placeholder="per page"
          value={pageSize}
          onChange={(e) => onChangeSize(e.target.value || 10)}
        />
      </div>
      {total > 0 && (
        <PaginationSection>
          <ChangeButton onClick={previousPage} disabled={!currentPage} inverted>
            <ArrowLeft />
            previous
          </ChangeButton>
          {visiblePages.map((page, i) => (
            <Pages key={page}>
              {visiblePages[i - 1] + 1 < page ? (
                <>
                  <Page> ... </Page>
                  <Page onClick={() => gotoPage(page - 1)}>{page}</Page>
                </>
              ) : (
                <Page
                  className={page - 1 === currentPage ? ' active ' : ''}
                  onClick={() => gotoPage(page - 1)}
                >
                  {page}
                </Page>
              )}
            </Pages>
          ))}
          <ChangeButton onClick={nextPage} disabled={currentPage >= pages - 1} inverted>
            next
            <ArrowRight />
          </ChangeButton>
        </PaginationSection>
      )}
    </Container>
  );
}

export default TablePagination;
