import { FormInstance, FormService, StepInstance } from '@gbhem/api';
import { useMemo } from 'react';
import { Column } from 'react-table';

import { ColumnLink, Table } from '../Table';

interface FormsTableProperties {
  userId: string;
}

export const UserFormsTable = ({ userId }: FormsTableProperties) => {
  const columns = useMemo(
    () =>
      [
        {
          Header: 'TITLE',
          accessor: 'version',
          id: 'title',
          disableSortBy: false,
          Cell: ({ row: { original: value } }) => {
            const stepInstance: StepInstance = value?.stepContentInstance.stepInstance;
            return (
              <>
                {!stepInstance.stepTemplate.inactive && (
                  <ColumnLink to={'/step/' + stepInstance.id}>
                    {value?.version.form.name}
                  </ColumnLink>
                )}
                {stepInstance.stepTemplate.inactive && <>{value?.version.form.name}</>}
              </>
            );
          }
        },
        {
          Header: 'TRACK',
          accessor: 'version',
          id: 'trackName',
          disableSortBy: false,
          Cell: ({ row: { original: value } }) => {
            const stepInstance = value?.stepContentInstance.stepInstance;
            const trackTemplate = stepInstance.trackInstance.trackTemplate;
            return <>{trackTemplate.name}</>;
          }
        },
        {
          Header: 'STEP',
          accessor: 'version',
          id: 'stepName',
          disableSortBy: false,
          Cell: ({ row: { original: value } }) => {
            const stepInstance = value?.stepContentInstance.stepInstance;
            return <>{stepInstance.stepTemplate.name}</>;
          }
        },
        {
          Header: 'PHASE',
          accessor: 'version',
          id: 'phaseName',
          Cell: ({ row: { original: value } }) => {
            const stepInstance = value?.stepContentInstance.stepInstance;
            return stepInstance.stepTemplate.phase.name;
          }
        },
        {
          Header: 'SUBMITTED AT',
          accessor: 'version',
          id: 'createdAt',
          disableSortBy: false,
          Cell: ({ row: { original: value } }) => {
            const date = value?.updatedAt;
            const displayDate = date?.split('T')[0];
            return value.submitted ? `${displayDate}` : 'Not Submitted';
          }
        }
      ] as Column<FormInstance>[],
    []
  );

  return (
    <Table
      columns={columns}
      loader={async () => {
        const query = await FormService.getFormInstancesByUser(userId);
        return { data: query, count: query.length };
      }}
      relations={['roles', 'permissions']}
    />
  );
};
