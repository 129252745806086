import styled from '@emotion/styled';
import { CommunicationService, User } from '@gbhem/api';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import {
  EthnicBackgroundOptions,
  GenderOptions,
  LanguageOptions,
  StateOptions,
  Suffix,
  TitleOptions
} from '../../models';
import { Can } from '../../providers';
import { useUserInfoStore } from '../../providers/UserProfileProvider/UserInfoProvider';
import { Button, CalendarPicker, Checkmark, Edit, Input, Select } from '..';
import { PhoneNumber } from '../Field';
import { UserMessageModal } from '../UserMessageModal';
import { TagPill } from './Tags';

const UserDetailsGrid = styled.div`
  ${tw`grid grid-flow-row	grid-cols-4 grid-rows-3 gap-3`}
`;

const InfoLabel = styled.p`
  ${tw`text-xs font-semibold`}
`;

const InfoValue = styled.p`
  ${tw`text-sm whitespace-pre-line`}
`;

const Pill = styled(TagPill)<{ flag?: boolean; isDisplayOnly: boolean }>`
  ${tw`mx-4 font-bold cursor-pointer`}
  ${(p) => (!p.flag ? tw`bg-red-400` : tw`bg-green-400`)}
  ${(p) => (p.isDisplayOnly ? tw`cursor-auto` : tw`cursor-pointer`)}

  caret-color: transparent;
  min-width: fit-content;
`;

const OptInPill = styled.div`
  ${tw`flex mt-4 max-h-5`}
`;

const SMSGridPill = styled(Pill)`
  ${tw`justify-center whitespace-nowrap`}

  min-width: fit-content;
`;

const VerificationInfo = styled.p`
  ${tw`mb-4`}
`;

const ItalicizedInfo = styled.p`
  ${tw`italic`}
`;

const ActionButton = styled(Button)`
  svg {
    ${tw`w-4 h-4`}
  }
`;

const COUNTRY_CODES = {
  US: '1'
};

type UserDetailsErrors = {
  primaryPhoneNumberErrors: string[];
  secondPhoneNumberErrors: string[];
  smsOptInErrors: string[];
};

interface UserDetailsProps {
  user: User;
}

interface UserEmailVerificationModalProps {
  email: string;
}

const UserEmailVerificationMessage = ({ email }: UserEmailVerificationModalProps) => (
  <>
    <VerificationInfo>Please check your inbox.</VerificationInfo>
    <VerificationInfo>
      A verification email has been sent to <strong>{email}</strong>.
    </VerificationInfo>
    <ItalicizedInfo>
      The email address will be updated as soon as the email is validated by the user.
    </ItalicizedInfo>
  </>
);

export function UserDetails({ user }: UserDetailsProps) {
  const { isChange, isEmailChanged, editUserDetails, saveUserDetails } = useUserInfoStore();

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [userDetailErrors, setUserDetailErrors] = useState<UserDetailsErrors>({
    primaryPhoneNumberErrors: [],
    secondPhoneNumberErrors: [],
    smsOptInErrors: []
  });
  const [isSmsVerificationTriggered, setIsSmsVerificationTriggered] = useState<boolean>(false);

  const [pendingNewEmail, setPendingNewEmail] = useState<string>('');
  const [showEmailVerificationModal, hideEmailVerificationModal] = useModal(
    () => (
      <UserMessageModal
        onClose={() => {
          hideEmailVerificationModal();
          setPendingNewEmail('');
        }}
        name="Verification Email Sent"
      >
        <UserEmailVerificationMessage email={pendingNewEmail} />
      </UserMessageModal>
    ),
    [pendingNewEmail]
  );

  const userFields: string[] = [
    'fullName',
    'title',
    'suffix',
    'gender',
    'dateOfBirth',
    'email',
    'secondEmail',
    'phoneNumber',
    'secondPhoneNumber',
    'roles',
    'primaryLanguage',
    'ethnicBackground',
    'umcaresId',
    'gcfaId',
    'pid',
    'address',
    'salesforceId',
    'smsOptIn',
    'emailNotificationsOptIn'
  ];

  const getAgeInYearsAndMonths = (dob: Date): string => {
    const now = new Date();
    const isDobMonthPast = now.getUTCMonth() > dob.getUTCMonth();
    const isDobMonthNow = now.getUTCMonth() === dob.getUTCMonth();
    const isDobDayPast = now.getUTCDate() >= dob.getUTCDate();
    const isDobPast = isDobMonthPast || (isDobMonthNow && isDobDayPast);
    const year = isDobPast ? now.getUTCFullYear() : now.getUTCFullYear() - 1;
    const ageYears = year - dob.getFullYear();
    let monthDifference = now.getUTCMonth() - dob.getUTCMonth();
    let ageMonthsRemainder = isDobDayPast ? 0 : 11;
    if (monthDifference !== 0) {
      if (!isDobMonthPast) monthDifference = 12 - Math.abs(monthDifference);
      ageMonthsRemainder = isDobDayPast ? monthDifference : monthDifference - 1;
    }

    return `${ageYears}yr ${ageMonthsRemainder}mo`;
  };

  const formatDisplayNumber = (phone: string | undefined | null) => {
    if (!phone || phone.length === 0) return '';

    const newPhoneNumber = phone.replace(/[^\d]/g, '');
    const newPhoneLength = newPhoneNumber.length;
    const last10 = newPhoneNumber.substring(newPhoneLength - 10);
    const cc = newPhoneNumber.substring(0, newPhoneLength - 10);

    return `+${cc} ${last10.slice(0, 3)}-${last10.slice(3, 6)}-${last10.substring(6)}`;
  };

  const toLocaleUTCDateString = (
    date: Date,
    locales: string,
    options?: Intl.DateTimeFormatOptions
  ) => {
    const timeDiff = date.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(date.valueOf() + timeDiff);
    return adjustedDate.toLocaleDateString(locales, options);
  };

  const fieldValue = (field: string) => {
    let details: any;

    switch (field) {
      case 'fullName': {
        details = `${user.firstName} ${user?.middleName || ''} ${user.lastName}`;
        break;
      }
      case 'roles': {
        details = `${user.roles.map((r) => r.name).join(', ')}`;
        break;
      }
      case 'dateOfBirth': {
        const dob = new Date(user.dateOfBirth);
        details = `${toLocaleUTCDateString(dob, 'en-US')} (${getAgeInYearsAndMonths(dob)}) `;
        break;
      }
      case 'address': {
        if (!user.address) {
          details = '';
        } else {
          const { street, unit, city, state, zipCode } = user.address;
          details = `${street} \n ${unit ? `${unit}\n` : ''} ${city}, ${state} ${zipCode}`;
        }
        break;
      }
      case 'email': {
        //only display changed email after verification
        details = user[field as keyof User];
        break;
      }
      case 'phoneNumber': {
        details = formatDisplayNumber(user.phoneNumber);
        break;
      }
      case 'secondPhoneNumber': {
        details = formatDisplayNumber(user.secondPhoneNumber);
        break;
      }
      case 'smsOptIn': {
        const smsOptIn = user['smsOptIn'] ? (user['smsOptIn'] ? 'Yes' : 'No') : 'No';
        details = (
          <OptInPill>
            <Pill flag={smsOptIn === 'Yes'} isDisplayOnly={true}>
              {smsOptIn}
            </Pill>
          </OptInPill>
        );
        break;
      }
      case 'emailNotificationsOptIn': {
        const emailNotificationsOptIn = user['emailNotificationsOptIn']
          ? user['emailNotificationsOptIn']
            ? 'Yes'
            : 'No'
          : 'No';
        details = (
          <OptInPill>
            <Pill flag={emailNotificationsOptIn === 'Yes'} isDisplayOnly={true}>
              {emailNotificationsOptIn}
            </Pill>
          </OptInPill>
        );
        break;
      }
      default: {
        details = user[field as keyof User];
      }
    }
    return <InfoValue>{details}</InfoValue>;
  };

  const fieldLabel = (field: string) => {
    let label = '';

    switch (field) {
      case 'fullName': {
        label = 'Full Name';
        break;
      }
      case 'dateOfBirth': {
        label = 'Date of Birth';
        break;
      }
      case 'phoneNumber': {
        label = 'Primary Phone Number';
        break;
      }
      case 'primaryLanguage': {
        label = 'Primary Language';
        break;
      }
      case 'ethnicBackground': {
        label = 'Ethnic Background';
        break;
      }
      case 'secondEmail': {
        label = 'Secondary Email';
        break;
      }
      case 'secondPhoneNumber': {
        label = 'Secondary Phone Number';
        break;
      }
      case 'umcaresId': {
        label = 'UMC ID';
        break;
      }
      case 'gcfaId': {
        label = 'GCFA ID';
        break;
      }
      case 'pid': {
        label = 'PID';
        break;
      }
      case 'email': {
        label = 'Primary Email';
        break;
      }
      case 'smsOptIn': {
        label = 'Opted-in SMS Messaging';
        break;
      }
      case 'emailNotificationsOptIn': {
        label = 'Opted-in Email Notifications';
        break;
      }
      case 'salesforceId': {
        label = 'Salesforce ID';
        break;
      }
      default: {
        label = field;
      }
    }
    return <InfoLabel>{label.toUpperCase()}</InfoLabel>;
  };

  const validateDistinctError = (error: string, errors?: string[]): boolean => {
    return errors ? errors.filter((e) => e === error).length < 1 : true;
  };

  const removeUserDetailError = (
    _userDetailErrors: UserDetailsErrors,
    error: string,
    property: keyof UserDetailsErrors
  ) => {
    _userDetailErrors[property] = [...removeError(error, userDetailErrors[property])];
  };

  const removeError = (error: string, errors?: string[]): string[] => {
    return errors ? errors.filter((e) => e !== error) : [];
  };

  const formatPhoneNumber = (phoneNumber?: string | null) => {
    return phoneNumber ? `+${phoneNumber.replace(/[^\d]/g, '')}` : phoneNumber;
  };

  const validatePhoneLength = (phoneNumber?: string | null): boolean => {
    const _phoneNumber = phoneNumber ? phoneNumber?.replace(/[^\d]/g, '') : '';
    return (
      phoneNumber === undefined ||
      phoneNumber === null ||
      phoneNumber.length === 0 ||
      _phoneNumber.length > 10
    ); //Country Code US only; makes all numbers (+1)[10 digits]
  };

  const validateCountryCode = (phoneNumber?: string | null): boolean => {
    if (phoneNumber && phoneNumber.replace(/[^\d]/g, '').length < 11) {
      return false;
    }
    if (!phoneNumber || phoneNumber.length === 0) {
      return true;
    } else {
      phoneNumber = phoneNumber.replace(/[^\d]/g, '');
      const l = phoneNumber.length;
      const phoneNumberNoCC = phoneNumber.substring(l - 10, l);
      const countryCode = phoneNumber.replace(phoneNumberNoCC, '');
      const countryCodes = [COUNTRY_CODES.US];
      return countryCodes.includes(countryCode); //Country Code US only; makes all numbers (+1)[10 digits]
    }
  };

  const addUserDetailError = (
    _userDetailErrors: UserDetailsErrors,
    error: string,
    property: keyof UserDetailsErrors
  ): void => {
    if (validateDistinctError(error, _userDetailErrors[property])) {
      _userDetailErrors[property] = [..._userDetailErrors[property], error];
    }
  };

  const handleUserDetailErrorCheck = (
    _userDetailErrors: UserDetailsErrors,
    errorsProperty: keyof UserDetailsErrors,
    error: string,
    isCheckValidated: boolean
  ) => {
    if (!isCheckValidated) {
      addUserDetailError(_userDetailErrors, error, errorsProperty);
    } else {
      removeUserDetailError(_userDetailErrors, error, errorsProperty);
    }
    return !isCheckValidated;
  };

  const checkPhoneNumbers = (_userDetailErrors: UserDetailsErrors, _user: User): boolean => {
    const phoneNumber = formatPhoneNumber(_user.phoneNumber);
    const secondaryPhoneNumber = formatPhoneNumber(_user.secondPhoneNumber);

    let error = 'Invalid phone number length';
    const isErrorPrimaryPhoneLength = handleUserDetailErrorCheck(
      _userDetailErrors,
      'primaryPhoneNumberErrors',
      error,
      validatePhoneLength(phoneNumber)
    );

    const isErrorSecondaryPhoneLength = handleUserDetailErrorCheck(
      _userDetailErrors,
      'secondPhoneNumberErrors',
      error,
      validatePhoneLength(secondaryPhoneNumber)
    );

    error = 'Invalid country code (+1 US only)';
    const isErrorPrimaryCountryCode = handleUserDetailErrorCheck(
      _userDetailErrors,
      'primaryPhoneNumberErrors',
      error,
      validateCountryCode(phoneNumber)
    );

    const isErrorSecondaryCountryCode = handleUserDetailErrorCheck(
      _userDetailErrors,
      'secondPhoneNumberErrors',
      error,
      validateCountryCode(secondaryPhoneNumber)
    );

    return (
      isErrorPrimaryPhoneLength ||
      isErrorPrimaryCountryCode ||
      isErrorSecondaryPhoneLength ||
      isErrorSecondaryCountryCode
    );
  };

  const validateUser = (user: User): boolean => {
    //validate Phone number
    let hasError = false;
    const _userDetailErrors = userDetailErrors;

    hasError = checkPhoneNumbers(_userDetailErrors, user);

    setUserDetailErrors({
      ..._userDetailErrors
    });

    return !hasError;
  };

  return (
    <div>
      <div className="flex items-center justify-end space-x-2">
        {isEdit ? (
          <ActionButton
            onClick={async (e) => {
              e.preventDefault();

              if (validateUser(user)) {
                if (isChange) {
                  saveUserDetails();

                  if (isEmailChanged) {
                    setPendingNewEmail(user.email);
                    showEmailVerificationModal();
                  }
                }

                setIsEdit(!isEdit);
              }
            }}
          >
            <Checkmark /> Save
          </ActionButton>
        ) : (
          <ActionButton
            onClick={(e) => {
              e.preventDefault();
              setIsEdit(!isEdit);
            }}
          >
            <Edit /> Edit
          </ActionButton>
        )}
      </div>
      <UserDetailsGrid>
        {isEdit ? (
          <>
            <Input
              onChange={(e) => editUserDetails('firstName', e)}
              name="First Name"
              value={user.firstName}
            />
            <Input
              onChange={(e) => editUserDetails('middleName', e)}
              name="Middle Name"
              value={user?.middleName || ''}
            />
            <Input
              onChange={(e) => editUserDetails('lastName', e)}
              name="Last Name"
              value={user.lastName}
            />
            <Select
              onChange={(e) => editUserDetails('title', e)}
              name="Title"
              value={user?.title}
              options={Object.values(TitleOptions)}
            />
            <Select
              onChange={(e) => editUserDetails('suffix', e)}
              name="Suffix"
              value={user?.suffix || ''}
              options={Object.values(Suffix)}
            />
            <Select
              onChange={(e) => editUserDetails('gender', e)}
              name="Gender"
              value={user?.gender}
              options={Object.values(GenderOptions)}
            />
            <CalendarPicker
              onChange={(e) => editUserDetails('dateOfBirth', e)}
              name="Date of Birth"
              value={user.dateOfBirth}
            />
            <Input
              type="email"
              onChange={(e) => editUserDetails('email', e)}
              name="Primary Email"
              value={user.email}
            />
            <Input
              type="email"
              onChange={(e) => editUserDetails('secondEmail', e)}
              name="Secondary Email"
              value={user?.secondEmail || ''}
            />
            <PhoneNumber
              onChange={(e) => editUserDetails('phoneNumber', e)}
              name="Primary Phone Number"
              value={user?.phoneNumber || ''}
              error={userDetailErrors.primaryPhoneNumberErrors}
              instructions={'Only US based numbers supported (+1)'}
            />
            <PhoneNumber
              onChange={(e) => editUserDetails('secondPhoneNumber', e)}
              name="Secondary Phone Number"
              value={user?.secondPhoneNumber || ''}
              error={userDetailErrors.secondPhoneNumberErrors}
              instructions={'Only US based numbers supported (+1)'}
            />
            <Select
              name="Primary Language"
              onChange={(e) => editUserDetails('primaryLanguage', e)}
              value={user?.primaryLanguage}
              options={Object.values(LanguageOptions)}
            />
            <Select
              name="Ethinic Background"
              onChange={(e) => editUserDetails('ethnicBackground', e)}
              value={user?.ethnicBackground}
              options={Object.values(EthnicBackgroundOptions)}
            />
            <Can do="manage" on="all">
              <Input
                onChange={(e) => editUserDetails('salesforceId', e)}
                name="Salesforce ID"
                value={user.salesforceId}
              />
              <Input onChange={(e) => editUserDetails('pid', e)} name="PID" value={user?.pid} />
            </Can>
            <OptInPill>
              <Input
                disabled={!user?.smsOptIn && !user?.isPhoneNumberVerified}
                type="checkbox"
                name="SMS Opt-In Messaging"
                onChange={(e) => editUserDetails('smsOptIn', e)}
                checked={user?.smsOptIn}
              ></Input>

              {user.phoneNumber && (
                <SMSGridPill
                  onClick={() => {
                    console.log(user.isPhoneNumberVerified, isSmsVerificationTriggered);
                    if (!user.isPhoneNumberVerified && !isSmsVerificationTriggered) {
                      CommunicationService.triggerSmsVerificationProcess(user.id);
                      setIsSmsVerificationTriggered(true);
                    }
                  }}
                  flag={user.isPhoneNumberVerified}
                  isDisplayOnly={false}
                >
                  {!isSmsVerificationTriggered
                    ? user.isPhoneNumberVerified
                      ? 'Has Verified Number'
                      : 'Verify Primary Number'
                    : 'Verification Sent'}
                </SMSGridPill>
              )}
              {!user.phoneNumber && (
                <SMSGridPill flag={user.isPhoneNumberVerified} isDisplayOnly={true}>
                  {'Primary Number Required'}
                </SMSGridPill>
              )}
            </OptInPill>
            <OptInPill>
              <Input
                type="checkbox"
                name="Subsrcibe to Email Notifications"
                onChange={(e) => editUserDetails('emailNotificationsOptIn', e)}
                checked={user?.emailNotificationsOptIn}
              ></Input>
            </OptInPill>

            <div className="font-bold text-lg my-4 col-span-4">Address</div>
            <Input
              onChange={(e) => editUserDetails('street', e, true)}
              name="Street Address"
              value={user.address?.street || ''}
            />
            <Input
              onChange={(e) => editUserDetails('unit', e, true)}
              name="Apartment, Unit, etc."
              value={user.address?.unit || ''}
            />
            <Input
              onChange={(e) => editUserDetails('city', e, true)}
              name="City"
              value={user.address?.city || ''}
              required
            />
            <Select
              onChange={(e) => editUserDetails('state', e, true)}
              name="State"
              value={user.address?.state || ''}
              options={Object.values(StateOptions)}
            />
            <Input
              onChange={(e) => editUserDetails('zipCode', e, true)}
              name="Zip Code"
              value={user.address?.zipCode || ''}
            />
          </>
        ) : (
          userFields.map((f, i) => (
            <div key={i}>
              {fieldLabel(f)}
              {fieldValue(f)}
            </div>
          ))
        )}
      </UserDetailsGrid>
    </div>
  );
}

export default UserDetails;
