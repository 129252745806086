import styled from '@emotion/styled';
import {
  AdminService,
  TrackInstance,
  TrackRoles,
  TrackService,
  TrackTemplate,
  UserService
} from '@gbhem/api';
import { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import { Can, useAuthenticatedUser, useTrackStore } from '../../providers';
import {
  Button,
  Dismissable,
  LoaderIcon,
  Option,
  OptionsMenu,
  Plus,
  SubNavbar,
  TrackSearch,
  TrackSummary
} from '..';
import { FadeIn } from '../animations';
import { Loader } from '../Loader';
import { TrackRoleSummary } from '.';
import {
  AddTrackModal,
  ConfirmDeactivateTrackModal,
  ConfirmDeleteTrackModal,
  CopyTrackModal,
  ImportTrackModal
} from './Dialogs';
import { ConferenceFilter } from './TrackSearch';

const Container = styled(FadeIn)`
  ${tw`p-4 space-y-4`}
`;

const Templates = styled.div`
  ${tw`mb-8 grid lg:grid-cols-3 gap-5`}
`;

export function Tracks() {
  const router = useHistory();
  const { load, tracks, deactivate, deleteTrack, loading, filteredTracks } = useTrackStore();
  const [assignedTrackRoles, setAssignedTrackRoles] = useState<TrackRoles[]>([]);
  const [addTrackName, setAddTrackName] = useState<string>('');

  const [participatingTracks, setParticipatingTracks] = useState<TrackInstance[]>();
  const [isTrackAdmin, setIsTrackAdmin] = useState<boolean>();

  const user = useAuthenticatedUser();

  useEffect(() => void load(), [load, user]);

  useAsync(async () => {
    const _assigned = await TrackService.getAssignedTracks();
    setAssignedTrackRoles(
      _assigned.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    );

    const _participating = await UserService.getParticipantTracks(user.id);
    setParticipatingTracks(
      _participating.sort((a, b) =>
        a.trackTemplate.name > b.trackTemplate.name
          ? 1
          : b.trackTemplate.name > a.trackTemplate.name
          ? -1
          : 0
      )
    );

    const _isTrackAdmin: boolean = await AdminService.isTrackAdmin().catch(() => false);

    setIsTrackAdmin(_isTrackAdmin);

    if (_participating.length === 0 && _assigned.length === 0 && !_isTrackAdmin) {
      router.push(`/users/${user.id}`);
    }
  }, []);

  const [trackConferences, setTrackConferences] = useState<Set<ConferenceFilter>>();
  useEffect(() => {
    if (tracks) {
      const _conferences = new Set<string>();
      const systemTrack: ConferenceFilter = { id: '0', name: 'System' };

      Object.values(tracks).map((t: TrackTemplate) => {
        const { id, name } = t.conference || systemTrack;
        _conferences.add(JSON.stringify({ id, name }));
        return t;
      });
      setTrackConferences(new Set<ConferenceFilter>([..._conferences].map((c) => JSON.parse(c))));
    }
  }, [tracks]);

  const [targetTrack, setTargetTrack] = useState<TrackTemplate>();

  const [showAddTrackModal, hideAddTrackModal] = useModal(
    () => <AddTrackModal onClose={hideAddTrackModal} />,
    [addTrackName, setAddTrackName]
  );

  const [showCopyFromExistingModal, hideCopyFromExistingModal] = useModal(
    () => <CopyTrackModal onClose={hideCopyFromExistingModal} />,
    [targetTrack]
  );

  const [showImportFromExistingModal, hideImportFromExistingModal] = useModal(
    () => <ImportTrackModal onClose={hideImportFromExistingModal} />,
    []
  );

  const [showConfirmDeactivateModal, hideConfirmDeactivateModal] = useModal(
    () =>
      targetTrack ? (
        <ConfirmDeactivateTrackModal
          track={targetTrack}
          onClose={hideConfirmDeactivateModal}
          onConfirm={() => {
            if (!targetTrack) {
              return;
            }

            deactivate(targetTrack);
          }}
        />
      ) : null,
    [targetTrack]
  );

  const [showConfirmDeleteModal, hideConfirmDeleteModal] = useModal(
    () =>
      targetTrack ? (
        <ConfirmDeleteTrackModal
          track={targetTrack}
          onClose={hideConfirmDeleteModal}
          onConfirm={() => {
            if (!targetTrack) {
              return;
            }

            deleteTrack(targetTrack.id);
          }}
        />
      ) : null,
    [targetTrack]
  );

  const TemplateList = ({
    templates,
    editable = true
  }: {
    templates: TrackTemplate[];
    editable?: boolean;
  }) =>
    templates.length ? (
      <Templates>
        {templates.map((track) => (
          <TrackSummary
            key={track.id}
            track={track}
            editable={editable}
            onDeactivate={(track: TrackTemplate) => {
              setTargetTrack(track);
              showConfirmDeactivateModal();
            }}
            onDelete={(track: TrackTemplate) => {
              setTargetTrack(track);
              showConfirmDeleteModal();
            }}
          />
        ))}
      </Templates>
    ) : null;

  const RoleBasedTrackList = ({ trackRoles }: { trackRoles: TrackRoles[] }) =>
    trackRoles.length ? (
      <Templates>
        {trackRoles.map((tr) => (
          <TrackRoleSummary key={tr.id} track={tr} />
        ))}
      </Templates>
    ) : null;

  const [isAddingTrack, setIsAddingTrack] = useState(false);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Can do="create" on="TrackInstance">
        <SubNavbar className="p-4">
          <Can do="create" on="TrackTemplate">
            <div className="font-semibold">Tracks</div>
            <Dismissable
              open={isAddingTrack}
              control={
                <Button onClick={() => setIsAddingTrack(true)}>
                  <Plus /> Track
                </Button>
              }
            >
              <OptionsMenu onClick={() => setIsAddingTrack(false)}>
                <Option onClick={showAddTrackModal}>Create New</Option>
                <Option onClick={showCopyFromExistingModal}>Copy from Existing</Option>
                <Option onClick={showImportFromExistingModal}>Import from Existing</Option>
              </OptionsMenu>
            </Dismissable>
          </Can>
          <TrackSearch
            tracks={Object.values(tracks).map((t: TrackTemplate) => t)}
            trackConferences={trackConferences}
          />
        </SubNavbar>
      </Can>
      <Container>
        <>
          {isTrackAdmin ? (
            <>
              <div className="font-bold text-lg">Track Templates ({filteredTracks.length})</div>
              <TemplateList templates={filteredTracks} />
            </>
          ) : null}
        </>
        {participatingTracks?.length ? (
          <>
            <hr className="border-neutral-700" />
            <div className="font-bold text-lg">Participating ({participatingTracks?.length})</div>
            <TemplateList
              templates={participatingTracks.map((t) => ({
                ...t.trackTemplate,
                // haxx 04/01/2022 https://i.giphy.com/media/Q2W4hziDOyzu0/giphy.webp
                id: `${t.trackTemplate.id}/assignments/${t.id}`
              }))}
              editable={false}
            />
          </>
        ) : Object.keys(tracks).length === 0 ? (
          <div className="space-y-10 text-lg flex flex-col items-center justify-center py-12">
            <LoaderIcon className="opacity-20 grayscale" />
            <p>You are not participating in any tracks right now.</p>
          </div>
        ) : null}
        {assignedTrackRoles.length ? (
          <>
            <hr className="border-neutral-700" />
            <div className="font-bold text-lg">
              Assignments (
              {assignedTrackRoles.length
                ? assignedTrackRoles
                    .map((tr) => tr.trackAssignments.length)
                    .reduce((a, b) => a + b, 0)
                : 0}
              )
            </div>
            {assignedTrackRoles.length ? (
              <RoleBasedTrackList trackRoles={assignedTrackRoles} />
            ) : null}
          </>
        ) : null}
      </Container>
    </>
  );
}

export default Tracks;
