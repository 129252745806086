import styled from '@emotion/styled';
import { UserComment } from '@gbhem/api';
import { ComponentProps, useState } from 'react';
import tw from 'twin.macro';

import { Button, CardFooter, Modal, ModalProps } from '../../components';
import { useUserInfoStore } from '../../providers/UserProfileProvider/UserInfoProvider';
import { Comment } from './Comment';

const CommentSection = styled.div`
  ${tw`grid grid-cols-4 auto-rows-max gap-4 mb-10`}
`;

type CommentsModalProps = Omit<ModalProps, 'onChange'> & ComponentProps<'form'>;

export function AdminCommentsModal({ onClose, name }: CommentsModalProps) {
  const [comments, setComments] = useState<(UserComment & { error?: string })[]>([
    { comment: '', type: '' } as any
  ]);

  const { addComments, userInfo } = useUserInfoStore();

  const validate = (): boolean => {
    let valid = true;

    const checkComments = comments.map((comment) => {
      let check = true;
      if (!comment.comment || !comment.type) {
        valid = false;
        check = false;
      }
      return (comment = !check
        ? { ...comment, ...{ error: 'Field must be filled out.' } }
        : comment);
    });
    setComments(checkComments);
    return valid;
  };

  return (
    <Modal name={name} onClose={onClose}>
      {({ close }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (validate()) {
              addComments(userInfo.id, comments);
              close(true);
            }
          }}
        >
          <CommentSection>
            {comments.map((comment, i) => {
              return (
                <Comment
                  key={i}
                  comment={comment.comment}
                  type={comment.type}
                  editComment={(comment: string) => {
                    comments[i].comment = comment;
                    setComments([...comments]);
                  }}
                  editType={(select: any) => {
                    comments[i].type = select;
                    setComments([...comments]);
                  }}
                  remove={(e) => {
                    e.preventDefault();
                    if (comments.length > 1) {
                      comments.splice(i, 1);
                      setComments([...comments]);
                    }
                  }}
                  error={comment.error}
                />
              );
            })}
          </CommentSection>
          <CardFooter>
            <div className="flex flex-1 space-x-4">
              <Button type="submit">Save</Button>
              <Button
                inverted
                onClick={(e) => {
                  e.preventDefault();
                  setComments([...comments, { comment: '', type: 'Behavior' } as UserComment]);
                }}
              >
                Add Another
              </Button>
            </div>
          </CardFooter>
        </form>
      )}
    </Modal>
  );
}

export default AdminCommentsModal;
