import { SVGProps } from 'react';

export function GrowCell(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={108}
      height={36}
      viewBox="0 0 108 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M23.578 0l3.738 3.79-11 10.998H56v5.317H16.053l11.263 11.262-3.738 3.79L6 17.577 23.578 0z" />
      <path d="M84.422 0l-3.738 3.79 11 10.998H52v5.317h39.947L80.683 31.367l3.738 3.79L102 17.577 84.422 0zM104 0h4v36h-4V0zM0 0h4v36H0V0z" />
    </svg>
  );
}

export default GrowCell;
