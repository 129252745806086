"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class FileService {
    static uploadFiles() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/file/files',
        });
    }
    static getUserUploads(userId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/file/{userId}/files',
            path: {
                'userId': userId,
            },
        });
    }
    static getUserAssociatedFiles(userId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/file/{userId}/associated-files',
            path: {
                'userId': userId,
            },
        });
    }
    static removeFile(key) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/file/files/{key}',
            path: {
                'key': key,
            },
        });
    }
    static downloadFile(key) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/file/files/{key}',
            path: {
                'key': key,
            },
        });
    }
    static removeUploadInstance(key) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/file/upload-instance/{key}',
            path: {
                'key': key,
            },
        });
    }
    static getFileInstances(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/file/file-instances/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getUploadInstance(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/file/upload-instance/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getUploadInstances(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/file/upload-instances/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
}
exports.FileService = FileService;
