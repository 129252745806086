import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import tw from 'twin.macro';

import { Search } from '../icons';
import { Input } from '../Input';

const Element = styled.div`
  ${tw`flex flex-1 items-center relative space-x-2`}
`;

const SearchInput = styled(Input)`
  ${tw`w-full`}

  max-width: 30rem;
`;

type TableToolbarProperties = PropsWithChildren<{
  disable: boolean;
  filterTerm: string;
  onFilter: (filter: string) => void;
}>;

export function TableToolbar({ disable, filterTerm, onFilter, children }: TableToolbarProperties) {
  return (
    <Element>
      {children}
      <SearchInput
        placeholder="Quick search..."
        value={disable ? '' : filterTerm || ''}
        onChange={(e) => onFilter(e.target.value)}
        disabled={disable}
      >
        <div className="absolute top-1.5 right-2">
          <Search className="text-neutral-400 w-4 h-4" />
        </div>
      </SearchInput>
    </Element>
  );
}

export default TableToolbar;
