import { User } from '@gbhem/api';
import { SyntheticEvent } from 'react';

import {
  LocationStoreTypes,
  SystemRole,
  useAuthenticatedUser,
  useLocationStore
} from '../../../providers';
import { OrderBy } from '../../../utils/resource.helper';
import Select, { SelectElement } from './Select';

type AdminLocationSelectProps<T> = {
  onSelect: (e: SyntheticEvent<SelectElement<T>, Event>) => void;
  options: T[];
  name: string;
  locationType: LocationStoreTypes;
  placeholder?: string;
  optionLabel?: (value: T) => string;
  addSystem?: boolean;
};

export function AdminLocationSelect<T>({
  onSelect,
  options,
  name,
  locationType,
  placeholder,
  optionLabel,
  addSystem
}: AdminLocationSelectProps<T>) {
  const user: User = useAuthenticatedUser();
  const isSuperAdmin: boolean = user.roles.some((r) => r.name === SystemRole.Superadmin);

  const { query, system } = useLocationStore();
  return (
    <Select<T>
      name={name}
      options={
        isSuperAdmin
          ? (searchTerm: string | undefined) => {
              if (!searchTerm) return;
              let results = query(
                {
                  conditions: [{ name: searchTerm }],
                  orderBy: OrderBy.Ascending,
                  limit: 10
                },
                locationType
              );

              if (results && addSystem) {
                results = [system, ...results];
              }

              return results;
            }
          : options || []
      }
      onSelect={(e) => {
        onSelect(e);
      }}
      placeholder={placeholder || undefined}
      optionLabel={optionLabel || undefined}
    />
  );
}

export default AdminLocationSelect;
