import styled from '@emotion/styled';
import { NotificationService, User, UserNotificationsPayload } from '@gbhem/api';
import { useEffect, useRef, useState } from 'react';
import { useAsyncFn } from 'react-use';
import tw from 'twin.macro';

import { Bell, FadeIn, Refresh } from '..';
import { Badge } from '../Badge';
import { Dismissable } from '../Dismissable';
import { Notification } from '../Notifications';

const Controls = styled(Dismissable)`
  ${tw`text-neutral`}

  svg {
    ${tw`w-5 h-5`}
  }
`;

const Actions = styled.div`
  ${tw`flex items-end justify-between pt-2 px-2 text-xs`}

  svg {
    ${tw`w-3 h-3`}
  }
`;

const BadgeContainer = styled.div`
  ${tw`absolute -ml-4 -mt-3 text-xs`}
`;

const Container = styled(FadeIn)`
  ${tw`absolute w-96 overflow-hidden rounded-md border border-solid mr-[-9.2em] mt-[1.5em] bg-neutral-1000 pb-2 flex flex-col`}
`;

const NotificationScroll = styled.div`
  ${tw`overflow-y-auto max-h-52 overflow-x-hidden`}

  max-height: 60vh;
`;

interface NotificationViewerProps {
  user: User;
}
//TODO:(michael.patterson@fortyau.com)
// bug: when clicking a step navlink, clicking another step navlink changes url but does not render new step

//TODO: (michael.patterson@fortyau.com) figure out what these values should be
const notificationsOnLoadLimit = 5;
const notificationsLoadMoreCount = 5;
const notificationsMaxLimit = 50;

export function NotificationViewer({ user }: NotificationViewerProps) {
  const [userNotifications, setUserNotifications] = useState<UserNotificationsPayload>();
  const [notificationsLimit, setNotificationsLimit] = useState(notificationsOnLoadLimit);

  const controlRef = useRef<HTMLDivElement>(null);

  const [, fetchNotifications] = useAsyncFn(
    async () =>
      setUserNotifications(await NotificationService.getUserNotifications(notificationsLimit)),
    [notificationsLimit]
  );

  useEffect(() => void fetchNotifications(), [fetchNotifications]);

  if (!userNotifications) {
    return <></>;
  }

  userNotifications.userNotifications.sort((a, b) => {
    if (a.isRead === b.isRead) {
      if (!a.createdAt || !b.createdAt) return 0;
      return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
    }
    return a.isRead ? 1 : -1;
  });
  return (
    <>
      <Controls
        ignoreInternalClicks
        control={
          <div ref={controlRef}>
            {userNotifications.unreadCount > 0 && (
              <BadgeContainer>
                <Badge count={userNotifications.unreadCount} />
              </BadgeContainer>
            )}
            <Bell className="w-4 h-4 text-neutral-1000" />
          </div>
        }
      >
        <Container>
          <div className="p-2 text-xs ml-auto">
            <b>{userNotifications.unreadCount}</b> unread, <b>{userNotifications.total}</b> total
          </div>
          <NotificationScroll>
            {userNotifications.userNotifications.length === 0 && (
              <div className="text-center p-4 pb-0">No notifications.</div>
            )}
            {userNotifications.userNotifications.map((n) => (
              <Notification
                key={n.notificationId}
                notification={n}
                onClick={() => {
                  fetchNotifications();
                  controlRef.current?.parentElement?.click();
                }}
              />
            ))}
          </NotificationScroll>

          <Actions>
            {userNotifications.total > userNotifications.userNotifications.length ? (
              <div
                className="flex space-x-1 items-center cursor-pointer"
                onClick={() => {
                  if (notificationsLimit + notificationsLoadMoreCount <= notificationsMaxLimit)
                    setNotificationsLimit(notificationsLimit + notificationsLoadMoreCount);
                }}
              >
                <Refresh />
                <span>Load more...</span>
              </div>
            ) : (
              <div />
            )}
            <div
              className="underline cursor-pointer text-xs"
              onClick={async () => {
                await NotificationService.markAllAsRead(user.id, true);
                fetchNotifications();
              }}
            >
              mark all as read
            </div>
          </Actions>
        </Container>
      </Controls>
    </>
  );
}
