import { SVGProps } from 'react';

export function ArrowLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      className="fill-current"
      width={50}
      height={36}
      viewBox="0 0 50 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.578 0L21.3157 3.78907L10.3167 14.7881H50V20.1049H10.0535L21.3157 31.3671L17.578 35.1561L0 17.5782L17.578 0Z" />
    </svg>
  );
}

export default ArrowLeft;
