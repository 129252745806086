import { SVGProps } from 'react';

export function ChevronDown(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={51}
      height={30}
      viewBox="0 0 51 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M50.288 4.28c0 .948-.375 1.856-1.048 2.526L27.805 28.24a3.572 3.572 0 01-2.525 1.048 3.572 3.572 0 01-2.526-1.048L1.32 6.806a3.569 3.569 0 01.017-5.034 3.569 3.569 0 015.034-.017l18.91 18.91 18.91-18.91a3.571 3.571 0 013.892-.774 3.571 3.571 0 012.207 3.3z" />
    </svg>
  );
}

export default ChevronDown;
