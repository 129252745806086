import { TrackInstance, TrackService, TrackTemplate } from '@gbhem/api';
import { useMemo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Column } from 'react-table';
import { useAsyncFn } from 'react-use';

import { Can } from '../../providers';
import RoleProvider from '../../providers/RoleProvider';
import { ColumnLink, Edit, Input, Table } from '..';
import { Participant } from '.';
import { EditParticipant } from './EditParticipantModal';

type ParticipantsTableProperties = {
  //participants: Participant[];
  //onChange: () => void;
  track: TrackTemplate;
};

export function ParticipantsTable({ track }: ParticipantsTableProperties) {
  const [selectedParticipant, setSelectedParticipant] = useState<Participant>();
  const [selectedParticipants, setSelectedParticipants] = useState<Participant[]>();

  const [showEditParticipant, hideEditParticipant] = useModal(
    () =>
      selectedParticipant ? (
        <RoleProvider>
          <EditParticipant
            onClose={hideEditParticipant}
            participant={selectedParticipant}
            onSubmit={async (change) => {
              // TODO(jesse@fortyau.com) refactor this when time allows. In its current version
              // there are too many API calls being made. This should be transactional instead
              // via one endpoint. TrackService.updateInstances can be used in the future.
              const newAssignees = [...change.assignments].filter(
                (c) =>
                  !selectedParticipant.assignments.find(
                    (a) => a.assignee.id === c.assignee.id && a.role.id === c.role.id
                  )
              );

              const removedAssignees = selectedParticipant.assignments.filter(
                (a) =>
                  !change.assignments.find(
                    (cA) => cA.assignee.id === a.assignee.id && cA.role.id === a.role.id
                  )
              );

              await Promise.all([
                ...newAssignees.map((a) =>
                  TrackService.assign({
                    trackInstanceId: selectedParticipant.trackInstanceId,
                    roleId: a.role.id,
                    assigneeIds: [a.assignee.id]
                  })
                ),
                ...removedAssignees.map((rA) => TrackService.unassign(rA!.id))
              ]);

              // TODO(eliot.clarke@fortyau.com) figure out a way to make changes on the front end
              // with new change (Participant) object rather than making an API call to rehydrate list
              // with new user data from backend.
              //onChange();
              window.location.reload();
            }}
          />
        </RoleProvider>
      ) : null,
    [selectedParticipant]
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
            // TODO (eliot) replace ALL Can component with useCan hook to do this operationally
            <Can do="manage" on="all">
              <Input type="checkbox" {...getToggleAllPageRowsSelectedProps()} />
            </Can>
          ),
          accessor: 'id',
          id: 'checkbox',
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row }) => (
            <Can do="manage" on="all">
              <Input type="checkbox" {...row.getToggleRowSelectedProps()} />
            </Can>
          )
        },
        {
          Header: 'track',
          accessor: 'trackInstanceId',
          id: 'track',
          disableSortBy: true,
          Cell: ({ value }) => (
            <ColumnLink to={`/tracks/${track.id}/assignments/${value}`}>View</ColumnLink>
          )
        },
        {
          Header: 'status',
          accessor: 'status',
          id: 'instance.status',
          Cell: ({ value }) => value
        },
        {
          Header: 'first name',
          accessor: 'firstName',
          id: 'participant.firstName',
          Cell: ({ row: { original: value } }) => (
            <ColumnLink to={`/users/${value.id}`}>{value.firstName}</ColumnLink>
          )
        },
        {
          Header: 'last name',
          accessor: 'lastName',
          id: 'participant.lastName',
          Cell: ({ row: { original: value } }) => (
            <ColumnLink to={`/users/${value.id}`}>{value.lastName}</ColumnLink>
          )
        },
        {
          Header: 'email',
          accessor: 'email',
          id: 'participant.email',
          Cell: ({ value }) => value
        },
        {
          Header: 'advisors',
          accessor: 'advisors',
          disableFilters: true,
          disableSortBy: true,
          Cell: ({ row }) => (
            <div className="space-x-2">
              {row.original.advisors.map((advisor) => (
                <ColumnLink key={advisor.id} to={`/users/${advisor.id}`}>
                  {advisor.firstName} {advisor.lastName}
                </ColumnLink>
              ))}
            </div>
          )
        },
        {
          Header: 'interviewers',
          accessor: 'interviewers',
          disableFilters: true,
          disableSortBy: true,
          Cell: ({ row }) => (
            <div className="space-x-2">
              {row.original.interviewers.map((interviewer) => (
                <ColumnLink key={interviewer.id} to={`/users/${interviewer.id}`}>
                  {interviewer.firstName} {interviewer.lastName}
                </ColumnLink>
              ))}
            </div>
          )
        },
        {
          Header: 'assigned date',
          accessor: 'createdAt',
          id: 'createdAt',
          disableSortBy: true,
          Cell: ({ row }) => {
            const trackInstanceCreatedAt = row.original.trackInstanceCreatedAt;
            if (!trackInstanceCreatedAt) return <></>;

            const assignedDate = new Date(trackInstanceCreatedAt);

            return <>{assignedDate.toUTCString()}</>;
          }
        },
        {
          Header: () =>
            track.conference ? (
              <Can do="update" type="Conference" on={track.conference}>
                EDIT
              </Can>
            ) : (
              <Can do="update" on="Conference">
                EDIT
              </Can>
            ),
          id: 'edit',
          accessor: 'id',
          disableSortBy: true,
          disableFilters: true,
          Cell: ({ row }) => (
            <Can do="update" on="Conference">
              <Edit
                className="cursor-pointer"
                onClick={() => {
                  setSelectedParticipant(row.original);
                  showEditParticipant();
                }}
              />
            </Can>
          )
        }
      ] as Column<Participant>[],
    [showEditParticipant, track]
  );

  const [, removeSelectedParticipants] = useAsyncFn(
    async (selectedParticipants: Participant[]) => {
      if (!selectedParticipants) {
        return;
      }

      await TrackService.removeInstances(selectedParticipants.map((c) => c.trackInstanceId));

      //onChange();
      window.location.reload();

      setSelectedParticipants(undefined);
    },
    [selectedParticipants]
  );

  return (
    <Table
      columns={columns}
      loader={async ({ page, size, filters, orderBy, isQuickSearch, relations }) => {
        const filterMap: Record<string, any> = {
          'participant.firstName': (firstName: string) => ({
            participant: { firstName: firstName.toLowerCase() }
          }),
          'participant.lastName': (lastName: string) => ({
            participant: { lastName: lastName.toLowerCase() }
          }),
          'participant.email': (email: string) => ({
            participant: { email: email.toLowerCase() }
          })
        };

        const instances = await TrackService.getInstances(track.id, !!isQuickSearch, {
          conditions: filters?.reduce((filters, f) => {
            const keys = Object.keys(f);

            keys.forEach((k) => {
              if (filterMap[k]) {
                filters.push(filterMap[k](f[k]));
              }
            });

            return filters;
          }, []),
          limit: size,
          offset: page * size,
          relations,
          orderBy
        });

        return {
          count: instances.count,
          data: instances.data.map(
            ({ id, participant, assignees, status, createdAt }: TrackInstance) => ({
              ...participant,
              participant,
              status,
              trackInstanceId: id,
              trackInstanceCreatedAt: createdAt,
              assignments: assignees,
              advisors: assignees.filter((a) => a.role.name === 'Advisor').map((a) => a.assignee),
              interviewers: assignees
                .filter((a) => a.role.name === 'Interviewer')
                .map((a) => a.assignee)
            })
          )
        };
      }}
      onSelect={setSelectedParticipants}
      onDelete={removeSelectedParticipants}
    />
  );
}
