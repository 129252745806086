import { FormInstance, Properties } from '@gbhem/api';
import { forwardRef } from 'react';

import { FieldType } from '../../models/Field';
import { Input, InputMetadataProps, Label } from '.';

type LinkEntryProps = {
  form?: FormInstance;
  name?: string;
  properties: Properties & Record<string, any>;
  value?: string;
  updateValue: (val: any) => void;
} & InputMetadataProps;

export const LinkEntry = forwardRef<HTMLInputElement, LinkEntryProps>(
  ({ form, name, properties, value, updateValue, ...props }, ref) => {
    if (form?.submitted)
      return (
        <div className="flex flex-col">
          <Label>{name || 'LinkEntry'}</Label>
          <a
            href={value ? (value.includes('http') ? value : `//${value}`) : ''}
            target="_blank"
            className="pointer-events-auto cursor-pointer"
            rel="noreferrer"
          >
            <span className="text-blue-600 border-blue-600 border-b-2">
              {value || 'No Value Provided'}
            </span>
          </a>
        </div>
      );
    return (
      <Input
        onChange={(val) => {
          updateValue(val);
        }}
        type={FieldType.LinkEntry}
        name={name}
        value={value || ''}
        {...props}
        {...properties}
        ref={ref}
      />
    );
  }
);
