import './App.css';

import { ModalProvider } from 'react-modal-hook';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { Announcements, Navbar, View } from './components';
import { Admin, Forms, StepInstance, Tracks, Users } from './pages';
import { AuthenticatedAbilityProvider, AuthenticationProvider, RoleProvider } from './providers';

function App() {
  return (
    <Router>
      <AuthenticationProvider>
        <AuthenticatedAbilityProvider>
          <RoleProvider>
            <ModalProvider>
              <View className="App">
                <Switch>
                  <Route path="/">
                    <Navbar />
                    <Announcements />
                    <Switch>
                      <Route exact path="/" component={() => <Redirect to="/tracks" />} />
                      <Route path="/tracks" component={Tracks} />
                      <Route path="/step/:id" component={StepInstance} />
                      <Route path="/admin" component={Admin} />
                      <Route path="/users" component={Users} />
                      <Route path="/forms" component={Forms} />
                    </Switch>
                  </Route>
                </Switch>
              </View>
              <div id="modals" />
            </ModalProvider>
          </RoleProvider>
        </AuthenticatedAbilityProvider>
      </AuthenticationProvider>
    </Router>
  );
}

export default App;
