import styled from '@emotion/styled';
import { User } from '@gbhem/api';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import tw from 'twin.macro';

import { Can } from '../../providers';
import { useUserInfoStore } from '../../providers/UserProfileProvider/UserInfoProvider';
import { useUserRelationshipStore } from '../../providers/UserProfileProvider/UserRelationshipProvider';
import { Button } from '../Button';
import { Cancel } from '../icons';
import { Select } from '../Input';

export const TagPill = styled.div`
  ${tw`flex items-center text-xs py-1 px-3 rounded-full cursor-pointer bg-secondary text-neutral-1000 space-x-1.5`}

  svg {
    ${tw`w-2 h-2`}
  }
`;

interface TagsProps {
  user: User;
}

export function Tags({ user }: TagsProps) {
  const form = useRef<HTMLFormElement>(null);

  const { searchTags } = useUserRelationshipStore();
  const { applyTag, removeTag } = useUserInfoStore();

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, isDirty }
  } = useForm({
    mode: 'all',
    defaultValues: { tag: { id: '', name: '' } }
  });

  const input = useRef<HTMLInputElement>(null);

  const submit = async (tag: { id: string; name: string }) => {
    await applyTag(user, tag);

    if (input.current) {
      input.current.value = '';
    }

    reset();
  };

  return (
    <div className="space-y-4">
      <Can do="create" on="User">
        <p className="text-xs">
          Add helpful tags for {user.firstName} here.{' '}
          <b>Tags are only visible to administrators.</b>
        </p>
      </Can>
      <div className="flex space-x-2 my-1 flex-wrap">
        {user.tags?.map((t) => (
          <TagPill key={t.id}>
            <div>{t.name}</div>
            <Cancel
              onClick={async () => {
                await removeTag(user.id, t.id);
              }}
            />
          </TagPill>
        ))}
      </div>
      <Can do="create" on="User">
        <form
          ref={form}
          className="flex items-center space-x-2"
          onSubmit={handleSubmit(({ tag }) => submit(tag!))}
        >
          <Controller
            name="tag"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, ...field } }) => (
              <Select
                className="w-full"
                maxLength={30}
                placeholder="Type tag name here"
                options={async (s) => searchTags(s || '', user.tags)}
                onSelect={(e) => {
                  submit(e.currentTarget.value!);
                }}
                onChange={(e) =>
                  onChange(
                    e.target.value || (e.target.rawValue ? { name: e.target.rawValue } : undefined)
                  )
                }
                {...field}
                rawRef={input}
                clearOnSelect
                inline
              />
            )}
          />

          <Button type="submit" disabled={!(isValid && isDirty)}>
            + Add Tag
          </Button>
        </form>
      </Can>
    </div>
  );
}

export default Tags;
