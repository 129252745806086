import styled from '@emotion/styled';
import { Phase as PhaseModel, PhaseService, TrackTemplate } from '@gbhem/api';
import { SetStateAction, useMemo, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import tw from 'twin.macro';

import { Can, SystemRole, useAuthenticatedUser } from '../../providers';
import { Button, Loader, Phase, PhaseEditor } from '..';
import { Dismissable } from '../Dismissable';
import { Plus } from '../icons';
import { Modal } from '../Modal';
import { Option, OptionsMenu } from '../OptionsMenu';
import ImportPhaseModal from '../Phase/ImportPhaseModal';

export function AddPhaseOptions() {
  const router = useHistory();
  const { url } = useRouteMatch();

  return (
    <Can do="update" on="TrackTemplate">
      <Dismissable
        control={
          <Button>
            <Plus /> Phase
          </Button>
        }
      >
        <OptionsMenu>
          <Option onClick={() => router.push(`${url}/phase/new`)}>Create new</Option>
          <Option onClick={() => router.push(`${url}/phase/import`)}>
            Import from existing track
          </Option>
        </OptionsMenu>
      </Dismissable>
    </Can>
  );
}

interface PhasesProperties {
  phases: PhaseModel[];
  isSystemTrack: boolean;
  onDelete: (phase: PhaseModel) => void;
  onReorder: (phases: PhaseModel[]) => void;
}

export const PhasesContainer = styled.div`
  ${tw`flex flex-1 divide-x divide-dashed overflow-x-auto`}
`;

export function Phases({ phases, isSystemTrack, onDelete, onReorder }: PhasesProperties) {
  const authUser = useAuthenticatedUser();
  const isSuperAdmin = useMemo(
    () => !!authUser.roles.find((r) => [SystemRole.Superadmin.toString()].includes(r.name)),
    [authUser]
  );

  if (phases.length === 0) {
    return (
      <div className="flex items-center justify-center flex-1 border-2 border-dashed border-neutral-400 m-4 p-4">
        <div className="flex flex-col items-center space-y-4">
          <p>
            It looks like this track doesn't have any phases yet!
            {(isSuperAdmin || !isSystemTrack) && ' Add one to get started.'}
          </p>
          {(isSuperAdmin || !isSystemTrack) && <AddPhaseOptions />}
        </div>
      </div>
    );
  }

  return (
    <PhasesContainer>
      {phases
        .sort((p1, p2) => p1.order - p2.order)
        .map((p) => (
          <Phase
            key={p.id}
            phase={p}
            isSystemTrack={isSystemTrack}
            onDelete={() => onDelete(p)}
            maxOrder={phases.length}
            onReorder={onReorder}
          />
        ))}
    </PhasesContainer>
  );
}

interface TrackProperties {
  track: TrackTemplate;
  setTrack: React.Dispatch<SetStateAction<TrackTemplate | undefined>>;
}

export function Track({ track, setTrack }: TrackProperties) {
  const router = useHistory();
  const { url } = useRouteMatch();

  const [phases, setPhases] = useState<PhaseModel[]>(track.phases);
  const [importedPhaseId, setImportedPhaseId] = useState<string | undefined>(undefined);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { createImportPhase } = PhaseService;

  if (!phases) {
    return <Loader message="Loading track..." />;
  }

  return (
    <>
      <Phases
        phases={phases}
        isSystemTrack={!track.conference}
        onDelete={async (p) => {
          const deletedPhase = await PhaseService.deletePhase(p.id);
          setPhases(
            phases
              .filter((ph) => ph.id !== deletedPhase.id)
              .map((phase, index) => {
                return { ...phase, order: index + 1 };
              })
          );
        }}
        onReorder={async (updatedPhases) => {
          setPhases(updatedPhases);
        }}
      />
      <Route exact path={`${url}/phase/new`}>
        <Modal name="Create Phase" onClose={() => void router.push(url)}>
          {({ close }) => (
            <PhaseEditor
              onSubmit={async (p) => {
                const newPhase = await PhaseService.createPhase({
                  ...p,
                  trackTemplateId: track.id
                });

                setPhases([...phases, newPhase]);
                setTrack((track) =>
                  track ? { ...track, phases: [...phases, newPhase] } : undefined
                );

                close();
              }}
            />
          )}
        </Modal>
      </Route>
      <Route exact path={`${url}/phase/import`}>
        <Modal
          name="Import Phase"
          onClose={() => {
            if (!importedPhaseId) router.push(url);

            router.push(`${url}/phase/${importedPhaseId}`);
            setImportedPhaseId(undefined);
          }}
        >
          {({ close }) => (
            <ImportPhaseModal
              isSubmitting={isSubmitting}
              onSubmit={async (p) => {
                setIsSubmitting(true);
                const newPhase = await createImportPhase(p.id, track.id);

                setPhases([...phases, newPhase]);
                setTrack((track) =>
                  track ? { ...track, phases: [...phases, newPhase] } : undefined
                );

                setImportedPhaseId(newPhase.id);
                setIsSubmitting(false);

                close();
              }}
            />
          )}
        </Modal>
      </Route>
    </>
  );
}

export default Track;
