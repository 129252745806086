import styled from '@emotion/styled';
import { TrackTemplate, User } from '@gbhem/api';
import { useState } from 'react';
import tw from 'twin.macro';

import {
  Button,
  CalendarPicker,
  CardBody,
  CardFooter,
  CardHeader,
  Modal,
  ModalProps,
  Select
} from '..';

const RowWrapper = styled.div`
  ${tw`grid grid-cols-2 gap-4`}
`;

const Calendar = styled(CalendarPicker)`
  ${tw`col-span-1`}
`;

enum InputErrors {
  Date = 'Please enter a start date to continue.',
  Mentor = 'Please select a Mentor to continue.',
  Track = 'Please select a Track to continue.'
}

type ProfileModalProps = Omit<ModalProps, 'onChange'> & {
  user: User | undefined;
  users: User[];
  tracks: TrackTemplate[];
  updateUser: (user: User) => void;
  changeRoute: (track: string) => void;
};

function validate(i: string, m: InputErrors): void;
function validate(i: User[], m: InputErrors): void;
function validate(i: TrackTemplate, m: InputErrors): void;
function validate(i: string | User[] | TrackTemplate, m: InputErrors) {
  if (!i || i?.toString().length <= 0) throw new Error(m);
}

export function ProfileModals({ onClose, tracks, user, changeRoute }: ProfileModalProps) {
  const [mentors, setMentors] = useState<User[]>([]);
  const [track, setTrack] = useState<TrackTemplate>();
  const [startDate, setStartDate] = useState<string>('');
  const [trackErr, setTrackErr] = useState<string>('');
  const [mentorErr, setMentorErr] = useState<string>('');
  const [dateErr, setDateErr] = useState<string>('');

  //Tracks Resolver

  //Mentors Mode

  //TODO (quinn.pommerening)
  // Fix name resolver
  // const contextualUserResolver = async (searchTerm?: string | undefined) =>
  //   users
  //     .filter((u) => (searchTerm ? u.name.toLowerCase().includes(searchTerm.toLowerCase()) : u))
  //     .map((u) => ({ label: u.name, value: u }));

  const saveToUser = () => {
    let check = true;
    if (!validator(track, InputErrors.Track, setTrackErr)) check = false;
    if (!validator(startDate, InputErrors.Date, setDateErr)) check = false;
    if (!validator(mentors, InputErrors.Mentor, setMentorErr)) check = false;
    console.log(setMentors, mentorErr);

    //TODO (quinn.pommerening)
    // Fix adding mentor to a user.
    // if (track && check && user && mentors) {
    //   track.trackStatus = TrackStatus.NotStarted;
    //   track.mentors = [...mentors];
    //   track.startDate = startDate;
    //   user.tracks = [...(user.tracks ? user.tracks : []), ...[track]];
    //   updateUser(user);
    // }
    return check;
  };

  const validator = (_input: any, msg: InputErrors, setErr: (error: any) => void) => {
    try {
      validate(_input, msg);
    } catch (err) {
      setErr((err as any).message);
      return false;
    }
    setErr('');
    return true;
  };

  return (
    <Modal onClose={onClose}>
      {({ close }) => (
        <>
          <CardHeader>Add Track to {`${user?.firstName} ${user?.lastName}`}</CardHeader>
          <CardBody>
            <Select
              placeholder="Select Track..."
              //Possibly add a filter to remove tracks already being used?
              options={tracks}
              onChange={(e) => {
                const id = (e.target.value as TrackTemplate)?.id;
                setTrack(tracks.find((t) => t.id === id));
              }}
              error={trackErr}
            />
            <RowWrapper>
              {/* <StepPermissions
                title="Add Mentors"
                type="Mentor"
                permissions={mentors}
                onChange={(use) => {
                  setMentors(use);
                }}
                optionsResolver={contextualUserResolver}
                color="yellow"
                error={mentorErr}
                needAdding
              /> */}
            </RowWrapper>
            <RowWrapper>
              <Calendar
                name="Select Start Date"
                onChange={(e) => setStartDate(e.target.value)}
                dateSelect={setStartDate}
                error={dateErr}
              />
            </RowWrapper>
          </CardBody>
          <CardFooter>
            <Button
              onClick={() => {
                if (track && saveToUser()) {
                  changeRoute(track.id);
                }
              }}
              inverted
            >
              Create and Customize Track
            </Button>
            <Button
              onClick={() => {
                if (saveToUser()) close();
              }}
            >
              Create and Return to Profile
            </Button>
          </CardFooter>
        </>
      )}
    </Modal>
  );
}

export default ProfileModals;
