import { SVGProps } from 'react';

export function Calendar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      className="fill-current"
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.4548 6.19708H42.6531C46.3516 6.19708 49.3709 9.21812 49.3709 12.9169L49.3729 42.7591C49.3729 46.4555 46.354 49.4768 42.6552 49.4768H6.71771C3.02125 49.4768 0 46.4578 0 42.7591V12.9148C0 9.21833 3.01896 6.19708 6.71771 6.19708H7.91604V3.07208C7.91604 1.355 9.26896 0 10.9881 0C12.7052 0 14.0602 1.35292 14.0602 3.07208V6.19708H21.6123V3.07208C21.6123 1.355 22.9652 0 24.6844 0C26.4015 0 27.7565 1.35292 27.7565 3.07208V6.19708H35.3106V3.07208C35.3106 1.355 36.6635 0 38.3827 0C40.0998 0 41.4548 1.35292 41.4548 3.07208V6.19708ZM4.47563 42.7592C4.47563 44.0104 5.46646 44.9992 6.71562 44.9992H42.6531C43.9023 44.9992 44.8933 44.0104 44.8933 42.7592V19.9993H4.47563V42.7592Z"
      />
      <path d="M16.1943 24.4794H9.9974C9.52946 24.4794 9.1124 24.8964 9.1124 25.3644V29.3235C9.1124 29.7915 9.52947 30.2085 9.9974 30.2085H16.1943C16.6622 30.2085 17.0793 29.7915 17.0793 29.3235V25.3644C17.0793 24.8964 16.6642 24.4794 16.1943 24.4794Z" />
      <path d="M27.8094 24.4794H21.5594C21.0914 24.4794 20.6744 24.8964 20.6744 25.3644V29.3235C20.6744 29.7915 21.0914 30.2085 21.5594 30.2085H27.8094C28.2773 30.2085 28.6944 29.7915 28.6944 29.3235V25.3644C28.6944 24.8964 28.2773 24.4794 27.8094 24.4794Z" />
      <path d="M39.4245 24.4794H33.1745C32.7065 24.4794 32.2895 24.8964 32.2895 25.3644V29.3235C32.2895 29.7915 32.7066 30.2085 33.1745 30.2085H39.3714C39.8393 30.2085 40.2564 29.7915 40.2564 29.3235V25.3644C40.3092 24.8964 39.8924 24.4794 39.4245 24.4794Z" />
      <path d="M16.1943 34.7393H9.9974C9.52946 34.7393 9.1124 35.1563 9.1124 35.6243V39.5834C9.1124 40.0514 9.52947 40.4684 9.9974 40.4684H16.1943C16.6622 40.4684 17.0793 40.0514 17.0793 39.5834V35.6243C17.0793 35.1563 16.6642 34.7393 16.1943 34.7393Z" />
      <path d="M27.8094 34.7393H21.5594C21.0914 34.7393 20.6744 35.1563 20.6744 35.6243V39.5834C20.6744 40.0514 21.0914 40.4684 21.5594 40.4684H27.8094C28.2773 40.4684 28.6944 40.0514 28.6944 39.5834V35.6243C28.6944 35.1563 28.2773 34.7393 27.8094 34.7393Z" />
      <path d="M39.4245 34.7393H33.1745C32.7065 34.7393 32.2895 35.1563 32.2895 35.6243V39.5834C32.2895 40.0514 32.7066 40.4684 33.1745 40.4684H39.3714C39.8393 40.4684 40.2564 40.0514 40.2564 39.5834V35.6243C40.3092 35.1563 39.8924 34.7393 39.4245 34.7393Z" />
    </svg>
  );
}

export default Calendar;
