import { SVGProps } from 'react';

export function Plus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M5.9.89v5.157H.89c-.16.001-.29.13-.29.29V7.81c0 .16.13.289.29.29H5.9v5.01c0 .16.13.289.29.29h1.62c.159-.001.288-.13.289-.29V8.1h5.01c.16-.001.29-.13.29-.29V6.337c0-.16-.13-.289-.29-.29H8.1V.89c0-.16-.13-.29-.29-.29H6.162A.291.291 0 005.9.89z" />
    </svg>
  );
}

export default Plus;
