import styled from '@emotion/styled';
import { StepInstance as StepInstanceModel } from '@gbhem/api';
import { ReactElement, SVGProps } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { Checkmark, Locked, Ready, StepContainer, Time } from '..';

// TODO(jesse@fortyau.com) when time allows, extend @gbhem/api to export
// other models/interfaces/enums so these enums don't have to be re-declared in @gbhem/app.
export enum StepStatus {
  Unavailable = 'Unavailable',
  Ready = 'Ready',
  Overdue = 'Overdue',
  Resubmit = 'Resubmit',
  PendingReview = 'Pending Review',
  Completed = 'Completed'
}

interface StepInstanceProperties {
  instance: StepInstanceModel;
}

interface ConditionalLinkProperties {
  children: ReactElement;
  status: InstanceStatus;
  to: string;
}

type InstanceStatus =
  | 'Unavailable'
  | 'Ready'
  | 'Overdue'
  | 'Resubmit'
  | 'Pending Review'
  | 'Completed'
  | 'Withdrawn'
  | 'Discontinued';

type StatusIconProperties = SVGProps<SVGSVGElement> & {
  status: InstanceStatus;
};

export const StatusIcon = ({ status, ...props }: StatusIconProperties) => {
  // TODO(jesse@fortyau.com) pull in the correct icon here.
  switch (status) {
    case StepStatus.Unavailable:
      return (
        <StatusIconWrapper className="bg-secondary">
          <Locked height={15} width={15} {...props} />
        </StatusIconWrapper>
      );
    case StepStatus.Ready:
      return (
        <StatusIconWrapper className="bg-green-700">
          <Ready color="white" height={15} width={15} {...props} />
        </StatusIconWrapper>
      );
    case StepStatus.PendingReview:
      return (
        <StatusIconWrapper className="bg-primary">
          <Time {...props} height={15} width={15} color="white" />
        </StatusIconWrapper>
      );
    case StepStatus.Completed:
      return (
        <StatusIconWrapper className="bg-green-600">
          <Checkmark color="white" height={15} width={15} {...props} />
        </StatusIconWrapper>
      );
    // TODO update icons when other status are implemented
    // case StepStatus.Resubmit:
    // case StepStatus.Overdue:
    default:
      return <Locked {...props} />;
  }
};

const Container = styled(StepContainer)<{ disabled?: boolean }>`
  ${tw`relative m-3 text-sm`}

  ${(p) => (!p.disabled ? tw`cursor-pointer` : tw`opacity-20 pointer-events-none`)}
`;

const Content = styled.div`
  ${tw`flex flex-col`}
`;

const StatusIconWrapper = styled.div`
  ${tw`rounded-full h-6 w-6 absolute -top-3 -left-3 flex items-center justify-center`}
`;

const ConditionalLink = ({ children, status, to }: ConditionalLinkProperties) =>
  status !== StepStatus.Unavailable ? <Link to={to}>{children}</Link> : <>{children}</>;

export function StepInstance({ instance }: StepInstanceProperties) {
  return (
    <ConditionalLink to={`/step/${instance.id}`} status={instance.status}>
      <Container disabled={instance.status === StepStatus.Unavailable}>
        <StatusIcon status={instance.status} />
        <Content>
          <div className="text-xs uppercase select-none">{instance.status}</div>
          <div className="font-semibold">{instance.stepTemplate.name}</div>
        </Content>
      </Container>
    </ConditionalLink>
  );
}

export default StepInstance;
