import styled from '@emotion/styled';
import { User, UserService } from '@gbhem/api';
import { forwardRef, MutableRefObject, useEffect, useState } from 'react';
import tw from 'twin.macro';

import { Checkmark } from '..';
import { Button } from '../Button';
import { Select } from '../Input';
import { Modal, ModalProps } from '../Modal';

type MergeUserModalProps = ModalProps & {
  profileUserRef: MutableRefObject<User>;
};

const ActionButton = styled(Button)`
  svg {
    ${tw`w-4 h-4`}
  }
`;

export const MergeUserModal = forwardRef(({ profileUserRef, onClose }: MergeUserModalProps) => {
  const profileUser = profileUserRef?.current;
  const [fromUser, setFromUser] = useState<User | undefined>(profileUser);
  const [toUser, setToUser] = useState<User | undefined>(profileUser);

  const [firstName, setFirstName] = useState<string>(
    fromUser?.firstName || toUser?.firstName || ''
  );
  const [middleName, setMiddleName] = useState<string | undefined>(
    fromUser?.middleName || toUser?.middleName
  );
  const [lastName, setLastName] = useState<string>(fromUser?.lastName || toUser?.lastName || '');

  useEffect(() => {
    if (fromUser && toUser) {
      setFirstName(firstName || fromUser.firstName);
      setMiddleName(middleName || fromUser.middleName);
      setLastName(lastName || fromUser.lastName);
    }
  }, [fromUser, toUser, firstName, middleName, lastName]);
  if (!fromUser || !toUser || !profileUser) return <></>;
  return (
    <Modal name="Merge Users" onClose={onClose}>
      <Select<User>
        label="From User"
        options={async (searchTerm) => {
          const users: User[] = (
            await UserService.getUsersByCurrentUser({
              conditions: [{ name: searchTerm }, { email: searchTerm }],
              limit: 100,
              offset: 0
            })
          ).data;
          return users.filter((user) => user.email.includes(searchTerm || ''));
        }}
        optionLabel={(user) => `${user.firstName} ${user.lastName} (${user.email})`}
        onSelect={(selection) => setFromUser(selection.currentTarget.value)}
        value={fromUser}
        defaultValue={profileUser}
      />

      <Select<User>
        label="To User"
        options={async (searchTerm) => {
          const users: User[] = (
            await UserService.getUsersByCurrentUser({
              conditions: [{ name: searchTerm }, { email: searchTerm }],
              limit: 100,
              offset: 0
            })
          ).data;
          return users.filter((user) => user.email.includes(searchTerm || ''));
        }}
        optionLabel={(user) => `${user.firstName} ${user.lastName} (${user.email})`}
        onSelect={(selection) => setToUser(selection.currentTarget.value)}
        value={toUser}
        defaultValue={profileUser}
      />
      {firstName &&
        lastName &&
        fromUser &&
        toUser &&
        (fromUser.id === profileUser.id || toUser.id === profileUser.id) && (
          <>
            {fromUser.firstName !== toUser.firstName && (
              <Select
                label="First Name"
                options={[fromUser.firstName, toUser.firstName]}
                value={firstName}
                onSelect={(select) => setFirstName(select.currentTarget.value || '')}
                defaultValue={fromUser.firstName || toUser.firstName}
              />
            )}
            {fromUser.middleName &&
              toUser.middleName &&
              fromUser.middleName !== toUser.middleName && (
                <Select
                  label="Middle Name"
                  options={[fromUser.middleName || '', toUser.middleName || '']}
                  value={middleName}
                  onSelect={(select) => setMiddleName(select.currentTarget.value)}
                  defaultValue={fromUser.middleName || toUser.middleName}
                />
              )}
            {fromUser.lastName !== toUser.lastName && (
              <Select
                label="Last Name"
                options={[fromUser.lastName, toUser.lastName]}
                value={lastName}
                onSelect={(select) => setLastName(select.currentTarget.value || '')}
                defaultValue={fromUser.lastName || toUser.lastName}
              />
            )}
            <div className="flex items-center justify-left space-x-2">
              <ActionButton
                onClick={async () => {
                  if (firstName && lastName) {
                    await UserService.mergeUser({
                      fromUserId: fromUser.id,
                      toUserId: toUser.id,
                      firstName: firstName,
                      middleName: middleName,
                      lastName: lastName
                    });
                    onClose!();
                  }
                }}
                disabled={!firstName || !lastName || fromUser.id === toUser.id}
              >
                <Checkmark /> Merge
              </ActionButton>
            </div>
          </>
        )}
    </Modal>
  );
});
