import { SVGProps } from 'react';

export function ChevronLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M36.009 50a3.558 3.558 0 01-2.526-1.049L12.048 27.516A3.572 3.572 0 0111 24.991c0-.948.379-1.856 1.048-2.526L33.483 1.03a3.569 3.569 0 015.034.017 3.569 3.569 0 01.017 5.034l-18.91 18.91 18.91 18.91a3.572 3.572 0 01.774 3.892 3.571 3.571 0 01-3.3 2.207z" />
    </svg>
  );
}

export default ChevronLeft;
