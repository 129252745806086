import produce, { Draft } from 'immer';
import { GetState, SetState, State, StateCreator, StoreApi } from 'zustand';

export type RelationshipsOf<Model> =
  | {
      [Property in keyof Model]: Model[Property] extends object ? Property : never;
    }[keyof Model][]
  | string[];

type Primitive = string | Function | number | boolean | Symbol | undefined | null;

type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>;
};

export type DeepOmit<T, K> = T extends Primitive
  ? T
  : {
      [P in Exclude<keyof T, K>]: T[P] extends infer TP
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
          ? DeepOmitArray<TP, K> // Array special handling
          : DeepOmit<TP, K>
        : never;
    };

export const immerMiddleware =
  <
    StateType extends State,
    CustomSetState extends SetState<StateType>,
    CustomGetState extends GetState<StateType>,
    CustomStoreApi extends StoreApi<StateType>
  >(
    config: StateCreator<
      StateType,
      (partial: ((draft: Draft<StateType>) => void) | StateType, replace?: boolean) => void,
      CustomGetState,
      CustomStoreApi
    >
  ): StateCreator<StateType, CustomSetState, CustomGetState, CustomStoreApi> =>
  (set, get, api) =>
    config(
      (partial, replace) => {
        const nextState =
          typeof partial === 'function'
            ? produce(partial as (state: Draft<StateType>) => StateType)
            : (partial as StateType);
        return set(nextState, replace);
      },
      get,
      api
    );
