"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroupService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class UserGroupService {
    static getUserGroupsByRole(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user-group/{id}/user-groups',
            path: {
                'id': id,
            },
        });
    }
    static create(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user-group',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static find(limit, offset, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user-group',
            query: {
                'limit': limit,
                'offset': offset,
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updateMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/user-group',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static removeMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user-group',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static createMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user-group/bulk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static quickSearch(tableName, limit, offset, relations, isQuickSearch, orderBy, where) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user-group/quickSearch',
            query: {
                'tableName': tableName,
                'limit': limit,
                'offset': offset,
                'relations': relations,
                'isQuickSearch': isQuickSearch,
                'orderBy': orderBy,
                'where': where,
            },
        });
    }
    static count(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user-group/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static query(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user-group/query',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static findOne(id, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user-group/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static update(id, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/user-group/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static remove(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user-group/{id}',
            path: {
                'id': id,
            },
        });
    }
    static search(requestBody, relations) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user-group/search',
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
exports.UserGroupService = UserGroupService;
