import styled from '@emotion/styled';
import { User } from '@gbhem/api';
import { useRef, useState } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import AvatarEditor from 'react-avatar-editor';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import tw from 'twin.macro';

import { Can } from '../../providers';
import { useUserInfoStore } from '../../providers/UserProfileProvider/UserInfoProvider';
import { DefaultProfile, Edit, fileCategoryToDipslayedFileTypesMap } from '..';
import { Button } from '../Button';
import { Modal } from '../Modal';

const Element = styled.div`
  ${tw`bg-neutral-300 relative`}

  height: 16rem;
  width: 16rem;
`;

const DefaultWrapper = styled.div`
  svg {
    ${tw`w-full h-full`}
  }
`;

const UploadFile = styled.label`
  ${tw`absolute cursor-pointer top-3 right-3 p-1 rounded-sm`}

  svg {
    ${tw`w-6 h-6`}
  }
`;

const InputLabel = styled.label`
  ${tw`px-4 py-1 rounded-sm text-sm font-bold outline-none cursor-pointer select-none focus:outline-none transition-all duration-75 transform active:shadow-xl border bg-primary text-neutral-1000 border-primary`}
`;

const FileInput = styled.input`
  ${tw`invisible`}
`;

interface UserAvatarProps {
  user: User;
}

type ImagePosition = {
  x: number;
  y: number;
};

interface EditAvatarProps {
  onClose: () => void;
}

export function InlineAvatar({ user }: UserAvatarProps) {
  return user.avatar && user.avatar.url ? (
    <img alt="avatar" className="h-8 w-8 bg-tan rounded-full" src={user.avatar.url} />
  ) : (
    <DefaultProfile className="h-8 w-8 profile" />
  );
}

export function UserAvatar({ user }: UserAvatarProps) {
  const router = useHistory();
  const { url } = useRouteMatch();

  return (
    <>
      <Element>
        {user.avatar && user.avatar.url ? (
          <img
            className="w-full h-full"
            src={user.avatar.url || ''}
            alt={`${user.firstName} profile`}
          />
        ) : (
          <DefaultWrapper>
            <DefaultProfile />
          </DefaultWrapper>
        )}
        <Can do="update" on={user} type="User">
          <UploadFile>
            <button onClick={() => router.push(`${url}/edit-avatar`)}>
              <Edit className="text-white" />
            </button>
          </UploadFile>
        </Can>
      </Element>
      <Route exact path={`${url}/edit-avatar`}>
        <Modal name="Upload New Profile Image" onClose={() => void router.push(url)}>
          {({ close }) => <EditAvatar onClose={close} />}
        </Modal>
      </Route>
    </>
  );
}

const EditAvatar = ({ onClose }: EditAvatarProps) => {
  const [scale, setScale] = useState<number>(1);
  const [image, setImage] = useState<string>('');
  const [position, setPosition] = useState<ImagePosition>({ x: 0.5, y: 0.5 });
  const imageRef = useRef<AvatarEditor | null>();
  const fileRef = useRef<FileList>();

  const { uploadAvatar } = useUserInfoStore();

  return (
    <>
      <div className="flex flex-col items-center">
        <ReactAvatarEditor
          width={256}
          height={256}
          scale={scale}
          image={image}
          borderRadius={0}
          position={position}
          onPositionChange={(_position: ImagePosition) => setPosition(_position)}
          ref={(edits) => (imageRef.current = edits)}
        />
        <p className="text-xs italic ">Drag image to reposition.</p>
      </div>
      <div className="flex flex-col items-center">
        <div className="flex flex-row">
          <InputLabel htmlFor="file-upload">
            <i>Upload Image</i>
          </InputLabel>
        </div>
        <div className="flex flex-row">
          <FileInput
            id="file-upload"
            type="file"
            accept={fileCategoryToDipslayedFileTypesMap.Image}
            onChange={(e) => {
              if (e.target.files) {
                setImage(URL.createObjectURL(e.target.files[0]));
                fileRef.current = e.target.files;
              }
            }}
          />
        </div>
        <div className="flex flex-row">
          <div className="px-2">
            <Button onClick={() => setScale(scale <= 1 ? 1 : scale - 0.1)} inverted>
              - Zoom Out
            </Button>
          </div>
          <div className="px-2">
            <Button onClick={() => setScale(scale >= 2 ? 2 : scale + 0.1)}>+ Zoom In</Button>
          </div>
        </div>
      </div>
      <div className="flex">
        <Button
          onClick={() => {
            imageRef.current?.getImageScaledToCanvas().toBlob(async (blob: any) => {
              const fileName = fileRef.current ? fileRef?.current[0].name : 'avatar';

              await uploadAvatar(
                new File([blob], fileName, {
                  type: blob.type,
                  lastModified: Date.now()
                })
              );
              onClose();
            });
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default UserAvatar;
