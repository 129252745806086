import { SVGProps } from 'react';

export function ChevronRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M14.571 0c.948 0 1.856.376 2.526 1.049l21.435 21.435a3.572 3.572 0 011.048 2.525c0 .948-.378 1.856-1.048 2.526L17.097 48.97a3.569 3.569 0 01-5.034-.017 3.569 3.569 0 01-.017-5.034l18.91-18.91-18.91-18.91A3.572 3.572 0 0114.571 0z" />
    </svg>
  );
}

export default ChevronRight;
