"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChurchService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class ChurchService {
    static filterByDistrictId(districtId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/church/{districtId}/filter',
            path: {
                'districtId': districtId,
            },
        });
    }
    static getChurchMemberships(userId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/church/church-memberships/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    static addChurchMemberships(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/church/add-church-memberships',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static deactivateChurchMembership(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/church/church-membership-deactivate/{id}',
            path: {
                'id': id,
            },
        });
    }
    static updateChurchMembershipConstituent(id, isConstituent) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/church/church-membership-constituent/{id}/{isConstituent}',
            path: {
                'id': id,
                'isConstituent': isConstituent,
            },
        });
    }
    static create(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/church',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static find(limit, offset, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/church',
            query: {
                'limit': limit,
                'offset': offset,
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updateMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/church',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static removeMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/church',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static createMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/church/bulk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static quickSearch(tableName, limit, offset, relations, isQuickSearch, orderBy, where) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/church/quickSearch',
            query: {
                'tableName': tableName,
                'limit': limit,
                'offset': offset,
                'relations': relations,
                'isQuickSearch': isQuickSearch,
                'orderBy': orderBy,
                'where': where,
            },
        });
    }
    static count(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/church/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static query(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/church/query',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static findOne(id, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/church/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static update(id, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/church/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static remove(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/church/{id}',
            path: {
                'id': id,
            },
        });
    }
    static search(requestBody, relations) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/church/search',
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
exports.ChurchService = ChurchService;
