import styled from '@emotion/styled';
import { Phase as PhaseModel, PhaseService, StepService, StepTemplate } from '@gbhem/api';
import { useEffect, useMemo, useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import toposort from 'toposort';
import tw from 'twin.macro';

import { Can, SystemRole, useAuthenticatedUser } from '../../providers';
import { Button, ErrorText, FadeIn, Step, StepEditor } from '..';
import AddStepModal from '../AddStepModal/AddStepModal';
import { Dismissable } from '../Dismissable';
import { ChevronLeft, ChevronRight, More, Plus } from '../icons';
import ImportStepModal from '../ImportStepModal/ImportStepModal';
import { Modal } from '../Modal';
import { Option, OptionsMenu } from '../OptionsMenu';
import { PhaseEditor } from './PhaseEditor';

const Action = styled(Button)`
  ${tw`w-full flex-1 border-dashed border-neutral-400 bg-transparent text-neutral-400 p-2`}
`;

const AddStepContainer = styled.div`
  ${tw`inline-flex flex-col`}
`;

interface StepsProperties {
  steps: StepTemplate[];
  phase: PhaseModel;
  isSystemTrack: boolean;
}

export enum MoveDirection {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT'
}

export const StepsContainer = styled.div`
  ${tw`px-2 flex flex-col flex-1`}
`;

export const prioritize = (steps: StepTemplate[]) => {
  const map = steps.reduce<Record<string, StepTemplate>>(
    (map, step) => (map[step.id] = step) && map,
    {}
  );

  const nodes = steps.map((s) => s.id);
  const edges = steps.flatMap((s) =>
    s.prerequisites.map<[string, string | undefined]>((p) => [p.id, s.id])
  );

  return toposort.array(nodes, edges).map((s) => map[s]);
};

function Steps({ steps: _steps, isSystemTrack, phase }: StepsProperties) {
  const router = useHistory();
  const { url } = useRouteMatch();

  const authUser = useAuthenticatedUser();
  const isSuperAdmin = useMemo(
    () => !!authUser.roles.find((r) => [SystemRole.Superadmin.toString()].includes(r.name)),
    [authUser]
  );

  const [steps, setSteps] = useState(_steps);

  useEffect(() => setSteps(_steps), [_steps]);

  return (
    <StepsContainer>
      {steps.length > 0 && (
        <div className="space-y-2 mb-2">
          {prioritize(steps).map((step) => (
            <div
              key={step.id}
              onClick={() => router.push(`${url}/phase/${phase.id}/step/${step.id}`)}
              className="cursor-pointer"
            >
              <Step
                step={step}
                phase={phase}
                isSystemTrack={isSystemTrack}
                readOnly={!!step.originStepTemplate || (!isSuperAdmin && isSystemTrack)}
                onChange={(step) => setSteps(steps.map((s) => (s.id === step.id ? step : s)))}
              />
            </div>
          ))}
        </div>
      )}
      <Can do="update" on="TrackTemplate">
        {(isSuperAdmin || !isSystemTrack) && (
          <AddStepContainer>
            <Dismissable
              control={
                <Action>
                  Add Step <Plus />
                </Action>
              }
            >
              <OptionsMenu>
                <Option onClick={() => router.push(`${url}/phase/${phase.id}/step/new`)}>
                  New Step
                </Option>
                <Option onClick={() => router.push(`${url}/phase/${phase.id}/step/import`)}>
                  Import Existing Step
                </Option>
              </OptionsMenu>
            </Dismissable>
          </AddStepContainer>
        )}
      </Can>
    </StepsContainer>
  );
}

interface PhaseProperties {
  phase: PhaseModel;
  maxOrder: number;
  isSystemTrack: boolean;
  onDelete: () => void;
  onReorder: (phases: PhaseModel[]) => void;
}

function PhaseDetails({
  phase: _phase,
  isSystemTrack,
  onDelete,
  onReorder,
  maxOrder
}: PhaseProperties) {
  const router = useHistory();
  const { url } = useRouteMatch();
  const authUser = useAuthenticatedUser();
  const isSuperAdmin = useMemo(
    () => !!authUser.roles.find((r) => [SystemRole.Superadmin.toString()].includes(r.name)),
    [authUser]
  );

  const [phase, setPhase] = useState(_phase);

  useEffect(() => setPhase(_phase), [_phase]);

  const canDelete = phase && phase.stepTemplates.length === 0;

  return (
    <>
      <div className="flex items-center justify-between p-2 sticky">
        <div className="text-lg font-semibold flex space-x-2">
          <div>
            {phase.order}. {phase.name}
          </div>
          <div className="ml-auto text-neutral-500 flex items-center space-x-2">
            {(isSuperAdmin || !isSystemTrack) && phase.order > 1 && (
              <Can do="update" on="TrackTemplate">
                <ChevronLeft
                  className="w-4 h-4 cursor-pointer"
                  onClick={async () => {
                    const updatedPhases = await PhaseService.updatePhaseOrder({
                      phaseId: phase.id,
                      direction: MoveDirection.LEFT
                    });

                    onReorder(updatedPhases);
                  }}
                />
              </Can>
            )}
            {(isSuperAdmin || !isSystemTrack) && phase.order < maxOrder && (
              <Can do="update" on="TrackTemplate">
                <ChevronRight
                  className="w-4 h-4 cursor-pointer"
                  onClick={async () => {
                    const updatedPhases = await PhaseService.updatePhaseOrder({
                      phaseId: phase.id,
                      direction: MoveDirection.RIGHT
                    });

                    onReorder(updatedPhases);
                  }}
                />
              </Can>
            )}
          </div>
        </div>
        {(isSuperAdmin || !isSystemTrack) && !phase.originPhase && (
          <Can do="update" on="TrackTemplate">
            <Dismissable control={<More className="cursor-pointer" />}>
              <OptionsMenu>
                <Option onClick={() => router.push(`${url}/phase/${phase.id}`)}>Edit</Option>
                <Option
                  onClick={() => router.push(`${url}/phase/${phase.id}/delete`)}
                  className="text-pink"
                >
                  Delete
                </Option>
              </OptionsMenu>
            </Dismissable>
          </Can>
        )}
      </div>

      <Route exact path={`${url}/phase/${phase.id}`}>
        <Modal name="Edit Phase" onClose={() => void router.push(url)}>
          {({ close }) => (
            <PhaseEditor
              phase={phase}
              onSubmit={async (p) => {
                const phase = await PhaseService.updatePhase({
                  name: p.name,
                  description: p.description,
                  phaseId: p.id
                });

                setPhase(phase);
                close();
              }}
            />
          )}
        </Modal>
      </Route>
      <Route exact path={`${url}/phase/${phase.id}/delete`}>
        <Modal name="Delete Phase" onClose={() => void router.push(url)}>
          {({ close }) => (
            <>
              {canDelete ? (
                <div>Are you sure you want to delete the phase "{phase.name}"?</div>
              ) : (
                <ErrorText>Cannot delete phase that contains steps.</ErrorText>
              )}

              {canDelete && (
                <div className="flex space-x-4">
                  <Button
                    onClick={() => {
                      onDelete();
                      close();
                    }}
                  >
                    Yes, delete it
                  </Button>
                  <Button inverted onClick={() => close()}>
                    Cancel
                  </Button>
                </div>
              )}
            </>
          )}
        </Modal>
      </Route>
    </>
  );
}

export const PhaseColumn = styled(FadeIn)`
  ${tw`flex flex-col flex-1 border-neutral-700`}

  min-width: 20rem;
`;

export function Phase({ phase, isSystemTrack, onDelete, onReorder, maxOrder }: PhaseProperties) {
  const router = useHistory();
  const { url } = useRouteMatch();
  const { createImportStepTemplate, createStepTemplate } = StepService;

  const [steps, setSteps] = useState(phase.stepTemplates);
  const [importedStepId, setImportedStepId] = useState<string | undefined>(undefined);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <>
      <PhaseColumn>
        <PhaseDetails
          phase={phase}
          onDelete={onDelete}
          isSystemTrack={isSystemTrack}
          maxOrder={maxOrder}
          onReorder={onReorder}
        />
        <Steps steps={steps} phase={phase} isSystemTrack={isSystemTrack} />
      </PhaseColumn>

      <Route exact path={`${url}/phase/${phase.id}/step/add-step`}>
        <Modal name="Add step" onClose={() => void router.push(url)}>
          {() => (
            <AddStepModal
              handleNewStep={() => {
                void router.push(`${url}/phase/${phase.id}/step/new`);
              }}
              handleImportStep={() => {
                void router.push(`${url}/phase/${phase.id}/step/import`);
              }}
            />
          )}
        </Modal>
      </Route>

      <Route exact path={`${url}/phase/${phase.id}/step/new`}>
        <Modal name="Create step" onClose={() => void router.push(url)}>
          {({ close }) => (
            <>
              <StepEditor
                phase={phase}
                isSystemTrack={isSystemTrack}
                onSubmit={async (step) => {
                  const updates = [
                    ...steps,
                    await createStepTemplate({ stepTemplate: step, phaseId: phase.id })
                  ];

                  updates[updates.length - 1].prerequisites = step.prerequisites;

                  phase.stepTemplates = updates;
                  setSteps(updates);

                  close();
                }}
              />
            </>
          )}
        </Modal>
      </Route>
      <Route exact path={`${url}/phase/${phase.id}/step/import`}>
        <Modal
          name="Import Existing Step"
          onClose={() => {
            if (!importedStepId) router.push(url);

            router.push(`${url}/phase/${phase.id}/step/${importedStepId}`);
            setImportedStepId(undefined);
          }}
        >
          {({ close }) => (
            <ImportStepModal
              isSubmitting={isSubmitting}
              onSubmit={async (step) => {
                setIsSubmitting(true);

                const update: StepTemplate = await createImportStepTemplate({ phase, step });

                setImportedStepId(update.id);

                phase.stepTemplates = [...steps, update];
                setSteps([...steps, update]);

                setIsSubmitting(false);

                close();
              }}
            />
          )}
        </Modal>
      </Route>
    </>
  );
}

export default Phase;
