import { Ability, InferSubjects, subject } from '@casl/ability';
import { AnyObject } from '@casl/ability/dist/types/types';
import { BoundCanProps, createContextualCan, useAbility } from '@casl/react';
import {
  Church,
  ChurchMembership,
  Conference,
  District,
  EpiscopalArea,
  FormTemplate,
  Jurisdiction,
  MentorAssignment,
  Permission,
  Role,
  RoleService,
  TrackAssignment,
  TrackInstance,
  TrackTemplate,
  User,
  UserService
} from '@gbhem/api';
import { MongoQuery } from '@ucast/mongo';
import { createContext, FC, PropsWithChildren, useState } from 'react';
import { useAsync } from 'react-use';

import { Loader } from '../components';
import { useAuthenticatedUser } from '.';

type ActionType = 'manage' | 'create' | 'read' | 'update' | 'delete' | 'deactivate';
type SubjectName =
  | 'User'
  | 'Church'
  | 'District'
  | 'TrackAssignment'
  | 'TrackInstance'
  | 'TrackTemplate'
  | 'Conference'
  | 'EpiscopalArea'
  | 'Jurisdiction'
  | 'Role'
  | 'Permission'
  | 'ChurchMembership'
  | 'FormTemplate'
  | 'MentorAssignment';

export type Subject = InferSubjects<
  | User
  | Church
  | District
  | TrackAssignment
  | TrackInstance
  | TrackTemplate
  | Conference
  | EpiscopalArea
  | Jurisdiction
  | Role
  | Permission
  | FormTemplate
  | ChurchMembership
  | MentorAssignment
  | SubjectName
  | 'all'
>;

export enum Action {
  Manage = 'manage',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
  Deactivate = 'deactivate'
}

const AuthenticatedAbilityContext = createContext(new Ability<[ActionType, Subject]>());
export const useAuthenticatedUserAbility = () => useAbility(AuthenticatedAbilityContext);

export const CaslCan = createContextualCan(AuthenticatedAbilityContext.Consumer);

type CanProps<OnType = Subject> = BoundCanProps<
  Ability<[ActionType, Subject], MongoQuery<AnyObject>>
> & { on: OnType } & (OnType extends string ? { type?: SubjectName } : { type: SubjectName }) & {
    loader?: FC;
  };

export function Can<OnType>({
  children,
  type,
  loader: Loader,
  ...props
}: PropsWithChildren<CanProps<OnType>>) {
  const user = useAuthenticatedUser();
  const ability = useAuthenticatedUserAbility();

  // this is required due to issues accessing the "do" and "on" props in the BoundCanProps type.
  const _props = props as any;

  const action = _props.do;
  const on = !type ? _props.on : subject(type, _props.on);

  const [can, setCan] = useState(false);

  useAsync(async () => {
    if (type && on.id) {
      const hierarchy = await RoleService.getPermissionHierarchy(type, on.id);

      if (hierarchy.length) {
        const permission = user.permissions.find(
          (p) => !p.denial && hierarchy.includes(p.entityId)
        );

        if (permission) {
          setCan(
            ability.can(action, subject(permission.entity, { id: permission.entityId } as any))
          );
          return;
        }
      }
    }

    setCan(ability.can(action, on));
  }, []);

  if (!can) {
    return Loader ? <Loader /> : <></>;
  }

  return <>{children}</>;
}

export const AuthenticatedAbilityProvider: FC = ({ children }) => {
  const [ability, setAbility] = useState<Ability<[Action, Subject]>>();

  useAsync(async () =>
    setAbility(new Ability<[Action, Subject]>(await UserService.getAbilities()))
  );

  if (!ability) {
    return <Loader />;
  }

  return (
    <AuthenticatedAbilityContext.Provider value={ability}>
      {children}
    </AuthenticatedAbilityContext.Provider>
  );
};

export default AuthenticatedAbilityProvider;
