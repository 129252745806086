import { TrackAssignment, TrackService, TrackTemplate, User } from '@gbhem/api';
import { useState } from 'react';
import { Route, useHistory, useRouteMatch } from 'react-router-dom';
import { useAsync } from 'react-use';

import { Can } from '../../providers';
import { Button, Loader, Plus } from '..';
import { Modal } from '../Modal';
import { AddParticipant } from './AddParticipant';
import { ParticipantsTable } from './ParticipantsTable';

export interface Participant extends User {
  trackInstanceId: string;
  trackInstanceCreatedAt: string;
  assignments: TrackAssignment[];
  advisors: User[];
  interviewers: User[];
  status: string;
}

interface ParticipantsDashboardProperties {
  track: TrackTemplate;
}

export function ParticipantsDashboard({ track }: ParticipantsDashboardProperties) {
  const router = useHistory();
  const { url } = useRouteMatch();

  const [participantCount, setParticipantCount] = useState<number>();
  const [reloadTable, setReloadTable] = useState(true);

  useAsync(async () => {
    if (reloadTable) {
      setParticipantCount((await TrackService.getInstances(track.id, false, { limit: 1 })).count);
      setReloadTable(false);
    }
  }, [track.id, reloadTable]);

  if (participantCount === undefined || reloadTable) {
    return <Loader />;
  }
  return (
    <>
      {participantCount > 0 ? (
        <ParticipantsTable track={track} />
      ) : (
        <div className="flex flex-col flex-1 space-y-4 justify-center items-center">
          <p>No participants enrolled in track.</p>
          <Can do="create" on="TrackInstance">
            <Button onClick={() => router.push(`${url}/add`)}>
              <Plus /> Participant
            </Button>
          </Can>
        </div>
      )}
      <Route exact path={`${url}/add`}>
        <Modal name="Add Participant" onClose={() => void router.push(url)}>
          {({ close }) => (
            <AddParticipant
              onAdd={async ({ participant, assignee, role }) => {
                const [instance] = await TrackService.createInstances({
                  trackId: track.id,
                  participantIds: [participant.id]
                });

                await TrackService.assign({
                  trackInstanceId: instance.id,
                  assigneeIds: [assignee.id],
                  roleId: role.id
                });

                setReloadTable(true);
                close();
              }}
            />
          )}
        </Modal>
      </Route>
    </>
  );
}
