import styled from '@emotion/styled';
import { User } from '@gbhem/api';
import tw from 'twin.macro';

interface RegisteredPillProps {
  user: User;
}

export const RegisteredPill = ({ user }: RegisteredPillProps) => {
  const PillContainer = styled.div`
    ${tw`my-4 h-auto bg-gray-100 w-64 rounded-xl`}
  `;

  const PillText = styled.p`
    ${tw`text-base text-center mx-2 my-2`}
  `;

  const { cognitoUsername, inactive } = user;

  const isRegistered = !!cognitoUsername;

  return (
    <PillContainer>
      <PillText>
        {isRegistered ? (
          <>
            This user is{' '}
            {!inactive ? (
              'active'
            ) : (
              <>
                registered, but <span className="text-yellow-600">inactive</span>
              </>
            )}
          </>
        ) : (
          <>This user is not registered{!inactive && ', but is active'}.</>
        )}
      </PillText>
    </PillContainer>
  );
};
