import { Cell } from '@gbhem/api';
import { v4 as uuid } from 'uuid';

import { Field, FieldType, StateOptions } from '../../models';

export type Widget = {
  layout: Cell;
  fields: Field[];
};

export const address = (): Widget => {
  const [addressSection, addressOne, addressTwo, city, state, zipCode] = Array.from({
    length: 6
  }).map(() => uuid());

  return {
    layout: {
      id: uuid(),
      fieldId: addressSection,
      children: [
        {
          id: uuid(),
          fieldId: addressOne
        },
        { id: uuid(), fieldId: addressTwo },
        {
          id: uuid(),
          children: [
            {
              id: uuid(),
              fieldId: city
            },
            { id: uuid(), fieldId: state },
            { id: uuid(), fieldId: zipCode }
          ]
        }
      ]
    },
    fields: [
      {
        id: addressSection,
        name: 'Address',
        type: FieldType.Section,
        properties: {}
      },
      {
        id: addressOne,
        name: 'Address One',
        type: FieldType.Text,
        properties: { required: true }
      },
      {
        id: addressTwo,
        name: 'Address Two',
        type: FieldType.Text,
        properties: {}
      },
      {
        id: city,
        name: 'City',
        type: FieldType.Text,
        properties: { required: true }
      },
      {
        id: state,
        name: 'State',
        type: FieldType.Select,
        properties: { required: true, options: Object.keys(StateOptions) }
      },
      {
        id: zipCode,
        name: 'Zip Code',
        type: FieldType.Text,
        properties: { required: true }
      }
    ]
  };
};

export default address;
