import { css, keyframes } from '@emotion/react';

export const backgroundFadeIn = keyframes`
  0% {
    backdrop-filter: blur(0);
    background: initial;
    overflow-y: hidden;
  }
  
  100% {
    backdrop-filter: blur(0.1rem);
    background: rgba(0, 0, 0, 0.5);
    overflow-y: initial;
  }
`;

export const backgroundFadeInAnimation = (time?: number) => css`
  animation: ${backgroundFadeIn} ${time || 1}s ease-in;
`;
