import { SVGProps } from 'react';

export function Visibility(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      className="fill-current"
      width={50}
      height={30}
      viewBox="0 0 50 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8876 29.7189C38.2431 29.7189 49.3366 16.6779 49.3366 16.6779C50.2057 15.7482 50.2292 14.215 49.3639 13.2794C49.3639 13.2794 38.8189 0.273438 25.0024 0.273438C11.1859 0.273438 0.638908 13.2794 0.638908 13.2794C-0.212642 14.2267 -0.218492 15.7677 0.654533 16.6915C0.654533 16.6915 11.5315 29.719 24.887 29.719L24.8876 29.7189ZM25.0028 25.3888C30.7253 25.3888 35.3643 20.7364 35.3643 14.9963C35.3643 9.25614 30.7257 4.60379 25.0028 4.60379C19.2803 4.60379 14.6393 9.25614 14.6393 14.9963C14.6393 20.7364 19.2799 25.3888 25.0028 25.3888ZM25.0028 19.6153C27.5458 19.6153 29.6083 17.5469 29.6083 14.9961C29.6083 12.4453 27.5458 10.377 25.0028 10.377C22.4599 10.377 20.3974 12.4453 20.3974 14.9961C20.3974 17.5469 22.4599 19.6153 25.0028 19.6153Z"
      />
    </svg>
  );
}
export default Visibility;
