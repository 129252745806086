import styled from '@emotion/styled';
import tw from 'twin.macro';

export const Button = styled.button<{
  inverted?: boolean;
  secondary?: boolean;
}>`
  ${tw`flex items-center flex-shrink-0 px-4 py-1 rounded-sm text-sm font-bold outline-none cursor-pointer select-none gap-2 focus:outline-none transition-all duration-75 transform active:shadow-xl`}

  ${(p) =>
    p.inverted
      ? tw`border bg-neutral-900 text-primary border-primary`
      : tw`border bg-primary text-neutral-1000 border-primary`}

  ${(p) => (p.secondary ? tw`bg-neutral-800 text-primary` : ``)}

  :not(:disabled):hover {
    filter: brightness(80%);
  }

  :disabled {
    ${tw`text-neutral-500 bg-neutral-700 border-neutral-600`}
  }

  svg {
    max-height: 1.5em;
    max-width: 1.5em;
  }
`;

export default Button;
