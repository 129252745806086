import styled from '@emotion/styled';
import { MentorAssignment, User, UserService } from '@gbhem/api';
import { useState } from 'react';
import tw from 'twin.macro';

import { useUserRelationshipStore } from '../../providers/UserProfileProvider/UserRelationshipProvider';
import { Button } from '../Button';
import { Cancel } from '../icons';
import { Select } from '../Input';
import { Modal, ModalProps } from '../Modal';

type AddMenteesModalProps = ModalProps & {
  mentor: User;
  mentees: MentorAssignment[];
};
export const AddMenteesModal = ({ mentor, mentees, ...props }: AddMenteesModalProps) => {
  const [newMentees, setNewMentees] = useState<User[]>([]);

  const { setMenteeAssignments } = useUserRelationshipStore();

  const CardContents = styled.div`
    ${tw`flex justify-between w-full`}
  `;

  return (
    <Modal name="Add Mentees" onClose={props.onClose}>
      {({ close }) => (
        <>
          {mentees &&
            mentees.map((mentee) => {
              return (
                <>
                  {mentee.mentee.firstName} {mentee.mentee.lastName}
                  <hr />
                </>
              );
            })}

          {newMentees &&
            newMentees.map((mentee) => {
              return (
                <>
                  <CardContents>
                    {mentee.firstName} {mentee.lastName}
                    <Cancel
                      className="ml-2 mt-0.5 w-3 h-3 cursor-pointer"
                      onClick={() => {
                        setNewMentees(newMentees.filter((m) => m !== mentee));
                      }}
                    />
                  </CardContents>
                  <hr />
                </>
              );
            })}

          <Select<User>
            placeholder="Start typing the name of the mentee you would like to add..."
            options={async (searchTerm) => {
              if (!searchTerm) return;

              const users = await UserService.getMentorRelationshipOptions(mentor.id, false, {
                conditions: [
                  { name: searchTerm, inactive: false },
                  { firstName: searchTerm, inactive: false },
                  { lastName: searchTerm, inactive: false }
                ],
                relations: ['roles', 'permissions'],
                limit: 15
              });

              // filter results by currently selected assignments
              return users.filter((u: User) => !newMentees.some((nM) => nM.id === u.id));
            }}
            onSelect={(e) => {
              if (e.currentTarget.value) {
                setNewMentees([...newMentees, e.currentTarget.value]);
              }
            }}
            clearOnSelect={true}
            optionLabel={(participant) =>
              `${participant.firstName} ${participant.lastName} (${participant.email})`
            }
          />
          <Button
            className="mr-auto"
            disabled={newMentees.length === 0}
            onClick={async () => {
              await setMenteeAssignments(mentor, newMentees);
              close();
            }}
          >
            Confirm
          </Button>
        </>
      )}
    </Modal>
  );
};
