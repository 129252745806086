import { SVGProps } from 'react';

export const Filter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={`${props.className} fill-current`}
  >
    <path d="M45.95 6H3.997A3.995 3.995 0 0 0 0 9.997a3.995 3.995 0 0 0 3.997 3.996h42.006A3.995 3.995 0 0 0 50 9.997C50 7.789 48.16 6 45.95 6ZM39.59 20.563H10.357a3.995 3.995 0 0 0-3.996 3.997 3.995 3.995 0 0 0 3.996 3.996h29.284a3.995 3.995 0 0 0 3.997-3.997c0-2.207-1.84-3.996-4.048-3.996ZM33.228 35.18H16.72a3.995 3.995 0 0 0-3.997 3.996 3.995 3.995 0 0 0 3.997 3.997H33.28a3.995 3.995 0 0 0 3.997-3.997c-.002-2.208-1.843-3.997-4.05-3.997Z" />
  </svg>
);

export default Filter;
