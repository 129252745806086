import styled from '@emotion/styled';
import { User } from '@gbhem/api';
import { MutableRefObject } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { Button } from '../Button';
import { ChevronLeft, ChevronRight } from '../icons';
import { MergeUserModal } from './MergeUserModal';

interface MergeUserButtonProps {
  user: User;
  userInfoRef: MutableRefObject<User>;
}

const MergeRightArrow = styled(ChevronRight)`
  height: 80%;
  margin-right: -6%;
  padding-right: -8%;
  width: 80%;
`;
const MergeLeftArrow = styled(ChevronLeft)`
  height: 80%;
  margin-left: 0%;
  padding-left: 0%;
  width: 80%;
`;

export const MergeUserButton = ({ user, userInfoRef }: MergeUserButtonProps) => {
  const MergeUserButton = styled(Button)`
    ${tw`my-2 h-auto w-64 rounded-xl`}
  `;

  const [showMergeUserModal, hideMergeUserModal] = useModal(() => (
    <MergeUserModal profileUserRef={userInfoRef} onClose={() => hideMergeUserModal()} />
  ));

  return (
    <>
      {user.salesforceId ? (
        <MergeUserButton onClick={() => showMergeUserModal()}>
          <div className={`min-w-full flex-col content-center`}>
            <span className="inline-flex py-1">
              <MergeRightArrow />
              <MergeLeftArrow />
              <div className="px-2">Merge User</div>
            </span>
          </div>
        </MergeUserButton>
      ) : (
        <></>
      )}
    </>
  );
};
