import { ComponentProps, FC, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = ComponentProps<'div'> & {
  container?: string;
  optional?: boolean;
};

export const Portal: FC<PortalProps> = ({ children, container, optional, ...props }) => {
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    let target = document.body;

    if (container) {
      target =
        document.getElementById(container) ||
        (document.getElementsByClassName(container)?.[0] as HTMLElement);
    }

    if (!target) {
      if (!optional) {
        throw new Error(`No portal target was found for the container reference: ${container}`);
      }
      return;
    }

    el.classList.add('portal', ...(props.className?.length ? props.className.split(' ') : []));

    target.appendChild(el);

    return () => void target.removeChild(el);
  }, [el, container, props.className, optional]);

  return createPortal(children, el);
};

export default Portal;
