import { SVGProps } from 'react';

export function ChevronUp(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={51}
      height={30}
      viewBox="0 0 51 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M.29 25.72c0-.948.375-1.856 1.048-2.526L22.773 1.76A3.572 3.572 0 0125.298.711c.948 0 1.856.378 2.526 1.048l21.435 21.435a3.569 3.569 0 01-.017 5.034 3.569 3.569 0 01-5.034.017l-18.91-18.91-18.91 18.91A3.572 3.572 0 01.289 25.72z" />
    </svg>
  );
}

export default ChevronUp;
