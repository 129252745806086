import styled from '@emotion/styled';
import { Role, RoleService } from '@gbhem/api';
import { useMemo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { Column } from 'react-table';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import { Can } from '../../providers';
import { Loader, Modal, Table } from '..';
import { Button } from '../Button';
import { Edit, Plus, Remove } from '../icons';
import { RoleEditor } from '../RoleEditor/RoleEditor';

const Actions = styled.div`
  ${tw`px-4 flex`}
`;

export function RolesTable() {
  const [roles, setRoles] = useState<Role[]>([]);
  const [selectedRole, setSelectedRole] = useState<Role>();

  useAsync(async () => setRoles(await RoleService.find()), []);

  const [showRoleEditor, hideRoleEditor] = useModal(
    () => (
      <Modal name="Role Editor" onClose={hideRoleEditor}>
        {({ close }) => (
          <RoleEditor
            role={selectedRole}
            onSubmit={async (role) => {
              if (role.id) {
                await RoleService.update(role.id, role);
                setRoles(roles.filter((r) => (r.id === role.id ? role : r)));
              } else {
                setRoles([...roles, await RoleService.create(role)]);
              }

              close();
            }}
          />
        )}
      </Modal>
    ),
    [roles, selectedRole]
  );

  const [showConfirmDeleteRole, hideConfirmDeleteRole] = useModal(
    () => (
      <Modal name="Confirm Delete" onClose={hideConfirmDeleteRole}>
        {({ close }) => (
          <>
            <p>Are you sure you want to delete the {selectedRole?.name} role?</p>
            <div className="flex space-x-2">
              <Button
                onClick={async () => {
                  await RoleService.remove(selectedRole!.id);

                  setRoles(roles.filter((r) => r.id !== selectedRole!.id));
                  setSelectedRole(undefined);

                  close();
                }}
              >
                Yes, delete
              </Button>
              <Button inverted onClick={() => close()}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Modal>
    ),
    [roles, selectedRole]
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: 'SYSTEM',
          accessor: 'system',
          id: 'system',
          Cell: ({ value }) => (value ? 'System' : '')
        },
        {
          Header: 'NAME',
          accessor: 'name',
          id: 'expander',
          Cell: ({ value }) => value
        },
        {
          Header: 'ACTIONS',
          disableSortBy: true,
          id: 'actions',
          accessor: 'policies',
          Cell: ({ value }) => (
            <div className="capitalize">
              {Array.from(
                new Set(value.flatMap((p) => (Array.isArray(p.action) ? p.action : [p.action])))
              ).join(', ')}
            </div>
          )
        },
        {
          Header: 'SUBJECTS',
          disableSortBy: true,
          id: 'subjects',
          accessor: 'policies',
          Cell: ({ value }) => (
            <div className="capitalize">
              {Array.from(
                new Set(value.flatMap((p) => (Array.isArray(p.subject) ? p.subject : [p.subject])))
              ).join(', ')}
            </div>
          )
        },
        {
          Header: 'EDIT',
          id: 'edit',
          accessor: 'id',
          disableSortBy: true,
          Cell: ({ row }) =>
            !row.original.system && (
              <Edit
                className="cursor-pointer"
                onClick={() => {
                  setSelectedRole(row.original);
                  showRoleEditor();
                }}
              />
            )
        },
        {
          Header: 'DELETE',
          id: 'delete',
          accessor: 'id',
          disableSortBy: true,
          Cell: ({ row }) =>
            !row.original.system && (
              <Remove
                className="cursor-pointer"
                onClick={() => {
                  setSelectedRole(row.original);
                  showConfirmDeleteRole();
                }}
              />
            )
        }
      ] as Column<Role>[],
    [showConfirmDeleteRole, showRoleEditor]
  );

  if (!roles) {
    return <Loader />;
  }

  return (
    <div className="flex-1 my-4">
      <Actions>
        <Can do="create" on="Role">
          <Button
            onClick={() => {
              setSelectedRole(undefined);
              showRoleEditor();
            }}
          >
            <Plus /> Add
          </Button>
        </Can>
      </Actions>
      <Table columns={columns} data={roles} />
    </div>
  );
}
