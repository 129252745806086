import styled from '@emotion/styled';
import { Role, StepActionTemplate as StepActionModel } from '@gbhem/api';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useEffectOnce, useUnmount } from 'react-use';
import tw from 'twin.macro';
import { v4 as uuidv4 } from 'uuid';

import {
  Accordion,
  Button,
  Dismissable,
  Input,
  Option,
  OptionsMenu,
  Select
} from '../../components';
import { useRoles } from '../../providers';
import { Checkmark, More, SignatureIcon } from '../icons';

interface StepActionEditorProperties {
  action: StepActionModel;
  roles: Role[];
  readOnly?: boolean;
  onChange: (action: StepActionModel) => void;
  onDelete: () => void;
}

const ContentTypeIconContainer = styled.div`
  ${tw`flex text-sm items-center space-x-2 mb-2`}

  svg {
    ${tw`h-5 w-5`}
  }
`;

function StepActionEditor({
  action: _action,
  roles,
  readOnly = false,
  onChange,
  onDelete
}: StepActionEditorProperties) {
  const [action, setAction] = useState(_action);
  const {
    register,
    unregister,
    clearErrors,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [nameField, setNameField] = useState('Name');

  useEffectOnce(() => {
    setValue(nameField, action.name ?? '');
    clearErrors(nameField);

    if (!readOnly) {
      setNameField(uuidv4());
    }
  });

  useUnmount(() => {
    clearErrors(nameField);
    unregister(nameField);
  });

  return (
    <Accordion
      className="flex flex-1"
      isOpen={true}
      header={
        <>
          <ContentTypeIconContainer>
            {action.stepActionType === 'Signature' ? <SignatureIcon /> : <Checkmark />}
            <div>
              <div className="text-xs font-normal text-neutral-500">{action.role?.name}</div>
              {action.stepActionType} <span className="ml-1 font-normal">{action.name}</span>
            </div>
          </ContentTypeIconContainer>
        </>
      }
      control={() =>
        !readOnly ? (
          <>
            <Dismissable control={<More />} className="text-base font-normal">
              <OptionsMenu>
                <Option onClick={onDelete} className="text-pink">
                  Delete
                </Option>
              </OptionsMenu>
            </Dismissable>
          </>
        ) : null
      }
    >
      <div className="space-y-4">
        <Input
          placeholder="Type action name..."
          {...register(nameField, { required: { value: true, message: 'required' } })}
          label="Name"
          required={true}
          disabled={readOnly}
          value={action.name}
          error={errors[nameField]?.message}
          onChange={(e) => {
            if (e.target.value) {
              clearErrors(nameField);
            }
            setAction({ ...action, name: e.target.value });
            onChange({ ...action, name: e.target.value });
          }}
        />
        <Select
          label="Completed by"
          placeholder="Select role responsible for action"
          options={roles}
          disabled={readOnly}
          value={action.role}
          onChange={(e) => {
            if (!e.target.value) {
              return;
            }
            setAction({ ...action, role: e.target.value });
            onChange({ ...action, role: e.target.value });
          }}
        />
      </div>
    </Accordion>
  );
}

interface StepActionsEditorProperties {
  actions: StepActionModel[];
  readOnly: boolean;
  onChange: (actions: StepActionModel[]) => void;
}

export function StepActionsEditor({ actions, readOnly, onChange }: StepActionsEditorProperties) {
  const { system: roles } = useRoles();

  const advisorRole = useMemo(() => roles.find((r) => r.name === 'Advisor'), [roles]);

  return (
    <div className="space-y-4">
      {actions.map((action, i) => (
        <div key={i} className="flex">
          <div className="flex-1">
            <StepActionEditor
              action={action}
              roles={roles}
              readOnly={readOnly}
              onChange={(action) => onChange(actions.map((m, _i) => (i === _i ? action : m)))}
              onDelete={() => onChange(actions.filter((a) => a !== action))}
            />
          </div>
        </div>
      ))}
      {!readOnly && (
        <div className="inline-flex space-x-2 text-xs">
          <Button
            inverted
            onClick={() =>
              onChange([
                ...actions,
                {
                  stepActionType: 'Signature',
                  name: '',
                  role: advisorRole || roles[0]
                } as StepActionModel
              ])
            }
          >
            <SignatureIcon /> Sign
          </Button>
          <Button
            inverted
            onClick={() =>
              onChange([
                ...actions,
                {
                  stepActionType: 'Approval',
                  name: '',
                  role: advisorRole || roles[0]
                } as StepActionModel
              ])
            }
          >
            <Checkmark />
            Approve
          </Button>
        </div>
      )}
    </div>
  );
}

export default StepActionsEditor;
