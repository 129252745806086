import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';

import { AdminCommentsTable } from '../../components';
import {
  Action,
  Can,
  useAuthenticatedUser,
  useAuthenticatedUserAbility,
  useTrackStore
} from '../../providers';
import { useUserInfoStore } from '../../providers/UserProfileProvider/UserInfoProvider';
import { useUserRelationshipStore } from '../../providers/UserProfileProvider/UserRelationshipProvider';
import { FadeIn } from '../animations';
import { Loader } from '../Loader';
import Aliases from './Aliases';
import { ChurchMemberships } from './ChurchMemberships';
import LocationPermissions from './LocationPermissions';
import { Mentees } from './Mentees';
import { Mentors } from './Mentors';
import { MergedPill } from './MergedPill';
import { MergeUserButton } from './MergeUserButton';
import { RegisteredPill } from './RegisteredPill';
import Tags from './Tags';
import Tracks from './Tracks';
import UserAvatar from './UserAvatar';
import UserDetails from './UserDetails';

const Container = styled(FadeIn)`
  ${tw`flex m-6 space-x-4`}
`;

const ProfileContainer = styled.div`
  ${tw`flex-1 p-8 bg-neutral-1000`}

  height: fit-content;
`;

const HorizontalRule = styled.hr`
  ${tw`my-6 border-neutral-800`}
`;

export function UserProfile() {
  const { id } = useParams<{ id: string }>();
  const isMounted = useRef(true);

  const userAbility = useAuthenticatedUserAbility();

  const authenticatedUser = useAuthenticatedUser();

  const { initializeState } = useUserRelationshipStore();
  const { initializeUserInfo, userInfo, clearUserInfo } = useUserInfoStore();

  const canEdit = userAbility.can(Action.Update, 'User');

  // intiialize user info state
  useEffect(() => {
    isMounted.current = true;
    const fetchUser = (_id: string) => {
      initializeUserInfo(_id, canEdit);
      initializeState(_id);
    };

    if (isMounted.current) {
      fetchUser(id);
    }

    return () => {
      clearUserInfo();
      isMounted.current = false;
    };
  }, [canEdit, clearUserInfo, id, initializeState, initializeUserInfo]);

  // Check to make sure the authenticated user has tracks loaded.
  // If a user is directed to a profile automatically,
  // the tracks might not be loaded from skipping the Tracks landing page.
  const { load, tracks, loading } = useTrackStore();

  const userInfoRef = useRef(userInfo);

  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  useEffect(() => {
    // used for cleanup function
    if (isMounted.current) {
      if (Object.keys(tracks).length === 0) load();
    }

    // clean up after unmount
    return () => {
      isMounted.current = false;
    };
  }, [clearUserInfo, load, tracks]);

  // we check for user id here instead of just user info because we have an initial state now that has a blank id
  if (!userInfo.id || loading) {
    return <Loader />;
  }

  return (
    <Container>
      <div className="flex flex-col">
        <UserAvatar user={userInfo} />
        <RegisteredPill user={userInfo} />
        <MergedPill user={userInfo} />
        <Can do="manage" on="all">
          <MergeUserButton userInfoRef={userInfoRef} user={userInfo} />
        </Can>
      </div>

      <ProfileContainer>
        <UserDetails user={userInfo} />
        <HorizontalRule />
        {authenticatedUser.id === userInfo.id ? (
          <Can do="read" on="MentorAssignment">
            <Mentors mentee={userInfo} />
            <HorizontalRule />
            {userInfo.roles.find((r) => r.name === 'Mentor') && (
              <>
                <Mentees mentor={userInfo} />
                <HorizontalRule />
              </>
            )}
          </Can>
        ) : (
          <>
            {authenticatedUser.roles.find((r) => r.name === 'Mentor') ? (
              <>
                <Mentors mentee={userInfo} />
                <HorizontalRule />
                {userInfo.roles.find((r) => r.name === 'Mentor') && (
                  <>
                    <Mentees mentor={userInfo} />
                    <HorizontalRule />
                  </>
                )}
              </>
            ) : (
              <Can do="create" on="MentorAssignment">
                <Mentors mentee={userInfo} />
                <HorizontalRule />
                {userInfo.roles.find((r) => r.name === 'Mentor') && (
                  <>
                    <Mentees mentor={userInfo} />
                    <HorizontalRule />
                  </>
                )}
              </Can>
            )}
          </>
        )}

        <Can do="create" on="User">
          {userInfo.aliases && (
            <>
              <div className="font-bold my-4">Aliases</div>
              <Aliases user={userInfo} />
              <HorizontalRule />
            </>
          )}

          {userInfo.tags && (
            <>
              <div className="font-bold my-4">
                Tags
                <div className="text-xs font-light text-neutral-500">
                  30 characters maximum per tag
                </div>
              </div>
              <Tags user={userInfo} />
              <HorizontalRule />
            </>
          )}
        </Can>

        <div className="font-bold mb-4">Locations</div>
        <LocationPermissions user={userInfo} />
        <HorizontalRule />

        <div className="font-bold mb-4">Church Memberships</div>
        {userInfo.memberships && (
          <>
            <ChurchMemberships user={userInfo} />
          </>
        )}

        <HorizontalRule />

        <div className="font-bold mb-4">Tracks</div>
        <Tracks user={userInfo} />

        {userInfo.comments && (
          <>
            <Can do="create" on="User">
              <HorizontalRule />
              <AdminCommentsTable user={userInfo} />
            </Can>
          </>
        )}
      </ProfileContainer>
    </Container>
  );
}

export default UserProfile;
