import styled from '@emotion/styled/macro';
import tw from 'twin.macro';

import { growInAnimation } from '../animations';

export const Option = styled.div`
  ${tw`flex justify-end px-3 py-2 cursor-pointer bg-neutral-1000 hover:bg-neutral-900 text-xs`}
`;

export const OptionsMenu = styled.div`
  ${tw`absolute right-0 overflow-hidden select-none filter drop-shadow-2xl bg-neutral-1000 rounded-md text-primary border border-neutral-700`}

  top: calc(100% + 1rem);
  width: max-content;

  > * + * {
    ${tw`border-t border-neutral-700`}
  }

  ${growInAnimation(0.1)}
`;

export default OptionsMenu;
