import {
  AddChurchMemberships,
  AddLocationPermissions,
  Address,
  ChurchMembership,
  ChurchService,
  Tag,
  TrackService,
  TrackTemplate,
  User,
  UserComment,
  UserService
} from '@gbhem/api';
import { ChangeEvent } from 'react';
import create from 'zustand';

import { SelectElement } from '../../components';

interface UserInfoStore {
  userInfo: User;
  isLoading: boolean;
  isChange: boolean;
  isEmailChanged: boolean;
  currentUserEmail: string;

  initializeUserInfo: (userId: string, canEdit: boolean) => Promise<void>;
  clearUserInfo: () => void;

  editUserDetails: (
    key: string,
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<SelectElement<string>>,
    editingAddress?: boolean
  ) => void;
  saveUserDetails: () => void;

  addAlias: (userId: string, alias: string) => Promise<void>;
  addChurchMembership: ({ userIds, churchId, constituent }: AddChurchMemberships) => Promise<void>;
  addComments: (userId: string, comments: UserComment[]) => Promise<void>;
  addLocationPermission: ({ userIds, entity, entityId }: AddLocationPermissions) => Promise<void>;
  addTrackInstance: (track: TrackTemplate, advisor: User, roleId: string) => Promise<void>;
  applyTag: (user: User, tag: { name: string; id: string }) => Promise<void>;
  removeAlias: (userId: string, aliasId: string) => Promise<void>;
  removeComment: (userId: string, comments: UserComment[], currentUserId: string) => Promise<void>;
  removeChurchMembership: (remove: ChurchMembership) => Promise<void>;
  removeTag: (userId: string, tagId: string) => Promise<void>;
  updateChurchMembership: (membershipId: string, checked: boolean) => Promise<void>;
  uploadAvatar: (avatar: File) => Promise<void>;
}

const initialState: User = {
  address: { user: { id: '' } as User, id: '' },
  aliases: [],
  avatar: { url: '', key: '' },
  cognitoUsername: '',
  comments: [],
  dateOfBirth: '',
  email: '',
  emailNotificationsOptIn: false,
  ethnicBackground: '',
  files: [],
  firstName: '',
  gcfaId: '',
  gender: '',
  groups: [],
  id: '',
  inactive: false,
  isPhoneNumberVerified: false,
  lastName: '',
  memberships: [],
  middleName: '',
  notifications: [],
  permissions: [],
  phoneNumber: null,
  primaryLanguage: '',
  roles: [],
  secondEmail: '',
  secondPhoneNumber: null,
  smsOptIn: false,
  suffix: '',
  tags: [],
  title: '',
  tracks: [],
  umcaresId: undefined,
  updatedAt: undefined,
  userAppAbility: []
};

export const useUserInfoStore = create<UserInfoStore>((set, get) => ({
  userInfo: initialState,
  isLoading: false,
  isChange: false,
  isEmailChanged: false,
  currentUserEmail: '',

  initializeUserInfo: async (userId: string, canEdit: boolean) => {
    const basicInfo: User = await UserService.getUserProfileBasicInfo(userId, canEdit);
    set(() => ({ userInfo: basicInfo, currentUserEmail: basicInfo.email }));

    //if can edit we grab the rest of the information that we need
    if (canEdit) {
      const churchMemberships = await ChurchService.getChurchMemberships(userId);
      set((state) => ({ userInfo: { ...state.userInfo, memberships: churchMemberships } }));

      const tracks = await UserService.getUserProfileTrackInstances(userId);
      set((state) => ({ userInfo: { ...state.userInfo, tracks } }));

      const comments = await UserService.getUserProfileComments(userId);
      set((state) => ({ userInfo: { ...state.userInfo, comments } }));
    }
  },
  clearUserInfo: () => set(() => ({ userInfo: initialState, isLoading: false })),

  editUserDetails: (
    key: string,
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<SelectElement<string>>,
    editingAddress?: boolean
  ) => {
    const updatedUser = get().userInfo;
    if (editingAddress) {
      if (!updatedUser.address) {
        updatedUser.address = { street: '', city: '', state: '', zipCode: '', unit: '' } as Address;
      }
      if (updatedUser.address[key as keyof Address] !== event.target.value) {
        // TODO fix this typing and the below
        //@ts-ignore
        updatedUser.address[key as keyof Address] = event.target.value;
        set(() => ({ isChange: true }));
      }
    } else if (key === 'email') {
      if (get().currentUserEmail !== event.target.value) {
        set(() => ({ isEmailChanged: true, isChange: true }));
      } else if (get().currentUserEmail === event.target.value) {
        set(() => ({ isEmailChanged: false, isChange: false }));
      }
      //@ts-ignore
      updatedUser[key as keyof User] = event.target.value;
    } else if (key === 'smsOptIn') {
      updatedUser.smsOptIn = !updatedUser.smsOptIn;
      set(() => ({ isChange: true }));
    } else if (key === 'emailNotificationsOptIn') {
      updatedUser.emailNotificationsOptIn = !updatedUser.emailNotificationsOptIn;
      set(() => ({ isChange: true }));
    } else {
      if (updatedUser[key as keyof User] !== event.target.value) {
        //@ts-ignore
        updatedUser[key as keyof User] = event.target.value;
        set(() => ({ isChange: true }));
      }
    }

    set((state) => ({ userInfo: { ...state.userInfo, updatedUser } }));
  },
  saveUserDetails: async () => {
    const update = get().userInfo;
    const updatedUser = await UserService.update(update.id, update);
    set(() => ({ userInfo: updatedUser, isChange: false, isEmailChanged: false }));
  },

  addAlias: async (userId: string, alias: string) => {
    const id = await UserService.addAlias(userId, alias);

    set((state) => ({
      userInfo: { ...state.userInfo, aliases: [...state.userInfo.aliases, { id, alias }] }
    }));
  },

  addChurchMembership: async ({ userIds, churchId, constituent }: AddChurchMemberships) => {
    const addedMemberships = await ChurchService.addChurchMemberships({
      userIds,
      churchId,
      constituent
    });

    const existingMemberships = get().userInfo.memberships;

    const updatedMemberships = addedMemberships.flatMap((aM) => [
      ...existingMemberships.filter((eM) => eM.id !== aM.id),
      ...[{ ...aM }]
    ]);

    set((state) => ({
      userInfo: {
        ...state.userInfo,
        memberships: [...updatedMemberships]
      }
    }));
  },

  addComments: async (userId: string, comments: UserComment[]) => {
    const savedComments = await UserService.comment(userId, comments);

    set((state) => ({
      userInfo: { ...state.userInfo, comments: [...state.userInfo.comments, ...savedComments] }
    }));
  },

  addLocationPermission: async ({ userIds, entity, entityId }: AddLocationPermissions) => {
    const addedPermission = await UserService.addLocationPermissions({
      userIds: userIds,
      entity: entity,
      entityId: entityId
    });

    set((state) => ({
      userInfo: {
        ...state.userInfo,
        permissions: [...state.userInfo.permissions, ...addedPermission]
      }
    }));
  },

  addTrackInstance: async (track: TrackTemplate, advisor: User, roleId: string) => {
    const { assign, createInstances } = TrackService;

    const [instance] = await createInstances({
      trackId: track.id,
      participantIds: [get().userInfo.id]
    });

    await assign({
      trackInstanceId: instance.id,
      assigneeIds: [advisor.id],
      roleId: roleId
    });

    instance.trackTemplate = track;
    set((state) => ({
      userInfo: { ...state.userInfo, tracks: [...state.userInfo.tracks, instance] }
    }));
  },

  applyTag: async (user: User, tag: { name: string; id: string }) => {
    if (!tag.name) return;

    if (tag.id) {
      await UserService.addTag(user.id, tag.id);
    } else {
      tag.id = (await UserService.addNewTag(user.id, { name: tag.name })).id;
    }

    set((state) => ({
      userInfo: { ...state.userInfo, tags: [...state.userInfo.tags, tag as Tag] }
    }));
  },

  removeAlias: async (userId: string, aliasId: string) => {
    await UserService.deleteAlias(userId, aliasId);
    set((state) => ({
      userInfo: {
        ...state.userInfo,
        aliases: [...state.userInfo.aliases.filter((alias) => alias.id !== aliasId)]
      }
    }));
  },

  removeChurchMembership: async (remove: ChurchMembership) => {
    await ChurchService.deactivateChurchMembership(remove.id);

    set((state) => ({
      userInfo: {
        ...state.userInfo,
        memberships: [
          ...state.userInfo.memberships.map((membership) => ({
            ...membership,
            active: membership.id === remove.id ? false : membership.active,
            primary: membership.id === remove.id ? false : membership.primary,
            constituent: membership.id === remove.id ? false : membership.constituent
          }))
        ]
      }
    }));
  },

  removeComment: async (userId: string, comments: UserComment[], currentUserId: string) => {
    const authorizedToDelete = comments.filter((comment) => comment.author.id === currentUserId);

    const update = await UserService.removeComments({ userId, comments: authorizedToDelete });

    const newComments = [
      ...get().userInfo.comments.filter(
        (comment) =>
          !(
            update.map((c: UserComment) => c.createdAt).includes(comment.createdAt) &&
            comments.includes(comment)
          )
      )
    ];

    set((state) => ({
      userInfo: {
        ...state.userInfo,
        comments: newComments
      }
    }));
  },

  removeTag: async (userId: string, tagId: string) => {
    await UserService.removeTag(userId, tagId);

    set((state) => ({
      userInfo: {
        ...state.userInfo,
        tags: [...state.userInfo.tags.filter((tag) => tag.id !== tagId)]
      }
    }));
  },

  updateChurchMembership: async (membershipId: string, checked: boolean) => {
    const updatedChurchMembership = await ChurchService.updateChurchMembershipConstituent(
      membershipId,
      checked
    );

    set((state) => ({
      userInfo: {
        ...state.userInfo,
        memberships: [
          ...state.userInfo.memberships.map((membership) =>
            membership.id === updatedChurchMembership.id
              ? { ...updatedChurchMembership }
              : { ...membership }
          )
        ]
      }
    }));
  },

  uploadAvatar: async (avatar: File) => {
    if (!avatar) {
      return;
    }

    //creates a local URL to display image right away.
    set((state) => ({
      userInfo: { ...state.userInfo, avatar: { url: URL.createObjectURL(avatar) } }
    }));

    const formData = new FormData();
    formData.append('avatar', avatar, avatar.name);
    await fetch(`/api/user/${get().userInfo.id}/avatar`, {
      method: 'POST',
      body: formData
    })
      .then((res) => res.json())
      .then((data: User) => {
        set((state) => ({
          userInfo: { ...state.userInfo, avatar: { url: data.avatar?.url || '' } }
        }));
      });
  }
}));
