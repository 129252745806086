import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { RolesTable, SubNavbar, SubNavLink } from '../../components';
import { Can } from '../../providers';
import { Advisors, Churches, Conferences, Districts, EpiscopalAreas, Jurisdictions } from '..';

export function Admin() {
  const { url } = useRouteMatch();

  return (
    <>
      <SubNavbar>
        <SubNavLink to={`${url}/churches`}>Churches</SubNavLink>
        <SubNavLink to={`${url}/districts`}>Districts</SubNavLink>
        <SubNavLink to={`${url}/conferences`}>Conferences</SubNavLink>

        <Can do="update" on="EpiscopalArea">
          <SubNavLink to={`${url}/episcopal-areas`}>Episcopal Areas</SubNavLink>
        </Can>
        <Can do="update" on="Jurisdiction">
          <SubNavLink to={`${url}/jurisdictions`}>Jurisdictions</SubNavLink>
        </Can>
        <SubNavLink to={`${url}/advisors`}>Advisors</SubNavLink>
        <Can do="update" on="Role">
          <SubNavLink to={`${url}/roles`}>Roles</SubNavLink>
        </Can>
      </SubNavbar>

      <Switch>
        <Route exact path={url}>
          <Redirect to={`${url}/conferences`} />
        </Route>
        <Route path={`${url}/churches`}>
          <Churches />
        </Route>
        <Route path={`${url}/districts`}>
          <Districts />
        </Route>
        <Route path={`${url}/conferences`}>
          <Conferences />
        </Route>
        <Route path={`${url}/episcopal-areas`}>
          <EpiscopalAreas />
        </Route>
        <Route path={`${url}/jurisdictions`}>
          <Jurisdictions />
        </Route>
        <Route path={`${url}/advisors`}>
          <Advisors />
        </Route>
        <Route path={`${url}/roles`}>
          <RolesTable />
        </Route>
      </Switch>
    </>
  );
}

export default Admin;
