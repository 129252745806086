import styled from '@emotion/styled';
import { Role, StepContentTemplate, TaskTemplate, UploadTemplate } from '@gbhem/api';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useEffectOnce, useUnmount } from 'react-use';
import tw from 'twin.macro';
import { v4 as uuid } from 'uuid';

import {
  Accordion,
  Button,
  Dismissable,
  Input,
  Option,
  OptionsMenu,
  Select
} from '../../components';
import { useRoles } from '../../providers';
import { FormIcon, More, TaskIcon, Upload } from '../icons';
import { StepUploadEditor, TaskEditor } from '.';
import FormSelectionEditor from './FormSelectionEditor';
import StepContentPrivacyEditor from './StepContentPrivacyEditor';

export interface StepContentProperties {
  content: StepContentTemplate;
  readOnly?: boolean;
  onChange: (content: StepContentTemplate) => void;
}

function StepContent({ content, readOnly = false, onChange }: StepContentProperties) {
  switch (content.type) {
    case 'Task':
      return (
        <TaskEditor
          task={content.taskTemplate || ({ name: '', description: '' } as TaskTemplate)}
          readOnly={readOnly}
          onChange={(taskTemplate) => onChange({ ...content, taskTemplate })}
        />
      );
    case 'Upload':
      return (
        <>
          <StepUploadEditor
            specification={
              content.uploadTemplate || ({ name: '', multiple: false } as UploadTemplate)
            }
            readOnly={readOnly}
            onChange={(uploadTemplate) => onChange({ ...content, uploadTemplate })}
          />

          <StepContentPrivacyEditor content={content} readOnly={readOnly} onChange={onChange} />
        </>
      );
    case 'Form':
    default:
      return (
        <>
          <FormSelectionEditor
            form={content.formTemplate}
            readOnly={readOnly}
            onChange={(form) =>
              onChange({
                ...content,
                formTemplate: form ? { ...content.formTemplate, ...form } : undefined
              })
            }
          />
          <StepContentPrivacyEditor content={content} readOnly={readOnly} onChange={onChange} />
        </>
      );
  }
}

interface StepContentEditorProperties {
  content: StepContentTemplate;
  roles: Role[];
  readOnly?: boolean;
  onChange: (content: StepContentTemplate) => void;
  onDelete: () => void;
}

const ContentTypeIconContainer = styled.div`
  ${tw`flex text-sm items-center space-x-2 mb-2`}

  svg {
    ${tw`h-5 w-5`}
  }
`;

function StepContentEditor({
  content: _content,
  roles,
  readOnly = false,
  onChange,
  onDelete
}: StepContentEditorProperties) {
  const [content, setContent] = useState(_content);
  const [isEditing, setIsEditing] = useState(!content.id);

  const {
    register,
    unregister,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext();

  const [contentNameField, setContentNameField] = useState('Step Content Name');

  useEffectOnce(() => {
    setValue(contentNameField, content.name ?? '');
    clearErrors(contentNameField);

    if (!readOnly) {
      setContentNameField(uuid());
    }
  });

  const Icon = useCallback(
    () =>
      content.type === 'Form' ? <FormIcon /> : content.type === 'Task' ? <TaskIcon /> : <Upload />,
    [content.type]
  );

  useUnmount(() => {
    clearErrors(contentNameField);
    unregister(contentNameField);
  });

  return (
    <Accordion
      className="flex flex-1"
      isOpen={isEditing}
      header={
        <>
          <ContentTypeIconContainer>
            <div className="text-primary">
              <Icon />
            </div>
            <div>
              <div className="text-xs font-normal text-neutral-500">{content.role?.name}</div>
              {content.type} <span className="ml-1 font-normal">{content.name}</span>
            </div>
          </ContentTypeIconContainer>
        </>
      }
      control={() =>
        !readOnly ? (
          <Dismissable control={<More />} className="text-base font-normal">
            <OptionsMenu>
              <Option onClick={() => setIsEditing(true)}>Edit</Option>
              <Option onClick={onDelete} className="text-pink">
                Delete
              </Option>
            </OptionsMenu>
          </Dismissable>
        ) : null
      }
    >
      <div className="space-y-4">
        <Input
          {...register(contentNameField, { required: { value: true, message: 'required' } })}
          value={content.name}
          label="Content Name"
          disabled={readOnly}
          placeholder="Type content name..."
          error={errors[contentNameField]?.message}
          onChange={(e) => {
            if (e.target.value) {
              clearErrors(contentNameField);
              setValue(contentNameField, e.target.value);
            }
            setContent({ ...content, name: e.target.value });
            onChange({ ...content, name: e.target.value });
          }}
          required
        />
        <Select
          {...register('completedBy', { required: { value: true, message: 'required' } })}
          label="Completed by"
          placeholder="Select role responsible for content"
          disabled={readOnly}
          options={roles}
          value={content.role}
          onChange={(e) => {
            if (!e.target.value) {
              return;
            }

            clearErrors('completedBy');
            setValue('completedBy', e.target.value);
            setContent({ ...content, role: e.target.value });
            onChange({ ...content, role: e.target.value });
          }}
          required
        />
        <StepContent
          content={content}
          readOnly={readOnly}
          onChange={(content) => {
            setContent(content);
            onChange(content);
          }}
        />
      </div>
    </Accordion>
  );
}

interface StepContentsEditorProperties {
  contents: (StepContentTemplate & { key?: string })[];
  readOnly?: boolean;
  onChange: (content: (StepContentTemplate & { key?: string })[]) => void;
}

export function StepContentsEditor({
  contents,
  readOnly = false,
  onChange
}: StepContentsEditorProperties) {
  const { system: roles } = useRoles();

  const participantRole = useMemo(() => roles.find((r) => r.name === 'Participant'), [roles]);

  return (
    <div className="space-y-4">
      {contents.map((content, i) => (
        <div key={content.id ? content.id : content.key} className="flex">
          <div className="flex-1">
            <StepContentEditor
              content={content}
              readOnly={readOnly}
              roles={roles}
              onChange={(content) => onChange(contents.map((c, _i) => (i === _i ? content : c)))}
              onDelete={() => onChange(contents.filter((c) => c !== content))}
            />
          </div>
        </div>
      ))}
      {!readOnly && (
        <div className="inline-flex space-x-2 text-xs mt-4">
          <Button
            inverted
            onClick={() =>
              onChange([
                ...contents,
                {
                  key: uuid(),
                  type: 'Task',
                  name: '',
                  role: participantRole || roles[0]
                } as StepContentTemplate & { key: string }
              ])
            }
          >
            <TaskIcon /> Task
          </Button>
          <Button
            inverted
            onClick={() =>
              onChange([
                ...contents,
                {
                  key: uuid(),
                  type: 'Upload',
                  name: '',
                  role: participantRole || roles[0]
                } as StepContentTemplate & { key: string }
              ])
            }
          >
            <Upload /> Upload
          </Button>
          <Button
            inverted
            onClick={() =>
              onChange([
                ...contents,
                {
                  key: uuid(),
                  type: 'Form',
                  name: '',
                  role: participantRole || roles[0]
                } as StepContentTemplate & { key: string }
              ])
            }
          >
            <FormIcon /> Form
          </Button>
        </div>
      )}
    </div>
  );
}

export default StepContentsEditor;
