import { Conference, Phase, PhaseService, TrackService, TrackTemplate } from '@gbhem/api';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useLocationStore } from '../../providers';
import { Button } from '..';
import { Select } from '../Input';

type SelectionState = {
  conference: Conference | undefined;
  track: TrackTemplate | undefined;
  phase: Phase | undefined;
};

interface ImportPhaseModalProperties {
  onSubmit: (phase: Phase) => void;
  isSubmitting: boolean;
}

export function ImportPhaseModal({ onSubmit, isSubmitting }: ImportPhaseModalProperties) {
  const [selectionState, setSelectionState] = useState<SelectionState>({
    conference: undefined,
    track: undefined,
    phase: undefined
  });

  const { getTrackTemplatesByConference } = TrackService;
  const { getPhasesByTrackTemplate } = PhaseService;

  const { conferences } = useLocationStore();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isDirty, isValid }
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true
  });

  const changeHandler = (
    name: string,
    newValue: Conference | TrackTemplate | Phase | undefined
  ) => {
    if (!newValue) return;

    const index = Object.keys(selectionState).findIndex((k) => k === name);

    setValue(name, newValue);

    const update = Object.entries(selectionState).map(([key, value], i) => {
      if (i === index) return [name, newValue];
      if (i > index) {
        setValue(key, undefined);
        return [key, undefined];
      }
      return [key, value];
    });

    setSelectionState(Object.fromEntries(update));
  };

  return (
    <form className="space-y-4">
      <Select<Conference>
        label="Select Conference"
        {...register('conference', {
          value: selectionState.conference,
          required: { value: true, message: 'Required' }
        })}
        options={Object.values({
          ...{ '0': { id: 'system', name: 'System' } as Conference },
          ...conferences
        })}
        onChange={(e) => {
          changeHandler(e.currentTarget.name, e.currentTarget.value);
        }}
      />
      <Select<TrackTemplate>
        label="Select Track Template"
        disabled={!selectionState.conference}
        {...register('track', {
          value: selectionState.track,
          required: { value: true, message: 'Required' }
        })}
        options={async () =>
          selectionState.conference
            ? await getTrackTemplatesByConference(selectionState.conference.id)
            : []
        }
        onChange={(e) => {
          changeHandler(e.currentTarget.name, e.currentTarget.value);
        }}
      />
      <Select<Phase>
        label="Select Phase"
        disabled={!selectionState.track}
        {...register('phase', {
          value: selectionState.phase,
          required: { value: true, message: 'Required' }
        })}
        options={async () =>
          selectionState.track ? getPhasesByTrackTemplate(selectionState.track.id) : []
        }
        onChange={(e) => {
          if (e.currentTarget.value) {
            changeHandler(e.currentTarget.name, e.currentTarget.value);
          }
        }}
      />

      <Button
        className="mr-auto"
        disabled={!selectionState.phase || isSubmitting || !isDirty || !isValid}
        onClick={handleSubmit(() => {
          if (selectionState.phase) onSubmit(selectionState.phase);
        })}
      >
        Save
      </Button>
    </form>
  );
}

export default ImportPhaseModal;
