import styled from '@emotion/styled';
import { SecurityService } from '@gbhem/api';
import { Link, NavLink } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import tw from 'twin.macro';

import { Can, useAuthenticatedUser } from '../../providers';
import { Dismissable } from '../Dismissable';
import { ChevronDown, Logo, Support } from '../icons';
import { NotificationViewer } from '../Notifications';
import { Option, OptionsMenu } from '../OptionsMenu';
import { InlineAvatar } from '../UserProfile/UserAvatar';

const NavbarElement = styled.div`
  ${tw`bg-primary text-white flex p-3 gap-2 items-center`}
`;

const Navigation = styled.div`
  ${tw`flex items-center flex-1 space-x-6`}

  a {
    ${tw`border-b-2 border-b-transparent`}

    &.active {
      ${tw`border-b-neutral-1000`}
    }
  }
`;

const Controls = styled(Dismissable)`
  svg {
    ${tw`w-4 h-4`}
  }
`;

const Control = styled.div`
  ${tw`flex items-center gap-2`}

  svg.profile {
    ${tw`w-8 h-8`}
  }
`;

const NameContainer = styled.div`
  ${tw`inline-flex`}
`;

const NotificationContainer = styled.div`
  ${tw`mt-2.5 mr-1.5`}
`;

export function Navbar() {
  const user = useAuthenticatedUser();

  const [, signOut] = useAsyncFn(async () => {
    await SecurityService.logOut();
    window.location.assign('/');
  }, []);

  const openSupport = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <NavbarElement>
      <Navigation>
        <Link to="/" className="flex items-center space-x-2">
          <Logo className="w-44 h-8" />
        </Link>
        <NavLink to="/tracks">Tracks</NavLink>
        <Can do="update" on="Conference">
          <NavLink to="/users">Users</NavLink>
        </Can>
        <Can do="create" on="FormTemplate">
          <NavLink to="/forms">Forms</NavLink>
        </Can>
        <Can do="update" on="Conference">
          <NavLink to="/admin">Admin</NavLink>
        </Can>
      </Navigation>

      <NotificationContainer>
        <NotificationViewer user={user} />
      </NotificationContainer>

      <Controls
        control={
          <Control>
            <InlineAvatar user={user} />
            <b>
              <NameContainer>
                {user.firstName} {user.lastName}
              </NameContainer>
            </b>
            <ChevronDown />
          </Control>
        }
      >
        <OptionsMenu>
          <Link to={`/users/${user.id}`}>
            <Option>My Profile</Option>
          </Link>
          <Option onClick={signOut}>Sign Out</Option>
        </OptionsMenu>
      </Controls>

      <div className="ml-2">
        <Support
          className="w-7 h-7 hover:cursor-pointer"
          onClick={() =>
            openSupport('https://forms.monday.com/forms/47296b3ee126c59d25cb240c6564997b?r=use1')
          }
        />
      </div>
    </NavbarElement>
  );
}

export default Navbar;
