import styled from '@emotion/styled';
import deepEqual from 'fast-deep-equal/react';
import {
  ComponentProps,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useModal } from 'react-modal-hook';
import { Link } from 'react-router-dom';
import {
  CellProps,
  Column,
  FilterProps,
  FilterValue,
  IdType,
  Row,
  useAsyncDebounce,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useMountedLayoutEffect,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import { useEffectOnce, usePrevious, useUpdateEffect } from 'react-use';
import tw from 'twin.macro';

import { Button, Modal, Select } from '../../components';
import { FadeIn } from '../animations';
import { Card } from '../Card';
import { Cancel, Filter, TriangleDown, TriangleUp } from '../icons';
import { Input } from '../Input';
import { TablePagination } from './TablePagination';
import { TableToolbar } from './TableToolbar';

const Container = styled(Card)`
  ${tw`m-4 p-4 bg-neutral-1000 shadow-none flex flex-col relative justify-center`}

  table {
    ${tw`w-full`}
  }

  tr {
    ${tw`border-b border-neutral-600`}
  }

  th {
    ${tw`pb-4 text-xs font-bold text-neutral-300 uppercase`}
  }

  th.sortable {
    svg {
      ${tw`w-2 h-2`}
    }
  }

  td {
    ${tw`py-4 text-sm`}
  }
`;

const Element = styled.table<{ loading?: string }>`
  ${tw`transition-all`}
  ${(p) => (p.loading ? tw`opacity-30` : '')}
`;

const Header = styled.thead`
  ${tw`border-b-2 border-primary sticky`}

  th {
    ${tw`px-1`}
  }

  div {
    input {
      ${tw`text-xs h-auto`}
    }
  }
`;

const SpecialDatumDisplay = styled.td`
  ${tw`text-yellow-600`}
`;

const ColumnHeader = styled.div`
  ${tw`flex space-x-1`}
`;

const RowContainer = styled.tr<{ selected?: boolean }>`
  td {
    ${tw`px-2`}
  }
  ${(p) => (p.selected ? tw`bg-secondary` : '')}
`;

const Up = styled(TriangleUp)`
  ${tw`text-primary`}
`;

const Down = styled(TriangleDown)`
  ${tw`text-primary`}
`;

const SelectionActions = styled.div`
  ${tw`flex items-center`}
`;

export const ColumnLink = styled(Link)`
  ${tw`underline`}
`;

export const ColumnUnderline = styled.p`
  ${tw`underline`}
`;

export type SelectFilterProps = Column & {
  id: string;
  filterValue: string;
  preFilterRows: any[];
  setFilter: (colID: string, filterValue: string) => void;
};

export type LoadResolver<DataType> = (params: {
  page: number;
  size: number;
  filters?: Record<string, any>[];
  relations?: string[];
  isQuickSearch?: boolean;
  orderBy?: Record<string, 'ASC' | 'DESC'>;
}) => Promise<{ data: DataType[]; count: number }>;

type TableProps<DataType extends object> = Omit<ComponentProps<'div'>, 'onSelect'> & {
  columns: Column<DataType>[];
  data?: DataType[];
  count?: number;
  pageIndex?: number;
  pageSize?: number;
  relations?: string[];
  loader?: LoadResolver<DataType>;
  onEdit?: (selection: DataType[]) => void | Promise<void>;
  onSelect?: (selection: DataType[]) => void;
  onDelete?: (selection: DataType[]) => void | Promise<void>;
  header?: string | ReactNode;
  row?: ({ row }: PropsWithChildren<Partial<CellProps<DataType, string>>>) => ReactNode;
  reload?: boolean;
  differentColor?: (datum: DataType) => boolean;
};

export const dateTableFormat = (input: Date | string) => {
  return new Date(input).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit'
  });
};

export function DefaultFilter<DataType extends object = {}>({
  column: { filterValue, setFilter }
}: PropsWithChildren<FilterProps<DataType>>) {
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder={`Search`}
    />
  );
}

export function SelectFilter<DataType>(options: DataType[]) {
  function SelectFilter<DataType extends object = {}>({
    column: { filterValue, setFilter }
  }: PropsWithChildren<FilterProps<DataType>>) {
    return (
      <div className="flex items-center space-x-2">
        <Select
          className="flex-1"
          options={options}
          placeholder={`Select...`}
          defaultValue={filterValue}
          onChange={(e) => setFilter(e.target.value)}
        />
        <Cancel
          className="text-neutral-500 h-3 w-3 cursor-pointer"
          onClick={() => setFilter(undefined)}
        />
      </div>
    );
  }

  return SelectFilter;
}

export function defaultFilter<DataType extends object>(
  rows: Row<DataType>[],
  ids: IdType<DataType>[],
  filterValue: FilterValue
): Row<DataType>[] {
  if (typeof filterValue === 'object') {
    return rows.filter((row) => ids.every((c) => deepEqual(row.values[c], filterValue)));
  }

  return rows.filter((row) =>
    ids.some((id) => `${row.values[id]}`.toLowerCase().includes(String(filterValue).toLowerCase()))
  );
}

export function Table<DataType extends object = {}>({
  columns,
  data: _data,
  count: _count,
  pageIndex: _pageIndex = 0,
  pageSize: _pageSize = 10,
  relations,
  loader,
  onEdit,
  onDelete,
  onSelect,
  header,
  row: Row,
  reload,
  ...props
}: TableProps<DataType>) {
  const [data, setData] = useState(_data || []);
  const [count, setCount] = useState<number>(_count || _data?.length || 0);

  const debounceMs = 600;

  useUpdateEffect(() => {
    if (loader) {
      return;
    }

    setData(_data || []);
    setCount(_data?.length || 0);
  }, [_data]);

  const initialized = useRef(false);

  const [loading, setLoading] = useState(loader !== undefined);

  const isPaginated = useMemo(() => loader !== undefined, [loader]);

  const resetPage = useRef(true);

  if (!_data && !isPaginated) {
    throw new Error(
      'If no data prop is suppled, then a load prop must be supplied to fetch data with.'
    );
  }

  const [showFilter, setShowFilter] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    gotoPage,
    setGlobalFilter,
    selectedFlatRows,
    setPageSize,
    state: { pageIndex, globalFilter, pageSize, filters, sortBy: orderBy, selectedRowIds }
  } = useTable<DataType>(
    {
      columns,
      data,
      initialState: {
        pageIndex: _pageIndex,
        pageSize: _pageSize,
        hiddenColumns: columns.filter((col) => col.isVisible === false)?.map((col) => `${col.id}`)
      },
      defaultColumn: { Filter: DefaultFilter, filter: defaultFilter },
      manualPagination: isPaginated,
      manualSortBy: isPaginated,
      manualFilters: isPaginated,
      manualGlobalFilter: isPaginated,
      autoResetPage: resetPage.current,
      pageCount: count
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useMountedLayoutEffect(() => {
    const selectedDataTypes = Object.keys(selectedRowIds)
      // unable to use index "strings" from selectedRowIds type without utilizing any type
      .map((selectedIndex: any) => data[selectedIndex])
      .filter((dataType) => dataType != null);

    onSelect?.(selectedDataTypes);
  }, [onSelect, selectedRowIds]);

  useEffect(() => {
    if (showFilter) setGlobalFilter('');
  }, [showFilter, setGlobalFilter]);

  const _filters = useMemo(
    () => [
      filters
        .filter((f) => f.id)
        .reduce(
          (filters, f) => ({
            ...filters,
            [f.id]: typeof f.value === 'object' ? f.value.id : f.value
          }),
          {}
        ),
      ...(globalFilter
        ? columns
            .filter(
              (c) =>
                c.id &&
                c.accessor !== 'id' &&
                c.id !== 'id' &&
                !c.disableFilters &&
                !(relations || []).includes(`${c.id}`)
            )
            .map((c) => ({ [`${c.id}`]: globalFilter }))
        : [])
    ],
    [columns, filters, globalFilter, relations]
  );

  const previousPageIndex = usePrevious(pageIndex);
  const previousPageSize = usePrevious(pageSize);

  const previousFilters = usePrevious(_filters);

  const previousOrderBy = usePrevious(orderBy);

  const _load = useAsyncDebounce(
    async (loader: () => Promise<{ data: DataType[]; count: number }>) => {
      setLoading(true);
      const { data, count } = await loader();
      initialized.current = true;

      setData(data);
      setCount(count);

      setLoading(false);
    },
    debounceMs
  );

  useEffectOnce(() => {
    loader &&
      _load(() =>
        loader({
          page: pageIndex,
          size: pageSize,
          filters: _filters,
          relations,
          isQuickSearch: !!globalFilter,
          orderBy: orderBy.reduce((sort, c) => ({ ...sort, [c.id]: !c.desc ? 'ASC' : 'DESC' }), {})
        })
      );
  });

  useEffect(() => {
    if (!isPaginated || !loader || loading) {
      return;
    }

    const filtersChanged = !deepEqual(previousFilters, _filters);
    const orderByChanged = !deepEqual(previousOrderBy, orderBy);

    if (
      !orderByChanged &&
      !filtersChanged &&
      pageIndex === previousPageIndex &&
      pageSize === previousPageSize &&
      !reload
    ) {
      return;
    }

    if (filtersChanged) {
      resetPage.current = true;
    } else {
      resetPage.current = false;
    }

    _load(() =>
      loader({
        page: pageIndex,
        size: pageSize,
        filters: _filters,
        relations,
        isQuickSearch: globalFilter ?? false,
        orderBy: orderBy.reduce((sort, c) => ({ ...sort, [c.id]: !c.desc ? 'ASC' : 'DESC' }), {})
      })
    );
  }, [
    loading,
    loader,
    isPaginated,
    pageIndex,
    globalFilter,
    pageSize,
    previousFilters,
    previousPageIndex,
    previousPageSize,
    previousOrderBy,
    relations,
    orderBy,
    _filters,
    _load,
    reload
  ]);

  const [showConfirmDeleteModal, hideConfirmDeleteModal] = useModal(
    () => (
      <Modal name="Confirm delete" onClose={hideConfirmDeleteModal}>
        {({ close }) => (
          <>
            <p>
              Are you sure you want to delete {selectedFlatRows.length} item(s)? This cannot be
              undone.
            </p>
            <div className="flex space-x-4">
              <Button
                onClick={async () => {
                  await onDelete?.(selectedFlatRows.map((r) => r.original));
                  close();
                }}
              >
                Yes, delete
              </Button>
              <Button inverted onClick={() => close()}>
                Cancel
              </Button>
            </div>
          </>
        )}
      </Modal>
    ),
    [selectedFlatRows]
  );

  return (
    <Container {...props}>
      {header !== undefined && <div className="font-bold my-1">{header}</div>}

      {selectedFlatRows.length && (onEdit || onDelete) ? (
        <SelectionActions>
          {onEdit && (
            <Button onClick={() => onEdit(selectedFlatRows.map((r) => r.original))}>
              Edit ({selectedFlatRows.length})
            </Button>
          )}
          {onDelete && (
            <Button onClick={showConfirmDeleteModal}>Delete ({selectedFlatRows.length})</Button>
          )}
        </SelectionActions>
      ) : null}

      <div className="flex space-x-2 items-center border-b border-primary w-full mt-2 mb-3 py-3 space-y-2">
        <TableToolbar disable={showFilter} filterTerm={globalFilter} onFilter={setGlobalFilter}>
          <Filter
            className="h-5 w-5 text-neutral-500 hover:text-primary cursor-pointer"
            onClick={() => setShowFilter(!showFilter)}
          />
        </TableToolbar>
      </div>

      <Element {...getTableProps()} loading={loading ? 'loading' : ''}>
        <Header>
          {headerGroups.map((group) => (
            <tr {...group.getHeaderGroupProps()}>
              {group.headers.map((column) => (
                <th
                  key={column.id}
                  className={!column.disableSortBy ? 'sortable space-y-2' : 'space-y-2'}
                >
                  <ColumnHeader {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <span>
                      {!column.disableSortBy ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <Down className="mt-1" />
                          ) : (
                            <Up className="mt-1" />
                          )
                        ) : (
                          <>
                            <Up />
                            <Down />
                          </>
                        )
                      ) : null}
                    </span>
                    <span>{column.render('Header')}</span>
                  </ColumnHeader>
                  {showFilter && column.canFilter && column.Filter && column.render('Filter')}
                </th>
              ))}
            </tr>
          ))}
        </Header>

        <tbody {...getTableBodyProps()} className={`${loading && `opacity-50`}`}>
          {page.map((row) => {
            prepareRow(row);

            return (
              <Fragment key={row.id}>
                <RowContainer {...row.getRowProps()} selected={row.isSelected}>
                  {row.cells.map((cell) => {
                    if (props.differentColor && props.differentColor(row.original))
                      return (
                        <SpecialDatumDisplay {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </SpecialDatumDisplay>
                      );
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </RowContainer>
                {row.isExpanded && Row ? Row({ row }) : null}
              </Fragment>
            );
          })}

          {!loading && !data.length && (
            <tr className="p-2">
              <td align="center" colSpan={columns.length}>
                <span className="text-xl">No results</span>
              </td>
            </tr>
          )}
        </tbody>
      </Element>
      {loading && (
        <FadeIn
          className={`text-xl text-neutral-500 w-full h-full flex items-center justify-center
            ${initialized.current ? 'absolute' : 'h-96 my-10'}`}
        >
          Loading...
        </FadeIn>
      )}

      <TablePagination
        total={count}
        currentPage={pageIndex}
        pageSize={pageSize}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        onChangeSize={setPageSize}
      />
    </Container>
  );
}

export default Table;
