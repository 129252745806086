import { District, TrackTemplate, User, UserService } from '@gbhem/api';
import { useEffect, useMemo, useState } from 'react';
import { useAsync, useAsyncFn } from 'react-use';

import { SystemRole, useAuthenticatedUser, useRoles, useTrackStore } from '../../providers';
import { useUserInfoStore } from '../../providers/UserProfileProvider/UserInfoProvider';
import { ErrorText, Select } from '..';
import { Button } from '../Button';
import { LocationDefinition, LocationDetail, PermissionsMap } from './LocationPermissions';

interface AddTrackProperties {
  user: User;
  close: (force?: boolean | undefined) => Promise<void>;
}

const { getLocationDetails } = UserService;

export function AddTrack({ user, close }: AddTrackProperties) {
  const [templates, setTemplates] = useState<TrackTemplate[]>();
  const [advisor, setAdvisor] = useState<User>();
  const [track, setTrack] = useState<TrackTemplate>();

  const [hasDistrict, setHasDistrict] = useState<boolean>(true);
  const [adminDistricts, setAdminDistricts] = useState<LocationDetail[]>([]);
  const [addDistrict, setAddDistrict] = useState<LocationDetail>();

  const { system } = useRoles();
  const advisorRole = useMemo(() => system.find((r) => r.name === 'Advisor'), [system]);

  const currentUser = useAuthenticatedUser();

  const { addLocationPermission, addTrackInstance } = useUserInfoStore();

  const { tracks } = useTrackStore();

  const [, fetchAdminLocations] = useAsyncFn(async () => {
    const locationDetails: LocationDetail[] = [];
    const locationMap = await getLocationDetails(currentUser.id);

    Object.keys(locationMap).forEach((locType) => {
      locationMap[locType as keyof PermissionsMap].forEach((entities: LocationDefinition[]) => {
        locType = locType === 'episcopalarea' ? 'Episcopal Area' : locType;
        entities.forEach(({ entity, active, primary, permissionId }: LocationDefinition) => {
          locationDetails.push({
            type: `${locType.charAt(0).toUpperCase() + locType.slice(1)}`,
            entity,
            active,
            primary,
            permissionId
          });
        });
      });
    });

    setAdminDistricts(locationDetails.filter((loc) => loc.type === 'District'));
  }, []);

  useAsync(async () => {
    // TODO this method needs better typing - need to fix
    const locations = await getLocationDetails(user.id);

    if (
      !locations ||
      !locations.district ||
      locations.district[0].filter((d: District) => d.active).length === 0
    ) {
      setHasDistrict(false);
      await fetchAdminLocations();
    }
  }, [user]);

  useEffect(() => {
    setTemplates(Object.values(tracks).filter((track) => !track.disabled));
  }, [tracks]);

  if (!templates?.length || !advisorRole) {
    return (
      <>
        {templates?.length === 0 && <p>No Available Templates</p>}

        {!advisorRole && <p>System role error has occurred, please try again later.</p>}
      </>
    );
  }

  return (
    <form
      className="space-y-4"
      onSubmit={async (e) => {
        e.preventDefault();

        if (!user || !track || !advisor || (!hasDistrict && !addDistrict)) {
          return;
        }

        if (!hasDistrict && addDistrict) {
          await addLocationPermission({
            userIds: [user.id],
            entity: addDistrict.type,
            entityId: addDistrict.entity.id
          });
        }

        const roleId = system.find((r) => r.name === SystemRole.Advisor)?.id;
        if (roleId) {
          await addTrackInstance(track, advisor, roleId);
        }
        close();
      }}
    >
      <p>Assign a new track to {user.firstName}.</p>
      <Select
        placeholder="Select a track..."
        options={templates}
        name="Track Template"
        onChange={(e) => setTrack(e.target.value)}
      />
      <Select<User>
        name="Advisor"
        placeholder="Select an advisor..."
        instructions="Begin by typing full name of intended Advisor"
        options={async (searchTerm) => {
          if (!searchTerm) {
            return;
          }

          return (
            await UserService.query({
              conditions: [
                {
                  name: searchTerm,
                  inactive: false
                },
                {
                  firstName: searchTerm,
                  inactive: false
                },
                {
                  lastName: searchTerm,
                  inactive: false
                }
              ],
              relations: ['roles'],
              limit: 15
            })
          ).data.filter((u: User) => u.roles.find((r) => r.id === advisorRole.id));
          // filtering on Advisor role for query response in advisor Select
        }}
        optionLabel={(advisor) => `${advisor.firstName} ${advisor.lastName} (${advisor.email})`}
        onSelect={(e) => setAdvisor(e.currentTarget.value)}
      />
      {!hasDistrict ? (
        <div>
          <ErrorText className="pb-2">
            The selected participant needs to have a district added to their profile.
          </ErrorText>
          {adminDistricts && adminDistricts.length ? (
            <Select<LocationDefinition>
              className="grow"
              name="Location"
              options={adminDistricts}
              optionLabel={(location) => `${location.entity.name}`}
              onChange={(e) => setAddDistrict(e.target.value)}
            />
          ) : (
            <p>
              You currently do not have a district on your profile. Please contact an admin to
              resolve this issue to continue.
            </p>
          )}
        </div>
      ) : null}
      <Button type="submit" disabled={!track || !advisor || (!hasDistrict && !addDistrict)}>
        Add Track
      </Button>
    </form>
  );
}

export default AddTrack;
