import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';

import { Button, Cancel, Input, Plus } from '../../components';
import { FieldSchemaPropertiesEditorProperties } from '.';

export function SelectEditor({ properties, onChange }: FieldSchemaPropertiesEditorProperties) {
  const {
    control,
    formState: { isValid },
    getValues,
    watch
  } = useForm({
    mode: 'all',
    defaultValues: { options: properties.options || [''] }
  });
  const { fields: options, append, remove } = useFieldArray({ control, name: 'options' });
  const watchOptions = watch('options');

  useUpdateEffect(
    () =>
      onChange({ ...properties, options: getValues('options').filter((option: string) => option) }),
    [JSON.stringify(watchOptions)]
  );

  return (
    <div className="flex flex-col space-y-2 my-4">
      {options.map((o, i) => (
        <Controller
          key={o.id}
          name={`options.${i}`}
          rules={{
            required: true,
            minLength: 1
          }}
          render={({ field }) => (
            <div className="flex flex-1 space-x-2 items-center">
              <Input inline {...field} className="flex-1" />
              <Cancel className="h-4 w-4 text-neutral-500" onClick={() => remove(i)} />
            </div>
          )}
          control={control}
        />
      ))}
      <div>
        <Button disabled={!isValid} onClick={() => append('')}>
          <Plus /> Option
        </Button>
      </div>
    </div>
  );
}

export default SelectEditor;
