import styled from '@emotion/styled';
import { MentorAssignment, User } from '@gbhem/api';
import { useEffect, useState } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { Can } from '../../providers';
import { useUserRelationshipStore } from '../../providers/UserProfileProvider/UserRelationshipProvider';
import { Button } from '../Button';
import { Cancel, Plus } from '../icons';
import { Loader } from '../Loader';
import { AddMentorModal } from '../MentorModals';
import { Modal, ModalProps } from '../Modal';

const MentorContainer = styled.div`
  ${tw`flex flex-wrap flex-shrink items-start border rounded-sm border-neutral-400 space-x-2 mx-1 my-1`}

  max-height: 10rem;
  max-width: 26rem;
  min-height: 3rem;
  min-width: 26rem;

  svg {
    ${tw`w-3 h-3`}
  }
`;

const MentorHeader = styled.div`
  ${tw`flex justify-between text-sm font-bold my-5 mx-4 w-full`}
`;

const MentorContent = styled.p`
  ${tw`text-xs my-3 mx-2`}
`;

interface MentorsProps {
  mentee: User;
}

export const Mentors = ({ mentee }: MentorsProps) => {
  const { mentorAssignments } = useUserRelationshipStore();

  const [shouldRemove, setShouldRemove] = useState<MentorAssignment>();

  const [showAddMentorModal, hideAddMentorModal] = useModal(() => (
    <AddMentorModal
      mentee={mentee}
      mentorAssignments={mentorAssignments}
      onClose={hideAddMentorModal}
    />
  ));

  const [showRemoveMentorModal, hideRemoveMentorModal] = useModal(
    () => (
      <RemoveMentorModal
        mentee={mentee}
        assignment={shouldRemove}
        onClose={() => {
          setShouldRemove(undefined);
          hideRemoveMentorModal();
        }}
      />
    ),
    [shouldRemove]
  );

  useEffect(() => {
    if (shouldRemove) {
      showRemoveMentorModal();
    }
  }, [shouldRemove, showRemoveMentorModal]);

  if (!mentorAssignments) return <Loader />;

  return (
    <>
      <h2 className="font-bold my-4">Mentors </h2>

      {mentorAssignments.length === 0 && <p>There are no mentors assigned to this user</p>}
      <div className="flex justify-start flex-wrap">
        {mentorAssignments.map((mentorAssignment) => {
          const mentor = mentorAssignment.mentor;
          return (
            <MentorContainer key={mentor.id}>
              <MentorHeader>
                <h3 className="pr-4">{`${mentor.firstName} ${mentor.lastName}`}</h3>
                <Can do="delete" on="MentorAssignment">
                  <Cancel
                    className="ml-2 mt-0.5 w-3 h-3 cursor-pointer"
                    onClick={() => {
                      setShouldRemove(mentorAssignment);
                    }}
                  />
                </Can>
              </MentorHeader>
              <p>
                <MentorContent>
                  {mentorAssignment.mentor.email && (
                    <div>{`Email: ${mentorAssignment.mentor.email}`}</div>
                  )}
                  Assigned {new Date(mentorAssignment.createdAt || '').toLocaleDateString()}
                </MentorContent>
              </p>
            </MentorContainer>
          );
        })}
      </div>
      <Can do="create" on="MentorAssignment">
        <Button className="ml-auto" onClick={showAddMentorModal}>
          <Plus /> Assign
        </Button>
      </Can>
    </>
  );
};

type RemoveMentorModalProps = ModalProps & {
  assignment?: MentorAssignment;
  mentee: User;
};

const RemoveMentorModal = ({ assignment, mentee, onClose }: RemoveMentorModalProps) => {
  const { removeMentors } = useUserRelationshipStore();
  return (
    <Modal name="Confirm Delete" onClose={onClose}>
      {({ close }) =>
        assignment && (
          <>
            {`Are you sure you want to unassign ${assignment.mentor.firstName} as a mentor?`}
            <div className="flex gap-4">
              <Button onClick={onClose} inverted>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  await removeMentors(mentee, [assignment.mentor]);
                  close();
                }}
              >
                Confirm
              </Button>
            </div>
          </>
        )
      }
    </Modal>
  );
};
