import {
  District,
  DistrictService,
  Permission,
  PhaseService,
  // StepContentTemplate,
  StepInstance as StepInstanceModel,
  StepService,
  StepTemplate,
  TrackInstance as TrackInstanceModel,
  TrackService,
  User,
  UserService
} from '@gbhem/api';
import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAsync } from 'react-use';

import {
  Loader,
  PhaseColumn as Phase,
  PhasesContainer as Phases,
  prioritize,
  Select,
  StepInstance,
  StepsContainer as Steps,
  StepStatus,
  SubNavbar
} from '../../components';
import { InlineAvatar } from '../../components/UserProfile/UserAvatar';
import { SystemRole, useAuthenticatedUser, useRoles } from '../../providers';
import {
  buildPhasePrereqs,
  buildStringIds,
  buildStringIdsByKey,
  createEntityObject
} from '../../utils';

type TrackStatusOptions = 'Unavailable' | 'Open' | 'Completed' | 'Withdrawn' | 'Discontinued';

const trackStatusOptions = [
  'Unavailable',
  'Open',
  'Completed',
  'Withdrawn',
  'Discontinued'
] as TrackStatusOptions[];

async function canAccess(track: TrackInstanceModel, user: User) {
  const isParticipant = track.participant.id === user.id;
  const isAssignee = track.assignees.some((a) => a.assignee.id === user.id);

  const isSuperAdmin = !!user.roles.find((r) =>
    [SystemRole.Superadmin.toString()].includes(r.name)
  );

  const isConferenceAdmin = !!user.roles.find((r) =>
    [SystemRole.ConferenceAdmin.toString()].includes(r.name)
  );

  // TODO(jesse@fortyau.com) revisit this. The feedback from GBHEM is that
  // participants should be able to see all steps on their track but we implemented this
  // logic in the past and (may?) want to revisit it.
  // https://docs.google.com/document/d/1W4uq1bW_CLJWd62c0AmfcKTn7DoBzp741iGh_RVsxEA/edit

  // const assignedAnyAction = step.step.actions.some((a) =>
  //   user.roles.find((r) => r.id === a.role.id)
  // );
  // const assignedAnyContent = step.step.contents.some((c) =>
  //   user.roles.find((r) => r.id === c.role.id)
  // );

  if (isParticipant || isSuperAdmin) {
    // will return true if either, but for this use case we need to know which.
    // return assignedAnyAction || assignedAnyContent;
    return true;
  }

  if (!isAssignee && isConferenceAdmin) {
    const conferenceId = track.trackTemplate.conference?.id;

    const hasConferenceUpdatePermission = !!user.roles.find((r) =>
      r.policies.find((p) => p.subject.includes('Conference') && p.action.includes('update'))
    );

    // if track is system track
    if (hasConferenceUpdatePermission && !conferenceId) {
      const filteredConferenceAdminPermissions = user.permissions.filter(
        (permission) => permission.active && !permission.denial
      );

      const filteredParticipantPermissions = track.participant.permissions.filter(
        (permission) => permission.active && !permission.denial
      );

      const participantsDistrictIds = filteredParticipantPermissions
        .filter((permission) => permission.entity === 'District')
        .map((districtPermission) => districtPermission.entityId);

      const participantsDistricts = await DistrictService.getDistrictsByIds(
        participantsDistrictIds
      );

      const isDistrictInConference = (
        participantPermission: Permission,
        conferenceAdminPermission: Permission,
        participantsDistricts: District[]
      ) => {
        if (participantPermission.entity !== 'District') {
          return false;
        }

        const matchingConference = participantsDistricts.find(
          (district) => district.id === participantPermission.entityId
        )?.conference;

        return matchingConference?.id === conferenceAdminPermission.entityId;
      };

      // Can only access trackInstance if conference admin is in the same conference as the participant
      const isConferenceAdminInParticipantsConference = filteredConferenceAdminPermissions.some(
        (conferenceAdminPermission) =>
          conferenceAdminPermission.entity === 'Conference' &&
          filteredParticipantPermissions.some(
            (participantPermission) =>
              (participantPermission.entity === 'Conference' &&
                conferenceAdminPermission.entityId === participantPermission.entityId) ||
              isDistrictInConference(
                participantPermission,
                conferenceAdminPermission,
                participantsDistricts
              )
          )
      );

      return isConferenceAdminInParticipantsConference;
    }

    return (
      hasConferenceUpdatePermission &&
      user.permissions.some((p) => p.entityId === conferenceId && p.entity === 'Conference')
    );
  }

  return true;
}

export function TrackInstance() {
  const { id } = useParams<{ id: string }>();
  const authUser = useAuthenticatedUser();
  const { system, hasTrackOverrideRole } = useRoles();

  const [instance, setInstance] = useState<TrackInstanceModel>();
  const [stepInstanceMap, setStepInstanceMap] = useState<Record<string, StepInstanceModel>>();
  const [isAdvisor, setIsAdvisor] = useState<boolean>();
  const [isParticipant, setIsParticipant] = useState<boolean>();
  const [hasAccess, setHasAccess] = useState<boolean>(true);

  const _hasTrackOverrideRole = useMemo(
    () =>
      instance
        ? hasTrackOverrideRole(
            authUser,
            instance.participant,
            instance.trackTemplate.conference
              ? createEntityObject(instance.trackTemplate.conference).id
              : undefined
          )
        : false,
    [hasTrackOverrideRole, authUser, instance]
  );

  const isValidStep = (stepTemplates: StepTemplate[], phaseStepTemplate: StepTemplate) => {
    let isValid: boolean = false;
    for (let i = 0; i < stepTemplates.length; i++) {
      const template: StepTemplate = stepTemplates[i];
      if (template.id === String(phaseStepTemplate)) {
        isValid = true;
        break;
      }
    }
    return isValid;
  };

  useAsync(async () => {
    await TrackService.verifyInstanceSteps(id);
    // Call the basic TrackInstance to build from.
    const _instance = await TrackService.getTrackInstance(id);
    // Call the next level of items that we want to capture for the instance.
    const [trackTemplate, stepInstances] = await Promise.all([
      TrackService.getTrackTemplate(String(_instance.trackTemplate)),
      StepService.getStepInstances(buildStringIds(_instance.stepInstances))
    ]);
    // Get the remaning information needed to build out the track instance.
    const [_phases, stepTemplates, _participant, _assignees] = await Promise.all([
      PhaseService.getPhases(buildStringIds(trackTemplate.phases)),
      StepService.getStepTemplates(buildStringIdsByKey(stepInstances, 'stepTemplate')),
      UserService.getUser(String(_instance.participant)),
      TrackService.getTrackAssignees(buildStringIds(_instance.assignees))
    ]);
    const _participantPermissions = await UserService.getUserPermissions(_participant.id);

    // Loop over step tempaltes to verify that each template is pulled correctly
    // If a step instance is missing a step template, the step template will just be pulled as a string id,
    // and not as a StepTemplate.
    // We want to remove these hanging step templates because this track has already been completed.
    const verifiedStepTemplates: StepTemplate[] = [];
    stepTemplates.forEach((template) => {
      if (template as StepTemplate) {
        verifiedStepTemplates.push(template);
      }
    });

    // Loop over each step template to clean up the pre-reqs for that step.
    // Like above, a step template might have hanging pre-reqs from invalid steps, so we want to remove those.
    verifiedStepTemplates.forEach((template: StepTemplate, index: number) => {
      const cleanPrereqs: StepTemplate[] = [];
      template.prerequisites.forEach((prereq) => {
        if (isValidStep(verifiedStepTemplates, prereq)) {
          cleanPrereqs.push(prereq);
        }
      });
      verifiedStepTemplates[index].prerequisites = cleanPrereqs;
    });

    // Loop over all the phases, to make sure they only have valid step templates.
    // Again, a step template might have hanging steps, so we need to remove these from the phases.
    _phases.forEach((phase, phaseIndex) => {
      const cleanedPhaseStepTemplates: StepTemplate[] = [];
      phase.stepTemplates.forEach((template) => {
        if (isValidStep(verifiedStepTemplates, template)) {
          cleanedPhaseStepTemplates.push(template);
        }
      });
      _phases[phaseIndex].stepTemplates = cleanedPhaseStepTemplates;
    });

    // Build the prereq requirments.
    const prereqTemplates = buildPhasePrereqs(verifiedStepTemplates);
    // Reassebmle the phases with the correct prereq information.
    _phases.forEach((phase, phaseIndex) => {
      phase.stepTemplates.forEach((stepTemp, stIndex) => {
        prereqTemplates.forEach((prereq) => {
          if (prereq.id === String(stepTemp)) {
            _phases[phaseIndex].stepTemplates[stIndex] = prereq;
          }
        });
      });
    });

    // Rebuild the step instances with the correct step template information.
    verifiedStepTemplates.forEach((stepTemp) => {
      stepInstances.forEach((stepInst, stepInstIndex) => {
        if (String(stepInst.stepTemplate) === stepTemp.id) {
          stepInstances[stepInstIndex].stepTemplate = stepTemp;
        }
      });
    });

    _phases.forEach((phase, phaseIndex) => {
      if (phase.stepTemplates.length === 0) delete _phases[phaseIndex];
    });

    //Finally combine alll the information into the main entity for the app to use.
    trackTemplate.phases = _phases;
    _participant.permissions = _participantPermissions;
    _instance.stepInstances = stepInstances;
    _instance.trackTemplate = trackTemplate;
    _instance.participant = _participant;
    _instance.assignees = _assignees;

    if (!(await canAccess(_instance, authUser))) {
      setHasAccess(false);
      return;
    }

    const stepInstanceMap = _instance.stepInstances.reduce<Record<string, StepInstanceModel>>(
      (map, stepInstance) => {
        map[stepInstance.stepTemplate.id] = stepInstance;
        return map;
      },
      {}
    );

    setInstance(_instance);
    setStepInstanceMap(stepInstanceMap);
    setIsParticipant(_instance.participant.id === authUser.id);

    const advisorRole = system.find((r) => r.name === SystemRole.Advisor);

    if (advisorRole) {
      setIsAdvisor(
        _instance.assignees.some(
          (a) => a.role.id === advisorRole.id && a.assignee.id === authUser.id
        )
      );
    }
  }, [id, system]);

  if (!hasAccess) {
    return <div className="text-center mt-5">You do not have permission to access this track</div>;
  }

  if (!instance || !stepInstanceMap) {
    return <Loader message="Loading your track..." />;
  }

  return (
    <>
      <SubNavbar>
        <div className="flex justify-between px-2 py-3 w-full">
          <div className="flex flex-col space-y-2">
            <div className="font-semibold flex flex-1 space-x-2 items-center">
              <InlineAvatar user={instance.participant} />
              <Link className="hover:underline" to={`/users/${instance.participant.id}`}>
                {instance.participant.firstName} {instance.participant.lastName}
              </Link>
            </div>
            <div className="flex items-center space-x-2 font-semibold">
              <div className="text-neutral-500 flex-1 text-sm">{instance.trackTemplate.name}</div>
              <div className="text-xs rounded-full py-1 px-2 border border-netural">
                {instance.status}
              </div>
            </div>
          </div>
          <div>
            {!isParticipant && (isAdvisor || _hasTrackOverrideRole) && (
              <Select
                name="Status"
                value={instance.status}
                options={trackStatusOptions}
                onChange={async (e) => {
                  if (!e.target.value) {
                    return;
                  }

                  await TrackService.updateInstance({ id: instance.id, status: e.target.value });
                  setInstance({ ...instance, status: e.target.value });
                }}
              />
            )}
          </div>
        </div>
      </SubNavbar>
      <Phases>
        {instance.trackTemplate.phases
          .sort((p1, p2) => p1.order - p2.order)
          .map((phase) => (
            <Phase key={phase.id}>
              <div className="flex items-center justify-between p-2 sticky">
                <div className="text-lg font-semibold">
                  {phase.order}. {phase.name}
                </div>
              </div>
              <Steps>
                {prioritize(phase.stepTemplates)
                  .filter((s) => {
                    const stepInstance = stepInstanceMap[s.id];
                    const isDisplayStep =
                      ([
                        StepStatus.Completed.toString(),
                        StepStatus.PendingReview.toString()
                      ].includes(stepInstance.status.toString()) &&
                        s.inactive) ||
                      !s.inactive;

                    return stepInstance && isDisplayStep;
                  })
                  .map((s) => (
                    <StepInstance key={s.id} instance={stepInstanceMap[s.id]} />
                  ))}
              </Steps>
            </Phase>
          ))}
      </Phases>
    </>
  );
}

export default TrackInstance;
