import styled from '@emotion/styled';
import { User } from '@gbhem/api';
import { useRef } from 'react';
import tw from 'twin.macro';

import { Can } from '../../providers';
import { useUserInfoStore } from '../../providers/UserProfileProvider/UserInfoProvider';
import { Button } from '../Button';
import { Cancel } from '../icons';
import { Control as InputControl } from '../Input';

const Alias = styled.div`
  ${tw`flex items-center text-xs py-1 px-3 bg-tan rounded-full cursor-pointer space-x-2`}

  svg {
    ${tw`w-2 h-2`}
  }
`;

interface AliasesProps {
  user: User;
}

export function Aliases({ user }: AliasesProps) {
  const { addAlias, removeAlias } = useUserInfoStore();

  const newAliasInput = useRef<HTMLInputElement>(null);

  return (
    <div className="space-y-4">
      <Can do="create" on="User">
        <p className="text-xs">
          Add known aliases for {user.firstName} here.{' '}
          <b>Aliases are only visible by administrators.</b>
        </p>
      </Can>
      <div className="flex space-x-2">
        {user.aliases?.map((a: any) => (
          <Alias key={a.id}>
            <div>{a.alias}</div>
            <Cancel
              onClick={async () => {
                await removeAlias(user.id, a.id);
              }}
            />
          </Alias>
        ))}
      </div>
      <Can do="create" on="User">
        <form
          className="flex items-center space-x-2"
          onSubmit={async (e) => {
            e.preventDefault();
            if (!newAliasInput.current) {
              return;
            }

            await addAlias(user.id, newAliasInput.current.value);
            newAliasInput.current.value = '';
          }}
        >
          <InputControl placeholder="Type alias name here" className="w-full" ref={newAliasInput} />
          <Button>+ Add Alias</Button>
        </form>
      </Can>
    </div>
  );
}

export default Aliases;
