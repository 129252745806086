"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunicationService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class CommunicationService {
    static onBounce() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/communication/bounce',
        });
    }
    static onComplaint() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/communication/complaint',
        });
    }
    static onSmsResponse() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/communication/sms-response',
        });
    }
    static triggerSmsVerificationProcess(userId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/communication/{userId}/trigger-sms-verification',
            path: {
                'userId': userId,
            },
        });
    }
}
exports.CommunicationService = CommunicationService;
