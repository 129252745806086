import { SVGProps } from 'react';

export function Locked(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={10}
      height={14}
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M8.472 5.79h-.136V3.781A3.34 3.34 0 005 .445a3.34 3.34 0 00-3.336 3.337V5.79h-.136c-.71 0-1.286.576-1.286 1.286v5.196c0 .71.576 1.286 1.286 1.286h6.943c.71 0 1.286-.576 1.286-1.286V7.076c0-.71-.576-1.286-1.285-1.286zM2.923 3.781c0-1.145.932-2.076 2.077-2.076s2.076.931 2.076 2.076V5.79H2.923V3.782zm2.614 6.478v1.35a.537.537 0 01-1.074 0v-1.35A1.26 1.26 0 015 7.857a1.262 1.262 0 01.537 2.402z" />
    </svg>
  );
}

export default Locked;
