import styled from '@emotion/styled';
import { FC, useState } from 'react';
import tw from 'twin.macro';

import { Input } from '../../components';
import { Field as FieldModel, FieldType, Properties } from '../../models';
import { FadeIn } from '../animations';
import { Field, Widget } from '../Field';
import { shouldDelegateControl } from '../FormEditor';
import { Cancel, DragHandle, Edit, GrowCell, Remove, ShrinkCell } from '../icons';
import { FieldUploadEditor } from './FieldUploadEditor';
import { LinkEditor } from './LinkEditor';
import { ListEditor } from './ListEditor';
import { ParagraphEditor } from './ParagraphEditor';
import { RadioEditor } from './RadioEditor';
import { SelectEditor } from './SelectEditor';
import { TextAreaEditor } from './TextAreaEditor';

const readOnlyWidgets = [FieldType.Separator];

export type FieldSchemaEditorProperties = FieldModel & {
  onChange: (field: Partial<FieldModel>) => void;
};

export type FieldSchemaPropertiesEditorProperties = {
  properties: Properties;
  onChange: (properties: Properties) => void;
};

const FieldSchemaPropertiesEditor = ({
  type,
  properties,
  onChange
}: Omit<FieldSchemaEditorProperties, 'id' | 'name'>) => {
  let Component: FC<FieldSchemaPropertiesEditorProperties>;

  switch (type) {
    case FieldType.TextArea:
      Component = TextAreaEditor;
      break;
    case FieldType.Paragraph:
      Component = ParagraphEditor;
      break;
    case FieldType.Link:
      Component = LinkEditor;
      break;
    case FieldType.Select:
      Component = SelectEditor;
      break;
    case FieldType.Radio:
      Component = RadioEditor;
      break;
    case FieldType.File:
      Component = FieldUploadEditor;
      break;
    case FieldType.List:
      Component = ListEditor;
      break;
    default:
      return <></>;
  }

  return (
    <Component
      properties={properties}
      onChange={(changes) => onChange({ properties: { ...properties, ...changes } })}
    />
  );
};

const FieldSchemaEditor: FC<FieldSchemaEditorProperties> = ({
  name,
  type,
  properties,
  children,
  onChange
}) => {
  const { required, placeholder, instructions } = properties;

  return (
    <FadeIn className="z-10 shadow space-y-2 bg-neutral-1000 p-4 border border-neutral-600 w-full font-xs">
      <div className="flex text-sm items-center space-x-2">
        <b className="capitalize">{type}</b>
        <div>
          {required !== undefined && (
            <Input
              type="checkbox"
              checked={required || false}
              name="required"
              onChange={() => onChange({ properties: { ...properties, required: !required } })}
            />
          )}
        </div>
      </div>
      <Input
        type="text"
        value={name}
        name="Name"
        onChange={(e) => onChange({ name: e.target.value })}
        placeholder="Enter field name here..."
        autoComplete="none"
      />
      {placeholder !== undefined && (
        <Input
          type="text"
          value={placeholder}
          name="Placeholder"
          onChange={(e) =>
            onChange({
              properties: { ...properties, placeholder: e.target.value }
            })
          }
          placeholder="Enter placeholder here..."
          autoComplete="none"
        />
      )}
      {instructions !== undefined && (
        <Input
          type="text"
          value={instructions || ''}
          name="Instructions"
          onChange={(e) =>
            onChange({
              properties: { ...properties, instructions: e.target.value }
            })
          }
          placeholder="Enter instructions here..."
          autoComplete="none"
        />
      )}
      <div className="my-4">
        <hr className="my-4 border-neutral-600" />
        <FieldSchemaPropertiesEditor type={type} properties={properties} onChange={onChange} />
      </div>
      {children}
    </FadeIn>
  );
};

const FieldEditorContainer = styled.div<{ preview: string; editing: string }>`
  ${tw`relative border-2 border-transparent`}

  min-height: 3.67rem;

  .controls {
    ${tw`cursor-pointer text-neutral-300 space-x-1 items-center hidden`}

    svg {
      ${tw`h-4 w-4`}
    }
  }

  :hover {
    > * > .controls {
      ${tw`flex`}
    }
  }

  ${(p) =>
    p.preview &&
    tw`bg-neutral-800 border-dashed border-neutral-600 opacity-30 cursor-move
  pointer-events-none`}

  ${(p) => p.editing && tw`bg-neutral-800`}
`;

// TODO(jesse@fortyau.com) consider putting this animation back in when
// form drag and drop feature is fully stabilized
// ${(p) => p.preview && growInAnimation()}

const Controls = styled.div`
  ${tw`uppercase font-semibold text-neutral-300 flex text-sm space-x-2`}

  font-size: 0.6rem;
`;

interface FieldEditorProperties {
  schema: FieldModel;
  span: number;
  onChange: (schema: FieldModel) => void;
  onResize: (span: number) => void;
  onRemove: () => void;
  open?: boolean;
  preview: boolean;
  target: Widget;
  disabled?: boolean;
}

export const FIELD_PREVIEW_ID = 'field-preview';

export function FieldEditor({
  onChange,
  onResize,
  onRemove,
  span,
  schema: _schema,
  open = false,
  preview,
  target,
  disabled,
  ...props
}: FieldEditorProperties) {
  const canEdit = !readOnlyWidgets.find((w) => w === _schema?.type);
  const [isEditing, setIsEditing] = useState(open && canEdit);
  const [schema, setSchema] = useState(_schema);

  if (!schema) {
    return <></>;
  }

  return (
    <FieldEditorContainer
      preview={preview ? 'true' : ''}
      {...(preview ? { id: FIELD_PREVIEW_ID } : {})}
      editing={isEditing ? 'true' : ''}
    >
      <Controls>
        {!disabled ? <DragHandle className="cursor-move" /> : null}
        <div>{schema.type}</div>
        {!disabled ? (
          <div className="controls">
            <ShrinkCell onClick={() => onResize(span > 1 ? span - 1 : span / 2)} />
            <GrowCell onClick={() => onResize(span < 1 ? span * 2 : span + 1)} />
            {canEdit && <Edit onClick={() => setIsEditing(!isEditing)} />}
            <Remove onClick={onRemove} />
          </div>
        ) : null}
      </Controls>

      <Field
        {...schema}
        {...props}
        target={target}
        editable
        {...(shouldDelegateControl(schema.type as FieldType) ? { onChange } : {})}
        onDragStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />

      {isEditing && (
        <div className="relative">
          <div
            draggable={true}
            onDragStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className="absolute w-full mt-4 pb-20 z-10"
            ref={(ref) => ref?.scrollIntoView({ block: 'end' })}
          >
            <FieldSchemaEditor
              {...schema}
              onChange={(s) => {
                setSchema({ ...schema, ...s });
                onChange({ ...schema, ...s });
              }}
            >
              <Cancel
                className="absolute cursor-pointer top-4 right-4 fill-current text-neutral-600 h-3 w-3"
                onClick={() => setIsEditing(false)}
              />
            </FieldSchemaEditor>
          </div>
        </div>
      )}
    </FieldEditorContainer>
  );
}

export default FieldEditor;
