import { SVGProps } from 'react';

export function Search(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path
        clipRule="evenodd"
        d="M36.906 32.954l12.262 12.264c1.098 1.094 1.098 2.877.067 3.904a2.85 2.85 0 01-1.985.822 2.742 2.742 0 01-1.986-.822L32.935 36.925c-3.631 2.81-8.018 4.18-12.4 4.18-5.275 0-10.483-1.989-14.524-6.03-8.015-8.014-8.015-21.031 0-29.046C10.051 1.986 15.26 0 20.534 0c5.274 0 10.482 1.988 14.523 6.03 7.4 7.329 8.015 18.909 1.85 26.924zm-26.924-1.78c2.877 2.807 6.578 4.383 10.619 4.383 3.974 0 7.809-1.507 10.619-4.384 2.807-2.876 4.383-6.577 4.383-10.618s-1.507-7.81-4.384-10.619c-2.876-2.807-6.577-4.383-10.618-4.383S12.79 7.126 9.98 9.936c-5.822 5.893-5.822 15.414 0 21.238z"
      />
    </svg>
  );
}

export default Search;
