import styled from '@emotion/styled';
import { NotificationService, UserNotification } from '@gbhem/api';
import { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import { ArrowRight } from '..';

const NotificationContainer = styled.div<ContainerProps>`
  ${tw`w-96 p-3 bg-neutral-1000 text-black cursor-pointer text-sm border-b border-neutral-600`}

  ${(props) => props.isRead && tw`bg-neutral-800 opacity-50`}
`;

const OverflowContainer = styled.div`
  ${tw`truncate w-11/12`}
`;

type ContainerProps = {
  isRead: boolean;
};

type NotificationProps = {
  notification: UserNotification;
  onClick?: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
};

export function Notification({ notification, onClick }: NotificationProps) {
  const [isRead, setIsRead] = useState(notification.isRead);

  return (
    <NotificationContainer
      isRead={isRead}
      onClick={async (e) => {
        if (!isRead) {
          await NotificationService.updateUserNotificationIsRead(notification.notificationId, true);
          setIsRead(true);
        }

        onClick?.(e);
      }}
    >
      <OverflowContainer>
        <Link title={notification.message} to={notification.link ? notification.link : '#'}>
          <div className="flex space-x-2 items-center underline">
            <span>{notification.message}</span> <ArrowRight className="w-1 h-1" />
          </div>
        </Link>
      </OverflowContainer>
    </NotificationContainer>
  );
}
