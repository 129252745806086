import { Phase, StepTemplate } from '@gbhem/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Button, Cancel, Plus, prioritize, Select } from '..';

interface StepPrerequisitesProperties {
  phase: Phase;
  step: StepTemplate;
  prerequisites: StepTemplate[];
  readOnly: boolean;
  onChange: (steps: StepTemplate[]) => void;
}

export function StepPrerequisites({
  prerequisites: _prerequisites = [],
  phase,
  step,
  readOnly,
  onChange
}: StepPrerequisitesProperties) {
  const getDependents = useCallback(
    (step: StepTemplate) =>
      Array.from(
        new Set(phase.stepTemplates.filter((s) => s.prerequisites.find((p) => p.id === step.id)))
      ),
    [phase.stepTemplates]
  );

  const dependents = useMemo(
    () =>
      getDependents(step).reduce<StepTemplate[]>((dependents, dependent) => {
        let subDependents = [dependent, ...getDependents(dependent)];

        while (subDependents.length) {
          dependents.push(...subDependents);
          subDependents = subDependents.flatMap((d) => getDependents(d));
        }

        return Array.from(new Set(dependents));
      }, []),
    [getDependents, step]
  );

  const options = useMemo(
    () =>
      prioritize(
        phase.stepTemplates.map((s) =>
          s.id === step.id ? { ...step, prerequisites: _prerequisites.filter((p) => p.id) } : s
        )
      ).filter((s) => s.id !== step.id),
    [_prerequisites, phase.stepTemplates, step]
  );

  // uuid is required because the id of each prerequisite
  // cannot be used as a key in the list for rendering each Select.
  // A persistent key is required to prevent React from re-rendering
  // incorrectly.
  const [prerequisites, setPrerequisites] = useState(() =>
    _prerequisites.map((item) => ({ id: uuid(), item }))
  );

  useEffect(() => {
    // synchronize addition / deletion to prerequisites
    if (!(prerequisites.length === _prerequisites.length)) {
      setPrerequisites(_prerequisites.map((item) => ({ id: uuid(), item })));
      return;
    }

    // handle the case where no change was detected (i.e. a new, empty prerequisite has been
    // added to the internal state of this component, but has not been chosen by the user
    // and so has not yet been propagated to the parent component.
    if (!prerequisites.some((p, i) => p.item.id !== _prerequisites[i].id)) {
      return;
    }

    // synchronize changes to existing dependencies
    setPrerequisites(
      prerequisites.map(({ id }, i) => ({
        id,
        item: _prerequisites[i]
      }))
    );
  }, [_prerequisites, prerequisites]);

  if (!options.length) {
    return <></>;
  }

  return (
    <div className="space-y-2">
      <h3 className="font-semibold">Prerequisites</h3>
      <div className="text-xs text-neutral-500">
        Select step(s) from this phase that must be completed before this step becomes available.
      </div>

      {prerequisites.map((p) => (
        <div key={p.id} className="flex space-x-2 items-center">
          <Select
            inline
            className="flex-1"
            placeholder="Select a prerequisite step..."
            disabled={readOnly}
            options={options.filter(
              (o) => o.id === p.item.id || !prerequisites.find((d) => d.item.id === o.id)
            )}
            optionLabel={(o) =>
              dependents.find((d) => d.id === o.id) ? `${o.name} (dependent)` : o.name
            }
            optionDisabled={(o) => dependents.find((d) => d.id === o.id) !== undefined}
            defaultValue={options.find((s) => s.id === p.item.id)}
            onChange={(e) => {
              const change = e.target.value;
              if (!change?.id) {
                return;
              }

              const changes = prerequisites.map((d) => (d.id === p.id ? change : d.item));
              onChange(changes);
            }}
          />
          {!readOnly && (
            <Cancel
              className="h-4 w-4 text-neutral-500 cursor-pointer"
              onClick={() =>
                onChange(prerequisites.filter((d) => d.id !== p.id).map((p) => p.item))
              }
            />
          )}
        </div>
      ))}
      {prerequisites.length === 0 && dependents.length === options.length ? (
        <p className="text-xs">
          No dependents can be set for this step - all other step(s) are dependent upon it. To make
          a change, please review any dependents
        </p>
      ) : (
        <></>
      )}
      {!readOnly && options.length > prerequisites.length + dependents.length && (
        <Button
          inverted
          className="text-sm"
          onClick={() => onChange([...prerequisites.map((p) => p.item), {} as StepTemplate])}
        >
          <Plus /> Prerequisite
        </Button>
      )}
    </div>
  );
}

export default StepPrerequisites;
