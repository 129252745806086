import { SVGProps } from 'react';

export function Task(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={51}
      height={50}
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.09 50h35.894c3.84 0 7.016-3.175 7.019-7.088V7.09A7.092 7.092 0 0042.912 0H7.09A7.092 7.092 0 000 7.09v35.82A7.092 7.092 0 007.09 50zM6.279 7.091c0-.444.369-.812.813-.812h35.818c.444 0 .813.368.813.812l.002 35.821a.82.82 0 01-.812.813H7.09a.819.819 0 01-.813-.813V7.092z"
      />
      <path d="M22.306 36.337c-.737 0-1.477-.296-2.066-.737l-8.197-7.016c-1.33-1.109-1.478-3.103-.37-4.432 1.11-1.33 3.104-1.478 4.433-.369l5.762 4.949 11.668-13.958c1.109-1.33 3.103-1.478 4.432-.37 1.33 1.11 1.478 3.104.37 4.433L24.67 35.228c-.517.665-1.33 1.034-2.142 1.109h-.222z" />
    </svg>
  );
}

export default Task;
