import { Role } from '@gbhem/api';
import { Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useUpdateEffect } from 'react-use';

import { Button, Input, Nudge, Select } from '../../components';
import { SystemRole, useRoles } from '../../providers';
import { Cancel, Plus } from '../icons';
import { StepContentProperties } from './StepContentsEditor';

function StepContentPrivacyEditor({ content, readOnly = false, onChange }: StepContentProperties) {
  return (
    <>
      <h3 className="font-semibold">Visibility</h3>
      <div className="space-y-2 ml-4">
        <Input
          type="checkbox"
          checked={content.isPrivate}
          disabled={readOnly}
          onChange={(e) => {
            onChange({
              ...content,
              isPrivate: e.target.checked,
              approvedRoles: content.approvedRoles ?? []
            });
          }}
          name="This content contains sensitive information."
        />
        {content.isPrivate ? (
          <>
            <StepContentPrivacyList content={content} readOnly={readOnly} onChange={onChange} />
          </>
        ) : null}
      </div>
    </>
  );
}

function StepContentPrivacyList({ content, readOnly = false, onChange }: StepContentProperties) {
  const { system } = useRoles();
  const { watch } = useFormContext();

  const {
    control,
    formState: { isValid },
    getValues
  } = useForm({
    mode: 'all',
    defaultValues: {
      approvedRoles: (content.approvedRoles as Omit<Role, 'users'>[]) || ['']
    }
  });
  const {
    fields: approvedRoles,
    append,
    remove
  } = useFieldArray({ control, name: 'approvedRoles' });

  useUpdateEffect(() => {
    onChange({
      ...content,
      approvedRoles: getValues('approvedRoles') as Role[]
    });
  }, [approvedRoles]);

  return (
    <div className="space-y-2">
      <p className="text-xs text-neutral-100">
        Please confirm the role you would like to complete this action, as any role selected to
        complete it can view this sensitive information when accessing the step.{' '}
        <span className="font-bold">Use "Completed by" drop down above to change this role.</span>
      </p>
      <div className="flex flex-col space-y-2 my-4">
        <Select
          label={'Completed by'}
          options={system}
          value={watch('completedBy') || system.find((r) => r.name === SystemRole.Participant)}
          disabled
        />
      </div>
      <p className="text-xs text-neutral-100">
        Select the role(s) that will be able to view this content after it is submitted on the
        participant's profile under Documents. <Nudge>* Required</Nudge>
      </p>
      {content.approvedRoles.length === 0 && (
        <Nudge>Please provide approved roles to private content.</Nudge>
      )}
      <div className="flex flex-col space-y-2 my-4">
        {approvedRoles.map((o, i) => (
          <Controller
            key={o.id}
            name={`approvedRoles.${i}`}
            rules={{
              required: true,
              minLength: 1
            }}
            render={({ field }) => (
              <div className="flex flex-1 space-x-2 items-center">
                <Select disabled={readOnly} options={system} {...field} inline className="flex-1" />
                {!readOnly && (
                  <Cancel
                    className="h-4 w-4 text-neutral-500 cursor-pointer"
                    onClick={() => remove(i)}
                  />
                )}
              </div>
            )}
            control={control}
          />
        ))}
        {!readOnly && (
          <div>
            <Button disabled={!isValid} onClick={() => append(system[0])}>
              <Plus /> Add Approved Role
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default StepContentPrivacyEditor;
