import { Cell } from '@gbhem/api';
import { v4 as uuid } from 'uuid';

import { Field, FieldType, Properties } from '../../models';
import { address } from '../Field/Address';

export type Widget = {
  layout: Cell;
  fields: Field[];
};

export const widgetMap: Partial<Record<FieldType, () => Widget>> = {
  [FieldType.Address]: address
};

export const defaultPropertyMap: Partial<Record<FieldType, Properties>> = {
  [FieldType.Heading]: {},
  [FieldType.Paragraph]: { paragraph: '' },
  [FieldType.Link]: { link: '' },
  [FieldType.Number]: { min: 0 },
  [FieldType.List]: { required: false }
};

export const getWidget = (type: FieldType) => {
  const widget = widgetMap[type]?.();
  if (widget) {
    return widget;
  }

  const fieldId = uuid();

  return {
    layout: { id: uuid(), fieldId },
    fields: [
      {
        id: fieldId,
        name: '',
        type,
        properties: defaultPropertyMap[type] || { required: false, placeholder: '' }
      }
    ]
  };
};
