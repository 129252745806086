import { css, keyframes } from '@emotion/react';

export const growIn = keyframes`
  0% {
    transform: scale(0.5);
  }

  80% {
    transform: scale(1.05);
  }
  
  100% {
    transform: scale(1); 
  }
`;

export const growInAnimation = (time?: number) => css`
  animation: ${growIn} ${time || 0.3}s ease-in-out;
`;
