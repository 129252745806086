import z from 'zod';

export const inviteUserInformationSchema = z.object({
  firstName: z
    .string({
      required_error: 'First Name is required',
      invalid_type_error: 'First Name must only contain characters'
    })
    .min(1)
    .trim(),
  lastName: z
    .string({
      required_error: 'Last Name is required',
      invalid_type_error: 'Last Name must only contain characters'
    })
    .min(1)
    .trim(),
  email: z
    .string({ required_error: 'Email is required' })
    .email({ message: 'Invalid email address' })
    .min(2)
});

export const inviteUserSuperAdminSchema = z.object({
  conferenceLocation: z.string().optional(),
  conferenceLocationType: z.string().optional(),
  districtLocation: z.string().optional(),
  districtLocationType: z.string().optional()
});

export const inviteUserOtherAdminSchema = z.object({
  conferenceLocation: z.string({
    required_error: 'Location is required'
  }),
  conferenceLocationType: z.string(),
  districtLocation: z.string({
    required_error: 'Location is required'
  }),
  districtLocationType: z.string()
});
