import { css } from '@emotion/react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import {
  ComponentProps,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useModal } from 'react-modal-hook';
import { useClickAway, useKey } from 'react-use';
import tw from 'twin.macro';

import { Button, Card, CardBody, CardFooter, CardHeader, Portal } from '../../components';
import {
  backgroundFadeInAnimation,
  backgroundFadeOutAnimation,
  fadeInAnimation,
  slideInAnimation,
  slideOutAnimation
} from '../animations';
import { Cancel as CancelIcon } from '../icons';

export type ModalProps = ComponentProps<'div'> & {
  name?: string;
  onClose?: () => void;
  onOpen?: () => void;
  closeMessage?: string;
  detectClickAway?: boolean;
  isClosable?: boolean;
  expand?: boolean;
  disableExpand?: boolean;
  children?: FC<{ close: (force?: boolean) => Promise<void>; expand: () => void }> | ReactNode;
  footer?: FC<{ close: (force?: boolean) => Promise<void> }> | ReactNode;
  container?: string;
};

const Backdrop = styled.div<{ isOpen?: boolean }>`
  ${tw`fixed top-0 bottom-0 left-0 right-0 z-20 flex flex-col items-center overflow-x-hidden overflow-y-auto`}

  backdrop-filter: blur(0.1rem);
  background: rgba(0, 0, 0, 0.5);
  min-height: 0;

  ${(p) => (p.isOpen ? backgroundFadeInAnimation(0.3) : backgroundFadeOutAnimation(0.3))}
`;

const Cancel = styled(CancelIcon)`
  height: 1rem;
  width: 1rem;
  ${tw`cursor-pointer fill-current`}
`;

const Dialog = styled(Card)<{
  isOpen?: boolean;
  expand?: boolean;
  disableExpand?: boolean;
}>`
  ${tw`relative flex-none m-auto my-6 shadow-2xl bg-neutral-1000 gap-0 transition-all duration-300`}

  max-width: 90%;
  min-height: 20%;
  min-width: min(48rem, 90%);

  ${(p) =>
    p.disableExpand
      ? css`
          max-height: 95%;
          overflow-y: scroll;
        `
      : css``}

  ${(p) => (p.isOpen ? slideInAnimation(0.3) : slideOutAnimation(0.3))}
  ${(p) =>
    p.expand
      ? css`
          min-width: 95%;
        `
      : css`
          min-width: min(48rem, 95%);
        `}
`;

const Header = styled(CardHeader)`
  ${tw`items-center justify-between p-3`}
`;

const StickyHeader = styled.div`
  ${tw`sticky z-20 flex-1 flex-grow w-full h-0 overflow-visible`}

  top: 0;
  ${fadeInAnimation(0.3)};
`;

const FixedFooter = styled.div`
  ${tw`fixed bottom-0 left-0 z-20 w-full p-4 border-t bg-neutral-1000`}
  ${fadeInAnimation(0.3)};
`;

const Content = styled(CardBody)`
  ${tw`p-3`}
`;

const Title = styled.div`
  ${tw`text-lg`}
`;

export const Modal: FC<ModalProps> = ({
  name,
  onOpen,
  onClose,
  isClosable = true,
  closeMessage,
  expand: _expand,
  footer: _footer,
  container = 'modals',
  disableExpand,
  children,
  ...props
}) => {
  const dialog = useRef<HTMLDivElement>(null);
  const header = useRef<HTMLDivElement>(null);
  const footer = useRef<HTMLDivElement>(null);
  const stickyHeader = useRef<HTMLDivElement>(null);
  const fixedFooter = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(true);
  const [canClose, setCanClose] = useState(!closeMessage);
  const [isClosing, setIsClosing] = useState(false);
  const [hasOpened, setHasOpened] = useState(false);
  const [isForcingExpand, setIsForcingExpand] = useState(_expand);
  const [expand, setExpand] = useState(_expand);

  const [showConfirmClose, hideConfirmClose] = useModal(() => (
    <Modal
      name="Confirm"
      onClose={() => {
        setCanClose(false);
        setIsClosing(false);
        hideConfirmClose();
      }}
    >
      <p>{closeMessage}</p>
      <CardFooter>
        <Button
          inverted
          onClick={() => {
            setIsClosing(false);
            hideConfirmClose();
          }}
        >
          No
        </Button>
        <Button
          onClick={() => {
            hideConfirmClose();
            setCanClose(true);
          }}
        >
          Yes
        </Button>
      </CardFooter>
    </Modal>
  ));

  const _onClose = useCallback(
    (force?: boolean) =>
      new Promise<void>((onFinishClose) => {
        if (!isClosable) {
          return;
        }

        if (force) {
          setCanClose(force);
        }

        if (!force && !canClose) {
          if (!isClosing) {
            setIsClosing(true);
            showConfirmClose();
          }

          return;
        }

        setIsOpen(false);
        setTimeout(() => {
          onClose?.();
          onFinishClose();
        }, 280);
      }),
    [canClose, isClosing, onClose, showConfirmClose, isClosable]
  );

  const onExpand = () => {
    if (!disableExpand) {
      setIsForcingExpand(true);
      setExpand(true);
    }
  };

  useEffect(() => {
    if (canClose && isClosing) {
      _onClose();
    }
  }, [canClose, isClosing, _onClose]);

  useEffect(() => void (isOpen ? onOpen?.() : _onClose()), [_onClose, isOpen, onOpen]);

  useKey('Escape', () => _onClose());

  useClickAway(dialog, (e: MouseEvent) => {
    if (
      e.target === stickyHeader.current ||
      stickyHeader.current?.contains(e.target as Node) ||
      e.target === fixedFooter.current ||
      fixedFooter.current?.contains(e.target as Node) ||
      e.offsetX >= document.body.clientWidth - 15 ||
      (props.detectClickAway !== undefined && !props.detectClickAway)
    ) {
      return;
    }

    _onClose();
  });

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  useEffect(() => {
    const transitioningDialog = dialog.current;

    const onDialogTransitionEnd = () => {
      if (hasOpened) {
        return;
      }

      setHasOpened(true);
      dialog.current?.scrollIntoView();
    };

    transitioningDialog?.addEventListener('animationend', onDialogTransitionEnd);

    return () => transitioningDialog?.removeEventListener('animationend', onDialogTransitionEnd);
  }, [hasOpened]);

  if (!isForcingExpand && dialog.current) {
    if (dialog.current.offsetHeight > window.innerHeight && !expand && !disableExpand) {
      setExpand(dialog.current.offsetHeight > window.innerHeight);
    }
  }

  useEffect(() => {
    if (!hasOpened) {
      return;
    }

    const headerElement = header.current;
    headerElement?.focus();
    const headerObserver = new IntersectionObserver(
      ([e]) => {
        setIsHeaderVisible(e.isIntersecting);

        if (e.isIntersecting) {
          content.current!.style.marginTop = '0';
        }

        if (
          (!dialog.current || dialog.current.offsetHeight < window.innerHeight) &&
          !disableExpand
        ) {
          setExpand(!e.isIntersecting);
        }
      },
      { threshold: [0.1] }
    );

    if (headerElement) {
      headerObserver.observe(headerElement);
    }

    const footerElement = footer.current;
    const footerObserver = new IntersectionObserver(
      ([e]) => {
        setIsFooterVisible(e.isIntersecting);
      },
      { threshold: [0.99] }
    );

    if (footerElement) {
      footerObserver.observe(footerElement);
    }

    return () => {
      headerElement && headerObserver.unobserve(headerElement);
      footerElement && footerObserver.unobserve(footerElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header, hasOpened]);

  useEffect(() => {
    if (!dialog.current || !hasOpened) {
      return;
    }

    const { bottom } = dialog.current.getBoundingClientRect();
    if (bottom > window.innerHeight && !disableExpand) {
      setExpand(true);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOpened]);

  const __children = useMemo(
    () =>
      typeof children === 'function' ? children({ close: _onClose, expand: onExpand }) : children,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, _onClose]
  );

  const __footer = useMemo(
    () => (typeof _footer === 'function' ? _footer({ close: _onClose }) : _footer),
    [_footer, _onClose]
  );

  return (
    <Portal {...props} className={classNames(props.className, 'modal')} container={container}>
      <Backdrop isOpen={isOpen}>
        {!isHeaderVisible && (
          <StickyHeader ref={stickyHeader}>
            <Header className="absolute top-0 w-full border-b shadow-lg bg-neutral-1000">
              <Title>{name}</Title>
              {isClosable && <Cancel onClick={() => _onClose()} />}
            </Header>
          </StickyHeader>
        )}
        <Dialog
          aria-describedby="aria-title"
          aria-modal={true}
          aria-hidden={isOpen}
          ref={dialog}
          isOpen={isOpen}
          expand={expand}
          disableExpand={disableExpand}
        >
          <div
            tabIndex={0}
            onFocus={() => {
              footer.current?.focus();
            }}
          />
          <Header ref={header} tabIndex={0}>
            <Title id="aria-title">{name}</Title>
            {isClosable && <Cancel onClick={() => _onClose()} />}
          </Header>
          <Content ref={content}>
            {__children}
            <div tabIndex={0} ref={footer}>
              {__footer}
            </div>
            <div
              tabIndex={0}
              onFocus={() => {
                header.current?.focus();
              }}
            />
          </Content>
        </Dialog>
      </Backdrop>
      {!isFooterVisible && _footer && expand && (
        <FixedFooter ref={fixedFooter}>{__footer}</FixedFooter>
      )}
    </Portal>
  );
};

export default Modal;
