import { SVGProps } from 'react';

export function ArrowRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={36}
      viewBox="0 0 50 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M32.422 0L28.6843 3.78907L39.6833 14.7881H0V20.1049H39.9465L28.6843 31.3671L32.422 35.1561L50 17.5782L32.422 0Z" />
    </svg>
  );
}

export default ArrowRight;
