import { css, keyframes } from '@emotion/react';

export const heartbeat = keyframes`
  0% {
    transform: scale(1.0);
    opacity: 0.5;
  }
  
  50% {
    transform: scale(1.3);
    opacity: 1;
  }

  100% {
    transform: scale(1.0);
    opacity: 0.5;
  }
`;

export const heartbeatAnimation = (time?: number) => css`
  animation: ${heartbeat} ${time || 1.5}s infinite ease-in-out;
`;
