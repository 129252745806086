import * as React from 'react';

export function FormPlaceholder(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={262}
      height={262}
      viewBox="0 0 262 262"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={131} cy={131} r={131} fill="#C4C4C4" />
      <path
        stroke="#666"
        d="M35.5 78.5h90v22h-90zM135.5 78.5h91v22h-91zM35.5 134.5h125v22h-125zM35.5 106.5h191v22h-191z"
      />
      <path stroke="#666" strokeDasharray="4 4" d="M35.5 162.5h191v22h-191z" />
      <path
        d="M129.969 167.272v4.835h-4.697a.274.274 0 00-.272.272v1.38c.001.15.122.272.272.272h4.697v4.697c0 .15.122.271.272.272h1.518a.274.274 0 00.272-.272v-4.697h4.697c.15 0 .271-.122.272-.272v-1.38a.274.274 0 00-.272-.272h-4.697v-4.835a.274.274 0 00-.272-.272h-1.544a.273.273 0 00-.246.272z"
        fill="#666"
      />
    </svg>
  );
}

export default FormPlaceholder;
