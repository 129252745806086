import { css, keyframes } from '@emotion/react';

export const backgroundFadeOut = keyframes`
  0% {
    backdrop-filter: blur(0.1rem);
    background: rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
  }
  
  100% {
    backdrop-filter: blur(0);
    background: initial;
    overflow-y: hidden;
  }
`;

export const backgroundFadeOutAnimation = (time?: number) => css`
  animation: ${backgroundFadeOut} ${time || 1}s ease-out;
`;
