import { Church, ChurchService, Conference, District, User } from '@gbhem/api';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { LocationStoreTypes, useLocationStore } from '../../providers';
import { useUserInfoStore } from '../../providers/UserProfileProvider/UserInfoProvider';
import { Button, ErrorText, Input, Modal, ModalProps, Radio, Select } from '..';
import { AdminLocationSelect } from '../Input/Select';

export type Identity = Record<string, any> & { id: string };

export type PermissionHierarchy = (Conference | District | Church) & { entity?: string };

export enum LocationType {
  Conference = 'Conference',
  District = 'District',
  Church = 'Church'
}

export type LocationTypeKey = keyof typeof LocationType;

export const hierarchy = ['Conference', 'District', 'Church'];

interface SearchLocationProperties {
  onFind: (type: LocationType, location?: Identity) => void;
}

export function SearchLocations({ onFind }: SearchLocationProperties) {
  const [locationType, setLocationType] = useState<LocationType>();
  const [filteredConferences, setFilteredConferences] = useState<Conference[]>([]);
  const [filteredDistricts, setFilteredDistricts] = useState<District[]>([]);

  const { conferences, districts } = useLocationStore();

  useEffect(() => {
    setFilteredConferences(Object.values(conferences));
  }, [conferences]);

  const addPermissionModalFormContext = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true
  });

  const { register, setValue, clearErrors } = addPermissionModalFormContext;
  return (
    <>
      <FormProvider {...addPermissionModalFormContext}>
        <Radio
          {...register('Location Type', {
            value: locationType,
            required: { value: true, message: 'Required' }
          })}
          value={locationType}
          options={[LocationType.Conference, LocationType.District]}
          onChange={(e) => {
            if (e.currentTarget.value) clearErrors();
            setValue('Location Type', e.currentTarget.value);
            setLocationType(LocationType[e.currentTarget.value as LocationTypeKey]);
          }}
          placeholder="Select location type to add..."
        />

        {!locationType && <ErrorText> Select a Location Type </ErrorText>}
      </FormProvider>
      {locationType && (
        <>
          <hr />
          <AdminLocationSelect<Conference>
            name="Conference"
            options={filteredConferences}
            locationType={LocationStoreTypes.Conference}
            onSelect={(e) => {
              const location: Conference | undefined = e.currentTarget.value;
              if (location) {
                setFilteredDistricts(
                  Object.values(districts).filter((d) => d.conference.id === location.id)
                );
                if (locationType === LocationType.Conference)
                  onFind(LocationType.Conference, location);
              }
            }}
          />
          {filteredDistricts.length > 0 && locationType === LocationType.District && (
            <Select<District>
              name={`District`}
              options={filteredDistricts}
              onSelect={(e) => {
                const location: District | undefined = e.currentTarget.value;
                if (location && locationType === LocationType.District)
                  onFind(LocationType.District, location);
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export function SearchChurchLocation({ onFind }: SearchLocationProperties) {
  const [filteredDistricts, setFilteredDistricts] = useState<District[]>([]);
  const [filteredChurches, setFilteredChurches] = useState<Church[]>([]);

  const { districts } = useLocationStore();

  useEffect(() => {
    setFilteredDistricts(Object.values(districts));
  }, [districts]);

  return (
    <>
      {filteredDistricts && (
        <AdminLocationSelect<District>
          name="District"
          options={filteredDistricts}
          locationType={LocationStoreTypes.District}
          onSelect={async (e) => {
            const location: District | undefined = e.currentTarget.value;
            if (location) setFilteredChurches(await ChurchService.filterByDistrictId(location.id));
          }}
          optionLabel={(location) => `${location.conference.name} - ${location.name}`}
        />
      )}
      {filteredChurches.length > 0 && (
        <Select<Church>
          name={`Church`}
          options={filteredChurches}
          onSelect={(e) => {
            const church: Church | undefined = e.currentTarget.value;
            if (church) onFind(LocationType.Church, church);
          }}
        />
      )}
    </>
  );
}

interface ManageLocationProperties {
  onAdd: (location: { entity: string; id: string }, isConstituent?: boolean) => void;
  isAddingChurchMembership: boolean;
}

export function AddLocation({ onAdd, isAddingChurchMembership }: ManageLocationProperties) {
  const [location, setLocation] = useState<{ entity: string; id: string }>();
  const [isConstituent, setIsConstituent] = useState(true);

  const onFind = (entity: string, location: Identity | undefined) =>
    setLocation(location ? { entity, id: location.id } : undefined);

  return (
    <>
      {isAddingChurchMembership ? (
        <SearchChurchLocation onFind={onFind} />
      ) : (
        <SearchLocations onFind={onFind} />
      )}
      {location?.id && location?.entity === LocationType.Church && (
        <Input
          name="Home"
          type="checkbox"
          checked={isConstituent}
          onChange={(e) => setIsConstituent(e.target.checked)}
        />
      )}
      <div>
        <Button
          disabled={!location?.id}
          onClick={() => {
            if (!location) return;
            onAdd(location, isConstituent);
          }}
        >
          Add
        </Button>
      </div>
    </>
  );
}

type AddPermissionsModalProperties = ModalProps & {
  users: User[];
  isAddingChurchMembership?: boolean;
};

// Modal is reused on both the users table view and the user profile view.
export function AddPermissionsModal({
  users,
  isAddingChurchMembership = false,
  ...props
}: AddPermissionsModalProperties) {
  const { addChurchMembership, addLocationPermission } = useUserInfoStore();

  return (
    <Modal {...props} name={`Add ${isAddingChurchMembership ? 'Membership' : 'Location'}`}>
      {({ close }) => (
        <>
          Add {isAddingChurchMembership ? 'membership' : 'location'} for{' '}
          {users.length <= 5
            ? `${users.map((u) => `${u.firstName} ${u.lastName}`).join(', ')}`
            : `${users.length || 0} user(s)`}
          <AddLocation
            onAdd={async ({ entity, id: entityId }, isConstituent) => {
              const userIds = users.map((u) => u.id);

              if (isAddingChurchMembership) {
                addChurchMembership({
                  userIds,
                  churchId: entityId,
                  constituent: isConstituent ?? true
                });
              } else {
                await addLocationPermission({
                  userIds,
                  entity,
                  entityId
                });
              }

              close();
            }}
            isAddingChurchMembership={isAddingChurchMembership}
          />
        </>
      )}
    </Modal>
  );
}

export default AddPermissionsModal;
