import styled from '@emotion/styled';
import { FormService } from '@gbhem/api';
import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';
import { useAsync } from 'react-use';

import { FieldSchemaPropertiesEditorProperties } from '.';

const Container = styled.div`
  .tox-notification--warning {
    display: none;
  }
`;

export function ParagraphEditor({ properties, onChange }: FieldSchemaPropertiesEditorProperties) {
  const [paragraphContent, setParagraphContent] = useState(properties.paragraph || '');
  const [apiKey, setApiKey] = useState<string | undefined>(undefined);

  useAsync(async () => setApiKey(await FormService.getTinyMceApiKey()));

  const handleParagraphChange = (change: string) => {
    setParagraphContent(change);
    onChange({ ...properties, paragraph: checkList(change) });
  };

  const checkList = (paragraph: string | undefined) => {
    if (paragraph) {
      return paragraph
        .replace('<ul>', '<ul class="list-disc list-inside">')
        .replace('<ol>', '<ol class="list-decimal list-inside">');
    }
    return paragraph;
  };

  if (!apiKey) {
    return <></>;
  }

  return (
    <Container>
      <Editor
        apiKey={apiKey}
        init={{
          height: 300,
          branding: false,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'table',
            'code',
            'help',
            'wordcount'
          ],
          toolbar:
            'undo redo | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help'
        }}
        value={paragraphContent}
        onEditorChange={(change) => handleParagraphChange(change)}
      />
    </Container>
  );
}

export default ParagraphEditor;
