import { SVGProps } from 'react';

export function ShrinkCell(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={108}
      height={36}
      viewBox="0 0 108 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M75.578 0l3.738 3.79-11 10.998H108v5.317H68.053l11.263 11.262-3.738 3.79L58 17.577 75.578 0zM32.422 0l-3.738 3.79 11 10.998H0v5.317h39.947L28.684 31.367l3.738 3.79L50 17.577 32.422 0zM52 0h4v36h-4V0z" />
    </svg>
  );
}

export default ShrinkCell;
