import { forwardRef, Ref, SVGProps } from 'react';

export const More = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
  return (
    <svg
      ref={ref}
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.367 3.333a1.667 1.667 0 110-3.333 1.667 1.667 0 010 3.333zM9.033 7.5a1.667 1.667 0 10-3.333 0 1.667 1.667 0 003.333 0zm-1.666 4.167a1.667 1.667 0 110 3.333 1.667 1.667 0 010-3.333z"
      />
    </svg>
  );
});

export default More;
