import { TrackTemplate } from '@gbhem/api';
import { useRef } from 'react';
import { useEffectOnce } from 'react-use';

import { Action, useAuthenticatedUserAbility, useTrackStore } from '../../providers';
import { Cancel } from '../icons';
import { Input, Select } from '../Input';
import { TagPill } from '../UserProfile/Tags';

export type ConferenceFilter = {
  id: string;
  name: string;
};

type TrackSearchProps = {
  tracks: TrackTemplate[];
  trackConferences: Set<ConferenceFilter> | undefined;
};

type FilterAction = {
  value: string | ConferenceFilter[] | boolean;
  active: boolean;
};

export type AppliedFilters = {
  search: FilterAction;
  activeTracks: FilterAction;
  inactiveTracks: FilterAction;
  conferences: FilterAction;
};

export function TrackSearch({ tracks, trackConferences }: TrackSearchProps) {
  const userAbility = useAuthenticatedUserAbility();
  const { applyFilter: storeFilters, setFilters, filters } = useTrackStore();
  const _filters = useRef<AppliedFilters>({ ...filters });

  const applyFilter = () => {
    setFilters(_filters.current);
    storeFilters([...tracks]);
  };

  useEffectOnce(() => {
    // logic specific to super admins that will auto set the filter to System only tracks
    // this will keep every track in the system from loading on first page view
    const systemTrack = trackConferences && [...trackConferences].find((tC) => tC.id === '0');
    if (userAbility.can(Action.Manage, 'all') && systemTrack)
      _filters.current = {
        ..._filters.current,
        conferences: {
          active: true,
          value: [systemTrack]
        }
      };
    applyFilter();
  });

  return (
    <>
      <Input
        className="w-72"
        placeholder="Search for Track..."
        value={!_filters.current.search.value ? '' : String(_filters.current.search.value)}
        onChange={(e) => {
          if (e.target.value && e.target.value.length > 0) {
            _filters.current.search = { value: e.target.value, active: true };
          } else {
            _filters.current.search = { value: false, active: false };
          }
          applyFilter();
        }}
      />
      <Select<ConferenceFilter>
        className="w-60"
        placeholder="Select Conference"
        options={
          trackConferences
            ? [...trackConferences].sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
            : []
        }
        optionLabel={(filter) => `${filter.name}`}
        defaultValue={
          trackConferences && userAbility.can(Action.Manage, 'all')
            ? [...trackConferences].find((tC) => tC.id === '0')
            : undefined
        }
        onSelect={(e) => {
          if (e.currentTarget.value && Array.isArray(_filters.current.conferences.value)) {
            // Check to make sure we don't add multiple of the same conference.
            if (
              !_filters.current.conferences.value.some(
                (conference) => conference.id === e.currentTarget.value?.id
              )
            ) {
              const activeFilterValues = [
                ...(_filters.current.conferences.value as ConferenceFilter[]),
                ...[e.currentTarget.value]
              ];

              _filters.current.conferences = {
                active: activeFilterValues.length > 0 || false,
                value: activeFilterValues
              };
            }
            applyFilter();
          }
        }}
      />
      <Input
        type="checkbox"
        name="Active Tracks?"
        checked={!!_filters.current.activeTracks.value}
        onChange={(e) => {
          if (e.target.checked) {
            _filters.current.activeTracks = { value: e.target.checked, active: e.target.checked };
          } else {
            _filters.current.activeTracks = { value: false, active: false };
          }
          applyFilter();
        }}
      />
      <Input
        type="checkbox"
        name="Inactive Tracks?"
        checked={!!_filters.current.inactiveTracks.value}
        onChange={(e) => {
          if (e.target.checked) {
            _filters.current.inactiveTracks = { value: e.target.checked, active: e.target.checked };
          } else {
            _filters.current.inactiveTracks = { value: false, active: false };
          }
          applyFilter();
        }}
      />
      {Array.isArray(_filters.current.conferences.value) &&
      _filters.current.conferences.value.length > 0 ? (
        <>
          <p className="text-sm">Filters Applied: </p>
          {(_filters.current.conferences.value as ConferenceFilter[]).map((c: ConferenceFilter) => {
            return (
              <TagPill key={c.id}>
                <p>{c.name}</p>
                <Cancel
                  onClick={() => {
                    const removeConference = (
                      _filters.current.conferences.value as ConferenceFilter[]
                    ).filter((remove) => remove.id !== c.id);

                    if (removeConference.length === 0) {
                      _filters.current.conferences = { value: [], active: false };
                    } else {
                      _filters.current.conferences = { value: removeConference, active: true };
                    }
                    applyFilter();
                  }}
                />
              </TagPill>
            );
          })}
        </>
      ) : null}
    </>
  );
}

export default TrackSearch;
