import styled from '@emotion/styled';
import { TrackTemplate } from '@gbhem/api';
import tw from 'twin.macro';

type PillProps = {
  color: keyof typeof pillColors;
};

const pillColors = { tan: tw`bg-tan`, pink: tw`bg-pink`, blue: tw`bg-blue-300` };
const Pill = styled.span<PillProps>`
  ${tw`text-xs rounded-full bg-tan text-neutral-100 py-1 px-2 select-none font-normal`}
  ${(props) => pillColors[props.color]}
`;

interface TrackTemplatePillDataProperties {
  track: TrackTemplate;
}

export function TrackPillData({ track }: TrackTemplatePillDataProperties) {
  return (
    <div className="flex space-x-2">
      <Pill color="tan">{track.conference ? track.conference.name : 'System'}</Pill>
      {track.disabled && <Pill color="blue">Inactive</Pill>}
      {track.originTrackTemplate && (
        <Pill color="pink">{`Copy Of ${track.originTrackTemplate.name}`}</Pill>
      )}
    </div>
  );
}

export default TrackPillData;
