import {
  Conference,
  Phase,
  PhaseService,
  StepService,
  StepTemplate,
  TrackService,
  TrackTemplate
} from '@gbhem/api';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useLocationStore } from '../../providers';
import { Button, Select } from '..';

// need to pass on submit
interface ImportStepModalProperties {
  onSubmit: (step: StepTemplate) => void;
  isSubmitting: boolean;
}

type SelectionState = {
  conference: Conference | undefined;
  track: TrackTemplate | undefined;
  phase: Phase | undefined;
  step: StepTemplate | undefined;
};

function ImportStepModal({ onSubmit, isSubmitting }: ImportStepModalProperties) {
  const [selectionState, setSelectionState] = useState<SelectionState>({
    conference: undefined,
    track: undefined,
    phase: undefined,
    step: undefined
  });

  const { getTrackTemplatesByConference } = TrackService;
  const { getPhasesByTrackTemplate } = PhaseService;
  const { getImportStepTemplatesByPhase } = StepService;

  const { conferences } = useLocationStore();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isDirty, isValid }
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    shouldFocusError: true
  });

  const changeHandler = (
    name: string,
    newValue:
      | Conference
      | TrackTemplate
      | Phase
      | StepTemplate
      | { id: string; name: string }
      | undefined
  ) => {
    if (!newValue) return;

    const index = Object.keys(selectionState).findIndex((k) => k === name);

    setValue(name, newValue);

    const update = Object.entries(selectionState).map(([key, value], i) => {
      if (i === index) return [name, newValue];
      if (i > index) {
        setValue(key, undefined);
        return [key, undefined];
      }
      return [key, value];
    });

    setSelectionState(Object.fromEntries(update));
  };

  return (
    <>
      <form className="flex flex-col space-y-4">
        <Select
          label="Select Conference"
          {...register('conference', {
            value: selectionState.conference,
            required: { value: true, message: 'Required' }
          })}
          options={Object.values({ ...{ '0': { id: 'system', name: 'System' } }, ...conferences })}
          onChange={(e) => {
            changeHandler(e.currentTarget.name, e.currentTarget.value);
          }}
        />
        <Select<TrackTemplate>
          label="Select Track Template"
          disabled={!selectionState.conference}
          {...register('track', {
            value: selectionState.track,
            required: { value: true, message: 'Required' }
          })}
          options={async () =>
            selectionState.conference
              ? await getTrackTemplatesByConference(selectionState.conference.id)
              : []
          }
          onChange={(e) => {
            changeHandler(e.currentTarget.name, e.currentTarget.value);
          }}
        />
        <Select<Phase>
          label="Select Phase"
          disabled={!selectionState.track}
          {...register('phase', {
            value: selectionState.phase,
            required: { value: true, message: 'Required' }
          })}
          options={async () =>
            selectionState.track ? getPhasesByTrackTemplate(selectionState.track.id) : []
          }
          onChange={(e) => {
            changeHandler(e.currentTarget.name, e.currentTarget.value);
          }}
        />
        <Select<StepTemplate>
          label="Select Step"
          disabled={!selectionState.phase}
          {...register('step', {
            value: selectionState.step,
            required: { value: true, message: 'Required' }
          })}
          options={async () =>
            selectionState.phase ? await getImportStepTemplatesByPhase(selectionState.phase.id) : []
          }
          onChange={(e) => {
            changeHandler(e.currentTarget.name, e.currentTarget.value);
          }}
        />
        <Button
          className="mr-auto"
          disabled={!selectionState.step || isSubmitting || !isDirty || !isValid}
          onClick={handleSubmit(() => {
            if (selectionState.step) onSubmit(selectionState.step);
          })}
        >
          Import Step
        </Button>
      </form>
    </>
  );
}

export default ImportStepModal;
