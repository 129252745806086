import { User, UserService } from '@gbhem/api';
import { Column } from 'react-table';

import { Input } from '../Input';
import { ColumnLink, Table } from '../Table';

const columns = [
  {
    Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
      <Input type="checkbox" {...getToggleAllPageRowsSelectedProps()} />
    ),
    accessor: 'id',
    id: 'checkbox',
    disableSortBy: true,
    Cell: ({ row }) => <Input type="checkbox" {...row.getToggleRowSelectedProps()} />
  },
  {
    Header: 'FIRST NAME',
    accessor: 'firstName',
    id: 'firstName',
    Cell: ({ row }) => {
      const value = row.original;
      return <ColumnLink to={`/users/${value.id}`}>{value.firstName}</ColumnLink>;
    }
  },
  {
    Header: 'LAST NAME',
    accessor: 'lastName',
    id: 'lastName',
    Cell: ({ row }) => {
      const value = row.original;
      return <ColumnLink to={`/users/${value.id}`}>{value.lastName}</ColumnLink>;
    }
  },
  {
    Header: 'EMAIL',
    accessor: 'email',
    id: 'email',
    disableSortBy: false,
    Cell: ({ value }) => value
  }
] as Column<User>[];

export function AdvisorsTable() {
  const initialPageSize = 10;
  const initialPageIndex = 0;

  return (
    <Table
      columns={columns}
      data={[]}
      pageIndex={initialPageIndex}
      pageSize={initialPageSize}
      count={0}
      loader={async (props) => {
        const quickSearch = await UserService.quickSearch(
          'Advisor',
          props.size,
          props.page * props.size,
          ['roles'],
          props.isQuickSearch,
          props.orderBy,
          props.filters
        );

        return { data: quickSearch.data, count: quickSearch.count };
      }}
    />
  );
}
