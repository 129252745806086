import { SVGProps } from 'react';

export function Cancel(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M29.738 25L48.756 5.983a3.355 3.355 0 001.172-2.423 3.357 3.357 0 00-5.91-2.315L25 20.263 5.983 1.245a3.358 3.358 0 00-4.562.176 3.358 3.358 0 00-.176 4.562L20.262 25 1.245 44.019a3.358 3.358 0 000 4.738 3.358 3.358 0 004.738 0L25 29.738l19.018 19.018a3.358 3.358 0 004.738 0 3.358 3.358 0 000-4.738L29.738 25z" />
    </svg>
  );
}

export default Cancel;
