import { Role, TrackAssignment, User, UserService } from '@gbhem/api';
import { useMemo } from 'react';

import { Button } from '../Button';
import { Cancel, Plus } from '../icons';
import { Select } from '../Input';

interface AssignmentsProperties {
  role: Role;
  assignments: TrackAssignment[];
  onChange: (assignments: TrackAssignment[]) => void;
}

export function Assignments({ role, assignments: _assignments, onChange }: AssignmentsProperties) {
  const assignments = useMemo(
    () => _assignments.filter((a) => a.role.id === role.id),
    [_assignments, role]
  );

  return (
    <>
      <div className="space-y-2">
        {assignments.map((assignment) => (
          <div key={assignment.assignee.id} className="flex space-x-4 items-center">
            <Select<User>
              className="flex-1"
              placeholder={`Search for ${role.name}...`}
              value={assignment.assignee}
              optionLabel={(assignee) =>
                assignee.id.length
                  ? `${assignee.firstName} ${assignee.lastName} (${assignee.email})`
                  : ''
              }
              options={async (searchTerm) => {
                if (!searchTerm) {
                  return;
                }

                return (
                  await UserService.query({
                    conditions: [
                      { name: searchTerm, inactive: false },
                      { firstName: searchTerm, inactive: false },
                      { lastName: searchTerm, inactive: false }
                    ],
                    relations: ['roles'],
                    limit: 20
                  })
                ).data
                  .filter((u: User) => u.roles.find((r) => r.id === role.id))
                  .filter(
                    (a: User) =>
                      a.id === assignment.assignee?.id ||
                      !assignments.find((cA) => cA.assignee?.id === a.id)
                  );
              }}
              onSelect={(e) => {
                const assignee = e.currentTarget.value!;
                if (!assignee) {
                  return;
                }

                assignment.assignee = assignee;

                onChange([..._assignments]);
              }}
            />
            <Cancel
              className="h-3 w-3 text-primary-300 cursor-pointer"
              onClick={() =>
                onChange(
                  _assignments.filter(
                    (a) =>
                      !(
                        a.assignee.id === assignment.assignee.id && a.role.id === assignment.role.id
                      )
                  )
                )
              }
            />
          </div>
        ))}
      </div>
      <div className="space-y-1">
        <Button
          inverted
          disabled={assignments.some((a) => a.assignee.id === '')}
          onClick={() =>
            onChange([
              ..._assignments,
              {
                id: '',
                assignee: { id: '', firstName: '', lastName: '' },
                role: role
              } as TrackAssignment
            ])
          }
        >
          Add {role.name} <Plus />
        </Button>
      </div>
    </>
  );
}
