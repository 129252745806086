"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class StepService {
    static updateContentInstance(stepContentInstanceId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/step/instance/content/{stepContentInstanceId}',
            path: {
                'stepContentInstanceId': stepContentInstanceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updateActionInstance(stepActionInstanceId, approved) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/step/instance/action/{stepActionInstanceId}/{approved}',
            path: {
                'stepActionInstanceId': stepActionInstanceId,
                'approved': approved,
            },
        });
    }
    static overrideStepInstanceStatus(stepInstanceId, status) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/step/instance/{stepInstanceId}/{status}',
            path: {
                'stepInstanceId': stepInstanceId,
                'status': status,
            },
        });
    }
    static createStepTemplate(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/step/template/create-step-template',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updateStepTemplate(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/step/template/update-step-template',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updateStepTemplatePrerequisites(stepTemplateId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/step/template/update-step-template-prerequisites/{stepTemplateId}',
            path: {
                'stepTemplateId': stepTemplateId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static getStepInstance(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-instance/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getStepInstances(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-instances/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getStepTemplate(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-template/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getStepTemplates(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-templates/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getStepContentInstances(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-content-instances/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getStepContentInstance(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-content-instance/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getStepActionTemplates(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-action-templates/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getStepActionTemplate(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-action-template/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getStepActionInstances(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-action-instances/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getStepContentTemplates(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-content-templates/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getStepContentTemplate(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-content-template/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getTaskTemplate(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-task-template/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getTaskTemplates(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-task-templates/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getUploadTemplate(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-upload-template/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getUploadTemplates(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/step-upload-templates/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getTaskInstance(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/task-instance/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getTaskInstances(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/task-instances/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getImportStepTemplatesByPhase(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/import-step-templates/phase/{id}',
            path: {
                'id': id,
            },
        });
    }
    static createImportStepTemplate(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/step/template/create-step-template-from-import',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static create(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/step',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static find(limit, offset, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step',
            query: {
                'limit': limit,
                'offset': offset,
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updateMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/step',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static removeMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/step',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static createMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/step/bulk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static quickSearch(tableName, limit, offset, relations, isQuickSearch, orderBy, where) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/quickSearch',
            query: {
                'tableName': tableName,
                'limit': limit,
                'offset': offset,
                'relations': relations,
                'isQuickSearch': isQuickSearch,
                'orderBy': orderBy,
                'where': where,
            },
        });
    }
    static count(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static query(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/step/query',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static findOne(id, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/step/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static update(id, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/step/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static remove(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/step/{id}',
            path: {
                'id': id,
            },
        });
    }
    static search(requestBody, relations) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/step/search',
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
exports.StepService = StepService;
