import styled from '@emotion/styled';
import {
  ComponentProps,
  FormEvent,
  forwardRef,
  MouseEvent,
  Ref,
  useImperativeHandle,
  useReducer,
  useRef,
  useState
} from 'react';
import { useClickAway } from 'react-use';
import tw from 'twin.macro';

import { Calendar, ChevronLeft, ChevronRight } from '../icons';
import { Input, InputMetadataProps } from '../Input';
import {
  Actions,
  DateData,
  dateFromDateStr,
  dateFromTimeStamp,
  DateString,
  monthlyDetails,
  Months,
  TODAY,
  TODAY_STAMP,
  WeekDayAbbreviation
} from './helper/calendarHelper';

const Element = styled.div`
  ${tw`relative`}
`;

const CalendarAnchor = styled.div`
  ${tw`relative z-10`}
`;

const CalendarContainer = styled.div`
  ${tw`relative overflow-y-auto border rounded-sm shadow-md border-neutral-800 bg-neutral-900 p-5 max-h-80 mb-4`}

  ::-webkit-scrollbar {
    ${tw`w-2`}
  }

  ::-webkit-scrollbar-thumb {
    ${tw`w-1 cursor-pointer bg-neutral-600`}
  }

  ::-webkit-scrollbar-track {
    ${tw`shadow-md bg-neutral-800`}
  }
`;

const InputWrapper = styled.div`
  svg {
    height: 0.75rem;
    width: 1rem;
  }
`;

const CalendarInput = styled(Input)<{ isFocus?: boolean }>`
  input {
    ${tw`h-[1.875rem]`}
  }

  ${(p) => (p.isFocus ? tw`border-primary` : ``)}
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const DateOption = styled.div<{ error?: boolean; isFocus?: boolean }>`
  ${tw`absolute flex cursor-pointer items-center justify-center border-t border-b
    border-r rounded-tr-sm rounded-br-sm bg-neutral-900 focus:outline-none
  h-full p-2 top-0 right-0`}

  ${(p) => (p.error ? tw`border-pink` : tw`border-neutral-500`)}
  ::-webkit-calendar-picker-indicator {
    display: none;
  }

  ${(p) => (p.isFocus ? tw`border-primary` : tw`border-neutral-500`)}
  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const CalendarHeader = styled.div`
  ${tw`flex items-center justify-center w-full h-12 `}
`;

const CalendarBody = styled.div`
  ${tw`float-left w-full mt-2.5`}
`;

const BodyWrapper = styled.div`
  ${tw`relative block float-left w-full h-full box-border`}
`;

const OffsetWrapper = styled.div`
  ${tw`w-2/12 h-full box-border relative `}
  svg {
    height: 0.75rem;
    width: 1rem;
  }
`;

const Offset = styled.div`
  ${tw`flex items-center justify-center w-full h-12 pt-2`}
`;

const HeaderTextContainer = styled.div`
  ${tw`float-left text-center w-28 h-full`}
`;

const YearText = styled.div`
  ${tw`text-xl w-full h-8 pt-2`}
`;

const MonthText = styled.div`
  ${tw`text-base w-full h-4`}
`;

const WeekWrapper = styled.div`
  ${tw`relative block float-left box-border w-full h-8 mt-2.5`}

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
`;

const Week = styled.div`
  ${tw`relative block float-left text-center text-xs box-border leading-9  h-2.5 `}

  box-sizing: border-box;
  width: 14.285%;
`;

const DayContainer = styled.div`
  ${tw`relative block float-left box-border h-1/6 py-1`}

  box-sizing: border-box;
  width: 14.285%;
`;

const Day = styled.div<{
  disable?: boolean;
  today?: boolean;
  selected?: boolean;
}>`
  ${tw`relative block float-left text-center text-xs box-border rounded-full w-full h-full py-1
    hover:cursor-pointer`}

  box-sizing: border-box;

  ${(p) => (p.disable ? tw`pointer-events-none bg-neutral-900 text-neutral-600` : ``)}
  ${(p) => (p.today ? tw`bg-pink-200` : ``)}
  ${(p) => (p.selected ? tw`bg-blue-200` : ``)}
`;

type CalendarManager = {
  year?: number;
  month?: number;
  current?: number | Date;
  today?: number;
  display?: DateData[];
};

type CalendarAction = {
  type: string;
  data: CalendarManager;
};

/**
 * Reducer is used to manage how the state of the calendar is managed.
 * The different Actions will change certain states.
 */
const reducer = (calendar: any, action: CalendarAction) => {
  //Grabs the old state, so that information does get changed if not needed.
  const calendarChange = { ...calendar };
  switch (action.type) {
    //If the current day is chaned.
    case Actions.ChangeCurrent: {
      calendarChange.current = action.data.current;
      return calendarChange;
    }
    //If the year is being changed.
    case Actions.ChangeYear: {
      calendarChange.year = action.data.year;
      calendarChange.display = action.data.display;
      return calendarChange;
    }
    //If the month is being changed.
    case Actions.ChangeMonth: {
      calendarChange.month = action.data.month;
      calendarChange.year = action.data.year;
      calendarChange.display = action.data.display;
      return calendarChange;
    }
    //If the intial state needs to be set.
    case Actions.ChangeAll: {
      calendarChange.current = action.data.current;
      calendarChange.month = action.data.month;
      calendarChange.year = action.data.year;
      calendarChange.display = action.data.display;
      return calendarChange;
    }
    default: {
      return calendar;
    }
  }
};

export type DatePickerMetadataProps = {
  error?: string | string[];
  instructions?: string;
  placeholder?: string;
  dateSelect?: (_date: string) => void;
};

export type DatePickerProps = ComponentProps<'input'> &
  DatePickerMetadataProps &
  InputMetadataProps;

const setValue = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')?.set!;

const _CalendarPicker = (
  { error, instructions, name, dateSelect, ...props }: DatePickerProps,
  ref: Ref<HTMLInputElement | null>
) => {
  let errors: string[] = [];
  if (error?.length) {
    errors = Array.isArray(error) ? error : [error];
  }

  //If a value is already set, we need to adjust the calendar to reflect where the selected input is.
  //We get the input prop value, and parse it into our DateString interface.
  let _date: DateString | null = null;
  //Feels like this could have a cleaner way.
  if (props?.value && props.value.toString().length > 0) {
    _date = dateFromDateStr(props.value.toString());
  }

  //Toggle state to show the calendar or hide it.
  const [showCalendar, toggleCalendar] = useState(false);
  //Default state for the application.
  //Checks against the the {@_date} above to see if the calendar needs to be set to
  //Input date or default dates.
  const intialCalendar: CalendarManager = {
    year: _date?.year ? _date.year : TODAY.getFullYear(),
    month: _date?.month ? _date.month - 1 : TODAY.getMonth(),
    current: _date?.date
      ? new Date(_date.year, _date.month - 1, _date.date).getTime()
      : new Date(''),
    today: TODAY_STAMP,
    display:
      _date?.month && _date?.year
        ? monthlyDetails(_date.year, _date.month - 1)
        : monthlyDetails(TODAY.getFullYear(), TODAY.getMonth())
  };

  const input = useRef<HTMLInputElement>(null);
  const componentElement = useRef<HTMLDivElement>(null);

  //State for the calendar.
  const [calendar, dispatch] = useReducer(reducer, intialCalendar);
  const [inputState, setInputState] = useState(dateFromTimeStamp(calendar.current));

  useImperativeHandle(
    ref,
    () => {
      if (!input.current) {
        return input.current;
      }

      return {
        ...input.current,
        value: inputState
      };
    },
    [inputState]
  );

  //Check to highlight the today.
  const isCurrentDay = (day: DateData) => {
    return day.timeStamp === TODAY_STAMP;
  };

  //Check to highlight the selected day.
  const isSelectedDay = (day: DateData) => {
    return day.timeStamp === calendar.current;
  };

  //Gets the full name of the month for display.
  const getMonthStr = (month: number) => {
    return Months[Math.max(Math.min(11, month), 0)] || 'Month';
  };

  useClickAway(componentElement, () => toggleCalendar(false));

  //Handles the update of the state when the input date is changed.
  const updateDateInput = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    const input: string = e.currentTarget.value;
    setInputState(input);
    if (input) {
      const date = dateFromDateStr(input);
      if (date !== null) {
        const newCalendar: CalendarManager = {
          year: date.year,
          month: date.month - 1,
          current: new Date(date.year, date.month - 1, date.date).getTime(),
          display: monthlyDetails(date.year, date.month - 1)
        };
        dispatch({ type: Actions.ChangeAll, data: newCalendar });
      }
    }
  };

  //Handles the day change when selected.
  const handleDayChange = (day: DateData, e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const newCalendar: CalendarManager = { current: day.timeStamp };
    setInputState(dateFromTimeStamp(day.timeStamp));
    dateSelect?.(dateFromTimeStamp(day.timeStamp));
    dispatch({ type: Actions.ChangeCurrent, data: newCalendar });
    setValue.call(input.current, dateFromTimeStamp(day.timeStamp));
    input.current?.dispatchEvent(new Event('change', { bubbles: true }));
  };

  //Handles Year change button.
  const handleYearChange = (offset: number, e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const year: number = calendar.year + offset;
    const newCalendar: CalendarManager = {
      year: year,
      display: monthlyDetails(year, calendar.month)
    };
    dispatch({ type: Actions.ChangeYear, data: newCalendar });
  };

  //Handles Month change button.
  const handleMonthChange = (offset: number, e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    let year: number = calendar.year;
    let month: number = calendar.month + offset;

    if (month === -1) {
      month = 11;
      year--;
    } else if (month === 12) {
      month = 0;
      year++;
    }

    const newCalendar: CalendarManager = {
      year: year,
      month: month,
      display: monthlyDetails(year, month)
    };
    dispatch({ type: Actions.ChangeMonth, data: newCalendar });
  };

  //Builds the render for the calendar.
  const buildCalender = calendar.display.map((v: DateData, i: number) => {
    return (
      <DayContainer key={i}>
        <Day
          disable={v.month !== 0 ? true : false}
          today={isCurrentDay(v) ? true : false}
          selected={isSelectedDay(v) ? true : false}
          onClick={(e) => handleDayChange(v, e)}
        >
          <span>{v.currentDay}</span>
        </Day>
      </DayContainer>
    );
  });

  //Builds the render for the week titles. IE "Sun" ... "Sat"
  const buildWeekHeader = (
    <WeekWrapper>
      {Object.values(WeekDayAbbreviation).map((i, v) => {
        return <Week key={v}>{i}</Week>;
      })}
    </WeekWrapper>
  );

  //Builds the render for the month/year display and the buttons to move in time.
  const buildControlHeader = (
    <CalendarHeader>
      <OffsetWrapper onClick={(e) => handleYearChange(-1, e)}>
        <Offset>
          <ChevronLeft />
        </Offset>
      </OffsetWrapper>
      <OffsetWrapper onClick={(e) => handleMonthChange(-1, e)}>
        <Offset>
          <ChevronLeft />
        </Offset>
      </OffsetWrapper>
      <HeaderTextContainer>
        <YearText>{calendar.year}</YearText>
        <MonthText>{getMonthStr(calendar.month)}</MonthText>
      </HeaderTextContainer>
      <OffsetWrapper onClick={(e) => handleMonthChange(1, e)}>
        <Offset>
          <ChevronRight />
        </Offset>
      </OffsetWrapper>
      <OffsetWrapper onClick={(e) => handleYearChange(1, e)}>
        <Offset>
          <ChevronRight />
        </Offset>
      </OffsetWrapper>
    </CalendarHeader>
  );

  return (
    <Element ref={componentElement}>
      <InputWrapper
        onClick={() => {
          toggleCalendar(!showCalendar);
        }}
      >
        <CalendarInput
          ref={input}
          type="date"
          max={'9999-12-31'}
          onChange={(e) => {
            if (props.onChange) props.onChange(e);
            updateDateInput(e);
          }}
          value={inputState}
          error={errors}
          instructions={instructions}
          name={name}
          isFocus={showCalendar}
        >
          <DateOption isFocus={showCalendar} error={errors.length > 0 ? true : false}>
            <Calendar />
          </DateOption>
        </CalendarInput>
      </InputWrapper>
      {showCalendar ? (
        <CalendarAnchor>
          <div className="absolute w-full">
            <CalendarContainer>
              {buildControlHeader}
              <CalendarBody>
                <BodyWrapper>
                  {buildWeekHeader}
                  {buildCalender}
                </BodyWrapper>
              </CalendarBody>
            </CalendarContainer>
          </div>
        </CalendarAnchor>
      ) : (
        ''
      )}
    </Element>
  );
};

export const CalendarPicker = forwardRef(_CalendarPicker);

export default CalendarPicker;
