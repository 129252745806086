import { AdminService, Conference, District, InviteUser, User } from '@gbhem/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  inviteUserInformationSchema,
  inviteUserOtherAdminSchema,
  inviteUserSuperAdminSchema
} from '../../models';
import { LocationStoreTypes, useAuthenticatedUser, useLocationStore } from '../../providers';
import { OrderBy } from '../../utils/resource.helper';
import { ErrorText } from '..';
import { Button } from '../Button';
import { Input, Select } from '../Input';

interface UserSignUpProps {
  onClose: () => void;
}

export function UserSignUp({ onClose }: UserSignUpProps) {
  const user: User = useAuthenticatedUser();
  const isSuperAdmin: boolean = !!user.roles.find((r) => r.name === 'Superadmin') || false;

  const { conferences, districts, query } = useLocationStore();

  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Used to build the zod validation schema for react hook forms.
  // Super Admins do not need to have a location on invite
  // Lower Admins do need to have locations
  const buildInviteUserSchema = (isSuperAdmin: boolean) => {
    return isSuperAdmin
      ? inviteUserInformationSchema.merge(inviteUserSuperAdminSchema)
      : inviteUserInformationSchema.merge(inviteUserOtherAdminSchema);
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid }
  } = useForm<InviteUser>({
    mode: 'all',
    resolver: zodResolver(buildInviteUserSchema(isSuperAdmin))
  });

  const storeDistrictLocation = (
    districtLocation: District | undefined,
    districLocType: string
  ) => {
    if (districtLocation) {
      setValue('districtLocation', districtLocation.id);
      setValue('districtLocationType', districLocType);
    }
  };

  const storeConferenceLocation = (
    conferenceLocation: Conference | undefined,
    conferenceLocType: string
  ) => {
    if (conferenceLocation) {
      setValue('conferenceLocation', conferenceLocation.id);
      setValue('conferenceLocationType', conferenceLocType);
    }
  };

  const onSubmit = async () => {
    const values = getValues();
    setIsLoading(true);
    const createUserResponse = await AdminService.signUpUser({
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      email: values.email,
      districtLocation: values.districtLocation,
      districtLocationType: values.districtLocationType,
      conferenceLocation: values.conferenceLocation,
      conferenceLocationType: values.conferenceLocationType
    }).catch((e) => {
      setError(String(e));
    });

    setIsLoading(false);
    if (!createUserResponse.User) {
      setError(createUserResponse.message);
      return;
    }

    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-row space-x-2 my-2">
        <Input
          error={errors.firstName?.message}
          {...(register('firstName'),
          {
            onChange: (e) => setValue('firstName', e.target.value)
          })}
          className="grow"
          name="First Name"
          type="text"
        />
        <Input
          error={errors.lastName?.message}
          {...(register('lastName'),
          {
            onChange: (e) => setValue('lastName', e.target.value)
          })}
          className="grow"
          name="Last Name"
          type="text"
        />
      </div>
      <div className="flex flex-row space-x-2 my-2">
        <Input
          error={errors.email?.message}
          {...(register('email'),
          {
            onChange: (e) => setValue('email', e.target.value, { shouldValidate: true })
          })}
          className="w-1/2"
          name="Email"
          type="email"
        />
      </div>
      <div className="flex flex-row space-x-2 mt-4">
        <p className="text-sm">Please select either a Conference or a District.</p>
      </div>
      <div className="flex flex-row space-x-2 my-2">
        <Select<Conference>
          className="grow"
          error={errors.conferenceLocation?.message}
          {...register('conferenceLocation')}
          placeholder="Select a Conference..."
          name="Conference"
          options={
            isSuperAdmin
              ? (searchTerm: string | undefined) => {
                  if (!searchTerm) return;

                  return query(
                    {
                      conditions: [{ name: searchTerm }],
                      orderBy: OrderBy.Ascending,
                      limit: 10
                    },
                    LocationStoreTypes.Conference
                  );
                }
              : Object.values(conferences) || []
          }
          onSelect={(e) => storeConferenceLocation(e.currentTarget.value, 'Conference')}
        />
        <Select<District>
          className="grow"
          error={errors.districtLocation?.message}
          {...register('districtLocation')}
          placeholder="Select a District..."
          name="District"
          options={
            isSuperAdmin
              ? (searchTerm: string | undefined) => {
                  if (!searchTerm) return;

                  return query(
                    {
                      conditions: [{ name: searchTerm }],
                      orderBy: OrderBy.Ascending,
                      limit: 10
                    },
                    LocationStoreTypes.District
                  );
                }
              : Object.values(districts) || []
          }
          onSelect={(e) => storeDistrictLocation(e.currentTarget.value, 'District')}
        />
      </div>
      <div className="flex flex-row mt-3">
        <Button type="submit" disabled={(!isValid && !isSuperAdmin) || isLoading}>
          Add User
        </Button>
      </div>
      {error && <ErrorText className="mt-4">{error}</ErrorText>}
    </form>
  );
}

export default UserSignUp;
