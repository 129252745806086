import { SVGProps } from 'react';

export function Download(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M49.81 36.282v10.984c0 .22-.032.422-.078.61a2.358 2.358 0 01-2.297 1.89H2.544a2.354 2.354 0 01-2.33-2.095c-.031-.142-.031-.266-.031-.422V36.265a2.35 2.35 0 012.343-2.344 2.347 2.347 0 012.344 2.344v8.796h40.266v-8.796a2.35 2.35 0 012.344-2.344c.64 0 1.236.266 1.655.689.408.437.674 1.03.674 1.672z" />
      <path d="M38.514 25.28L26.997 36.798c-.014.032-.046.046-.063.064-.422.422-.97.688-1.516.766-.046 0-.093.015-.142.015-.093.014-.188.014-.28.014l-.267-.014c-.046 0-.092-.015-.141-.015a2.669 2.669 0 01-1.516-.767l-.064-.063-11.516-11.516a2.73 2.73 0 01-.796-1.939c0-.703.266-1.406.796-1.938 1.062-1.062 2.798-1.062 3.874 0l6.922 6.921.007-25.356A2.749 2.749 0 0125.029.234c.75 0 1.438.313 1.939.796.5.5.796 1.172.796 1.939v25.358l6.921-6.92c1.062-1.063 2.798-1.063 3.874 0 1.016 1.078 1.017 2.812-.045 3.874z" />
    </svg>
  );
}

export default Download;
