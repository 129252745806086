import { User, UserGroup } from '@gbhem/api';
import { useMemo } from 'react';
import { Column } from 'react-table';

import { ColumnLink, Table } from '..';

type InterviewerGroupsTableProps = {
  group: UserGroup;
};

export function InterviewerGroupsTable({ group }: InterviewerGroupsTableProps) {
  const { name, users } = group;
  const columns = useMemo(
    () =>
      [
        {
          Header: 'NAME',
          accessor: 'firstName',
          id: 'firstName',
          Cell: ({ row: { original: value } }) => (
            <ColumnLink to={`/users/${value.id}`}>
              {String(`${value.firstName} ${value.lastName}`)}
            </ColumnLink>
          )
        },
        {
          Header: 'EMAIL',
          accessor: 'email',
          id: 'email',
          disableSortBy: true,
          Cell: ({ value }) => value
        },
        {
          Header: 'JOIN DATE',
          accessor: 'createdAt',
          id: 'createdAt',
          disableSortBy: true,
          Cell: ({ value }) => value
        }
      ] as Column<User>[],
    []
  );

  return <Table columns={columns} data={users} header={`${name} Interviewers`} />;
}
