import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentProps, FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import tw from 'twin.macro';

import { fadeInAnimation, fadeOutAnimation } from '../animations';
import { ChevronDown } from '../icons';

const Container = styled.div`
  ${tw`flex flex-col`}

  & + & {
    ${tw`pt-2 mt-2 border-t border-neutral-500`}
  }
`;

const Header = styled.div<{ disabled?: boolean }>`
  ${tw`flex items-center justify-between flex-1 py-4 text-xl font-bold select-none space-x-4`}
  ${(p) => (!p.disabled ? tw`cursor-pointer` : '')}
`;

const HeaderContent = styled.div`
  ${tw`w-full`}
`;

const Content = styled.div<{ open?: boolean }>`
  ${tw`flex`}
  ${(p) => (!p.open ? tw`overflow-hidden` : '')}

  ${(p) =>
    !p.open
      ? css`
          max-height: 0;
          transition: max-height 0.3s ease-out;
          ${fadeOutAnimation(0.3)}
        `
      : css`
          max-height: 200vh;
          transition: max-height 0.5s ease-in;
          ${fadeInAnimation(0.3)}
        `}
`;

const InnerContent = styled.div`
  ${tw`flex flex-col flex-1`}
`;

const Control = styled(ChevronDown)<{ open?: boolean }>`
  ${tw`transform transition-all`}

  height: 1.5rem;
  width: 1.5rem;

  ${(p) => (p.open ? tw`rotate-180` : '')}
`;

type AccordionProps = ComponentProps<'div'> & {
  header: string | ReactNode;
  isOpen?: boolean;
  children: FC<{ close: () => void }> | ReactNode;
  control?: FC;
  scrollIntoView?: boolean;
  disabled?: boolean;
};

export const Accordion: FC<AccordionProps> = ({
  header,
  isOpen: _isOpen = true,
  children,
  control,
  scrollIntoView,
  disabled,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(!!_isOpen);
  const content = useRef<HTMLDivElement>(null);

  const ControlIcon = control || Control;

  useEffect(() => {
    if (!(isOpen && scrollIntoView)) {
      return;
    }

    setTimeout(() => {
      if (!content.current) {
        return;
      }

      const { bottom } = content.current.getBoundingClientRect();

      if (bottom > window.innerHeight) {
        content.current?.parentElement?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }, 320);
  }, [isOpen, scrollIntoView]);

  useUpdateEffect(() => setIsOpen(!!_isOpen), [_isOpen]);

  const _children = useMemo(
    () =>
      typeof children === 'function' ? children({ close: () => void setIsOpen(false) }) : children,
    [children]
  );

  return (
    <Container {...props}>
      <Header disabled={disabled} onClick={() => !disabled && setIsOpen(!isOpen)}>
        <HeaderContent>{header}</HeaderContent>
        <ControlIcon open={isOpen} />
      </Header>
      <Content open={isOpen} ref={content} className="flex">
        <InnerContent className="flex-reverse">{_children}</InnerContent>
      </Content>
    </Container>
  );
};

export default Accordion;
