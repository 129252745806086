import { District, DistrictService, Permission, User, UserService } from '@gbhem/api';

import { SystemRole } from '../providers';

const filterByCurrentUserLocation = async function (userList: User[], user: User) {
  if (!userList) return;

  const isSuperAdmin = !!user.roles.find((r) => SystemRole.Superadmin.toString() === r.name);
  const isConferenceAdmin = !!user.roles.find(
    (r) => SystemRole.ConferenceAdmin.toString() === r.name
  );
  const isDistrictAdmin = !!user.roles.find((r) => SystemRole.DistrictAdmin.toString() === r.name);

  if (isSuperAdmin)
    return userList.sort((base: User, compare: User) =>
      base.lastName > compare.lastName ? 1 : base.lastName < compare.lastName ? -1 : 0
    );

  const userIds: string[] = [];
  userList.forEach((u: User) => userIds.push(u.id));
  const optionPermissions: Permission[] = await UserService.getUsersPermissions(userIds);

  const userDistrictIds: string[] = [];

  optionPermissions.forEach((permission: Permission) => {
    if (permission.entity === 'District') {
      userDistrictIds.push(permission.entityId);
    }
  });
  const userDistricts: District[] = await DistrictService.getDistrictsByIds(userDistrictIds);
  const currentUserPermissions = await UserService.getUsersPermissions([user.id.toString()]);

  const currentUserDistricts: string[] = [];

  if (isDistrictAdmin && !isConferenceAdmin) {
    currentUserPermissions.forEach((permission: Permission) => {
      if (permission.entity === 'District') currentUserDistricts.push(permission.entityId);
    });
    return userList.filter(
      (u: User) =>
        optionPermissions.filter(
          (permission: Permission) =>
            currentUserDistricts.includes(permission.entityId) && permission.user.id === u.id
        ).length > 0
    );
  }

  const currentUserConferences: string[] = [];
  currentUserPermissions.forEach((permission: Permission) => {
    if (permission.entity === 'Conference') {
      currentUserConferences.push(permission.entityId);
    }
    if (permission.entity === 'District') {
      const districtConference = userDistricts.find(
        (district) => permission.entityId === district.id
      )?.conference.id;
      if (districtConference && !currentUserConferences.includes(districtConference))
        currentUserConferences.push(districtConference);
    }
  });

  return userList.filter(
    (u: User) =>
      optionPermissions.filter((permission) => {
        const userConferenceFromDistrict = userDistricts.find(
          (district: District) => district.id === permission.entityId
        )?.conference.id;
        const userInConference =
          currentUserConferences.includes(permission.entityId) ||
          (userConferenceFromDistrict &&
            currentUserConferences.includes(userConferenceFromDistrict));
        return userInConference && permission.user.id === u.id;
        // User is member of authenticated user's conference
        // or a district under that conference and is not the currently auth'd user
      }).length > 0
  );
};

export { filterByCurrentUserLocation };
