import styled from '@emotion/styled';
import tw from 'twin.macro';

export const View = styled.div`
  ${tw`w-full flex-1 bg-neutral-800 flex flex-col`}

  *::-webkit-scrollbar {
    ${tw`cursor-pointer`}

    height: 0.5rem;
    width: 0.5rem;
  }

  *::-webkit-scrollbar-track {
    ${tw`bg-neutral-800`}
  }

  *::-webkit-scrollbar-thumb {
    ${tw`bg-neutral-600`}
  }
`;

export default View;
