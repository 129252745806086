import { TrackTemplate } from '@gbhem/api';
import { useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { DrillDownList } from '../DrillDownList';

interface ImportProps<T> {
  list: T[];
  onChange?: (value?: T) => void;
}

export function Import({ list, onChange }: ImportProps<TrackTemplate>) {
  const [updates, setUpdates] = useState<Record<string, any[]>>();

  useUpdateEffect(() => {
    if (!updates) {
      return;
    }

    const { track, phases: _phases, steps } = updates;

    const phases =
      _phases?.map((p) => ({
        ...p,
        steps: steps?.filter((t) => p.steps?.find((s: any) => s.id === t.id)) || []
      })) || [];

    onChange?.({ ...track, phases } as any);
  }, [updates]);

  return <DrillDownList list={list} onChange={setUpdates} />;
}

export default Import;
