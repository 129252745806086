import { Button, CardBody, CardFooter, Modal, ModalProps } from '..';

type UserMessageModalProps = Omit<ModalProps, 'onChange'> & {
  name: string;
};

export function UserMessageModal({ name, onClose, children }: UserMessageModalProps) {
  return (
    <Modal name={name} onClose={onClose}>
      {({ close }) => (
        <>
          <CardBody>{children}</CardBody>
          <CardFooter>
            <Button inverted onClick={() => close()}>
              Close
            </Button>
          </CardFooter>
        </>
      )}
    </Modal>
  );
}
