import styled from '@emotion/styled';
import tw from 'twin.macro';

import { Button } from '../Button';

const AddStepContainer = styled.div`
  ${tw`flex justify-around items-center flex-grow mt-4`}
`;

const LargeButton = styled(Button)`
  ${tw`flex flex-grow text-lg h-16 max-w-xs justify-center`}
`;

interface AddStepModalProperties {
  handleNewStep: () => void;
  handleImportStep: () => void;
}

function AddStepModal({ handleNewStep, handleImportStep }: AddStepModalProperties) {
  return (
    <AddStepContainer>
      <LargeButton onClick={handleNewStep}>Add new step</LargeButton>
      <LargeButton inverted onClick={handleImportStep}>
        Import existing step
      </LargeButton>
    </AddStepContainer>
  );
}

export default AddStepModal;
