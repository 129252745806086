import styled from '@emotion/styled';
import { Announcement } from '@gbhem/api';
import tw from 'twin.macro';

import { Card, Modal, ModalProps } from '../../components';

const AnnouncementContainer = styled.div`
  ${tw`max-w-2xl`}
`;

const AnnouncementMessage = styled.div`
  ${tw`p-5 m-5 `}
`;

const AnnouncementBar = styled.hr`
  ${tw`pt-5 pb-5`}
`;

type AnnouncementModalProps = Omit<ModalProps, 'onChange'> & {
  announcements: Announcement[];
};

export function AnnouncementsModal({ onClose, name, announcements }: AnnouncementModalProps) {
  return (
    <Modal name={name} onClose={onClose} disableExpand>
      {() => (
        <div>
          <AnnouncementBar />
          {announcements?.length > 0 &&
            announcements.map((a) => (
              <AnnouncementContainer key={`${a.id}-container`}>
                <AnnouncementMessage key={`${a.id}-message`}>
                  <Card key={`${a.id}-card`}>{a.message}</Card>
                </AnnouncementMessage>
              </AnnouncementContainer>
            ))}
        </div>
      )}
    </Modal>
  );
}

export default AnnouncementsModal;
