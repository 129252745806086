import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';

import {
  DocumentsTable,
  SubNavbar,
  SubNavLink,
  UserEmailVerification,
  UserProfile,
  UsersTable
} from '../../components';
import { UserFormsTable } from '../../components/FormsTable';
import { UserAssignments } from '../../components/UserAssignments';
import { Can } from '../../providers/PermissionProvider';

export function Users() {
  const { url } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={url}>
          <UsersTable />
        </Route>
        <Route path={`${url}/email-verification`}>
          <UserEmailVerification />
        </Route>
        <Route path={`${url}/:id`}>
          <UserPage />
        </Route>
      </Switch>
    </>
  );
}

function UserPage() {
  const { url } = useRouteMatch();
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <SubNavbar>
        <SubNavLink exact to={url}>
          Profile
        </SubNavLink>
        <Can do="update" on="User">
          <SubNavLink to={`${url}/documents`}>Documents</SubNavLink>
          {/* TODO: if desired, block participants from seeing this tab for themselves */}
          <SubNavLink to={`${url}/user-assignments`}>User Assignments</SubNavLink>
          <SubNavLink to={`${url}/forms`}>Forms</SubNavLink>
        </Can>
      </SubNavbar>
      <Switch>
        <Route path={`${url}/user-assignments`}>
          <UserAssignments userId={id} />
        </Route>
        <Route path={`${url}/documents`}>
          <DocumentsTable userId={id} />
        </Route>
        <Route path={`${url}/forms`}>
          <UserFormsTable userId={id} />
        </Route>
        <Route>
          <UserProfile />
        </Route>
      </Switch>
    </>
  );
}

export default Users;
