import styled from '@emotion/styled';
import { UserService } from '@gbhem/api';
import { useMemo, useState } from 'react';
import { Column } from 'react-table';
import { useAsync } from 'react-use';
import tw from 'twin.macro';

import { ColumnLink, Table } from '..';

const Container = styled.div`
  ${tw`flex w-full h-full`}
`;

const AssignmentsSection = styled.div`
  ${tw`bg-neutral-1000 w-full mt-5 mx-24 mb-24 p-10`}
`;

type UserAssignmentRow = {
  assignmentUserId: string;
  assignmentName: string;
  email: string;
  trackName: string;
  roleName: string;
};

interface UserAssignmentsProperties {
  userId: string;
}

export function UserAssignments({ userId }: UserAssignmentsProperties) {
  const [assignmentRows, setAssignmentRows] = useState<UserAssignmentRow[]>([]);

  useAsync(async () => {
    const assignedUsers = await UserService.getAssignedUsers(userId);

    const rows: UserAssignmentRow[] = assignedUsers.map((au) => ({
      assignmentUserId: au.trackInstance.participant.id,
      assignmentName: `${au.trackInstance.participant.firstName} ${au.trackInstance.participant.lastName}`,
      email: au.trackInstance.participant.email,
      trackName: au.trackInstance.trackTemplate.name,
      roleName: au.role.name
    }));

    setAssignmentRows(rows);
  }, [userId]);

  const columns = useMemo(
    () =>
      [
        {
          Header: 'NAME',
          accessor: 'assignmentName',
          id: 'assignmentName',
          disableSortBy: true,
          Cell: ({ row: { original: value } }) => (
            <ColumnLink to={`/users/${value.assignmentUserId}`}>{value.assignmentName}</ColumnLink>
          )
        },
        {
          Header: 'EMAIL',
          accessor: 'email',
          id: 'email',
          Cell: ({ value }) => value
        },
        {
          Header: 'TRACK NAME',
          accessor: 'trackName',
          id: 'trackName',
          Cell: ({ value }) => value
        },
        {
          Header: 'ROLE',
          accessor: 'roleName',
          id: 'roleName',
          Cell: ({ value }) => value
        }
      ] as Column<UserAssignmentRow>[],
    []
  );

  return (
    <Container>
      <AssignmentsSection>
        {assignmentRows ? <Table columns={columns} data={assignmentRows} /> : null}
      </AssignmentsSection>
    </Container>
  );
}

export default UserAssignments;
