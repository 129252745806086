import { Field as FieldBase, Properties as PropertiesBase } from '@gbhem/api';

export type Properties = PropertiesBase & Record<string, any>;

export type Field = Omit<FieldBase, 'form'> & {
  properties: Properties;
};

export enum FieldType {
  Number = 'number',

  Text = 'text',
  TextArea = 'textarea',

  Checkbox = 'checkbox',
  Radio = 'radio',

  Date = 'date',
  Time = 'time',

  File = 'file',

  Select = 'select',

  Link = 'link',

  Heading = 'heading',
  Paragraph = 'paragraph',
  Separator = 'separator',
  Section = 'section',

  Address = 'address',
  PhoneNumber = 'phone',

  List = 'list',

  LinkEntry = 'linkEntry'
}
