import { Phase as PhaseModel } from '@gbhem/api';
import { useForm } from 'react-hook-form';

import { Button } from '../../components';
import { Input, TextArea } from '../Input';

interface PhaseEditorProperties {
  phase?: PhaseModel;
  onSubmit: (phase: Omit<PhaseModel, 'trackTemplate' | 'stepTemplates'>) => void;
}

export function PhaseEditor({ phase, onSubmit }: PhaseEditorProperties) {
  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm<Omit<PhaseModel, 'trackTemplate' | 'stepTemplates' | 'originPhase'>>({
    defaultValues: phase ?? { name: '' },
    mode: 'all'
  });

  return (
    <form onSubmit={handleSubmit((phase) => onSubmit(phase))} className="space-y-4">
      {/* @ts-ignore */}
      <Input {...register('name', { required: 'Name is required.' })} />
      <TextArea {...register('description')} />
      <Button disabled={!isValid}>Save</Button>
    </form>
  );
}

export default PhaseEditor;
