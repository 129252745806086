import styled from '@emotion/styled/macro';
import { ComponentProps, forwardRef } from 'react';
import tw from 'twin.macro';

import { ErrorText } from '../ErrorText';

export type TextAreaMetadataProps = {
  instructions?: string;
  label?: string;
  error?: string;
};

//Intersect ComponentProps textarea and TextAreaMetadata
//Create new Type.
export type TextAreaProps = ComponentProps<'textarea'> & TextAreaMetadataProps;

//TODO-- Styles used from Input. Will probably need to be changed.
const Label = styled.label`
  ${tw`self-start p-0 text-xs`}
`;

const Element = styled.div`
  ${tw`flex flex-col gap-1`}
`;

const ControlWrapper = styled.div`
  ${tw`relative block`}
`;

const Control = styled.textarea<{ error?: boolean }>`
  ${tw`bg-neutral-900 border-neutral-500 focus:border-primary border py-1 px-2 rounded-sm outline-none w-full overflow-y-scroll text-sm`}

  min-height: 6rem;

  ::selection {
    ${tw`bg-secondary`};
  }
  ${(p) => (p.error ? tw`border-pink` : tw`border-neutral-500 focus:border-primary`)}
`;

const Instructions = styled.div`
  ${tw`text-sm`}
`;

const MaxLengthIndicator = styled.div`
  ${tw`text-xs flex justify-end`}
`;

const Nudge = styled.span`
  font-size: 0.6rem;
  ${tw`ml-1 text-red-400`}
`;
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ instructions, children, label, error, ...props }, ref) => {
    let errors: string[] = [];
    if (error?.length) errors = Array.isArray(error) ? error : [error];
    return (
      <Element>
        {label && (
          <Label>
            {label} {props.required && <Nudge>* Required</Nudge>}
          </Label>
        )}
        {props.name && !label && (
          <Label>
            {props.name} {props.required && <Nudge>* Required</Nudge>}
          </Label>
        )}
        {instructions && <Instructions>{instructions}</Instructions>}
        <ControlWrapper>
          <Control {...props} ref={ref} error={errors.length > 0} />
          {props.maxLength && (
            <MaxLengthIndicator>
              {props.value?.toString().length || 0}/{props.maxLength} Max
            </MaxLengthIndicator>
          )}
          {children}
        </ControlWrapper>
        {errors.map((error) => (
          <ErrorText key={error}>{error}</ErrorText>
        ))}
      </Element>
    );
  }
);

export default TextArea;
