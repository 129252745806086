import styled from '@emotion/styled';
import { ComponentProps, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import tw from 'twin.macro';

const Container = styled.div`
  ${tw`inline-flex flex-col`}
`;

const Wrapper = styled.div`
  ${tw`relative flex-initial -top-3 z-50`}
`;

type DismissableProps = ComponentProps<'div'> & {
  open?: boolean;
  control: ReactNode;
  ignoreInternalClicks?: boolean;
};

export const Dismissable: FC<DismissableProps> = ({
  open,
  children,
  control,
  ignoreInternalClicks,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const element = useRef<HTMLDivElement>(null);
  const toggleElement = useRef<HTMLDivElement>(null);

  useEffect(() => void setIsOpen(open), [open]);

  useClickAway(element, (e) => {
    if (!(e.target && e.target instanceof Element && toggleElement.current)) {
      return;
    }

    if (!toggleElement.current.contains(e.target as Element)) {
      setIsOpen(false);
    }
  });

  useClickAway(
    toggleElement,
    () => {
      if (ignoreInternalClicks) {
        return;
      }

      setIsOpen(false);
    },
    ['mouseup']
  );

  useEffect(() => {
    if (!isOpen || !element.current?.firstElementChild) {
      return;
    }

    const content = element.current.firstElementChild as HTMLElement;

    const { x, y, width, height } = content.getBoundingClientRect();

    if (x < 0) {
      content.style.left = '1rem';
    }

    if (y < 0) {
      content.style.top = '1rem';
    }

    if (x + width > window.innerWidth) {
      content.style.right = '1rem';
    }

    if (y + height > window.innerHeight) {
      content.style.bottom = '1rem';
    }
  }, [isOpen]);

  return (
    <Container {...props}>
      <div
        ref={toggleElement}
        className="cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();

          setIsOpen(!isOpen);
        }}
      >
        {control}
      </div>
      <Wrapper ref={element} hidden={!isOpen} onClick={(e) => e.stopPropagation()}>
        {children}
      </Wrapper>
    </Container>
  );
};

export default Dismissable;
