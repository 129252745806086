import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import tw from 'twin.macro';

import { fadeInAnimation } from '../animations';
import { ChevronRight } from '../icons';
import { Input } from '../Input';

export type DrillDownLevel = {
  name: string;
  singularName?: string;
  multiSelect?: boolean;
  child?: DrillDownLevel;
  displayProperty?: string;
};

export const drillConfig: DrillDownLevel = {
  name: 'tracks',
  singularName: 'track',
  child: {
    name: 'phases',
    multiSelect: true,
    child: {
      name: 'steps',
      multiSelect: true
    }
  }
};

interface DrillDownLevelProps<T extends Record<string, any>> {
  config?: DrillDownLevel;
  list: T[];
  onChange?: (selection: Record<string, any[]>) => void;
}

const Container = styled.div`
  ${tw`flex flex-col bg-neutral-1000 gap-2`}
`;

const Instruction = styled.div`
  ${tw`text-xs text-neutral-500`}
`;

const Controls = styled.div`
  ${tw`flex`}

  > * {
    :nth-of-type(even) {
      ${tw`border-l border-r border-neutral-500`}
    }
  }
`;

const Title = styled.div`
  ${tw`mb-4 font-bold capitalize`}
`;

const List = styled.div`
  ${tw`flex flex-col max-w-xs p-3`}

  ${fadeInAnimation(0.3)}
`;

const Item = styled.div<{ selected?: boolean }>`
  ${tw`flex items-center justify-between px-3 py-2 font-bold capitalize cursor-pointer select-none hover:bg-neutral-900 hover:text-pink gap-10`}

  ${(p) => (p.selected ? tw`bg-neutral-900 text-pink` : '')}

  ${fadeInAnimation(0.3)}

  svg {
    height: 1rem;
    width: 1rem;
    ${tw`text-neutral-500`}
  }
`;

const Details = styled.div`
  ${tw`flex flex-col`}
`;

const Name = styled.div``;

const NextLevelPreview = styled.div`
  ${tw`text-sm font-light text-neutral-500`}
`;

const Modifier = styled.span`
  ${tw`px-1 rounded-sm bg-neutral-700 text-neutral-1000`}
`;

export const getMaxLevel = (root: DrillDownLevel) => {
  let count = 1;
  let level: DrillDownLevel | undefined = root;

  while (level?.child) {
    level = level.child;
    count++;
  }

  return count;
};

export const getLevelConfig = (root: DrillDownLevel, level: number) => {
  let config = root;
  for (let traversal = 0; traversal < level; traversal++) {
    if (!config.child) {
      throw new Error(`No configuration provided for level ${level} of ${root.name} config.`);
    }

    config = config.child;
  }

  return config;
};

export const getPathToLevel = (root: DrillDownLevel, level: number): string[] => {
  let path: string[] = [];

  for (let traversal = 0; traversal < level; traversal++) {
    const config = getLevelConfig(root, traversal);
    if (!config.child) {
      throw new Error(`No configuration provided for level ${level} of ${root.name} config.`);
    }

    path = [...path, config.child.name];
  }

  return path;
};

export function DrillDownList<T extends Record<string, any>>({
  config = drillConfig,
  list,
  onChange
}: DrillDownLevelProps<T>) {
  const [level, setLevel] = useState(1);
  const [levelSelections, setLevelSelections] = useState<any[]>([]);

  useUpdateEffect(() => {
    const path = getPathToLevel(config, level - 1);
    onChange?.(
      path.reduce(
        (changes, path, index) => ({
          ...changes,
          [path]: levelSelections[index + 1]?.map((s: any) => ({ ...s }))
        }),
        {} as Record<string, any[]>
      )
    );
  }, [levelSelections]);

  const options = [list];
  for (let traversal = 1; traversal < level; traversal++) {
    const { name } = getLevelConfig(config, traversal);

    if (!name) {
      continue;
    }

    options.push(
      levelSelections[traversal - 1].reduce(
        (selections: T[], s: Record<string, any>) => [...selections, ...(s[name] || [])],
        [] as T[]
      )
    );
  }

  const maxLevel = getMaxLevel(config);

  const configs = useMemo(
    () => Array.from({ length: maxLevel }).map((_, level) => getLevelConfig(config, level)),
    [config, maxLevel]
  );

  const selectLevel = (selectedLevel: number, item: any, multi?: boolean) => {
    const existingItemSelectionIndex =
      levelSelections[selectedLevel - 1]?.findIndex((s: any) => s === item) ?? -1;

    if (existingItemSelectionIndex !== -1) {
      levelSelections[selectedLevel - 1]?.splice(existingItemSelectionIndex, 1);
    } else {
      levelSelections[selectedLevel - 1] = [
        ...(multi && configs[selectedLevel - 1].multiSelect
          ? [...(levelSelections[selectedLevel - 1] || []), item]
          : [item])
      ];
    }

    setLevelSelections([...levelSelections.slice(0, selectedLevel)]);
    if (selectedLevel < maxLevel) {
      setLevel(selectedLevel + 1);
    }
  };

  return (
    <Container>
      <Instruction>
        <Modifier>Shift</Modifier> + click to select multiple items in a list (if supported)
      </Instruction>
      <Controls>
        {options.map((list, l) => (
          <List key={`list-${l}`}>
            <Title>
              Select{' '}
              {configs[l].multiSelect
                ? configs[l].name
                : configs[l].singularName || configs[l].name}
            </Title>
            {configs[l].multiSelect ? (
              <Input
                name="Select all"
                type="checkbox"
                onClick={() => {
                  levelSelections[l] = [
                    ...(levelSelections[l]?.length === list.length ? [] : list)
                  ];

                  setLevelSelections([...levelSelections]);
                }}
              />
            ) : null}
            {list
              .filter(
                (item) =>
                  (configs[l].child?.name && item[configs[l].child!.name].length) ||
                  !configs[l].child
              )
              .map((item, i) => (
                <Item
                  key={`list-${l}-item-${i}`}
                  onClick={(e) => selectLevel(l + 1, item, e.shiftKey)}
                  selected={levelSelections[l]?.find((i: any) => i.id === item.id)}
                >
                  <Details>
                    <Name>{item[configs[l].displayProperty || 'name']}</Name>
                    {configs[l].child && (
                      <NextLevelPreview>
                        {item[configs[l].child!.name].length} {configs[l].child!.name}
                      </NextLevelPreview>
                    )}
                  </Details>
                  {configs[l].child && <ChevronRight />}
                </Item>
              ))}
          </List>
        ))}
      </Controls>
    </Container>
  );
}

export default DrillDownList;
