import styled from '@emotion/styled';
import {
  ComponentProps,
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useClickAway } from 'react-use';
import tw from 'twin.macro';

import { Button } from '../Button';
import { Cancel, Time } from '../icons';
import { Input, InputMetadataProps, Select } from '../Input';

const Element = styled.div<{ hidden?: boolean }>`
  ${tw`w-full pb-1 float-left relative flex flex-col gap-1`}

  ${(p) => (p.hidden ? tw`hidden` : '')}
`;

const InputWrapper = styled.div`
  ${tw`overflow-hidden float-left`}
  svg {
    height: 0.85rem;
    width: 1rem;
  }
`;

const TimeSelect = styled(Select)`
  select + div {
    ${tw`col-span-1 border-0`}
  }

  input {
    ${tw`hidden`}
  }
`;

const TimeOption = styled.div<{ error?: boolean }>`
  ${tw`absolute flex items-center justify-center h-full p-2 top-0 right-0 `}
  ${(p) => (p.error ? tw`border-pink` : tw`border-primary`)}
`;

const ComboBox = styled.div`
  ${tw`grid grid-cols-3`}
`;

const RemoveTime = styled(Button)`
  ${tw`bg-transparent border-transparent`}
  svg {
    height: 0.85rem;
    width: 1rem;
  }
`;

const InputTime = styled(Input)`
  ${tw`cursor-pointer caret-transparent`}
`;

export type TimePickerMetadataProps = {
  error?: string | string[];
  instructions?: string;
  value?: string;
};

enum TimeStandards {
  BASE = '--:-- --',
  AM = 'AM',
  PM = 'PM',
  HOUR = 'hour',
  MINUTE = 'minute',
  TOD = 'tod'
}

const parseInitTime = (time: string | undefined, section: string) => {
  if (time && time !== '') {
    const splitHour = time.split(':');
    const splitMinuteDoT = splitHour[1].split(' ');
    switch (section) {
      case TimeStandards.HOUR:
        return splitHour[0];
      case TimeStandards.MINUTE:
        return splitMinuteDoT[0];
      case TimeStandards.TOD:
        return splitMinuteDoT[1];
    }
  }
  return '--';
};

const setValue = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')?.set!;

export type TimePickerProps = Omit<ComponentProps<'input'>, 'value'> &
  TimePickerMetadataProps &
  InputMetadataProps;

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>(
  ({ error, instructions, name, ...props }, ref: Ref<HTMLInputElement | null>) => {
    const [showTime, setShowTime] = useState<boolean>(false);

    const [_hours, setHours] = useState<string>(parseInitTime(props.value, TimeStandards.HOUR));
    const [_minutes, setMinutes] = useState<string>(
      parseInitTime(props.value, TimeStandards.MINUTE)
    );
    const [_isMorning, setIsMorning] = useState<string>(
      parseInitTime(props.value, TimeStandards.TOD)
    );

    const input = useRef<HTMLInputElement>(null);
    const componentElement = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => {
        if (!input.current) {
          return input.current;
        }

        return {
          ...input.current,
          value: `${_hours}:${_minutes} ${_isMorning}`
        };
      },
      [_hours, _minutes, _isMorning]
    );

    let errors: string[] = [];
    if (error?.length) {
      errors = Array.isArray(error) ? error : [error];
    }

    const addLeadingZero = (value: number) => {
      return value < 10 ? `0${value}` : `${value}`;
    };

    useEffect(() => {
      setValue.call(input.current, `${_hours}:${_minutes} ${_isMorning}`);
      input.current?.dispatchEvent(new Event('change', { bubbles: true }));
    }, [_hours, _minutes, _isMorning]);

    //Dropdown Fill
    const hours: string[] = Array.from({ length: 12 }, (_, i) => addLeadingZero(i + 1));
    const minutes: string[] = Array.from({ length: 60 }, (_, i) => addLeadingZero(i));
    const period: string[] = [TimeStandards.AM, TimeStandards.PM];

    useClickAway(componentElement, () => setShowTime(false));

    return (
      <Element ref={componentElement}>
        <InputWrapper>
          <InputTime
            {...props}
            ref={input}
            error={errors}
            placeholder={props?.placeholder}
            instructions={instructions}
            name={name}
            onClick={() => setShowTime(!showTime)}
            value={props.value || TimeStandards.BASE}
          >
            <TimeOption error={errors.length > 0 ? true : false}>
              {props.value && props.value !== TimeStandards.BASE ? (
                <RemoveTime
                  onClick={() => {
                    setHours('--');
                    setMinutes('--');
                    setIsMorning('--');
                    setShowTime(false);
                  }}
                  inverted
                >
                  <Cancel />
                </RemoveTime>
              ) : null}
              <Time />
            </TimeOption>
          </InputTime>
        </InputWrapper>

        {showTime ? (
          <ComboBox>
            <TimeSelect
              value={_hours || ''}
              options={hours}
              onChange={(e) => setHours(String(e.target.value).padStart(2, '0'))}
              open
            />
            <TimeSelect
              value={_minutes || ''}
              options={minutes}
              onChange={(e) => setMinutes(String(e.target.value).padStart(2, '0'))}
              open
            />
            <TimeSelect
              value={_isMorning || ''}
              options={period}
              onChange={(e) => setIsMorning(String(e.target.value))}
              open
            />
          </ComboBox>
        ) : null}
      </Element>
    );
  }
);
