import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const blink = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
    transform: scale(1.05);
  }
  
  100% {
    opacity: 1;
  }
`;

export const blinkAnimation = (time?: number) => css`
  animation: ${blink} ${time || 0.3}s infinite ease-in-out;
`;

export const Blink = styled.div`
  ${blinkAnimation(0.1)}
`;
