"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class UserService {
    static user() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/user',
        });
    }
    static getAbilities() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/abilities',
        });
    }
    static comment(id, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/{id}/comment',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static removeComments(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user/comments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static getUsersByCurrentUser(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/get-users-by-current-user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static aliases(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/{id}/alias',
            path: {
                'id': id,
            },
        });
    }
    static addAlias(id, name) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/{id}/alias/{name}',
            path: {
                'id': id,
                'name': name,
            },
        });
    }
    static deleteAlias(id, aliasId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user/{id}/alias/{aliasId}',
            path: {
                'id': id,
                'aliasId': aliasId,
            },
        });
    }
    static searchTags(searchTerm) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/tag',
            query: {
                'searchTerm': searchTerm,
            },
        });
    }
    static addNewTag(id, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/{id}/tag',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static addTag(id, tagId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/{id}/tag/{tagId}',
            path: {
                'id': id,
                'tagId': tagId,
            },
        });
    }
    static removeTag(id, tagId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user/{id}/tag/{tagId}',
            path: {
                'id': id,
                'tagId': tagId,
            },
        });
    }
    static deleteTag(tagId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user/tag/{tagId}',
            path: {
                'tagId': tagId,
            },
        });
    }
    static getParticipantTracks(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/{id}/tracks',
            path: {
                'id': id,
            },
        });
    }
    static getAssignedUsers(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/{id}/assigned-users',
            path: {
                'id': id,
            },
        });
    }
    static getAdvisors() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/advisors',
        });
    }
    static addLocationPermission(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/location',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static selectPrimaryLocationPermission(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/user/location',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static addLocationPermissions(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/locations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static deactivateLocationPermission(permissionId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user/location/{permissionId}',
            path: {
                'permissionId': permissionId,
            },
        });
    }
    static getPermissionHierarchy() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/permission-hierarchy',
        });
    }
    static getLocationDetails(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/{id}/locations-details',
            path: {
                'id': id,
            },
        });
    }
    static getUserConferences(includeSystemTrack) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/user-conferences/{includeSystemTrack}',
            path: {
                'includeSystemTrack': includeSystemTrack,
            },
        });
    }
    static getCurrentUserTrackTemplates() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/templates/{userId}',
        });
    }
    static addNewAvatar(userId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/{userId}/avatar',
            path: {
                'userId': userId,
            },
        });
    }
    static getActiveInterviewers() {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/active-interviewers',
        });
    }
    static getUser(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/get-user/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getUsers(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/get-users/{ids}',
            query: {
                'ids': ids,
            },
        });
    }
    static getUsersPermissions(ids) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/users-permissions',
            query: {
                'ids': ids,
            },
        });
    }
    static getUserPermissions(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/user-permissions',
            query: {
                'id': id,
            },
        });
    }
    static getUserVisibleLocations(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/user-visible-locations/{id}',
            path: {
                'id': id,
            },
        });
    }
    static getMentorRelationshipOptions(userId, mentorSearch, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/get-mentor-options',
            query: {
                'userId': userId,
                'mentorSearch': mentorSearch,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static assignUsersToMentor(mentorId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/{userId}/assign',
            query: {
                'mentorId': mentorId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static getUserMentors(userId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/{userId}/mentors',
            path: {
                'userId': userId,
            },
        });
    }
    static getUserMentees(userId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/{userId}/mentees',
            path: {
                'userId': userId,
            },
        });
    }
    static removeMentees(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user/mentor-assignments/remove-mentees',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static unassignMentorsFromUser(userId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user/{userId}/unassign-mentors-from-user',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static unassignMenteesFromUser(userId, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user/{userId}/unassign-mentees-from-user',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static getUserProfileBasicInfo(userId, canEdit) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/user-profile-basic-info/{userId}/{canEdit}',
            path: {
                'userId': userId,
                'canEdit': canEdit,
            },
        });
    }
    static getUserProfileTrackInstances(userId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/user-profile-track-instances/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    static getUserProfileComments(userId) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/user-profile-comments/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    static mergeUser(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/user/user-profile/merge',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static create(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static find(limit, offset, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user',
            query: {
                'limit': limit,
                'offset': offset,
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static updateMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static removeMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static createMany(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/bulk',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static quickSearch(tableName, limit, offset, relations, isQuickSearch, orderBy, where) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/quickSearch',
            query: {
                'tableName': tableName,
                'limit': limit,
                'offset': offset,
                'relations': relations,
                'isQuickSearch': isQuickSearch,
                'orderBy': orderBy,
                'where': where,
            },
        });
    }
    static count(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/count',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static query(requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/query',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static findOne(id, relations, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/api/user/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static update(id, requestBody) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'PATCH',
            url: '/api/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    static remove(id) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'DELETE',
            url: '/api/user/{id}',
            path: {
                'id': id,
            },
        });
    }
    static search(requestBody, relations) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/api/user/search',
            query: {
                'relations': relations,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
exports.UserService = UserService;
