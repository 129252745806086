import { ApiError, SecurityService, User, UserService } from '@gbhem/api';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAsync } from 'react-use';

import { Loader } from '../components';
import useLocationStore from './LocationsProvider';

const UserContext = createContext<User>(undefined!);

export const AuthenticationProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User>();
  const location = useLocation();

  // Load the users location information.
  const { load } = useLocationStore();
  useEffect(() => {
    if (user) {
      load(user.id);
    }
  }, [user, load]);

  useAsync(async () => {
    try {
      setUser(await UserService.user());
    } catch (error) {
      if (!(error instanceof ApiError) || error.status !== 401) {
        throw error;
      }

      const { url } = await SecurityService.logInSpa(window.location.pathname);
      window.location.assign(url);
    }
  }, [location]);

  if (!user) {
    return <Loader message="Loading your account..." />;
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default AuthenticationProvider;

export const useAuthenticatedUser = () => useContext(UserContext);
