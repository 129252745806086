import { TrackTemplate } from '@gbhem/api';

import { ParticipantsDashboard } from '../../components/Participants';

interface ParticipantsProperties {
  track: TrackTemplate;
}

export function Participants({ track }: ParticipantsProperties) {
  return <ParticipantsDashboard track={track} />;
}

export default Participants;
