import { css, keyframes } from '@emotion/react';

export const grow = keyframes`
  0% {
    flex: 0;
  }
  100% {
    flex: 1;
  }
`;

export const growAnimation = (time?: number) => css`
  animation: ${grow} ${time || 0.3}s ease-in;
`;
