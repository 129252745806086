export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum GenderOptions {
  Male = 'Male',
  Female = 'Female',
  NonBinary = 'Non-binary',
  NotSay = 'Prefer not to say'
}

export enum LanguageOptions {
  English = 'English',
  French = 'French',
  German = 'German',
  Hmong = 'Hmong',
  Italian = 'Italian',
  Japanese = 'Japanese',
  Korean = 'Korean',
  Mandarin = 'Mandarin',
  Portuguese = 'Portuguese',
  Russian = 'Russian',
  Spanish = 'Spanish',
  Swahili = 'Swahili',
  Vietnamese = 'Vietnamese'
}

export enum StateOptions {
  AL = 'AL', // Alabama
  AK = 'AK', // Alaska
  AZ = 'AZ', // Arizona
  AR = 'AR', // Arkansas
  CA = 'CA', // California
  CO = 'CO', // Colorado
  CT = 'CT', // Connecticut
  DC = 'DC', // District of Columbia (for address purposes)
  DE = 'DE', // Delaware
  FL = 'FL', // Florida
  GA = 'GA', // Georgia
  HI = 'HI', // Hawaii
  ID = 'ID', // Idaho
  IL = 'IL', // Illinois
  IN = 'IN', // Indiana
  IA = 'IA', // Iowa
  KS = 'KS', // Kansas
  KY = 'KY', // Kentucky
  LA = 'LA', // Louisiana
  ME = 'ME', // Maine
  MD = 'MD', // Maryland
  MA = 'MA', // Massachusetts
  MI = 'MI', // Michigan
  MN = 'MN', // Minnesota
  MS = 'MS', // Mississippi
  MO = 'MO', // Missouri
  MT = 'MT', // Montana
  NE = 'NE', // Nebraska
  NV = 'NV', // Nevada
  NH = 'NH', // New Hampshire
  NJ = 'NJ', // New Jersey
  NM = 'NM', // New Mexico
  NY = 'NY', // New York
  NC = 'NC', // North Carolina
  ND = 'ND ', // North Dakota
  OH = 'OH', // Ohio
  OK = 'OK', // Oklahoma
  OR = 'OR', // Oregon
  PA = 'PA', // Pennsylvania
  RI = 'RI', // Rhode Island
  SC = 'SC', // South Carolina
  SD = 'SD', // South Dakota
  TN = 'TN', // Tennessee
  TX = 'TX', // Texas
  UT = 'UT', // Utah
  VT = 'VT', // Vermont
  VA = 'VA', // Virginia
  WA = 'WA', // Washington
  WV = 'WV', // West Virginia
  WI = 'WI', // Wisconsin
  WY = 'WY' // Wyoming
}

export enum TitleOptions {
  Mr = 'Mr.',
  Mrs = 'Mrs.',
  Ms = 'Ms.',
  Prof = 'Prof.',
  Dr = 'Dr.',
  Rev = 'Rev.',
  RevDr = 'Rev. Dr.'
}

export interface UserAvatar {
  avatarImg: string;
}

// Marking original values next to new values updated to match salesforce.
// TODO: GBHEM needs to get salesforce admin to update ethnic background options to what GBHEM wants
// then these changes can be reversed. (will also need to update values in user table when reversal happens)
export enum EthnicBackgroundOptions {
  AmericanIndianOrAlaskanNative = 'American Indian or Alaska Native', //American Indian or Alaskan Native
  Asian = 'Asian',
  BlackOrAfricanAmerican = 'Black or African American',
  Hispanic = 'Hispanic/Latino', //Hispanic
  LatinoAXOrSpanish = 'Latino/a/x. or Spanish',
  MiddleEasternOrNorthAfrican = 'Middle Eastern or North African',
  NativeHawaiianOrOtherPacificIslander = 'Native Hawaiian or Pacific Islander', //Native Hawaiian or Other Pacific Islander
  White = 'Caucasian/White', //White
  Multiracial = 'Multiracial',
  PreferNotToSay = 'Declined', //Prefer not to say
  SomeOtherRaceOrEthnicity = 'Some other race or ethnicity'
}

export enum Suffix {
  Senior = 'Sr.',
  Junior = 'Jr.',
  II = 'II',
  III = 'III',
  IV = 'IV',
  PhD = 'PhD',
  Esquire = 'Esq.',
  MD = 'MD',
  Ret = 'Ret.'
}
