import { SVGProps } from 'react';

export function Expand(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={64}
      height={64}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`${props.className} fill-current`}
    >
      <path d="M25.166 15.69l.033 4.804H39.24L13.91 45.826l3.393 3.393 25.5-25.499v14.378l4.805.033V15.69H25.166z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.09 57h35.894c3.84 0 7.016-3.175 7.019-7.088V14.09A7.092 7.092 0 0049.912 7H14.09A7.092 7.092 0 007 14.09v35.82A7.092 7.092 0 0014.09 57zm-.812-42.909c0-.444.369-.812.813-.812h35.818c.444 0 .813.368.813.812l.002 35.821a.82.82 0 01-.812.813H14.09a.819.819 0 01-.813-.813v-35.82z"
      />
    </svg>
  );
}

export default Expand;
