import styled from '@emotion/styled';
import { Cell as CellModel, Layout as LayoutModel } from '@gbhem/api';
import { ComponentProps, FC, memo, useMemo } from 'react';
import tw from 'twin.macro';

const CellContainer = styled.div<{
  span?: number;
}>`
  flex: ${(p) => p.span || 1};
  transition: all 0.1s linear;

  ${tw`flex relative h-full`}
`;

export const Row = styled(CellContainer)`
  flex-direction: row;
  padding: 0.75rem 0;
`;

export const Column = styled(CellContainer)`
  flex-direction: column;
`;

const Content = styled.div`
  ${tw`flex flex-col w-full`}
`;

export type CellBody = FC<CellModel & { depth?: number }>;

export type CellProps = ComponentProps<'div'> &
  CellModel & {
    depth?: number;
    body: CellBody;
  };

function Cell({ id, span = 1, children, body: Body, depth = 1, ...props }: CellProps) {
  const Container = useMemo(() => (depth % 2 === 1 ? Row : Column), [depth]);

  if (!children) {
    return (
      <Container {...props} id={id} span={span || 1}>
        <Content>
          <Body id={id} span={span} {...props} />
        </Content>
      </Container>
    );
  }

  if (props.fieldId) {
    const { fieldId: _, ...rest } = props;

    return (
      <div id={id}>
        <Body id={id} span={span} {...props} depth={depth + 1}>
          {
            children.map((cell) => (
              <Cell key={cell.id} {...rest} {...cell} depth={depth + 2} body={Body} />
            )) as any
          }
        </Body>
      </div>
    );
  }

  return (
    <Container id={id} span={children.reduce((s, c) => s + (c.span || 0), span || 0)}>
      {children.map((cell) => (
        <Cell key={cell.id} {...cell} depth={depth + 1} body={Body} {...props} />
      ))}
    </Container>
  );
}

interface LayoutProps {
  layout: LayoutModel;
  cell: CellBody;
  editable?: boolean;
}

export const Layout = memo(({ layout, cell: Body, editable }: LayoutProps) => (
  <>
    {layout.children.map((c) => (
      <Cell key={c.id} {...c} draggable={editable} body={Body} />
    ))}
  </>
));

export default Layout;
