import styled from '@emotion/styled';
import { ChurchMembership, User } from '@gbhem/api';
import { useState } from 'react';
import { useModal } from 'react-modal-hook';
import tw from 'twin.macro';

import { Can, SystemRole, useAuthenticatedUser } from '../../providers';
import { useUserInfoStore } from '../../providers/UserProfileProvider/UserInfoProvider';
import { AddPermissionsModal } from '../AddPermissionsModal';
import { Button } from '../Button';
import { CardFooter } from '../Card';
import { Cancel } from '../icons';
import { Input } from '../Input';
import { Modal, ModalProps } from '../Modal';

const Membership = styled.div`
  ${tw`flex items-start border rounded-sm border-neutral-400 space-x-2`}

  svg {
    ${tw`w-3 h-3`}
  }
`;

interface ChurchMembershipsProps {
  user: User;
}

export function ChurchMemberships({ user }: ChurchMembershipsProps) {
  const [deactivateMembership, setDeactivateMembership] = useState<ChurchMembership>();
  const authenticatedUser = useAuthenticatedUser();

  const { updateChurchMembership } = useUserInfoStore();

  const [showAddMembershipModal, hideAddMembershipModal] = useModal(
    () => (
      <AddPermissionsModal
        isAddingChurchMembership={true}
        onClose={hideAddMembershipModal}
        users={[user]}
      />
    ),
    []
  );

  const [showConfirmDeactivateModal, hideConfirmDeactivateModal] = useModal(() => {
    return deactivateMembership ? (
      <ConfirmDeactivate onClose={hideConfirmDeactivateModal} membership={deactivateMembership!} />
    ) : null;
  }, [deactivateMembership]);

  const canEditConstituent = !!authenticatedUser.roles.find((r) =>
    [
      SystemRole.Superadmin.toString(),
      SystemRole.ConferenceAdmin.toString(),
      SystemRole.DistrictAdmin.toString()
    ].includes(r.name)
  );

  return (
    <div className="space-y-4">
      {user.memberships.length === 0 ? (
        <p>Please contact an admin to add a church membership to your profile.</p>
      ) : (
        <div className="flex space-x-4">
          {user.memberships.map((membership) => (
            <Membership key={membership.id}>
              <div
                className={`p-2 space-y-2 ${
                  membership.active ? 'bg-neutral-1000' : 'bg-neutral-800 text-neutral-500'
                }`}
              >
                <div className="flex justify-between">
                  <span className="text-xs font-semibold">Membership</span>
                  {membership.active && (
                    <Can do="deactivate" on="ChurchMembership">
                      <Cancel
                        className="ml-2 mt-0.5 w-3 h-3 cursor-pointer"
                        onClick={() => {
                          setDeactivateMembership(membership);
                          showConfirmDeactivateModal();
                        }}
                      />
                    </Can>
                  )}
                </div>
                <div className="text-sm flex items-center space-x-2">
                  <span>{membership.church.name}</span>
                  {membership.primary && <span className="ml-1 text-neutral-500">(primary)</span>}
                </div>
                <div className="text-sm flex items-center space-x-2">
                  <Input
                    type="checkbox"
                    name="Home"
                    checked={membership.constituent}
                    disabled={!canEditConstituent || !membership.active}
                    onChange={async (e) => {
                      await updateChurchMembership(membership.id, e.target.checked);
                    }}
                  />
                </div>
              </div>
            </Membership>
          ))}
        </div>
      )}
      <Can do="create" on="ChurchMembership">
        <Button className="ml-auto" onClick={showAddMembershipModal}>
          + Add Membership
        </Button>
      </Can>
    </div>
  );
}

type ConfirmDeactivateModal = ModalProps & {
  membership: ChurchMembership;
};

function ConfirmDeactivate({ membership, ...props }: ConfirmDeactivateModal) {
  const { removeChurchMembership } = useUserInfoStore();
  return (
    <Modal name="Confirm deactivate" {...props}>
      {({ close }) => (
        <>
          Are you sure you want to deactivate the {membership.church.name} membership?
          <CardFooter>
            <Button inverted onClick={() => close()}>
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await removeChurchMembership(membership);
                close();
              }}
            >
              Confirm
            </Button>
          </CardFooter>
        </>
      )}
    </Modal>
  );
}
