import { TrackTemplate } from '@gbhem/api';

import { InterviewerDashboard } from '../../components/Interviewers';
import { RoleProvider } from '../../providers';

interface InterviewersProperties {
  track: TrackTemplate;
}

export function Interviewers({ track }: InterviewersProperties) {
  return (
    <RoleProvider>
      <InterviewerDashboard track={track} />
    </RoleProvider>
  );
}

export default Interviewers;
