import {
  FormService,
  Phase,
  StepActionTemplate,
  StepContentTemplate,
  StepService,
  StepTemplate
} from '@gbhem/api';
import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAsync } from 'react-use';

import { Button, ErrorText } from '../../components';
import { SystemRole, useAuthenticatedUser } from '../../providers';
import { Input } from '../Input';
import { StepActionsEditor, StepContentsEditor } from '.';
import StepAttachmentsEditor from './StepAttachmentsEditor';
import StepPrerequisites from './StepPrerequisites';

interface StepEditorProperties {
  phase: Phase;
  step?: StepTemplate;
  readOnly?: boolean;
  isSystemTrack: boolean;
  onSubmit: (step: StepTemplate) => Promise<void>;
}

export function StepEditor({
  phase,
  step: _step,
  readOnly = false,
  isSystemTrack,
  onSubmit
}: StepEditorProperties) {
  const [step, setStep] = useState<Partial<StepTemplate> | undefined>(_step);
  const [apiKey, setApiKey] = useState<string | undefined>();
  const authUser = useAuthenticatedUser();
  const isSuperAdmin = useMemo(
    () => !!authUser.roles.find((r) => [SystemRole.Superadmin.toString()].includes(r.name)),
    [authUser]
  );

  const formContext = useForm({
    defaultValues: step,
    mode: 'all'
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = formContext;

  const [prerequisites, setPrerequisites] = useState(_step ? _step?.prerequisites : []);
  const [contents, setContents] = useState<StepContentTemplate[]>(step?.stepContentTemplates || []);
  const [actions, setActions] = useState<StepActionTemplate[]>(step?.stepActionTemplates || []);
  const [missingContentOrAction, setMissingContentOrAction] = useState<boolean>(false);
  const [missingApprovedRoles, setMissingApprovedRoles] = useState<boolean>(false);

  // we need this in order to handle state in the text editor now
  const [descriptionContent, setDescriptionContent] = useState(step?.description || '');
  const handleDescriptionChange = (change: string) => {
    setDescriptionContent(change);
    setStep({ ...step, description: change });
  };

  useEffect(() => {
    setMissingContentOrAction(contents.length === 0 && actions.length === 0);
    setMissingApprovedRoles(contents.some((c) => c.isPrivate && c.approvedRoles.length === 0));
  }, [contents, actions]);

  useAsync(async () => {
    setApiKey(await FormService.getTinyMceApiKey());

    if (!step?.id) {
      setStep({
        name: '',
        description: '',
        required: false
      });

      return;
    }

    const _step = await StepService.findOne(step.id, [
      'stepActionTemplates',
      'stepContentTemplates',
      'prerequisites',
      'attachments',
      'denials'
    ]);

    setContents(_step.stepContentTemplates);

    setActions(_step.stepActionTemplates);
    setPrerequisites(_step.prerequisites);

    setStep(_step);
  }, []);

  if (!step || !apiKey) {
    return <></>;
  }

  return (
    <FormProvider {...formContext}>
      <div className="space-y-8">
        <div className="space-y-2">
          <Input
            type="checkbox"
            name="Required"
            checked={step.required}
            disabled={readOnly}
            onChange={(e) => setStep({ ...step, required: e.target.checked })}
          />
          <Input
            disabled={readOnly}
            label="Step Name"
            required
            /**
             * TODO: quinn.pommerening@fortyau.com
             * Fix the ts-ignore by creating an Omit type for the register.
             * Look at StepContentPrivacyEditor.tsx around line 49 for example.
             * **/
            //@ts-ignore
            {...register('name', { required: 'required' })}
          />
          <div className="mt-2">
            <Editor
              apiKey={apiKey}
              disabled={readOnly}
              init={{
                height: 200,
                branding: false,
                menubar: false,

                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'table',
                  'code',
                  'help',
                  'wordcount'
                ],
                toolbar:
                  'undo redo | ' +
                  'bold italic backcolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help |' +
                  'link'
              }}
              value={descriptionContent}
              onEditorChange={(newValue) => handleDescriptionChange(newValue)}
            />
          </div>
        </div>
        <StepPrerequisites
          phase={phase}
          readOnly={!isSuperAdmin && isSystemTrack}
          step={step as StepTemplate}
          prerequisites={prerequisites}
          onChange={setPrerequisites}
        />
        <hr />
        <StepAttachmentsEditor
          attachments={step.attachments || []}
          onChange={(attachments) => setStep({ ...step, attachments })}
        />
        <hr />
        <div className="space-y-2">
          <div className="font-semibold">Content</div>
          <div className="text-xs text-neutral-500">
            Choose from the content types below to add them to this step. Once selected, you can
            configure the details.
          </div>
          <StepContentsEditor contents={contents} readOnly={readOnly} onChange={setContents} />
        </div>
        <hr />
        <div className="space-y-2">
          <div className="font-semibold">Actions</div>
          <div className="text-xs text-neutral-500">
            Choose from the action types below to add them to this step. Once selected, you can
            configure who should perform this action.
          </div>
          <StepActionsEditor actions={actions} readOnly={readOnly} onChange={setActions} />
        </div>
        {!isValid && <ErrorText>Not all contents have been completed!</ErrorText>}
        {(isSuperAdmin || !isSystemTrack) && (
          <>
            {missingContentOrAction && <ErrorText>Content or Actions are required</ErrorText>}
            {missingApprovedRoles && (
              <ErrorText>Please provide private content with approved roles.</ErrorText>
            )}
            <Button
              disabled={isSubmitting || !isValid || missingContentOrAction || missingApprovedRoles}
              onClick={handleSubmit(async (changes) => {
                await onSubmit({
                  ..._step,
                  ...changes,
                  required: step.required,
                  description: step.description,
                  prerequisites,
                  stepContentTemplates: contents,
                  stepActionTemplates: actions
                } as StepTemplate);
              })}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </FormProvider>
  );
}

export default StepEditor;
