import { Jurisdiction, JurisdictionService } from '@gbhem/api';
import { Column } from 'react-table';

import { Table } from '../../components';

const columns = [
  {
    Header: 'NAME',
    accessor: 'name',
    id: 'name',
    disableSortBy: true,
    Cell: ({ value }) => value
  }
] as Column<Jurisdiction>[];

export function Jurisdictions() {
  const initialPageSize = 10;
  const initialPageIndex = 0;

  return (
    <Table
      columns={columns}
      data={[]}
      pageIndex={initialPageIndex}
      pageSize={initialPageSize}
      count={0}
      loader={async (props) => {
        const quickSearch = await JurisdictionService.quickSearch(
          'Jurisdiction',
          props.size,
          props.page * props.size,
          [],
          props.isQuickSearch,
          props.orderBy,
          props.filters
        );
        return { data: quickSearch.data, count: quickSearch.count };
      }}
    />
  );
}

export default Jurisdictions;
