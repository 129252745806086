import { TaskTemplate } from '@gbhem/api';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useEffectOnce, useUnmount } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import { Input, TextArea } from '../Input';

export interface TaskEditorProperties {
  task: TaskTemplate;
  readOnly?: boolean;
  onChange: (task: TaskTemplate) => void;
}

export function TaskEditor({ task, readOnly = false, onChange }: TaskEditorProperties) {
  const { name, description } = task;
  const {
    register,
    unregister,
    clearErrors,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [nameField, setNameField] = useState('Task Name');
  const [descriptionField, setDescriptionField] = useState('Task Description');

  useEffectOnce(() => {
    setValue(nameField, task.name ?? '');
    setValue(descriptionField, task.description ?? '');

    clearErrors([nameField, descriptionField]);

    if (!readOnly) {
      setNameField(uuidv4());
      setDescriptionField(uuidv4());
    }
  });

  useUnmount(() => {
    clearErrors([nameField, descriptionField]);
    unregister([nameField, descriptionField]);
  });

  return (
    <div className="flex flex-col space-y-4">
      <Input
        label="Name"
        required={true}
        error={errors[nameField]?.message}
        {...register(nameField, { required: { value: true, message: 'required' } })}
        placeholder="Give the task a meaningful name..."
        disabled={readOnly}
        value={name}
        onChange={(e) => {
          if (e.target.value) {
            clearErrors(nameField);
          }
          onChange({ ...task, name: e.target.value });
        }}
      />

      <TextArea
        label="Description"
        required={true}
        error={errors[descriptionField]?.message}
        {...register(descriptionField, { required: { value: true, message: 'required' } })}
        placeholder="Type a specific description here..."
        disabled={readOnly}
        value={description}
        onChange={(e) => {
          if (e.target.value && e.target.value.length > 0) {
            clearErrors(descriptionField);
          }
          onChange({ ...task, description: e.target.value });
        }}
      />
    </div>
  );
}

export default TaskEditor;
