import styled from '@emotion/styled';
import tw from 'twin.macro';

import { Remove as RemoveIcon } from '..';
import { Input, Select } from '../Input';

const SelectGridWrapper = styled.div`
  ${tw`col-span-1`}
`;

const InputGridWrapper = styled.div`
  ${tw`col-span-3`}
`;

type CommentProps = {
  comment: string;
  type: string;
  editComment: (comment: string) => void;
  editType: (select: unknown) => void;
  remove: (e: any) => void;
  error?: string | string[];
};

export function Comment({
  comment,
  type: commentType,
  editComment,
  editType,
  remove,
  error
}: CommentProps) {
  return (
    <>
      <SelectGridWrapper>
        <Select
          name="Comment Type"
          value={commentType}
          onChange={(e) => editType(e.target.value)}
          options={['Behavior', 'Character', 'Performance', 'Other']}
          error={error}
        />
      </SelectGridWrapper>
      <InputGridWrapper>
        <Input
          name="Comment"
          value={comment}
          onChange={(e) => {
            editComment(e.target.value);
          }}
          error={error}
        />
      </InputGridWrapper>
      <RemoveIcon
        className="h-6 w-6 mt-5 col-start-5 col-span-1 text-neutral-500 cursor-pointer"
        onClick={(e) => remove(e)}
      />
    </>
  );
}

export default Comment;
