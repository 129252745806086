import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import tw from 'twin.macro';

import { OptionType } from './Select';

const Element = styled.div`
  ${tw`relative mt-1 overflow-y-auto border rounded-sm shadow-md bg-neutral-900 border-neutral-800 max-h-80 mb-4 font-normal text-left`}

  ::-webkit-scrollbar {
    ${tw`w-2`}
  }

  ::-webkit-scrollbar-thumb {
    ${tw`w-1 cursor-pointer bg-neutral-600`}
  }

  ::-webkit-scrollbar-track {
    ${tw`shadow-md bg-neutral-800`}
  }
`;

const Options = styled.div<{ disabled?: boolean }>`
  ${(p) => p.disabled && tw`cursor-not-allowed`}

  ${(p) =>
    p.disabled &&
    css`
      > * {
        ${tw`text-neutral-300 bg-neutral-700 pointer-events-none`}
      }
    `}
`;

const Option = styled.div<{ selected?: boolean }>`
  ${tw`text-sm p-2 cursor-pointer hover:text-neutral-1000`}
  ${(p) => (p.selected ? tw`bg-secondary` : '')}

  :hover {
    background-color: #0061b7;
  }
`;

interface SelectMenuProperties<ValueType> {
  open?: boolean;
  options?: OptionType<ValueType>[];
  selection: string;
  onSelect: (option: OptionType<ValueType>) => void;
}

export function Menu<ValueType>({
  open,
  options,
  selection,
  onSelect
}: SelectMenuProperties<ValueType>) {
  const menu = useRef<HTMLDivElement>(null);

  useEffectOnce(() => {
    if (!menu.current || !selection) {
      return;
    }

    const option = options?.findIndex((o) => o.label === selection);
    if (!option) {
      return;
    }

    const element = menu.current.children[option] as HTMLElement;
    if (!element) {
      return;
    }

    menu.current.scrollTo({
      top: element.offsetTop < menu.current.clientHeight ? 0 : element.offsetTop
    });
  });

  useEffect(() => {
    // if duplicate label exists
    if (options && options?.length !== new Set(options?.map((o) => o.label)).size) {
      console.warn(
        'WARNING: Duplicate labels in select.\n\tRemove duplicate labels in the select component to get rid of duplicate key warnings'
      );
    }
  }, [options]);

  if (!open) {
    return <></>;
  }

  if (!options) {
    return (
      <Element>
        <div className="p-2 text-center text-sm text-neutral-500">Loading options...</div>
      </Element>
    );
  }

  if (!options.length) {
    return (
      <Element>
        <Option>No options found.</Option>
      </Element>
    );
  }

  return (
    <Element ref={menu}>
      {options.map((o) => (
        <Options key={o.label} disabled={o.disabled}>
          <Option key={o.label} onClick={() => onSelect(o)} selected={o.label === selection}>
            {o.label}
          </Option>
        </Options>
      ))}
    </Element>
  );
}

export default Menu;
