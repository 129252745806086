import styled from '@emotion/styled';
import { ComponentProps, forwardRef, memo } from 'react';
import tw from 'twin.macro';

import { CheckmarkThick, ErrorText } from '..';

export interface InputMetadataProps {
  error?: string | string[];
  inline?: boolean;
  instructions?: string;
  placeholder?: string;
  label?: string;
}

export type InputProps = Omit<ComponentProps<'input'>, 'onError'> & InputMetadataProps;

export const Label = styled.label<{ inline?: boolean }>`
  ${tw`block p-0 text-xs`}
  ${(p) => (p.inline ? '' : tw`mb-1`)}
`;

const Element = styled.div<{ hidden?: boolean; type?: string }>`
  ${tw`flex`}

  ${(p) => (p.hidden ? tw`hidden` : '')}
  ${(p) =>
    p.type === 'checkbox'
      ? tw`space-x-2 space-x-reverse flex-row-reverse items-center justify-end`
      : tw`flex-col`}
`;

const ControlWrapper = styled.div`
  ${tw`relative`}
`;

export const Control = styled.input<{ error?: boolean; inline?: boolean }>`
  ${tw`w-full px-2 py-1 border rounded-sm outline-none bg-neutral-900 text-sm transition-all
  duration-100`}

  &[disabled] {
    ${tw`bg-neutral-800 cursor-not-allowed`}
  }

  &[type='checkbox'] {
    ${tw`flex appearance-none h-4 w-4 p-0 border-2 focus:border-neutral-500`}

    :checked {
      ${tw`border-primary bg-secondary`}
    }
  }

  ${(p) => (p.error ? tw`border-pink` : tw`border-neutral-500 focus:border-primary`)}
`;

const Nudge = styled.span`
  font-size: 0.6rem;
  ${tw`ml-1 text-red-400`}
`;

const Instructions = styled.div`
  ${tw`text-xs my-1 text-neutral-400`}
`;

export const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(
    ({ error, instructions, children, inline, hidden, label, ...props }, ref) => {
      let errors: string[] = [];
      if (error?.length) {
        errors = Array.isArray(error) ? error : [error];
      }

      return (
        <Element hidden={hidden} type={props.type} className={props.className}>
          {!inline && label && (
            <Label inline={props.type === 'checkbox'}>
              {label} {props.required && <Nudge>* Required</Nudge>}
            </Label>
          )}
          {!inline && !label && props.name && (
            <Label inline={props.type === 'checkbox'}>
              {props.name} {props.required && <Nudge>* Required</Nudge>}
            </Label>
          )}
          {instructions && <Instructions>{instructions}</Instructions>}
          <ControlWrapper>
            {props.type === 'checkbox' && !!(props.checked || props.value) ? (
              <CheckmarkThick className="absolute text-primary top-0 left-0.5 h-full w-3 pointer-events-none" />
            ) : null}
            <Control
              autoComplete="off"
              {...props}
              ref={ref}
              error={errors.length > 0}
              inline={inline}
              hidden={hidden}
            />
            {children}
          </ControlWrapper>
          {errors.map((error) => (
            <ErrorText key={error}>{error}</ErrorText>
          ))}
        </Element>
      );
    }
  )
);

export default Input;
